import React, { ReactElement, useEffect, useState } from 'react'

import FavoriteFoodOverviewCard from './FavoriteFoodOverviewCard'
import FavoriteMotionCardContainer from './FavoriteMotionCardContainer'
import FavoriteRecipeOverviewCard from './FavoriteRecipeOverviewCard'
import { FavoriteTabNames } from './helpers/FavoriteTabNames'
import { getFavoriteEatablesSectionList, getFavoriteMotionsSectionList } from './helpers/getFavoriteSectionList'

import { PageLayout, SectionList } from 'Components'
import TabSwitcher from 'Components/TabSwitcher/TabSwitcher'
import {
  FavoritesFood,
  FavoritesMapping,
  FavoritesMotionLevel,
  FavoritesRecipe,
  QuantifiedFood,
  QuantifiedRecipe
} from 'Models'
import FoodModal from 'Pages/JournalPage/components/Modals/FoodModal/FoodModal'
import RecipeModal from 'Pages/JournalPage/components/Modals/RecipeModal/RecipeModal'
import { currentWeightSelector, fetchLatestBodyMeasurementsAction } from 'ReduxStore/bodyMeasurements'
import {
  favoriteSystemEatablesSelector,
  favoriteUserEatablesSelector,
  favoritesSelector
} from 'ReduxStore/favorites/favorites'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'

const FavoritesOverviewPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentWeight = useAppSelector(currentWeightSelector)
  const [detailFood, setDetailFood] = useState<QuantifiedFood>()
  const [detailRecipe, setDetailRecipe] = useState<QuantifiedRecipe>()

  const [currentTabName, setCurrentTabName] = useState(FavoriteTabNames.FOODS)
  const favoritesMotionLevels = useAppSelector(favoritesSelector<FavoritesMotionLevel>(FavoritesMapping.MOTIONS))
  const favoriteUserEatables = useAppSelector(favoriteUserEatablesSelector)

  const favoriteSystemFoods = useAppSelector(favoriteSystemEatablesSelector<FavoritesFood>(FavoritesMapping.FOODS))
  const favoriteSystemRecipes = useAppSelector(
    favoriteSystemEatablesSelector<FavoritesRecipe>(FavoritesMapping.RECIPES)
  )

  useEffect(() => {
    if (!currentWeight) {
      dispatch(fetchLatestBodyMeasurementsAction({ type: 'WEIGHT' }))
    }
  }, [])

  const renderEatables = (): JSX.Element => {
    const renderCard = (favorite: FavoritesFood | FavoritesRecipe): ReactElement => {
      return 'food' in favorite ? (
        <FavoriteFoodOverviewCard
          favorite={favorite}
          onClick={() => setDetailFood({ food: favorite.food, quantity: favorite.quantity, unit: favorite.unit })}
        />
      ) : (
        <FavoriteRecipeOverviewCard
          favorite={favorite}
          recipe={favorite.recipe}
          onClick={() => setDetailRecipe({ recipe: favorite.recipe, quantity: favorite.quantity, unit: favorite.unit })}
        />
      )
    }

    const sectionList = getFavoriteEatablesSectionList({
      key: currentTabName,
      favoriteSystemFoods,
      favoriteSystemRecipes,
      favoriteUserEatables
    })

    return <SectionList<FavoritesFood | FavoritesRecipe> sectionList={sectionList} renderCard={renderCard} />
  }

  const renderMotions = (): JSX.Element => {
    const renderCard = (favorite: FavoritesMotionLevel): ReactElement => (
      <FavoriteMotionCardContainer favorite={favorite} weight={currentWeight} />
    )

    return (
      <SectionList<FavoritesMotionLevel>
        sectionList={getFavoriteMotionsSectionList(favoritesMotionLevels)}
        renderCard={renderCard}
      />
    )
  }

  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <h2>Favoriten</h2>
        <p>
          Hier finden Sie alle Ihre Favoriten. Sie können Menge und Einheit ändern und so die für Sie ideale Einheit
          speichern.
        </p>
        <TabSwitcher
          onTabSwitch={(tabName) => setCurrentTabName(tabName as FavoriteTabNames)}
          tabs={[
            {
              name: FavoriteTabNames.FOODS,
              title: 'Nahrungsmittel',
              renderContent: renderEatables()
            },
            {
              name: FavoriteTabNames.RECIPES,
              title: 'Rezepte',
              renderContent: renderEatables()
            },
            {
              name: FavoriteTabNames.USER_EATABLES,
              title: 'Mein Essen',
              renderContent: renderEatables()
            },
            {
              name: FavoriteTabNames.MOTIONS,
              title: 'Aktivitäten',
              renderContent: renderMotions()
            }
          ]}
        />
      </div>
      <FoodModal quantifiedFood={detailFood} onClose={() => setDetailFood(undefined)} />
      <RecipeModal quantifiedRecipe={detailRecipe} onClose={() => setDetailRecipe(undefined)} />
    </PageLayout>
  )
}

export default FavoritesOverviewPage
