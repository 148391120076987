import React, { useMemo } from 'react'

import { Table } from 'Components/Table'
import { Nutritions } from 'Models'
import { convertNutritionsToTableArray } from 'Utils/helpers/nutritionHelper'

type Props = {
  nutritionsPer100g?: Nutritions
  nutritionsPerPortion?: Nutritions
}

export const TableNutrition: React.FC<Props> = ({ nutritionsPerPortion, nutritionsPer100g }: Props) => {
  const titleRows = [
    '',
    'Nährwertangaben',
    ...(nutritionsPer100g ? ['100 g'] : []),
    ...(nutritionsPerPortion ? ['1 Port.'] : [])
  ]
  const dataRows = useMemo(() => convertNutritionsToTableArray(nutritionsPer100g, nutritionsPerPortion), [
    nutritionsPerPortion,
    nutritionsPer100g
  ])

  return <Table theme="nutrition" titleRow={titleRows} rows={dataRows} />
}
