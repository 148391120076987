import { addFavoritesAction, isLoadingFavoritesSelector, removeFavoritesAction } from './favorites'

import { AddFavoritesUnion, FavoritesStateObjectsUnion } from 'Models'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'

export const useAddOrRemoveFavorite = (): {
  onFavoriteChange: (favorite: FavoritesStateObjectsUnion | undefined, payload: AddFavoritesUnion) => void
} => {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(isLoadingFavoritesSelector)
  const onFavoriteChange = (favorite: FavoritesStateObjectsUnion | undefined, payload: AddFavoritesUnion): void => {
    if (isLoading) {
      return
    }

    if (favorite) {
      dispatch(removeFavoritesAction(favorite))
    } else {
      dispatch(addFavoritesAction(payload))
    }
  }
  return { onFavoriteChange }
}
