import React, { FC, useState } from 'react'
import Modal from 'react-modal'
import { ConnectedProps, connect } from 'react-redux'

import { Button, FormInputNumber, FormInputSelect, FormInputText, IconButton, IconName } from 'Components'
import { JournalEnergyRecord, MealCategory, allMealCategories } from 'Models'
import { updateJournalEnergyRecordAction } from 'ReduxStore/journalEnergyRecords'
import { Formatter } from 'Utils'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

const mapDispatchToProps = {
  updateJournalEnergyRecord: updateJournalEnergyRecordAction
}

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  isOpen: boolean
  onToggle: () => void
  journalEnergyRecord?: JournalEnergyRecord
  addJournalEnergyRecord?: (description: string, energy: number, mealCategory: MealCategory) => void
  resetOnToggle?: boolean
}

const EnergyModal: FC<Props> = ({
  isOpen,
  onToggle,
  resetOnToggle = false,
  journalEnergyRecord,
  addJournalEnergyRecord,
  updateJournalEnergyRecord
}: Props) => {
  const [mealCategory, setMealCategory] = useState<MealCategory>(
    (journalEnergyRecord && journalEnergyRecord.mealCategory) || 'BREAKFAST'
  )

  const [energy, setEnergy] = useState<number | undefined>(
    (journalEnergyRecord && journalEnergyRecord.energy) || undefined
  )

  const [description, setDescription] = useState(() => (journalEnergyRecord && journalEnergyRecord.description) || '')

  const onClose = (): void => {
    if (resetOnToggle) {
      setMealCategory('BREAKFAST')
      setEnergy(undefined)
      setDescription('')
    }
    onToggle()
  }

  const onSubmit = (): void => {
    if (energy && energy > 0 && description && description.length > 0 && mealCategory) {
      if (!journalEnergyRecord) {
        addJournalEnergyRecord && addJournalEnergyRecord(description, energy, mealCategory)
      } else {
        updateJournalEnergyRecord({
          id: journalEnergyRecord.id,
          energy,
          description,
          mealCategory
        })
      }
    }
    onClose()
  }

  return (
    <Modal style={modalZIndexPriority} isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
      <div className="modal is-small">
        <IconButton name={IconName.close} onClick={onClose} />
        <div className="row">
          <div className="col-12">
            <h2>Direkter Eintrag</h2>
          </div>
          <div className="col-12 col-lg-6">
            <FormInputSelect
              name="mealCategory"
              label="Mahlzeit"
              items={allMealCategories}
              selectedKey={mealCategory}
              labelExtractor={(value) => Formatter.formatMealCategory(value as MealCategory)}
              onChange={(value) => setMealCategory(value as MealCategory)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <FormInputNumber name="energy" label="kcal" value={energy} onChange={setEnergy} />
          </div>
          <div className="col-12">
            <FormInputText name="description" label="Beschreibung" value={description} onChange={setDescription} />
          </div>
          <div className="col-12">
            <Button
              id="submitJournalEnergyRecord"
              onClick={onSubmit}
              disabled={energy === undefined || description === '' ? true : false}
            >
              {journalEnergyRecord ? 'Speichern' : 'Hinzufügen'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default connector(EnergyModal)
