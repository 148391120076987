import { ChangeEvent } from 'react'

import { SelectItemKeyExtractor, SelectItemType } from './SelectPickerProps'

type SelectChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void

export const useSingleSelect = function <Item extends SelectItemType>(
  items: Readonly<Item[]>,
  keyExtractor: SelectItemKeyExtractor<Item>,
  onChange?: (item: Item) => void
): SelectChangeHandler {
  const itemsByKeys = (): Map<string, Item> => {
    const data: Map<string, Item> = new Map()
    items.forEach((item) => data.set(keyExtractor(item), item))
    return data
  }

  return (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const key = event.target.value
    onChange && onChange(itemsByKeys().get(key) as Item)
  }
}
