import { ResourceReduxState } from 'Models/Common'
import { ProfessionalMotionLevel } from 'yup-schema/Schemas/anamnesis'

export enum EnergyLevelNames {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export type EnergyLevelName = `${EnergyLevelNames}`

export const EnergyLevels: { [K in EnergyLevelName]: { name: EnergyLevelName; position: number } } = {
  HIGH: { name: 'HIGH', position: 1 },
  MEDIUM: { name: 'MEDIUM', position: 2 },
  LOW: { name: 'LOW', position: 3 }
}

export type FetchEnergyRequirementsPayload = {
  age: number
  gender: 'MALE' | 'FEMALE' | null
  height: number
  overweightForTwoYears: boolean
  parentsOverweight: boolean
  previousFailureCount: number
  professionalMotionLevel: ProfessionalMotionLevel
  weight: number
  weightToLose: number
  workingHours: number
}

export type EnergyRequirementsPlan = {
  days: number
  goal: number
  level: {
    percentage: number
    name: EnergyLevelName
  }
}

export type EnergyRequirements = {
  activeMetabolicRate: number
  basalMetabolicRate: number
  bmi: number
  energyPlans: EnergyRequirementsPlan[]
  maxEnergyReduction: number
}

export type EnergyRequirementsReduxType = EnergyRequirements | null

export type EnergyRequirementsReduxState = ResourceReduxState<EnergyRequirementsReduxType>
