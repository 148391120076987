import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { DayModifiers } from 'react-day-picker'

import { FormInputText } from '../FormInputText'

import { DayPicker } from './DayPicker'

import { fromMonth, toMonth } from 'Components/DatePicker/YearMonthForm'
import { DayPickerRange } from 'Models'
import { Formatter } from 'Utils'

dayjs.extend(customParseFormat)
dayjs.extend(isBetween)

export type DatePickerProps = {
  label: string
  value: Date
  format?: string
  onChange?: (value: Date) => void
  error?: string
  range?: DayPickerRange
  ignoreInternalErrors?: boolean
  inputRef?: RefObject<HTMLInputElement>
}

const defaultInputFormat = 'D.M.YYYY'
const defaultOutputFormat = 'DD.MM.YYYY'

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value,
  format,
  onChange,
  error,
  range,
  ignoreInternalErrors = false,
  inputRef
}: DatePickerProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [inputText, setInputText] = useState<string>(() => Formatter.formatDate(value, format, defaultOutputFormat))
  const [errorText, setErrorText] = useState(error)

  const DayPickerRef = useRef<HTMLDivElement>(null)

  const handleHideDatePicker = useCallback(
    (e) => {
      if (e && e.target && !(DayPickerRef.current && DayPickerRef.current.contains(e.target))) {
        setShowDatePicker(false)
      }
    },
    [showDatePicker]
  )

  useEffect(() => {
    if (showDatePicker) {
      document.addEventListener('mousedown', handleHideDatePicker, false)
    } else {
      document.removeEventListener('mousedown', handleHideDatePicker, false)
    }
    return () => document.removeEventListener('mousedown', handleHideDatePicker, false)
  }, [showDatePicker])

  useEffect(() => {
    if (error !== undefined) {
      setErrorText(error)
    }
  }, [error])

  useEffect(() => {
    value === undefined && setInputText('')
  }, [value])

  const handleDayClick = useCallback(
    (date: Date, dayModifiers: DayModifiers) => {
      if (!dayModifiers.disabled) {
        setErrorText('')
        setShowDatePicker(false)
        setInputText(Formatter.formatDate(date, format, defaultOutputFormat))
        onChange && onChange(date)
      }
    },
    [onChange]
  )

  const buildErrorMessage = (): string => {
    let template = 'Das Datum ist ausserhalb des erlaubten Zeitraums.'
    if (range && range.from && range.to) {
      template = `Das Datum muss Zeitraum zwischen dem $from und dem $to liegen.`
    } else if (range?.from) {
      template = `Das Datum darf nicht vor dem $from liegen.`
    } else if (range?.to) {
      template = `Das Datum darf nicht nach dem $to liegen.`
    }
    return template
      .replace('$from', Formatter.formatDate(range?.from || fromMonth, format, defaultOutputFormat))
      .replace('$to', Formatter.formatDate(range?.to || toMonth, format, defaultOutputFormat))
  }

  const handleInputChange = useCallback(
    (input: string) => {
      const newDate = dayjs(input, defaultInputFormat)
      const dateRangeIsValid =
        range && newDate.isValid() ? newDate.isBetween(range.from || fromMonth, range.to || toMonth, 'day', '[]') : true
      if (!dateRangeIsValid && !ignoreInternalErrors) {
        setErrorText(buildErrorMessage())
        setShowDatePicker(false)
      } else {
        setErrorText('')
        setShowDatePicker(true)
        onChange && newDate.isValid() && onChange(newDate.toDate())
      }
      setInputText(input)
    },
    [onChange]
  )

  const handleDisplayDatePicker = useCallback(() => {
    setShowDatePicker(true)
  }, [showDatePicker])

  return (
    <div className="DayPickerRef" ref={DayPickerRef}>
      <FormInputText
        label={label}
        value={inputText}
        onFocus={handleDisplayDatePicker}
        onChange={handleInputChange}
        // onBlur={() => setShowDatePicker(false)}
        inputRef={inputRef}
      />
      <DayPicker date={value} isDatePickerDisplayed={showDatePicker} onDayClick={handleDayClick} rangeAllowed={range} />
      {errorText && <div className="input-error">{errorText}</div>}
    </div>
  )
}
