import { useKeycloak } from '@react-keycloak/web'
import React, { ComponentType, FC } from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'

import { LoadingIndicator } from 'Components'
import { onLogin } from 'Config'

type PrivateRouteProps = Omit<RouteProps, 'component'> & {
  component: ComponentType<RouteComponentProps<never>> | ComponentType<Record<string, string>>
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const { keycloak } = useKeycloak()
  const isAuthenticated = keycloak && keycloak.authenticated
  if (!isAuthenticated) {
    onLogin()
  } else {
    return <Route {...rest} component={Component} />
  }

  return <LoadingIndicator />
}
