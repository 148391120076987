import React, { FC } from 'react'
import { Highlight } from 'react-instantsearch-dom'

import { IngredientSearchHit } from './IngredientSearchHit'

import { UserEatableSearchHit } from 'Components/Search/searchHelpers'
import { Food } from 'Models'
import { AlgoliaFoodSearchHit } from 'Utils/algoliaSearchHelpers'

type Props = {
  userFoodHits?: UserEatableSearchHit<Food>[]
  hits: AlgoliaFoodSearchHit[]
  selectedFoodIds: string[]
  onHitClick?: (hit: AlgoliaFoodSearchHit | UserEatableSearchHit<Food>) => void
}

type EventTargetWithDataset = EventTarget & {
  dataset?: DOMStringMap
}

export const IngredientSearchInfiniteHits: FC<Props> = ({
  userFoodHits = [],
  hits: algoliaHits,
  selectedFoodIds,
  onHitClick
}) => {
  const combinedHits = [...userFoodHits, ...algoliaHits]
  return (
    <ul
      className="inlinesearch-dropdown"
      role="listbox"
      onClick={({ target }) => {
        const { dataset } = target as EventTargetWithDataset
        if (!dataset) return
        if (!dataset.foodId) return
        const hit = combinedHits.find((h) => h.id === dataset.foodId)
        if (!hit) return
        onHitClick?.(hit)
      }}
    >
      {combinedHits.map((hit) => (
        <IngredientSearchHit
          key={hit.id}
          foodId={hit.id}
          title={hit.name}
          checked={selectedFoodIds.includes(hit.id)}
          renderTitle={hit._highlightResult && (() => <Highlight attribute="name" tagName="mark" hit={hit} />)}
        />
      ))}
    </ul>
  )
}
