import { combineReducers } from 'redux'
import { FluxStandardAction } from 'redux-promise-middleware'

import { FoodCategoriesById, FoodCategoriesReduxState, FoodCategory, PromiseAction, ReduxStoreState } from 'Models'
import { FETCH_FOOD_CATEGORIES, FETCH_FOOD_CATEGORIES_FULFILLED } from 'ReduxStore/actionTypes'
import { createPendingCounterReducer } from 'ReduxStore/common'
import { coreBackendService } from 'Services'

// Reducer
const byId = (state: FoodCategoriesById = {}, { type, payload }: FluxStandardAction): FoodCategoriesById => {
  switch (type) {
    case FETCH_FOOD_CATEGORIES_FULFILLED:
      return (payload || []).reduce(
        (acc: FoodCategoriesById, cat: FoodCategory) => ({
          ...acc,
          ...(!cat.parentId
            ? {
                [cat.id]: {
                  ...cat,
                  subCategories: payload.filter(({ parentId }: FoodCategory) => parentId === cat.id)
                }
              }
            : {})
        }),
        {}
      )
    default:
      return state
  }
}

// Actions
export const fetchFoodCategoriesAction = (): PromiseAction<FoodCategory[]> => ({
  type: FETCH_FOOD_CATEGORIES,
  payload: coreBackendService.fetchFoodCategories()
})

// Selectors
export const foodCategoriesSelector = ({ foodCategories }: ReduxStoreState): FoodCategory[] =>
  Object.values(foodCategories.byId).filter((cat) => cat.subCategories && cat.subCategories.length > 0)

export default combineReducers<FoodCategoriesReduxState>({
  byId,
  pendingCounter: createPendingCounterReducer(FETCH_FOOD_CATEGORIES)
})
