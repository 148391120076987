import Highcharts from 'highcharts'

import { colors } from 'Config'

export default function getOptions(): Highcharts.Options {
  return {
    chart: {
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        shadow: {
          color: colors.lineChart.shadow,
          offsetX: -2,
          offsetY: 5
        },
        color: colors.lineChart.primaryColor
      },
      line: {
        color: colors.lineChart.primaryColor,
        lineWidth: 1.5,
        marker: {
          fillColor: colors.lineChart.marker,
          lineColor: colors.lineChart.primaryColor,
          lineWidth: 1.5,
          radius: 6
        }
      }
    },
    credits: {
      enabled: false
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineColor: colors.lineChart.gridLines,
      tickColor: colors.lineChart.gridLines,
      tickmarkPlacement: 'on',
      maxPadding: 0.05,
      lineWidth: 0,
      labels: {
        rotation: 0,
        style: {
          color: colors.lineChart.gridLabels
        }
      }
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: colors.lineChart.gridLines,
      tickColor: colors.lineChart.gridLines,
      tickmarkPlacement: 'on',
      maxPadding: 0.05,
      lineWidth: 0,
      labels: {
        rotation: 0,
        style: {
          color: colors.lineChart.gridLabels
        }
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      style: {
        color: colors.lineChart.tooltipColor,
        fontSize: '16',
        letterSpacing: 0,
        lineHeight: 22
      },
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: colors.lineChart.tooltipBackground,
      shadow: {
        color: colors.lineChart.shadow,
        offsetX: 0,
        offsetY: 0,
        opacity: 0.1,
        width: 8
      },
      pointFormat: '{point.y}',
      outside: true,
      xDateFormat: '%d. %B'
    }
  }
}
