import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { ConnectedProps, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { CreateRecipePage } from './CreateRecipePage'
import { RecipeFormValuesType, mapValuesToRecipeData } from './recipeFormHelpers'
import { recipeFormValidationSchema } from './recipeFormSchema'

import { routes } from 'Config'
import { EatableSource, ReduxStoreState } from 'Models'
import { eatableSourcesSelector, fetchEatableSourcesAction } from 'ReduxStore/eatableSources'
import { addUserRecipeAction } from 'ReduxStore/userRecipes'
import { searchClient } from 'Utils/algoliaSearchHelpers'
import { useImageUploading } from 'Utils/helpers/useImageUploading'

type SelectorProps = {
  eatableSources: EatableSource[]
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  eatableSources: eatableSourcesSelector
})

const mapDispatchToProps = {
  addUserRecipe: addUserRecipeAction,
  fetchEatableSources: fetchEatableSourcesAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const CreateRecipePageContainer: FC<Props> = ({ eatableSources, addUserRecipe, fetchEatableSources }: Props) => {
  const { isImageUploading, setTempImageId, setIsImageUploading, onImageSubmit, onPageExit } = useImageUploading({})
  const {
    register,
    control,
    errors,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting }
  } = useForm<RecipeFormValuesType>({
    resolver: yupResolver(recipeFormValidationSchema)
  })

  const history = useHistory()

  useEffect(() => {
    if (eatableSources.length === 0) {
      fetchEatableSources()
    }

    return () => {
      onPageExit()
    }
  }, [])

  const onSubmit = handleSubmit((values) => {
    addUserRecipe(mapValuesToRecipeData(values))
      //@ts-ignore
      .then(async ({ value }) => {
        await onImageSubmit(values.imageUrl)
        history.push(routes.myEatables.myRecipeView(value.id))
      })
      .catch((errors: FieldErrors<RecipeFormValuesType>) => {
        console.error(errors)
      })
  })

  return (
    <CreateRecipePage
      algoliaSearchClient={searchClient}
      isImageUploading={isImageUploading}
      setIsImageUploading={setIsImageUploading}
      setTempImageId={setTempImageId}
      onSubmit={onSubmit}
      onCancel={history.goBack}
      isSubmitting={isSubmitting}
      setValue={setValue}
      setError={setError}
      register={register}
      control={control}
      errors={errors}
      eatableSources={eatableSources}
    />
  )
}

export default connector(CreateRecipePageContainer)
