import React, { ReactElement } from 'react'

type Props<T> = {
  sectionList: { header: string; data: T[] }[]
  renderCard: (value: T) => ReactElement
}

export const SectionList = <T,>({ sectionList, renderCard }: Props<T>): ReactElement => {
  return (
    <>
      {sectionList.map(({ header, data }) => {
        return (
          <>
            <div className="mt-2 mb-1 text-gray-2">{header}</div>
            <div>{((data as unknown) as T[]).map(renderCard)}</div>
          </>
        )
      })}
    </>
  )
}
