import React, { FC, useMemo } from 'react'

import { ChartPie } from '../ChartPie'

import { Nutritions, nutritionsConfig } from 'Models'
import { displayNutritionsChart } from 'Utils/helpers/nutritionHelper'

interface Props {
  nutritionsPerWeight: Partial<Nutritions>
}

const chartPieColors: string[] = displayNutritionsChart.map((key) => nutritionsConfig[key].color) as string[]

export const DetailCardChart: FC<Props> = ({ nutritionsPerWeight }: Props) => {
  const chartData: [string, number][] = useMemo(() => {
    return displayNutritionsChart.map((key) => [nutritionsConfig[key].label, nutritionsPerWeight[key] || 0])
  }, [nutritionsPerWeight])

  return <ChartPie data={chartData} colors={chartPieColors} />
}
