import { combineReducers } from 'redux'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'
import { createSelector } from 'reselect'

import {
  FETCH_ENERGY_DISTRIBUTION,
  FETCH_ENERGY_DISTRIBUTION_FULFILLED,
  FETCH_ENERGY_HISTORY,
  FETCH_ENERGY_HISTORY_FULFILLED,
  FETCH_NUTRITION_SUMMARY,
  FETCH_NUTRITION_SUMMARY_FULFILLED
} from './actionTypes'
import { createPendingCounterReducer } from './common'

import {
  DateRange,
  EnergyHistoryItem,
  Macronutrients,
  MyAnalyticsData,
  MyAnalyticsReduxState,
  PendingCounterMultipleState,
  ReduxStoreState
} from 'Models'
import { coreBackendService } from 'Services'
import { Calculator } from 'Utils'

// Helpers
const sumObject = <T>(obj: T): number => Object.values(obj).reduce((acc: number, val: number) => acc + val, 0)

// Actions
export const fetchNutritionSummaryAction = (range: DateRange): AsyncAction => ({
  type: FETCH_NUTRITION_SUMMARY,
  payload: coreBackendService.fetchNutritionSummary(range)
})

export const fetchEnergyHistoryAction = (range: DateRange): AsyncAction => ({
  type: FETCH_ENERGY_HISTORY,
  payload: coreBackendService.fetchEnergyHistory(range)
})

export const fetchEnergyDistributionAction = (range: DateRange): AsyncAction => ({
  type: FETCH_ENERGY_DISTRIBUTION,
  payload: coreBackendService.fetchEnergyMealDistribution(range)
})

// Reducer and state
export const initialState: MyAnalyticsData = {
  nutritionSummary: {},
  energyHistory: []
}

const data = (state: MyAnalyticsData = initialState, { type, payload }: FluxStandardAction): MyAnalyticsData => {
  switch (type) {
    case FETCH_NUTRITION_SUMMARY_FULFILLED: {
      const summaryAccepted = sumObject<Macronutrients>(payload.accepted.amount)
      const summaryGoal = sumObject<Macronutrients>(payload.goal.amount)
      return {
        ...state,
        nutritionSummary: {
          ...(summaryAccepted ? { accepted: payload.accepted } : {}),
          ...(summaryGoal ? { goal: payload.goal } : {})
        }
      }
    }
    case FETCH_ENERGY_HISTORY_FULFILLED: {
      const energyDataComplete = payload.some(
        (item: EnergyHistoryItem) => item.acceptedEnergy > 0 && item.goalEnergy > 0
      )
      return {
        ...state,
        ...(energyDataComplete ? { energyHistory: payload } : {})
      }
    }
    case FETCH_ENERGY_DISTRIBUTION_FULFILLED: {
      const energyTotal = Object.values<number>(payload).reduce((acc: number, val: number) => acc + val, 0)
      return {
        ...state,
        ...(energyTotal > 0
          ? {
              energyDistribution: Object.keys(payload).reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: Calculator.calculatePercentage(payload[key], energyTotal)
                }),
                {}
              )
            }
          : {})
      }
    }
    default:
      return state
  }
}

// Selectors
export const myAnalyticsSelector = ({ myAnalytics }: ReduxStoreState): MyAnalyticsReduxState => myAnalytics

export const myAnalyticsDataSelector = ({ myAnalytics }: ReduxStoreState): MyAnalyticsData => myAnalytics.data

export const isNutritionSummaryLoadingSelector = ({ myAnalytics }: ReduxStoreState): boolean =>
  !!myAnalytics.pendingCounter.pendingCounterNutritionSummary

export const isEnergyHistoryLoadingSelector = ({ myAnalytics }: ReduxStoreState): boolean =>
  !!myAnalytics.pendingCounter.pendingCounterEnergyHistory

export const isEnergyDistributionLoadingSelector = ({ myAnalytics }: ReduxStoreState): boolean =>
  !!myAnalytics.pendingCounter.pendingCounterEnergyDistribution

export const nutritionSummarySelector = createSelector(
  [myAnalyticsDataSelector],
  ({ nutritionSummary }) => nutritionSummary
)
export const nutritionSummaryRecommendedSelector = createSelector([nutritionSummarySelector], (data) => data.goal)
export const nutritionSummaryTrackedSelector = createSelector([nutritionSummarySelector], (data) => data.accepted)

export const energyHistorySelector = createSelector([myAnalyticsDataSelector], ({ energyHistory }) => energyHistory)

export const energyDistributionSelector = createSelector(
  [myAnalyticsDataSelector],
  ({ energyDistribution }) => energyDistribution
)

export default combineReducers<MyAnalyticsReduxState>({
  data,
  pendingCounter: combineReducers<PendingCounterMultipleState>({
    pendingCounterNutritionSummary: createPendingCounterReducer(FETCH_NUTRITION_SUMMARY),
    pendingCounterEnergyHistory: createPendingCounterReducer(FETCH_ENERGY_HISTORY),
    pendingCounterEnergyDistribution: createPendingCounterReducer(FETCH_ENERGY_DISTRIBUTION)
  })
})
