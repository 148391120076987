import { combineReducers } from 'redux'
import { FluxStandardAction } from 'redux-promise-middleware'
import { createSelector } from 'reselect'

import { SET_JOURNAL_DATE } from './actionTypes'

import { today } from 'Config'
import { DateType, JournalDayReduxState, ReduxStoreState } from 'Models'
import { Formatter } from 'Utils'

// Actions
export const setJournalDateAction = (date: Date): FluxStandardAction => ({
  type: SET_JOURNAL_DATE,
  meta: { date }
})

// Reducer && Initialstate
export const initialDateState: DateType = {
  date: today,
  dateFormatDay: Formatter.formatIso8601Date(today)
}

const date = (state: DateType = initialDateState, { type, meta }: FluxStandardAction): DateType => {
  switch (type) {
    case SET_JOURNAL_DATE:
      return {
        ...state,
        date: meta.date,
        dateFormatDay: Formatter.formatIso8601Date(meta.date)
      }
    default:
      return state
  }
}

// Selectors
export const journalDateSelector = ({ journalDay: { date } }: ReduxStoreState): DateType => date

export const currentJournalDateSelector = createSelector([journalDateSelector], ({ date }) => date)

export const currentJournalDateFormattedSelector = createSelector(
  [journalDateSelector],
  ({ dateFormatDay }) => dateFormatDay
)

export default combineReducers<JournalDayReduxState>({
  date
})
