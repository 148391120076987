import _, { uniqueId } from 'lodash'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { IoMdCheckmark } from 'react-icons/io'

import CopyJournalRecordModal from '../Modals/CopyJournalRecordModal'
import MoveJournalRecordModal from '../Modals/MoveJournalRecordModal'

import styles from './JournalCardEatableContainer.module.css'

import { CardIconButton, FormInputSelect, IconColor, JournalCardEatable } from 'Components'
import { DebugPanel } from 'Components/Debug'
import {
  copyButton,
  deleteButton,
  editButton,
  favoriteButton,
  markAsEatenButton,
  moveButton,
  suggestionButton
} from 'Components/IconButton/iconButtons'
import {
  Amount,
  FavoritesMapping,
  JournalEatableContainerObject,
  JournalEatableRecord,
  MealCategory,
  PromiseAction,
  QuantifiedEatable,
  allMealCategories
} from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAddOrRemoveFavorite } from 'ReduxStore/favorites/useAddOrRemoveFavorite'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { currentJournalDateSelector } from 'ReduxStore/journalDay'
import {
  postJournalEatableRecordAction,
  postOrPatchJournalEatableRecordAction,
  removeJournalEatableRecordAction,
  updateJournalEatableRecordAction
} from 'ReduxStore/journalEatableRecords'
import { Formatter, Utils } from 'Utils'
import { usePagination } from 'Utils/helpers/usePagination'

type Props = JournalEatableContainerObject & {
  onCardClick: (eatable: QuantifiedEatable) => void
}

const isMenuPlanSlot = (isInMenuPlan: boolean, isAccepted: boolean): boolean => isInMenuPlan && !isAccepted
const isMenuPlanEatableRecord = (isInMenuPlan: boolean, isAccepted: boolean): boolean => isInMenuPlan && isAccepted

const JournalCardEatableContainer: FC<Props> = ({
  eatableCardObjects,
  eatableCardsActiveIndex,
  mealCategory,
  journalRecordId,
  menuPlanSlotId,
  hint,
  onCardClick
}: Props) => {
  const dispatch = useAppDispatch()
  const currentJournalDate = useAppSelector(currentJournalDateSelector)

  const [quantifiedEatable, setQuantifiedEatable] = useState(eatableCardObjects[eatableCardsActiveIndex].eatable)
  const { currentPage: eatableSelectedIndex, setNextPage: setNextEatable } = usePagination(
    [...eatableCardObjects.keys()],
    1,
    eatableCardsActiveIndex
  )
  const [displayedMealCategory, setDisplayedMealCategory] = useState<MealCategory>(mealCategory)

  const [moveModalIsOpen, setMoveModalIsOpen] = useState(false)
  const [copyModalIsOpen, setCopyModalIsOpen] = useState(false)

  const [toggleChildrenIsOn, setToggleChildrenIsOn] = useState(false)

  const favoritesFood = useAppSelector(favoriteSelector(FavoritesMapping.FOODS, eatableCardObjects[0].eatable.food?.id))

  const favoritesRecipe = useAppSelector(
    favoriteSelector(FavoritesMapping.RECIPES, eatableCardObjects[0].eatable.recipe?.id)
  )
  const { onFavoriteChange: onFoodFavoriteChange } = useAddOrRemoveFavorite()
  const { onFavoriteChange: onRecipeFavoriteChange } = useAddOrRemoveFavorite()

  useEffect(() => {
    setQuantifiedEatable(eatableCardObjects[eatableSelectedIndex].eatable)
  }, [eatableCardObjects, eatableSelectedIndex])

  const currentMenuplanSlotId = eatableCardObjects[eatableSelectedIndex].menuPlanSlotId || menuPlanSlotId

  const isInMenuPlan = !!menuPlanSlotId

  const isAccepted = !!journalRecordId

  const handleToggleAccepted = (): PromiseAction<JournalEatableRecord> | void => {
    return journalRecordId
      ? dispatch(removeJournalEatableRecordAction(journalRecordId))
      : dispatch(
          postJournalEatableRecordAction(
            quantifiedEatable,
            mealCategory,
            currentJournalDate,
            Utils.menuPlanSlotIdToJournalSource(currentMenuplanSlotId)
          )
        )
  }

  const handleAmountChanged = useCallback(
    _.debounce(
      ({ unit, quantity }: Amount) =>
        journalRecordId &&
        dispatch(
          updateJournalEatableRecordAction(
            { id: journalRecordId, quantity, unit },
            { oldUnit: eatableCardObjects[0].eatable.unit, oldQuantity: eatableCardObjects[0].eatable.quantity }
          )
        ),
      1000
    ),
    [journalRecordId]
  )

  const handleCardClick = useCallback(() => onCardClick(quantifiedEatable), [quantifiedEatable])

  const handleOnChange = (modifiedEatable: QuantifiedEatable): void => {
    const { unit = 'GRAM', quantity = 0 } = modifiedEatable
    if (quantity > 0 && (quantifiedEatable.unit !== unit || quantifiedEatable.quantity !== quantity)) {
      setQuantifiedEatable(modifiedEatable)
      handleAmountChanged({ unit, quantity })
    }
  }

  const onReset = (): void => {
    setCopyModalIsOpen(false)
    setMoveModalIsOpen(false)
    setDisplayedMealCategory(mealCategory)
  }

  const onCopy = (date: Date): void =>
    dispatch(
      postOrPatchJournalEatableRecordAction(
        quantifiedEatable,
        displayedMealCategory,
        date,
        Utils.menuPlanSlotIdToJournalSource(currentMenuplanSlotId)
      )
    )

  const eatableName = (): string | undefined => {
    const item = quantifiedEatable.food || quantifiedEatable.recipe
    return item && item.name
  }

  const onMove = (date: Date): void => {
    journalRecordId &&
      dispatch(
        updateJournalEatableRecordAction(
          {
            id: journalRecordId,
            datetime: date,
            mealCategory: displayedMealCategory
          },
          { oldUnit: eatableCardObjects[0].eatable.unit, oldQuantity: eatableCardObjects[0].eatable.quantity }
        )
      )
    setMoveModalIsOpen(false)
  }

  const callbackCopyButton = (): void => setCopyModalIsOpen(!copyModalIsOpen)

  const callbackMoveButton = (): void => setMoveModalIsOpen(!moveModalIsOpen)

  const callbackFavoritesRecipeButton = (): void => {
    onRecipeFavoriteChange(favoritesRecipe, {
      quantity: eatableCardObjects[0].eatable.quantity!,
      unit: eatableCardObjects[0].eatable.unit!,
      recipeId: eatableCardObjects[0].eatable.recipe!.id
    })
  }

  const callbackFavoritesFoodButton = (): void => {
    onFoodFavoriteChange(favoritesFood, {
      quantity: eatableCardObjects[0].eatable.quantity!,
      unit: eatableCardObjects[0].eatable.unit!,
      foodId: eatableCardObjects[0].eatable.food!.id
    })
  }

  const callbackEditButton = (): void => setToggleChildrenIsOn(!toggleChildrenIsOn)

  const renderSuggestionButton = (color?: IconColor): CardIconButton[] => {
    if (eatableCardObjects.length > 1 && !isAccepted) {
      return [suggestionButton(setNextEatable, color)]
    }
    return []
  }

  const renderMarkAsEatenButton = (): CardIconButton[] => {
    if (isInMenuPlan) {
      return [markAsEatenButton(handleToggleAccepted, isAccepted)]
    }
    return []
  }

  const renderCopyAndMoveButton = (): CardIconButton[] => {
    if (!isInMenuPlan) {
      return [copyButton(callbackCopyButton), moveButton(callbackMoveButton)]
    }
    return []
  }

  const renderDeleteButton = (): CardIconButton[] => {
    if (isInMenuPlan) {
      return []
    }
    return [deleteButton(handleToggleAccepted)]
  }

  const renderFavoritesButton = (): CardIconButton[] => {
    if (!journalRecordId) {
      return []
    }
    return eatableCardObjects[0].eatable.recipe
      ? [favoriteButton(callbackFavoritesRecipeButton, !!favoritesRecipe)]
      : [favoriteButton(callbackFavoritesFoodButton, !!favoritesFood)]
  }
  const setupIconButtons = (): CardIconButton[] => {
    return [
      editButton(callbackEditButton),
      ...renderSuggestionButton(),
      ...renderMarkAsEatenButton(),
      ...renderCopyAndMoveButton(),
      ...renderFavoritesButton(),
      ...renderDeleteButton()
    ]
  }

  const cardButtons = (): JSX.Element[] => {
    const cardButtons = []
    if (isInMenuPlan) {
      cardButtons.push(
        <p style={{ color: '#E99508' }} className="text-small">
          {hint}
        </p>
      )
      cardButtons.push(
        <div className="cursor" key={uniqueId()} onClick={handleToggleAccepted}>
          <IoMdCheckmark key={uniqueId()} size={'1.5rem'} color={'#E99508'} />
        </div>
      )
    }

    return cardButtons
  }

  const cardStyle = (): string | undefined => {
    if (isMenuPlanSlot(isInMenuPlan, isAccepted)) {
      return 'is-menuplan'
    }
    if (isMenuPlanEatableRecord(isInMenuPlan, isAccepted)) {
      return styles.menuPlanEatableRecord
    }
    return
  }

  return (
    <>
      <DebugPanel>
        <code>
          {JSON.stringify({
            journalRecordId,
            eatable: Utils.eatableDebugData(quantifiedEatable),
            menuPlanSlotId,
            currentMenuplanSlotId,
            eatableSelectedIndex: eatableSelectedIndex
          })}
        </code>
      </DebugPanel>
      <MoveJournalRecordModal
        isOpen={moveModalIsOpen}
        title={eatableName()}
        onReset={onReset}
        displayedMealCategory={displayedMealCategory}
        onChange={(value) => setDisplayedMealCategory(value as MealCategory)}
        onMove={onMove}
      >
        <div className="col-md-5 col-12">
          <FormInputSelect
            name="mealCategory"
            items={allMealCategories}
            selectedKey={displayedMealCategory}
            labelExtractor={(value) => Formatter.formatMealCategory(value as MealCategory)}
            onChange={(value) => setDisplayedMealCategory(value as MealCategory)}
          />
        </div>
      </MoveJournalRecordModal>
      <CopyJournalRecordModal isOpen={copyModalIsOpen} onReset={onReset} title={eatableName()} onCopy={onCopy}>
        <div className="col-12 col-lg-6">
          <FormInputSelect
            label=" "
            name="mealCategory"
            items={allMealCategories}
            selectedKey={displayedMealCategory}
            labelExtractor={(value) => Formatter.formatMealCategory(value as MealCategory)}
            onChange={(value) => setDisplayedMealCategory(value as MealCategory)}
          />
        </div>
      </CopyJournalRecordModal>
      <JournalCardEatable
        className={_.compact(['is-eatable', cardStyle()]).join(' ')}
        eatable={quantifiedEatable}
        isAccepted={isAccepted}
        showCheckbox={isInMenuPlan}
        onChange={handleOnChange}
        onClick={handleCardClick}
        isEditable
        toggleChildrenIsOn={toggleChildrenIsOn}
        cardButtons={cardButtons()}
        iconButtons={setupIconButtons()}
      />
    </>
  )
}

export default JournalCardEatableContainer
