import { Config } from './Types'

import { colors } from 'Config'

/**
 * List of all nutritons a food, recipe or meal has.
 */
export interface Nutritions {
  /**
   * energy in kcal
   */
  energy: number
  protein: number
  carbohydrates: number
  fat: number
  alcohol?: number
  fibre?: number
  salt?: number | null
  sugar?: number | null
}

export interface NutritionsList {
  [key: string]: Nutritions
}

export interface Macronutrients {
  carbohydrates: number
  fat: number
  protein: number
  alcohol?: number
  fibre?: number
}

export interface MacronutrientSet {
  distribution: Macronutrients
  amount: Macronutrients
}

export interface NutritionSummary {
  goal?: MacronutrientSet
  accepted?: MacronutrientSet
}

/**
 * Nutritions configuration and order
 */

export const nutritionsConfig: Config = {
  energy: {
    label: 'Energie'
  },
  protein: {
    label: 'Protein',
    color: colors.pieChart.protein
  },
  carbohydrates: {
    label: 'Kohlenhydrate',
    color: colors.pieChart.carbohydrates
  },
  sugar: {
    label: 'Zucker (natürlich vorkommender und zugesetzter)'
  },
  fibre: {
    label: 'Nahrungsfasern'
  },
  fat: {
    label: 'Fett',
    color: colors.pieChart.fat
  },
  salt: {
    label: 'Salz'
  },
  alcohol: {
    label: 'Alkohol',
    color: colors.pieChart.alcohol
  }
}
