import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { ConnectedProps, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { CreateFoodPage } from './CreateFoodPage'
import { FoodFormValuesType, foodFormInitialValues, mapValuesToFood } from './foodFormHelpers'
import { foodFormValidationSchema } from './foodFormSchema'

import { routes } from 'Config'
import { EatableSource, ReduxStoreState } from 'Models'
import { eatableSourcesSelector, fetchEatableSourcesAction } from 'ReduxStore/eatableSources'
import { addUserFoodAction } from 'ReduxStore/userFoods'
import { useImageUploading } from 'Utils/helpers/useImageUploading'

type SelectorProps = {
  eatableSources: EatableSource[]
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  eatableSources: eatableSourcesSelector
})

const mapDispatchToProps = {
  addUserFood: addUserFoodAction,
  fetchEatableSources: fetchEatableSourcesAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const CreateFoodPageContainer: FC<Props> = ({ eatableSources, addUserFood, fetchEatableSources }: Props) => {
  const { isImageUploading, setTempImageId, setIsImageUploading, onImageSubmit, onPageExit } = useImageUploading({})

  const {
    register,
    control,
    errors,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting }
  } = useForm<FoodFormValuesType>({
    defaultValues: foodFormInitialValues,
    resolver: yupResolver(foodFormValidationSchema)
  })

  const history = useHistory()

  useEffect(() => {
    if (eatableSources.length === 0) {
      fetchEatableSources()
    }

    return () => {
      onPageExit()
    }
  }, [])

  const onSubmit = handleSubmit((values) => {
    //@ts-ignore
    addUserFood(mapValuesToFood({ values })) //@ts-ignore
      .then(async ({ value }) => {
        await onImageSubmit(values.imageUrl)
        history.push(routes.myEatables.myFoodView(value.id))
      })
      .catch((errors: FieldErrors<FoodFormValuesType>) => {
        console.error(errors)
      })
  })

  return (
    <CreateFoodPage
      isImageUploading={isImageUploading}
      setIsImageUploading={setIsImageUploading}
      setTempImageId={setTempImageId}
      onSubmit={onSubmit}
      onCancel={history.goBack}
      isSubmitting={isSubmitting}
      setValue={setValue}
      setError={setError}
      register={register}
      control={control}
      errors={errors}
      eatableSources={eatableSources}
    />
  )
}

export default connector(CreateFoodPageContainer)
