import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import 'axios-debug-log'
import qs from 'qs'

import { BackendError, BackendErrorData, MetaInfo, Query } from 'Models'
import { Formatter, Utils } from 'Utils'
import { getDefaultErrorMessage } from 'Utils/helpers/errorMessages'

export interface ProtectedBackendService {
  authToken: string | undefined
}

/**
 * Base class for all REST API Implementations of eBalance platform services.
 */
export class EbalanceBackendService implements ProtectedBackendService {
  protected client: AxiosInstance

  constructor(private config: AxiosRequestConfig, public userId?: string) {
    this.client = axios.create(this.config)
  }

  get authToken(): string | undefined {
    let authHeader = this.client.defaults.headers.common['Authorization']
    if (typeof authHeader === 'string') {
      authHeader = authHeader.replace(/^Bearer/, '').trim()
    }
    return authHeader
  }

  set authToken(newValue: string | undefined) {
    if (!newValue) {
      delete this.client.defaults.headers.common['Authorization']
    } else {
      this.client.defaults.headers.common['Authorization'] = `Bearer ${newValue}`
    }
  }

  get baseUrl(): string {
    return this.config.baseURL as string
  }

  async ping(): Promise<string> {
    return this.client.get('ping')
  }

  async metainfo(): Promise<MetaInfo> {
    return (await this.client.get('metainfo')).data
  }

  // Helpers
  protected buildUrlQuery(query: Query): string {
    return qs.stringify(query, {
      serializeDate: (d) => Utils.convertDateToIsoString(d)
    })
  }

  // Error Handling

  static getBackendErrorMessages = (error: BackendError): string[] | undefined => {
    if (!Array.isArray(error.response?.data)) {
      // Error did not match the expected structure
      return undefined
    }

    const backendErrors = error.response?.data as BackendErrorData[]
    if (backendErrors.length === 0) {
      return undefined
    }

    return backendErrors.map(
      (errorItem) =>
        EbalanceBackendService.buildUserErrorMessage(errorItem) || getDefaultErrorMessage(error.response?.status)
    )
  }

  static buildUserErrorMessage = (error: BackendErrorData): string | undefined => {
    const formattedRange = Formatter.formatRange(error.range)

    switch (error.code) {
      case 'RANGE_CONSTRAINT_VIOLATION': {
        return `Ihr Eingabe von ${error.givenValue} liegt nicht zwischen ${formattedRange.min} und ${formattedRange.max}.`
      }

      default:
        return undefined
    }
  }
}
