import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { LocaleUtils } from 'react-day-picker'

import { today } from 'Config'

dayjs.locale('de-ch')
dayjs.extend(localizedFormat)
dayjs.extend(localeData)
const localeDayJs = dayjs(today).localeData()

export const DayJsLocaleUtils = {
  ...LocaleUtils,
  getMonths: () => localeDayJs.months(),
  formatWeekdayShort: (i: number) => localeDayJs.weekdaysShort()[i]
}
