import React, { FC } from 'react'

import { IconName, InfoParagraph, InfoPlaceholder, LoadingIndicator } from 'Components'
import { BodyAnalyticsSection } from 'Models'

export interface Props {
  bodyAnalytics: BodyAnalyticsSection[] | null
  loading: boolean
}

const IconSwitch = (category: string): IconName => {
  switch (category) {
    case 'Goal':
      return IconName.goal
    case 'EnergyRequirement':
      return IconName.fire
    case 'WeeklyWeightScaling':
      return IconName.scale
    case 'BMI':
      return IconName.bmi
    case 'BellyCircumference':
      return IconName.tapeline
    case 'MotionMotivation':
      return IconName.timer
    case 'Age':
      return IconName.cupcake
    case 'PersonalBackground':
      return IconName.users
    case 'NoBans':
      return IconName.close
    case 'NoStress':
      return IconName.refresh
    case 'Motivation':
      return IconName.biceps
    default:
      return '' as IconName
  }
}

const BodyAnalytics: FC<Props> = ({ bodyAnalytics, loading }: Props) => {
  if (loading) {
    return <LoadingIndicator />
  }

  if (!bodyAnalytics || bodyAnalytics.length < 1) {
    return (
      <InfoPlaceholder text="Sobald Sie mehr als einmal Ihren Bauchumfang eingetragen haben, sehen Sie hier die Veränderung über die Zeit. Sie sollten Ihren Bauchumfang einmal im Monat messen. Wie misst man ihn? Ohne Bekleidung, auf Höhe das Bachnabels, das Massband verdeckt den Bauchnabel, waagerecht rund um den Körper." />
    )
  }

  return (
    <>
      <div className="row">
        {bodyAnalytics.map(({ category, headline, text }) => {
          return (
            <div className="col-12" key={category}>
              <InfoParagraph icon={IconSwitch(category)} title={headline} text={text} />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default BodyAnalytics
