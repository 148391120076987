/*
  Redux functions that implements the ability to hide objects. 
 
  Please note: Hiding items from the user is rather the technical implementation. The user will perceive it as Deleting the items. 
*/

import { ActionType as PromiseActionTypeSuffix } from 'redux-promise-middleware'

import { findItemAndIndexForUpdateActionMetaData, nullableStateToArray } from './helpers'
import { ToastMessageActionMetaData } from './toastMessage'
import { ReduxNullableArrayReducer, ReduxNullableArrayState } from './types'

import { Hidable, Identifiable, ReduxStoreState, ThunkAction } from 'Models'

// Types

export type ReduxHidable = Hidable & Identifiable

export type UpdateVisibilityActionMetaData<H extends Hidable> = ToastMessageActionMetaData & {
  data: {
    id: string
    hidden: boolean
  }
  indexBefore: number
  itemBefore: H
}

export type HideActionCreator<H extends Hidable> = (
  id: string,
  getHidablesState: (rootState: ReduxStoreState) => ReduxNullableArrayState<H>,
  backendServiceFunction: (id: string) => Promise<void>,
  successToastMessage: string
) => ThunkAction

// Factory

/**
 * This higher order function creates components of a redux stack to manage the ability of hiding items from the user.
 *
 * For the time being only hiding it implemented as setting an hidden item back to visible is not needed yet.
 *
 * @param hidableActionTypeToken A token representing the model or type of items, should be an uppercase string.
 * @returns A collection of:
 *  - action types base on the `hidableActionTypeToken`
 *  - an action creator
 *  - the reducer
 */
export const createHidableReduxItems = <H extends ReduxHidable>(
  hidableActionTypeToken: string
): {
  actionTypes: {
    UpdateVisibility: string
  }
  hideAction: HideActionCreator<H>
  reducer: ReduxNullableArrayReducer<H>
} => {
  const actionTypes = {
    UpdateVisibility: `UPDATE_USER_${hidableActionTypeToken}_VISIBILITY`
  }

  return {
    actionTypes,

    hideAction: (id, getHidablesState, backendServiceFunction, successToastMessage) => async (dispatch, getState) => {
      const hidables = getHidablesState(getState())
      const _meta = findItemAndIndexForUpdateActionMetaData(hidables, id)
      if (!_meta) return

      const meta: UpdateVisibilityActionMetaData<H> = {
        ..._meta,
        data: { id, hidden: true },
        customSuccessToastMessage: successToastMessage
      }

      dispatch({
        type: actionTypes.UpdateVisibility,
        payload: backendServiceFunction(id),
        meta
      })
    },

    reducer: (state, action) => {
      const { type, meta } = action
      const hidables = nullableStateToArray(state)
      switch (type) {
        case `${actionTypes.UpdateVisibility}_${PromiseActionTypeSuffix.Pending}`: {
          const {
            data: { id, hidden }
          } = meta as UpdateVisibilityActionMetaData<H>
          return hidden ? hidables.filter((f) => f.id !== id) : hidables
        }

        case `${actionTypes.UpdateVisibility}_${PromiseActionTypeSuffix.Rejected}`: {
          const { indexBefore, itemBefore } = meta as UpdateVisibilityActionMetaData<H>
          hidables.splice(indexBefore, 0, itemBefore)
          return hidables
        }

        default:
          return state
      }
    }
  }
}
