import { AnamnesisStepIds, EnergyPlan } from '../../types'

import { StepConfig } from 'Components/FormWizard'
import { anamnesisSchema } from 'yup-schema/Schemas/anamnesis'

// @ts-ignore
const validationSchema = anamnesisSchema.pick(['energyLevelName'])

export const energyPlanConfig: StepConfig<EnergyPlan> = {
  id: AnamnesisStepIds.energyPlan,
  scrollTop: true,
  validationSchema
}
