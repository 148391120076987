import { useState } from 'react'

const generateRandomNumberString = (): string => Math.random().toString().substring(2)

/**
 * Generates a random name that will be stable trough-out re-rendering.
 * @param prefix
 */
export const useRandomName = (prefix: string): string => {
  const [name] = useState(() => `${prefix}-${generateRandomNumberString()}`)
  return name
}

/**
 * Generates a random number that will be stable trough-out re-rendering.
 * @param prefix
 */
export const useRandomNumber = (): number => {
  const [number] = useState(() => parseInt(generateRandomNumberString()))
  return number
}
