import React, { FC } from 'react'

import { Icon, IconName } from '../Icon'

interface Props {
  icon: IconName
  color?: string
  title: string
  text: string
}

export const InfoParagraph: FC<Props> = ({ icon, color, title, text }: Props) => {
  return (
    <div className="infoparagraph">
      <Icon name={icon} color={color} />
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  )
}
