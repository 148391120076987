// eslint-disable-next-line @typescript-eslint/no-explicit-any
const env = (window as any).env || {}

const configValue = (key: string, fallback?: string): string => {
  const value = env[key] || process.env[`REACT_APP_${key}`] || fallback
  if (value === undefined && process.env.NODE_ENV === 'development') {
    throw new Error(`no value found for config key ${key}`)
  }
  return value
}

export default {
  backendUrl: configValue('CORE_BACKEND_BASE_URL', 'https://core-backend.ebalance.dev'),
  showDebugInfo: configValue('SHOW_DEBUG_INFO') === 'true',
  bannerMessage: configValue('BANNER_MESSAGE'),
  algolia: {
    applicationId: configValue('ALGOLIA_APP_ID'),
    apikey: configValue('ALGOLIA_API_KEY'),
    environment: configValue('ALGOLIA_ENVIRONMENT')
  },
  anamnesisService: {
    baseUrl: configValue('ANAMNESIS_SERVICE_BASE_URL')
  },
  energyCalculatorService: {
    baseUrl: configValue('ENERGY_CALCULATOR_BASE_URL', 'https://energy-calculator.ebalance.dev')
  },
  memberShipService: {
    baseUrl: configValue('MEMBERSHIP_SERVICE_BASE_URL', 'https://membership-service.ebalance.dev')
  },
  userDataImportService: {
    baseUrl: configValue('USER_DATA_IMPORTER_BASE_URL', '')
  },
  imageServer: {
    baseUrl: configValue('IMAGE_SERVICE_URL'),
    baseImagePath: configValue('IMAGE_SERVICE_BASE_PATH'),
    apiLogin: {
      username: configValue('IMAGE_SERVICE_USERNAME', ''),
      password: configValue('IMAGE_SERVICE_PASSWORD', '')
    }
  },
  keycloak: {
    url: configValue('KEYCLOAK_BASE_URL'),
    realm: configValue('KEYCLOAK_REALM'),
    clientId: configValue('KEYCLOAK_CLIENT_ID')
  },
  keycloakAdminApi: {
    baseURL: configValue('KEYCLOAK_ADMIN_API_BASE_URL')
  }
}
