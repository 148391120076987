import React, { FC } from 'react'

import { Icon, IconName } from '../Icon'

interface Props {
  color?: string
}

export const LoadingIndicator: FC<Props> = ({ color = 'gray-1' }: Props) => (
  <div className="loading-indicator">
    <Icon name={IconName.loadingspinner} color={color} />
  </div>
)
