import React from 'react'

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator'
import { ProgressBar } from '../ProgressBar/ProgressBar'

import { Circle } from './Circle'

import { ByIdMap, Nutritions } from 'Models'
import { Calculator } from 'Utils'

interface ItemProps {
  label: string
  value: string
}

interface Props {
  topItem?: ItemProps
  leftItem?: ItemProps
  rightItem?: ItemProps
  progressLeft?: number
  acceptedNutritions?: Nutritions
  goalNutritions?: Nutritions
  loading?: boolean
}

export const ProgressCircle: React.FC<Props> = ({
  topItem,
  leftItem,
  rightItem,
  progressLeft = 0,
  goalNutritions,
  acceptedNutritions,
  loading
}: Props) => {
  const titles: ByIdMap<string> = {
    carbohydrates: 'Kohlenhy.',
    protein: 'Protein',
    fat: 'Fett'
  }

  return (
    <>
      <div className="progresscircle2-wrapper mb-2">
        <div className="progresscircle2-wings">
          {leftItem && (
            <>
              {leftItem.value}
              <span>{leftItem.label}</span>
            </>
          )}
        </div>
        <div className="progresscircle2-main">
          <div className="progresscircle2-main-inner">
            {topItem && (
              <>
                {topItem.value}
                <span>{topItem.label}</span>
              </>
            )}
          </div>
          <Circle radius={80} stroke={5} percent={progressLeft} />
          {loading && (
            <div style={{ position: 'absolute' }}>
              <LoadingIndicator color="white" />
            </div>
          )}
        </div>
        <div className="progresscircle2-wings">
          {rightItem && (
            <>
              {rightItem.value}
              <span>{rightItem.label}</span>
            </>
          )}
        </div>
      </div>
      {acceptedNutritions && goalNutritions && (
        <div className="d-flex justify-content-between">
          {Object.keys(titles).map((key) => (
            <div key={key}>
              <ProgressBar
                key={key}
                title={titles[key]}
                progressAsPercentage={
                  (Calculator.calculateNutritionPercentage(acceptedNutritions, goalNutritions)[
                    key as keyof Nutritions
                  ] || 0) * 100
                }
                progressText={`${Math.round(acceptedNutritions[key as keyof Nutritions] || 0)} / ${Math.round(
                  goalNutritions[key as keyof Nutritions] || 0
                )} G`}
              />
            </div>
          ))}
        </div>
      )}
    </>
  )
}
