import { AnyAction, combineReducers } from 'redux'

import { FETCH_MENU_PLAN, FETCH_MENU_PLAN_FULFILLED } from './actionTypes'
import { createPendingCounterReducer } from './common'

import {
  FetchMenuPlanPayload,
  MenuPlanDays,
  MenuPlanReduxState,
  MenuPlanWeek,
  PromiseAction,
  ReduxStoreState
} from 'Models'
import { coreBackendService } from 'Services'

export const fetchMenuPlanAction = (payload: FetchMenuPlanPayload): PromiseAction<MenuPlanWeek> => ({
  type: FETCH_MENU_PLAN,
  payload: coreBackendService.fetchMenuplan(payload)
})

const initialState: MenuPlanWeek = { days: [] }

const data = (state = initialState, { type, payload }: AnyAction): MenuPlanWeek => {
  switch (type) {
    case FETCH_MENU_PLAN_FULFILLED: {
      return payload
    }

    default:
      return state
  }
}

export const menuPlanNewDaysSelector = ({ menuPlan }: ReduxStoreState): MenuPlanDays[] => menuPlan.data.days

const asyncActionTypes = [FETCH_MENU_PLAN]

export default combineReducers<MenuPlanReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
