import React, { FC } from 'react'
import Modal from 'react-modal'

import Search from './Search'

import { IconName } from 'Components/Icon'
import { IconButton } from 'Components/IconButton'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

type Props = {
  isOpen: boolean
  /** callback fired when the search page asks to get closed,
   * which is either by toggling the modal or after saving a search result */
  onClose: () => void
}

export const SearchModal: FC<Props> = ({ isOpen, onClose }: Props) =>
  isOpen ? (
    <Modal style={modalZIndexPriority} isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
      <div className="modal is-medium py-5 px-2 px-lg-5">
        <IconButton name={IconName.close} onClick={onClose} />
        <Search onSaveSearchResult={onClose} />
      </div>
    </Modal>
  ) : null
