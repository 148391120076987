import React, { FC } from 'react'

export enum IconName {
  'arrowdown' = 'arrowdown',
  'arrowleft' = 'arrowleft',
  'arrowright' = 'arrowright',
  'arrowup' = 'arrowup',
  'belly' = 'belly',
  'biceps' = 'biceps',
  'bmi' = 'bmi',
  'bookmark' = 'bookmark',
  'bookmarkfilled' = 'bookmarkfilled',
  'calendar' = 'calendar',
  'camera' = 'camera',
  'check' = 'check',
  'clock' = 'clock',
  'close' = 'close',
  'construction' = 'construction',
  'copy' = 'copy',
  'cupcake' = 'cupcake',
  'edit' = 'edit',
  'fire' = 'fire',
  'goal' = 'goal',
  'heart' = 'heart',
  'heartfilled' = 'heartfilled',
  'loadingspinner' = 'loadingspinner',
  'menuh' = 'menuh',
  'menuv' = 'menuv',
  'minus' = 'minus',
  'move' = 'move',
  'plus' = 'plus',
  'refresh' = 'refresh',
  'replace' = 'replace',
  'scale' = 'scale',
  'search' = 'search',
  'socialfacebook' = 'socialfacebook',
  'socialinstagram' = 'socialinstagram',
  'socialyoutube' = 'socialyoutube',
  'tapeline' = 'tapeline',
  'timer' = 'timer',
  'trash' = 'trash',
  'users' = 'users',
  'utensils' = 'utensils',
  'waterempty' = 'waterempty',
  'wateremptyplus' = 'wateremptyplus',
  'waterfull' = 'waterfull',
  'watch' = 'watch',
  'weight' = 'weight'
}

export enum IconColor {
  GRAY_1 = 'gray-1',
  GRAY_2 = 'gray-2',
  BLUE_2 = 'blue-2',
  WHITE = 'white',
  GREEN = 'green'
}

interface Props {
  name: IconName
  color?: string
}

export const Icon: FC<Props> = ({ name, color = IconColor.GRAY_1 }: Props) => {
  return <div aria-label={name} className={`icon icon-${name}-${color}`}></div>
}
