import dayjs from 'dayjs'
import _ from 'lodash'
import { isNull, isUndefined } from 'node-util'

import { today } from 'Config'
import {
  Amount,
  ChartArrayCombinedType,
  Config,
  Coordinates,
  DateRange,
  DateRangeString,
  EatableType,
  FOODCRITERIA,
  Food,
  FoodCriteriaType,
  Identifiable,
  ObjectLiteral,
  QuantifiedEatable,
  Recipe,
  UNITS,
  UnitId,
  defaultVolumeUnitIds,
  defaultWeightUnitIds
} from 'Models'
import { Calculator } from 'Utils/Calculator'
import { eatableType } from 'Utils/helpers/eatableType'

interface TimeSortable {
  datetime?: Date
}

const mergeRecipeAmount = (
  quantifiedEatableA: QuantifiedEatable,
  quantifiedEatableB: QuantifiedEatable
): Amount | null => {
  const { quantity: quantityA, unit: unitA } = quantifiedEatableA
  const { quantity: quantityB, unit: unitB } = quantifiedEatableB

  if (!isUndefined(quantityA) && !isUndefined(quantityB) && unitA && unitB) {
    if (unitA === unitB) {
      return {
        unit: unitA,
        quantity: quantityA + quantityB
      }
    }

    // Units are different, so one uses portions and the other grams
    const [portionEatable, gramEatable] =
      unitA === 'PORTION' ? [quantifiedEatableA, quantifiedEatableB] : [quantifiedEatableB, quantifiedEatableA]

    if (!isUndefined(portionEatable.recipe?.portionAmount)) {
      return {
        unit: 'GRAM',
        quantity:
          ((portionEatable.recipe as Recipe).portionAmount as number) * (portionEatable.quantity as number) +
          (gramEatable.quantity as number)
      }
    }
  }

  return null
}

const mergeFoodAmount = (
  quantifiedEatableA: QuantifiedEatable,
  quantifiedEatableB: QuantifiedEatable
): Amount | null => {
  const { quantity: quantityA, unit: unitA } = quantifiedEatableA
  const { quantity: quantityB, unit: unitB } = quantifiedEatableB

  if (!isUndefined(quantityA) && !isUndefined(quantityB) && unitA && unitB) {
    if (unitA === unitB) {
      return {
        unit: unitA,
        quantity: quantityA + quantityB
      }
    }

    const amountA = Calculator.foodAmountInGram(quantifiedEatableA)
    const amountB = Calculator.foodAmountInGram(quantifiedEatableB)

    if (!isNull(amountA) && !isNull(amountB)) {
      return {
        unit: 'GRAM',
        quantity: amountA + amountB
      }
    }
  }

  return null
}

export class Utils {
  static eatableType = eatableType

  /**
   * Converts a normal JS date object to a ISO string while also taking possible timezone shifts into account
   * @param date
   */
  static convertDateToIsoString(date: Date): string {
    return date ? date.toISOString() : ''
  }

  /**
   * checks if two given eatable are for the same food or recipe ignoring the amount.
   */
  static isSameEabtale(a: QuantifiedEatable | undefined, b: QuantifiedEatable | undefined): boolean {
    if (!a || !b) {
      return false
    }

    const foodIdA = Utils.eatableFoodId(a)
    const matchFoodId = foodIdA !== undefined && foodIdA === Utils.eatableFoodId(b)

    const recipeIdA = Utils.eatableRecipeId(a)
    const matchRecipeId = recipeIdA !== undefined && recipeIdA === Utils.eatableRecipeId(b)

    return matchFoodId || matchRecipeId
  }

  static eatableItemIds(eatable: QuantifiedEatable | undefined): { foodId?: string; recipeId?: string } {
    return {
      foodId: this.eatableFoodId(eatable),
      recipeId: this.eatableRecipeId(eatable)
    }
  }

  static eatableFoodId(eatable: QuantifiedEatable | undefined): string | undefined {
    if (eatable === undefined) {
      return undefined
    }
    return eatable.foodId || (eatable.food && eatable.food.id)
  }

  static eatableRecipeId(eatable: QuantifiedEatable | undefined): string | undefined {
    if (eatable === undefined) {
      return undefined
    }
    return eatable.recipeId || (eatable.recipe && eatable.recipe.id)
  }

  static isFoodLiquid(food?: Pick<Food, 'unitMappings'>): boolean {
    return (
      !!food &&
      !!food.unitMappings &&
      food.unitMappings
        .map(({ unit }) => unit)
        .join('|')
        .indexOf('LITER') !== -1
    )
  }

  static availableUnitsForFood(food?: Pick<Food, 'unitMappings'>): UnitId[] {
    const units = new Set<UnitId>()
    if (food && food.unitMappings) {
      food.unitMappings.forEach(({ unit }) => units.add(unit))
    }

    defaultWeightUnitIds.forEach(units.add, units)
    if (this.isFoodLiquid(food)) {
      defaultVolumeUnitIds.forEach(units.add, units)
    }
    return [...units]
  }

  // recipe is a dummy paramter for the sake of consistency with availableUnitsForFood()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static availableUnitsForRecipe(recipe?: Recipe): UnitId[] {
    return ['PORTION', 'GRAM']
  }

  static availableUnits(eatable: QuantifiedEatable): UnitId[] {
    switch (eatableType(eatable)) {
      case 'FOOD':
        return this.availableUnitsForFood(eatable.food)
      case 'RECIPE':
      default:
        return this.availableUnitsForRecipe(eatable.recipe)
    }
  }

  static eatableDebugData(eatable: QuantifiedEatable): QuantifiedEatable {
    return {
      foodId: Utils.eatableFoodId(eatable),
      recipeId: Utils.eatableRecipeId(eatable),
      quantity: eatable.quantity,
      unit: eatable.unit
    }
  }

  static defaultEatableAmount(eatable: QuantifiedEatable): Amount
  static defaultEatableAmount(eatableType: EatableType): Amount
  static defaultEatableAmount(eatableOrType: QuantifiedEatable | EatableType): Amount {
    let type: EatableType | null = eatableOrType as EatableType
    let eatable: QuantifiedEatable | undefined = undefined
    if (typeof eatableOrType !== 'string') {
      type = eatableType(eatableOrType)
      eatable = eatableOrType
    }
    switch (type) {
      case 'FOOD':
        return this.defaultEatableAmountForFood(eatable && eatable.food)
      case 'RECIPE':
      default:
        return { quantity: 1, unit: 'PORTION' }
    }
  }

  static defaultEatableAmountForFood(food?: Pick<Food, 'unitMappings' | 'defaultUnit'>): Amount {
    if (food?.defaultUnit) {
      return {
        quantity: UNITS[food.defaultUnit].defaultQuantity,
        unit: food.defaultUnit
      }
    }

    if (food?.unitMappings) {
      return {
        quantity: UNITS[food.unitMappings[0].unit].defaultQuantity,
        unit: food.unitMappings[0].unit
      }
    }

    return {
      quantity: UNITS.GRAM.defaultQuantity,
      unit: 'GRAM'
    }
  }

  static portionAmountOfFood(food?: Food): Amount | null {
    if (!food || !food.unitMappings) return null
    const mapping = food.unitMappings.find((m) => m.unit === 'PORTION')
    if (mapping && mapping.gramsPerUnit !== undefined) {
      return { quantity: mapping.gramsPerUnit, unit: 'GRAM' }
    }
    return null
  }

  /**
   * Returns whether a food criteria id is of a specific type.
   *
   * @param foodCriteriaId food criteria id
   * @param type type to check for
   */
  static isFoodCriteriaOfType(foodCriteriaId: string, type: FoodCriteriaType): boolean {
    return !!FOODCRITERIA[type] && !!FOODCRITERIA[type][foodCriteriaId]
  }

  /**
   * Return the item with the latest `datetime` value or `undefined` for an empty list.
   * @param data
   */
  static findLatest<T extends TimeSortable>(data: T[]): T | undefined {
    return data.reduce((latest: T | undefined, current: T) => {
      if (latest === undefined) return current
      if (latest.datetime === undefined || current.datetime === undefined) return current
      return latest.datetime >= current.datetime ? latest : current
    }, undefined)
  }

  /**
   * Return the item with the earliest `datetime` value or `undefined` for an empty list.
   * @param data
   */
  static findEarliest<T extends TimeSortable>(data: T[]): T | undefined {
    return data.reduce((latest: T | undefined, current: T) => {
      if (latest === undefined) return current
      if (latest.datetime === undefined || current.datetime === undefined) return current
      return latest.datetime < current.datetime ? latest : current
    }, undefined)
  }

  /**
   * Return the duration for time amount entered by the user.
   * @returns duration in seconds
   */
  static durationFromAmount = (amount: Amount): number | never => {
    if (amount.unit !== 'MINUTE') throw new Error(`unsupported Unit: ${amount.unit}`)
    return amount.quantity * 60
  }

  /**
   * Build the correct DateRange object from a simple DateRangeString (e.g. '1W')
   */
  static dateRangeFromString = (range: DateRangeString, registration: Date, programStart: Date): DateRange => {
    if (!registration || !dayjs(registration).isValid()) {
      throw new Error(`missing or invalid registration date: ${registration}`)
    }

    const dateTo = dayjs(today)
    const registrationStartDate = dayjs(registration)
    const programStartDate = dayjs(programStart)
    let dateFrom = dateTo // default 1DAY === same day

    switch (range) {
      case '1WEEK':
        dateFrom = dateTo.subtract(6, 'day')
        break
      case '2WEEKS':
        dateFrom = dateTo.subtract(13, 'day')
        break
      case '1MONTH':
        dateFrom = dateTo.subtract(1, 'month').add(1, 'day')
        break
      case '1YEAR':
        dateFrom = dateTo.subtract(1, 'year').add(1, 'day')
        break
      case 'PROGRAMSTART':
        dateFrom = programStartDate
    }

    if (dateFrom.isBefore(registrationStartDate, 'day')) {
      dateFrom = registrationStartDate
    }

    return { dateFrom: dateFrom.toDate(), dateTo: dateTo.toDate() }
  }

  /**
   * Converts an object into data and color Arrays used by Highchart (PieChart)
   * Optionally sorts the output from an given sorting array
   */
  static convertObjectToPieChartArrays = <T>(
    obj: T,
    config: Config,
    displayKeys?: string[]
  ): ChartArrayCombinedType => {
    if (!obj || !Object.keys(obj).length) return {} as ChartArrayCombinedType

    if (!displayKeys) {
      return {
        data: Object.keys(obj).map((entry) => [config[entry].label, (obj[entry as keyof T] as unknown) as number]),
        colors: Object.keys(obj).map((entry) => config[entry].color || '')
      }
    }

    return {
      data: displayKeys.map((key) => [config[key].label, (obj[key as keyof T] as unknown) as number]),
      colors: displayKeys.map((key) => config[key].color || '')
    }
  }

  /**
   * Merges the amounts of two quantified eatables of the same type using this logic:
   * - if they have the same unit then the quantities are added and the unit preserved
   * - if they have different units then they are both converted to grams and added
   * - if they can't be merged then null is returned
   *
   * @param quantifiedEatableA First quantified eatable
   * @param quantifiedEatableB Second quantified eatable
   *
   * @returns Merged amount or null if the merge isn't possible
   */
  static mergeEatableAmount = (
    quantifiedEatableA: QuantifiedEatable,
    quantifiedEatableB: QuantifiedEatable
  ): Amount | null => {
    const typeA = eatableType(quantifiedEatableA)
    const typeB = eatableType(quantifiedEatableB)

    if (!typeA || typeA !== typeB) {
      return null
    }

    return typeA === 'FOOD'
      ? mergeFoodAmount(quantifiedEatableA, quantifiedEatableB)
      : mergeRecipeAmount(quantifiedEatableA, quantifiedEatableB)
  }

  /**
   * Converts the property datetime: Date to strings in flat or array objects
   * @param data
   */
  static convertObjectDatetimeToString = <T extends ObjectLiteral>(data: T): T => {
    if (Array.isArray(data)) {
      return (data as T).map(Utils.convertObjectDatetimeToString)
    }

    return {
      ...data,
      ...(data.datetime ? { datetime: Utils.convertDateToIsoString(data.datetime) } : {})
    }
  }

  /**
   * Converts the property datetime: string to Date in flat or array objects
   */
  static convertObjectDatestringToDate = <T extends ObjectLiteral>(data: T, fieldnames: string[] = ['datetime']): T => {
    if (Array.isArray(data)) {
      return (data as T).map((d: T) => Utils.convertObjectDatestringToDate(d, fieldnames))
    }

    return {
      ...data,
      ...fieldnames.reduce(
        (acc, field) => ({
          ...acc,
          ...(data[field] ? { [field]: new Date(data[field]) } : {})
        }),
        {} as T
      )
    }
  }

  static unique<T extends Identifiable>(array: T[]): T[] {
    const lookup = new Set()
    return array.filter((item: T) => !lookup.has(item.id) && lookup.add(item.id))
  }

  static menuPlanSlotIdToJournalSource(id: string | undefined | null): string {
    return [`menu-plan`, id].filter((token) => !!token).join(':')
  }

  static menuPlanSlotIdFromJournalSource(source?: string): string | undefined {
    return source ? source.split('menu-plan:')[1] : undefined
  }

  // https://stackoverflow.com/a/18473154
  static polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number): Coordinates {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    }
  }

  // https://stackoverflow.com/a/18473154
  static describeSvgArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string {
    const start = this.polarToCartesian(x, y, radius, endAngle)
    const end = this.polarToCartesian(x, y, radius, startAngle)

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

    return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ')
  }

  /**
   * Filter an object based on array of keys
   * @param object the object that will be filtred
   * @param predicateArray an array of keys
   * @returns filtered object
   */

  /* eslint-disable @typescript-eslint/no-explicit-any */
  static filterObject(object: Record<string, any>, predicateArray: string[]): Record<string, any> {
    return Object.keys(object)
      .filter((key) => predicateArray.includes(key))
      .reduce((obj: Record<string, any>, key) => {
        obj[key] = object[key]
        return obj
      }, {})
  }

  /**
   * Filters an object base on difference in values to an original object. Only changed key-values will remain in the result.
   * This operation is performed deeply.
   * @param changeSet A set of key-value pairs updating an object.
   * @param originalObject The original object being about to update.
   * @returns filtered object
   */
  static filterObjectChanges = <Changes extends Record<string, any>>(
    changeSet: Changes,
    originalObject: Record<string, any>,
    options?: {
      filterArrayValues?: boolean
      filterNestedObjects?: boolean
    }
  ): Changes => {
    const shouldFilterArrayValues = options?.filterArrayValues ?? true
    const shouldFilterNestedObjects = options?.filterNestedObjects ?? true

    if (!originalObject) return changeSet
    const isPrimitive = (test: unknown): boolean => test !== Object(test)
    const isEmptyObject = (obj: unknown): boolean => {
      if (obj === undefined) return false
      if (obj === null) return false
      if (isPrimitive(obj)) return false
      return Object.keys(obj as any).length === 0
    }

    const result = Object.keys(changeSet).reduce((filteredChanges: any, key) => {
      const value = changeSet[key]
      if (!Object.keys(originalObject).includes(key)) {
        return { ...filteredChanges, [key]: value }
      }
      const origValue = originalObject[key]

      if (Array.isArray(value) && typeof value !== 'string') {
        if (value.length + origValue.length === 0) {
          return filteredChanges
        }

        const valueFiltered = _.chain(value)
          .map((item, index) => {
            const origItem = origValue[index]
            if (isPrimitive(item)) {
              return item !== origItem ? item : undefined
            }
            return Utils.filterObjectChanges(item, origItem, options)
          })
          .compact()
          .filter((item) => !isEmptyObject(item))
          .value()

        if (valueFiltered.length === 0 && value.length === origValue.length) {
          return filteredChanges
        }

        if (shouldFilterArrayValues) {
          return { ...filteredChanges, [key]: valueFiltered }
        }

        return { ...filteredChanges, [key]: value }
      }
      if (typeof value === 'object' && value !== null) {
        if (isEmptyObject(origValue)) {
          return filteredChanges
        }
        if (origValue === undefined) {
          return { ...filteredChanges, [key]: value }
        }

        const valueFiltered = Utils.filterObjectChanges(value, originalObject[key], options)

        if (isEmptyObject(valueFiltered)) {
          return filteredChanges
        }

        if (shouldFilterNestedObjects) {
          return { ...filteredChanges, [key]: valueFiltered }
        }

        return { ...filteredChanges, [key]: value }
      }
      if (value !== origValue) {
        return { ...filteredChanges, [key]: value }
      }
      return filteredChanges
    }, {})
    return result
  }
  static fallBackDefaultPropBehaviour = (): undefined => undefined
}
