import React, { FC } from 'react'
import ReactPlayer from 'react-player'

interface Props {
  url: string
}

export const YoutubePlayer: FC<Props> = ({ url }): JSX.Element => (
  <ReactPlayer url={url} controls={true} width="100%" height="100%" style={{ aspectRatio: '16 / 9' }} />
)
