import React, { FC } from 'react'

import { FoodForm, FoodFormProps } from './FoodForm'

import { PageLayout } from 'Components'

type CreateFoodPageProps = FoodFormProps

export const CreateFoodPage: FC<CreateFoodPageProps> = ({ ...foodFormProps }) => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <h1>Nahrungsmittel erstellen</h1>
        <p>
          Hier können Sie ihr eigenes Nahrungsmittel hinterlegen. Übertragen Sie einfach die Daten vom entsprechenden
          Nahrungsmittel in diesen fünf Schritten und speichern die Daten ab. Nun können Sie Ihr eigenes Nahrungsmittel
          über die Suche ins Tagebuch hinzufügen.
        </p>
        <FoodForm {...foodFormProps} />
      </div>
    </PageLayout>
  )
}
