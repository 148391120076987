import React, { FC } from 'react'

import { PageLayout } from 'Components'

export const ErrorMessage: FC = () => {
  return (
    <>
      <h2 className="text-center">Etwas ist schiefgegangen</h2>
      <p>
        Leider ist uns ein Fehler passiert, versuchen Sie es bitte später erneut. Melden Sie sich bitte beim Support,
        falls der Fehler wiederholt auftritt:
      </p>
      <p>
        E-Mail: <a href="mailto:service@eBalance.ch">service@eBalance.ch</a>
      </p>
      <p>Telefon: 41 43 508 80 05</p>
      <p>Supportzeiten: Mo. - Fr., 10:00 ­– 16:00 Uhr</p>
      <p>Wir sind gern für Sie da! Ihr eBalance Team</p>
    </>
  )
}

const ErrorPage: FC = () => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container max-500">
        <ErrorMessage />
      </div>
    </PageLayout>
  )
}

export default ErrorPage
