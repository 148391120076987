import yup from '../index'

export const bodyMeasurementValueBoundary = {
  min: 38,
  max: 250
}

export const bodyMeasurementsRangeSchema = yup
  .number()
  .min(bodyMeasurementValueBoundary.min)
  .max(bodyMeasurementValueBoundary.max)
