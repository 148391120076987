import React, { useEffect, useState } from 'react'

import { MetaInfo } from 'Models'
import { EbalanceBackendService } from 'Services'

interface Props {
  backendService: EbalanceBackendService
}

const BackendVersion: React.FC<Props> = ({ backendService }) => {
  const [version, setVersion] = useState('Loading …')

  useEffect(() => {
    backendService
      .metainfo()
      .then((info: MetaInfo) => setVersion(`${info.version} [${info.commitHash || 'n/a'}]`))
      .catch((err: Error) => setVersion(`${err}`))
  }, [])

  return <>{version}</>
}

export default BackendVersion
