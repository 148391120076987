// this is a list of all the units supported by the backend
export type UnitId =
  | 'GRAM'
  | 'HECTOGRAM'
  | 'KILOGRAM'
  | 'MILLILITER'
  | 'CENTILITER'
  | 'DECILITER'
  | 'LITER'
  | 'TEASPOON'
  | 'TABLESPOON'
  | 'PORTION'
  | 'PIECE'
  | 'PINCH'
  | 'BAR'
  | 'PIZZA'
  | 'BUNCH'
  | 'BECHER'
  | 'STALKS'
  | 'ROW'
  | 'LEAF'
  | 'TUBER'
  | 'HEAD'
  | 'DROPS'
  | 'CUBE'
  | 'CLOVE'
  | 'BRANCH'
  | 'CUP'
  | 'SLICE'
  | 'TASSE'
  | 'KNIFETIP'
  | 'CAN'
  | 'PACKET'
  | 'POD'
  | 'LITTLE'
  | 'BAG'
  | 'BOX'
  | 'TABLETTE'
  | 'HALF'
  | 'BEER_JAR'
  | 'JAR'
  | 'BOTTLE'
  | 'KERNEL'
  | 'COB'
  | 'SCOOP'
  | 'PACKAGE'
  | 'TUBE'
  | 'TRANCHE'
  | 'MINUTE'
  | 'CENTIMETER'

type UnitMeasurementCategory = 'WEIGHT' | 'VOLUME' | 'TIME' | 'LENGTH' | 'MISC'

export interface Unit {
  abbreviation?: string
  name: string
  defaultQuantity: number
  // template to format an amount in the unit with pluralisation support as of http://www.unicode.org/cldr/charts/latest/supplemental/language_plural_rules.html
  template?: {
    [key: string]: string
  }
  baseUnit?: {
    id: UnitId
    factor: number
  }
  measurementCategory: UnitMeasurementCategory
}

type UnitsDefinition = {
  [key in UnitId]: Unit
}

export const UNITS: UnitsDefinition = {
  GRAM: {
    name: 'Gramm',
    abbreviation: 'g',
    defaultQuantity: 100,
    measurementCategory: 'WEIGHT'
  },
  HECTOGRAM: {
    abbreviation: 'hg',
    name: 'Hektogramm',
    defaultQuantity: 1,
    baseUnit: {
      id: 'GRAM',
      factor: 100
    },
    measurementCategory: 'WEIGHT'
  },
  KILOGRAM: {
    abbreviation: 'kg',
    name: 'Kilogramm',
    defaultQuantity: 1,
    baseUnit: {
      id: 'GRAM',
      factor: 1000
    },
    measurementCategory: 'WEIGHT'
  },
  MILLILITER: {
    abbreviation: 'ml',
    name: 'Milliliter',
    defaultQuantity: 100,
    measurementCategory: 'VOLUME'
  },
  CENTILITER: {
    abbreviation: 'cl',
    name: 'Centiliter',
    defaultQuantity: 1,
    baseUnit: {
      id: 'MILLILITER',
      factor: 10
    },
    measurementCategory: 'VOLUME'
  },
  DECILITER: {
    abbreviation: 'dl',
    name: 'Deciliter',
    defaultQuantity: 1,
    baseUnit: {
      id: 'MILLILITER',
      factor: 100
    },
    measurementCategory: 'VOLUME'
  },
  LITER: {
    abbreviation: 'l',
    name: 'Liter',
    defaultQuantity: 1,
    baseUnit: {
      id: 'MILLILITER',
      factor: 1000
    },
    measurementCategory: 'VOLUME'
  },
  TEASPOON: {
    abbreviation: 'TL',
    name: 'Teelöffel',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  TABLESPOON: {
    abbreviation: 'EL',
    name: 'Esslöffel',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  PORTION: {
    abbreviation: 'P.',
    name: 'Portion',
    defaultQuantity: 1,
    template: {
      one: '1 Portion',
      other: '{value} Portionen'
    },
    measurementCategory: 'MISC'
  },
  PIECE: {
    abbreviation: 'Stk.',
    name: 'Stück',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  PINCH: {
    name: 'Prise',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  BAR: {
    name: 'Riegel',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  PIZZA: {
    name: 'Pizza',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  BUNCH: {
    name: 'Bund',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  BECHER: {
    name: 'Becher',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  STALKS: {
    name: 'Stange',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  ROW: {
    name: 'Reihe',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  LEAF: {
    name: 'Blatt',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  TUBER: {
    name: 'Knolle',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  HEAD: {
    name: 'Kopf',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  DROPS: {
    name: 'Tropfen',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  CUBE: {
    name: 'Würfel',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  CLOVE: {
    name: 'Zehe',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  BRANCH: {
    name: 'Zweig',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  CUP: {
    name: 'Cup',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  SLICE: {
    name: 'Scheibe',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  TASSE: {
    name: 'Tasse',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  KNIFETIP: {
    name: 'Messerspitze',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  CAN: {
    name: 'Dose',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  PACKET: {
    name: 'Packung',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  POD: {
    name: 'Schote',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  LITTLE: {
    name: 'wenig',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  BAG: {
    name: 'Beutel',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  BOX: {
    name: 'Box',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  TABLETTE: {
    name: 'Tablett',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  HALF: {
    name: 'Hälfte',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  BEER_JAR: {
    name: 'Bierkrug',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  JAR: {
    name: 'Glas',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  BOTTLE: {
    name: 'Flasche',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  KERNEL: {
    name: 'Kerne',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  COB: {
    name: 'Kolben',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  SCOOP: {
    name: 'Kugel',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  PACKAGE: {
    name: 'Packung',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  TUBE: {
    name: 'Tube',
    defaultQuantity: 1,
    measurementCategory: 'MISC'
  },
  TRANCHE: {
    name: 'Tranche',
    defaultQuantity: 1,
    template: {
      one: '1 Tranche',
      other: '{value} Tranchen'
    },
    measurementCategory: 'MISC'
  },
  MINUTE: {
    name: 'Minute',
    abbreviation: 'min',
    defaultQuantity: 1,
    template: {
      one: '1 Minute',
      other: '{value} Minuten'
    },
    measurementCategory: 'TIME'
  },
  CENTIMETER: {
    name: 'Centimeter',
    abbreviation: 'cm',
    defaultQuantity: 1,
    measurementCategory: 'LENGTH'
  }
}

export const allUnitIds = Object.keys(UNITS) as UnitId[]

const filterAllUnitsByCategories = (...categories: UnitMeasurementCategory[]): UnitId[] =>
  allUnitIds.filter((unitId) => categories.includes(UNITS[unitId].measurementCategory))

export const defaultWeightUnitIds = filterAllUnitsByCategories('WEIGHT')
export const defaultVolumeUnitIds = filterAllUnitsByCategories('VOLUME')

export const foodUnitIds = filterAllUnitsByCategories('WEIGHT', 'VOLUME', 'MISC')
export const foodUnitMappingUnitIds = filterAllUnitsByCategories('VOLUME', 'MISC')
