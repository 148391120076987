import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Action, AnyAction, Store, applyMiddleware, combineReducers, compose, createStore } from 'redux'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'

import { RESET_REDUX_STORE, RESET_USER_FULFILLED } from './actionTypes'

import { ReduxStoreState } from 'Models'
import anamnesis from 'ReduxStore/anamnesis'
import bodyAnalytics from 'ReduxStore/bodyAnalytics'
import bodyMeasurements from 'ReduxStore/bodyMeasurements'
import pendingCounter from 'ReduxStore/common/pendingCounter'
import coreBackendUser from 'ReduxStore/coreBackendUser'
import customer from 'ReduxStore/customerSlice'
import eatableSources from 'ReduxStore/eatableSources'
import energyRequirements from 'ReduxStore/energyRequirements'
import error from 'ReduxStore/error'
import favorites from 'ReduxStore/favorites/favorites'
import foodCategories from 'ReduxStore/foodCategories'
import journalDay from 'ReduxStore/journalDay'
import journalEatableRecords from 'ReduxStore/journalEatableRecords'
import journalEnergyMotionRecords from 'ReduxStore/journalEnergyMotionRecords'
import journalEnergyRecords from 'ReduxStore/journalEnergyRecords'
import journalHydrationRecords from 'ReduxStore/journalHydrationRecordsSlice'
import journalMotionRecords from 'ReduxStore/journalMotionRecords'
import keyCloakUser from 'ReduxStore/keyCloakUser'
import menuPlan from 'ReduxStore/menuPlan'
import myAnalytics from 'ReduxStore/myAnalytics'
import programs from 'ReduxStore/programs'
import recipes from 'ReduxStore/recipes'
import resetUser from 'ReduxStore/resetUser'
import temporaryAccess from 'ReduxStore/temporaryAccessSlice'
import toastMessage from 'ReduxStore/toastMessage'
import userFoods from 'ReduxStore/userFoods'
import userRecipes from 'ReduxStore/userRecipes'
import userSettings from 'ReduxStore/userSettings'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const storeEnhancer = applyMiddleware(thunk, promise)
const isDev = process.env.NODE_ENV === 'development'
const enhancer = isDev ? composeEnhancers(storeEnhancer) : storeEnhancer

const appReducer = combineReducers<ReduxStoreState>({
  anamnesis,
  customer,
  energyRequirements,
  favorites,
  journalDay,
  journalEatableRecords,
  journalEnergyMotionRecords,
  journalEnergyRecords,
  journalHydrationRecords,
  journalMotionRecords,
  menuPlan,
  programs,
  bodyMeasurements,
  foodCategories,
  userFoods,
  userRecipes,
  eatableSources,
  keyCloakUser,
  coreBackendUser,
  userSettings,
  bodyAnalytics,
  myAnalytics,
  pendingCounter,
  recipes,
  resetUser,
  toastMessage,
  temporaryAccess,
  error
})

const rootReducer = (state: ReduxStoreState | undefined, action: AnyAction): ReduxStoreState => {
  if (state && (action.type === RESET_REDUX_STORE || action.type === RESET_USER_FULFILLED)) {
    const { coreBackendUser } = state
    return {
      ...appReducer(undefined, action),
      coreBackendUser: {
        ...coreBackendUser,
        data: coreBackendUser.data
      },
      toastMessage: {
        content: `⚠️ \u00A0 The user's data  has been reset. You may need to reload the page! \u00A0 ⚠️`,
        type: 'dark'
      }
    }
  }
  return appReducer(state, action)
}

export const resetStoreAction = (): Action => ({
  type: RESET_REDUX_STORE
})

export const createRootStore = (preloadedState?: ReduxStoreState): Store<ReduxStoreState> =>
  createStore(rootReducer, preloadedState, enhancer)

const rootStore = createRootStore()

export type RootState = ReturnType<typeof rootStore.getState>
export type AppDispatch = typeof rootStore.dispatch

export type TypedDispatch = ThunkDispatch<RootState, unknown, AnyAction>
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>

export default rootStore
