import React, { ChangeEvent, FC, RefObject, useCallback } from 'react'
import { UseFormMethods } from 'react-hook-form'

export type FormInputTextProps = Partial<Pick<UseFormMethods, 'register' | 'errors'>> & {
  name?: string
  label?: string
  value?: string
  role?: string
  defaultValue?: string
  type?: 'default' | 'password'
  autocomplete?: string
  disabled?: boolean
  onBlur?: () => void
  onChange?: (text: string) => void
  onFocus?: () => void
  errorText?: string
  inputRef?: RefObject<HTMLInputElement>
}

export const FormInputText: FC<FormInputTextProps> = ({
  name,
  label,
  value,
  defaultValue,
  type = 'default',
  role,
  autocomplete,
  disabled = false,
  onBlur,
  onChange,
  onFocus,
  register,
  errorText,
  errors,
  inputRef
}) => {
  const handleTextChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(value)
    },
    [onChange]
  )

  const errorMessage = (errors && name && errors[name] && errors[name].message) || errorText

  return (
    <div className="input">
      <input
        ref={register || inputRef}
        name={name}
        type={type === 'default' ? 'text' : 'password'}
        role={role}
        autoComplete={autocomplete}
        placeholder=" "
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleTextChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {label && <label>{label}</label>}
      {errorMessage && <div className="input-error">{errorMessage}</div>}
    </div>
  )
}
