import { combineReducers } from 'redux'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'
import { createSelector } from 'reselect'

import {
  FETCH_CURRENT_PROGRAM,
  FETCH_CURRENT_PROGRAM_FULFILLED,
  FETCH_PROGRAMS,
  FETCH_PROGRAMS_FULFILLED,
  POST_PROGRAM,
  POST_PROGRAM_FULFILLED
} from './actionTypes'
import { createPendingCounterReducer } from './common'

import { today } from 'Config'
import { NewProgram, Program, ProgramsReduxState, ReduxStoreState } from 'Models'
import { coreBackendService } from 'Services'

// Helper functions
const joinPrograms = (programs1: Program[] | null, programs2: Program[] | null): Program[] => {
  const copy = [...(programs2 || [])]
  ;(programs1 || []).forEach((proragm) => {
    if (
      !copy.find((candidate) => {
        return candidate.id === proragm.id
      })
    ) {
      copy.push(proragm)
    }
  })

  return copy
}

// Actions
export const fetchProgramsAction = (): AsyncAction => ({
  type: FETCH_PROGRAMS,
  payload: coreBackendService.fetchPrograms()
})

export const fetchCurrentProgramAction = (): AsyncAction => ({
  type: FETCH_CURRENT_PROGRAM,
  payload: coreBackendService.fetchCurrentProgram()
})

export const postProgramAction = (program: NewProgram): AsyncAction => ({
  type: POST_PROGRAM,
  payload: coreBackendService.postProgram(program)
})

// Reducer
const data = (state: Program[] | null = null, { type, payload }: FluxStandardAction): Program[] | null => {
  switch (type) {
    case FETCH_PROGRAMS_FULFILLED:
      return joinPrograms(state, payload)
    case FETCH_CURRENT_PROGRAM_FULFILLED:
    case POST_PROGRAM_FULFILLED:
      return joinPrograms(state, [payload])
    default:
      return state
  }
}

// Selectors
export const programsSelector = ({ programs }: ReduxStoreState): Program[] | null => programs.data

export const currentProgramSelector = createSelector([programsSelector], (data) =>
  data === null ? undefined : data.find(({ active }) => active) || null
)

export const firstProgramSelector = createSelector([programsSelector], (data) =>
  data ? data.reduce((a, b) => (a.startDate < b.startDate ? a : b)) : null
)

export const currentProgramStartDateSelector = createSelector([currentProgramSelector], (program) =>
  program ? program.startDate : today
)

export const programStartDatesSelector = createSelector([programsSelector], (data) =>
  data === null
    ? undefined
    : data
        .filter(({ startDate }) => startDate)
        .map(({ startDate }) => ({
          value: startDate.getTime()
        }))
)

const asyncActionTypes = [FETCH_PROGRAMS, FETCH_CURRENT_PROGRAM, POST_PROGRAM]

export default combineReducers<ProgramsReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
