import React, { useEffect, useState } from 'react'

type Props = {
  radius: number
  stroke: number
  percent: number
}

export const Circle = ({ radius, stroke, percent }: Props): JSX.Element => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgress(percent)
    }, 200)
    return () => clearTimeout(timer)
  }, [percent])

  const normalizedRadius = radius - stroke - 1
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = (circumference * (100 - progress)) / 100 || 0

  return (
    <svg height={radius * 2} width={radius * 2} className="circle">
      <circle
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        strokeDashoffset={strokeDashoffset}
        style={{ transition: 'stroke-dashoffset 1s ease' }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  )
}
