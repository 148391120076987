import React from 'react'
import Modal from 'react-modal'

import JournalFavoriteTabs from './JournalFavoriteTabs'

import { Icon, IconButton, IconColor, IconName } from 'Components'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

const JournalFavoriteTabsModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  return (
    <Modal style={modalZIndexPriority} isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
      <div className="modal is-medium">
        <IconButton name={IconName.close} onClick={onClose} />
        <div className="p-5">
          <div className="row">
            <div style={{ backgroundRepeat: 'no-repeat' }} className="pr-1">
              <Icon name={IconName.heart} color={IconColor.GRAY_2} />
            </div>
            <h2 className="pl-0">Favoriten</h2>
          </div>
          <JournalFavoriteTabs onClose={onClose} />
        </div>
      </div>
    </Modal>
  )
}

export default JournalFavoriteTabsModal
