import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import FormData from 'form-data'
import { v4 as uuidv4 } from 'uuid'

import { ProtectedBackendService } from './EbalanceBackendService'

export const EXTENSIONS: { [key: string]: string } = {
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif'
}

export type ImageReturnType = { url: string; fileName: string; id: number }

type Config = AxiosRequestConfig

export class ImageService implements ProtectedBackendService {
  static acceptedTypes = Object.keys(EXTENSIONS).join(',')

  private client: AxiosInstance
  private _authToken: string | undefined = undefined

  constructor(private config: Config, public baseImagePath: string) {
    this.client = axios.create(this.config)
  }

  set authToken(newValue: string | undefined) {
    this._authToken = newValue
  }

  get authToken(): string | undefined {
    return this._authToken
  }

  async getImageServerToken(): Promise<string> {
    const isDev = process.env.NODE_ENV === 'development'
    const postRequest = isDev
      ? this.client.post(`token`)
      : axios.post('/image-service-token', null, {
          headers: {
            Authorization: `Bearer ${this._authToken}`
          }
        })
    const response = await postRequest
    return response.data.data.token
  }

  async uploadImage(file: File, path: string, fileName: string | null): Promise<ImageReturnType> {
    fileName = fileName ?? `${uuidv4()}.${EXTENSIONS[file.type]}`
    const formData = new FormData()
    formData.append('files', file, fileName)
    formData.append('path', `${this.baseImagePath}/${path}`)
    formData.append('overwrite', 'yes')

    const token = await this.getImageServerToken()
    const config = {
      auth: {
        username: token,
        password: 'unused'
      }
    }
    const response = await this.client.post(`upload`, formData, config)

    return {
      url: response.data.data[fileName].url,
      fileName,
      id: response.data.data[fileName].id
    }
  }

  async fetchImageIdBySrc(src: string): Promise<number> {
    const response = await this.client.get(`details/?src=${src}`)
    return response.data.data.id
  }

  async fetchImageIdByUrl(imageUrl: string): Promise<number | null> {
    try {
      const src = this.getImageSrcFromUrl(imageUrl)
      if (!src) return null
      return await this.fetchImageIdBySrc(src)
    } catch (err) {
      console.error(`fetchImageIdByUrl: invalid imageUrl '${imageUrl}'.`)
    }

    return null
  }

  getImageSrcFromUrl(imageUrl: string): string | null {
    const url = new URL(imageUrl)
    return url.searchParams.get('src')
  }

  async deleteImage(id: number): Promise<void> {
    const token = await this.getImageServerToken()
    const config = {
      auth: {
        username: token,
        password: 'unused'
      }
    }
    await this.client.delete(`admin/filesystem/images/${id}/`, config)
  }
}
