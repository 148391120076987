import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import _ from 'lodash'
import React, { ReactNode } from 'react'

import getTheme from './themes'

// The wrapper exports only a default component class that at the same time is a
// namespace for the related Props interface (HighchartsReact.Props). All other
// interfaces like Options come from the Highcharts module itself.
type DataEntry = [ReactNode, ReactNode] | { name: ReactNode; y: ReactNode }

interface Props {
  /** Defaults to 'linear'. */
  dataType?: 'linear' | 'logarithmic' | 'datetime' | 'category'
  /** Either `data` or `series` should be set. */
  data?: Array<DataEntry>
  /** can be set if 'data' is set */
  name?: string
  /** Overrules the `data` property. Either `data` or `series` should be set. */
  series?: Highcharts.SeriesOptionsType[]
  xAxisPlotLines?: Highcharts.XAxisPlotLinesOptions[]
  /** Defaults to 'exact'. */
  theme?: 'exact' | 'smooth'
  /** if datatype is 'datetime' the tick interval represents 1 day */
  xAxisTickInterval?: number
  /** Boolean that determines whether a data change should be animated (defaults to true)*/
  animated?: boolean
  /** Specific height, defaults to 'auto' */
  height?: number | string
}

export const ChartLine: React.FC<Props> = ({
  theme: themeName = 'exact',
  xAxisTickInterval,
  animated = true,
  height = 'auto',
  name,
  ...props
}: Props) => {
  const theme = getTheme(themeName)

  const options: Highcharts.Options = _.merge({}, theme.options, {
    chart: {
      animation: animated,
      height: height
    },
    xAxis: {
      type: props.dataType,
      tickInterval: xAxisTickInterval && xAxisTickInterval * (props.dataType === 'datetime' ? 24 * 3600 * 1000 : 1),
      plotLines: props.xAxisPlotLines
    },
    series: props.series || [{ ...theme.defaultSeries, data: props.data, showInLegend: !!name, name }]
  })

  return <HighchartsReact options={options} {...props} />
}
