import { AnyAction, Reducer } from 'redux'
import { ActionType } from 'redux-promise-middleware'

import { PendingCounterState } from 'Models'

/**
 * Creates an reducer that counter pending promises.
 * @param actionTypes A list of base action types that should be reacted on. When omitted the reducer will react to all promise actions.
 * @returns the counter reducer
 */
export const createPendingCounterReducer = (...actionTypes: string[]): Reducer<PendingCounterState> => {
  const actionTypesPatter = actionTypes.length === 0 ? '.+?' : `(${actionTypes.join(`|`)})`
  const incremenetActionRegex = new RegExp(`^${actionTypesPatter}_${ActionType.Pending}`)
  const decremenetActionRegex = new RegExp(`^${actionTypesPatter}_(${ActionType.Fulfilled}|${ActionType.Rejected})`)

  return (state: PendingCounterState = 0, { type }: AnyAction): PendingCounterState => {
    if (incremenetActionRegex.test(type)) {
      return state + 1
    }

    if (decremenetActionRegex.test(type)) {
      return Math.max(state - 1, 0)
    }

    return state
  }
}

export default createPendingCounterReducer()
