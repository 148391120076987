import React, { FC } from 'react'
import Modal from 'react-modal'
import { ConnectedProps, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { IconButton, IconName } from 'Components'
import { routes } from 'Config'
import { ReduxStoreState } from 'Models'
import { hideAccessDeniedModalAction, showAccessDeniedModalSelector } from 'ReduxStore/error'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

type SelectorProps = {
  showAccessDeniedModal: boolean
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  showAccessDeniedModal: showAccessDeniedModalSelector
})

const mapDispatchToProps = {
  hideAccessDeniedModal: hideAccessDeniedModalAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const registerMembershipPath = routes.register.plan

export const AccessDeniedModal: FC<PropsFromRedux> = ({ showAccessDeniedModal, hideAccessDeniedModal }) => {
  if (!showAccessDeniedModal) {
    return null
  }
  if (location.pathname === registerMembershipPath) {
    return null
  }
  return (
    <Modal style={modalZIndexPriority} isOpen={true} onRequestClose={hideAccessDeniedModal} ariaHideApp={false}>
      <div className="modal is-small">
        <IconButton name={IconName.close} onClick={hideAccessDeniedModal} />
        <article>
          <h2>Ihre Mitgliedschaft ist leider ausgelaufen</h2>
          <p>
            Da Sie aktuell über keine aktive eBalance-Mitgliedschaft verfügen, sind einige Funktionen für Sie gesperrt.
            Aber keine Sorge, von Ihren Voreinstellungen, Kalorienanalysen oder Nahrungsmittel-Präferenzen ist nichts
            verloren gegangen. Werden Sie einfach wieder Mitglied und nutzen Sie noch heute alle Funktionen von
            eBalance.
            <br />
            <br />
            Sie haben eine Frage oder brauchen unsere Unterstützung? Unser Support-Team hilft Ihnen gerne! Schreiben Sie
            uns ungeniert an <a href="mailto:service@eBalance.ch">service@eBalance.ch</a>.
            <br />
            <br />
            Herzliche Grüsse
            <br />
            Ihr eBalance-Team
          </p>
          <Link to={registerMembershipPath} onClick={hideAccessDeniedModal} className="btn">
            Jetzt wieder Mitglied werden
          </Link>
        </article>
      </div>
    </Modal>
  )
}

export default connector(AccessDeniedModal)
