import React, { useEffect } from 'react'
import Modal from 'react-modal'

import { DetailCardRecipe } from 'Components'
import { FavoritesMapping, QuantifiedRecipe } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAddOrRemoveFavorite } from 'ReduxStore/favorites/useAddOrRemoveFavorite'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { fetchRecipeAction, recipesSelector } from 'ReduxStore/recipes'
import { Utils } from 'Utils'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

type Props = {
  quantifiedRecipe?: QuantifiedRecipe
  onClose: () => void
}

const RecipeModal: React.FC<Props> = ({ quantifiedRecipe, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const favorite = useAppSelector(favoriteSelector(FavoritesMapping.RECIPES, quantifiedRecipe?.recipe?.id))
  const { onFavoriteChange } = useAddOrRemoveFavorite()
  const recipe = useAppSelector(recipesSelector)?.find((recipe) => recipe.id === quantifiedRecipe?.recipe?.id)
  const recipeId = Utils.eatableRecipeId(quantifiedRecipe)

  useEffect(() => {
    if (recipeId) {
      dispatch(fetchRecipeAction(recipeId))
    }
  }, [quantifiedRecipe?.recipe?.id])

  const handleOnFavoriteChange = (): void => {
    if (quantifiedRecipe!.unit && quantifiedRecipe!.quantity) {
      onFavoriteChange(favorite, {
        quantity: quantifiedRecipe!.quantity!,
        unit: quantifiedRecipe!.unit!,
        recipeId: quantifiedRecipe!.recipe!.id!
      })
    } else {
      onFavoriteChange(favorite, {
        quantity: 1,
        unit: 'PORTION',
        recipeId: quantifiedRecipe!.recipe!.id!
      })
    }
  }

  return (
    <Modal
      style={modalZIndexPriority}
      isOpen={!!(quantifiedRecipe && quantifiedRecipe.recipe)}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <div className="modal is-medium">
        {quantifiedRecipe && quantifiedRecipe.recipe ? (
          <DetailCardRecipe
            recipe={recipe || quantifiedRecipe.recipe}
            isFavorite={!!favorite}
            onFavoriteChange={handleOnFavoriteChange}
            onClose={onClose}
          />
        ) : (
          <div>Modal Placeholder</div>
        )}
      </div>
    </Modal>
  )
}

export default RecipeModal
