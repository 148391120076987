import React from 'react'

import { PageLayout } from 'Components'

const MemberRecruitPage: React.FC = () => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700 pb-5">
        <h2>Mitglieder werben Mitglieder </h2>
        <p>
          Wir freuen uns, Ihnen unser «Mitglieder werben Mitglieder»-Programm vorstellen zu dürfen. Das ermöglicht es
          Ihnen, Ihre Reise zum Wunschgewicht mit Ihren Freunden und Liebsten zu teilen – und dabei selbst von einem
          15-%-Rabattgutschein zu profitieren.
        </p>
        <p>
          Wir wissen, dass Erfolg im Team besonders lohnend sein kann. Deshalb haben wir dieses Programm entwickelt, um
          Ihre Bemühungen zu belohnen und gleichzeitig anderen dabei zu helfen, ihre Ziele zu erreichen.
        </p>
        <ol style={{ listStyle: 'auto' }} className="my-3">
          <li>
            Teilen Sie Ihre Erfolge: Wenn Sie bereits Mitglied von eBalance sind und Fortschritte erzielt haben, möchten
            wir Sie ermutigen, Ihre Erfahrungen mit Freunden und Familie zu teilen. Ihre Geschichte und Ihr Erfolg
            können eine Inspirationsquelle sein, um andere dazu zu motivieren, ebenfalls den wichtigen Weg zu einer
            besseren Gesundheit einzuschlagen.
          </li>
          <li className="mt-1">
            Wenn Sie sich bei unserem Programm anmelden, erhalten Sie per E-Mail einen 15-%-für-3-Monate-Gutscheincode
            für Ihre Freunde. Diesen können Sie an all Ihre Freunde weitergeben.
          </li>
          <li className="mt-1">
            Sie profitieren ebenfalls: Als Dankeschön für Ihre Empfehlung belohnen wir auch Sie. Für jedes neu
            registrierte Mitglied, das Ihren Gutscheincode eingelöst* hat, erhalten Sie zur nächsten Abrechnung einen
            15%igen Rabatt, der in Ihrem Kundenkonto hinterlegt wird.
          </li>
          <li className="mt-1">
            Das Anmeldeformular finden Sie am Ende dieser Seite. Beantworten Sie einfach unsere Fragen und schicken Sie
            das Anmeldeformular ab. Sie erhalten dann eine E-Mail von uns mit Ihrem individuellen Gutscheincode für Ihre
            Freunde und Familie.
          </li>
        </ol>
        <p>
          Erreichen Sie Ihr Wunschgewicht zusammen mit Ihren Liebsten und sparen Sie dabei noch 15 % auf die
          Mitgliedschaft.
        </p>
        <h2 className="mt-2 pt-2">Jetzt Anmelden</h2>
        <div className="text-tiny">*nur bei erfolgreicher Zahlung gültig.</div>
      </div>
      <div className="container">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScGn_yQ0QXio_BMdu1eiOycY4piPL5NXGwo5TnOtq_2r-POvg/viewform?embedded=true"
          style={{ border: 0 }}
          width="100%"
          height="1024"
        >
          Loading…
        </iframe>
      </div>
    </PageLayout>
  )
}

export default MemberRecruitPage
