import React, { FC, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import WeightWarningModal from './WeightWarningModal'
import BellyLineChart from './components/BellyLineChart'
import { BodyMeasurementInput } from './components/BodyMeasurementInput'
import InfoPanel from './components/InfoPanel'
import WeightGauge from './components/WeightGauge'
import WeightLineChart from './components/WeightLineChart'

import { BellyInput, PageLayout, Panel } from 'Components'
import { today } from 'Config'
import { BodyMeasurementPoint, Program, ReduxStoreState, UNITS } from 'Models'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'
import { useUpdateBodyMeasurmentBMIBoundary } from 'Pages/BaseDataPage/useUpdateBodyMeasurmentBMIBoundary'
import { getAnamnesisAction, getAnamnesisDataSelector } from 'ReduxStore/anamnesis'
import {
  bodyMeasurementsBellySelector,
  bodyMeasurementsWeightSelector,
  createOrUpdateBodyMeasurementsAction,
  fetchBodyMeasurementsAction,
  latestBodyMeasurementSelector
} from 'ReduxStore/bodyMeasurements'
import { isLoadingSelector } from 'ReduxStore/common'
import { fetchUserGoalNutritionsAction } from 'ReduxStore/coreBackendUser'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { currentProgramSelector, fetchProgramsAction, programsSelector } from 'ReduxStore/programs'
import { Calculator } from 'Utils'

type SelectorProps = {
  programs: Program[] | null
  currentProgram?: Program | null
  latestWeightBodyMeasurement?: BodyMeasurementPoint
  latestHeightBodyMeasurement?: BodyMeasurementPoint
  latestBellyBodyMeasurement?: BodyMeasurementPoint
  bodyMeasurementsBelly: BodyMeasurementPoint[]
  bodyMeasurementsWeight: BodyMeasurementPoint[]
  bellyLoading: boolean
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  programs: programsSelector,
  latestHeightBodyMeasurement: (state) => latestBodyMeasurementSelector(state, 'HEIGHT'),
  latestBellyBodyMeasurement: (state) => latestBodyMeasurementSelector(state, 'BELLY'),
  bodyMeasurementsBelly: bodyMeasurementsBellySelector,
  bodyMeasurementsWeight: bodyMeasurementsWeightSelector,
  bellyLoading: (state) => isLoadingSelector(state.bodyMeasurements)
})

const mapDispatchToProps = {
  fetchBodyMeasurements: fetchBodyMeasurementsAction,
  fetchUserGoalNutritions: fetchUserGoalNutritionsAction,
  postBodyMeasurements: createOrUpdateBodyMeasurementsAction,
  fetchPrograms: fetchProgramsAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const MassPage: FC<PropsFromRedux> = ({
  fetchBodyMeasurements,
  fetchUserGoalNutritions,
  postBodyMeasurements,
  fetchPrograms,
  latestHeightBodyMeasurement,
  latestBellyBodyMeasurement,
  bodyMeasurementsBelly,
  bodyMeasurementsWeight,
  programs,
  bellyLoading
}: PropsFromRedux) => {
  const dispatch = useAppDispatch()
  const [weightWarningModalProps, setWeightWarningModalProps] = useState<{
    height?: number | null
    weight?: number
    programType?: ProgramTypes
    isOpen: boolean
  }>({ isOpen: false })
  const currentProgram = useAppSelector(currentProgramSelector)
  const anamnesisData = useAppSelector(getAnamnesisDataSelector)
  const latestWeightBodyMeasurement = useAppSelector((state) => latestBodyMeasurementSelector(state, 'WEIGHT'))

  const updateBodyMeasurmentsBMIBoundary = useUpdateBodyMeasurmentBMIBoundary()

  useEffect(() => {
    fetchPrograms()
    fetchBodyMeasurements()
    fetchUserGoalNutritions(today)
  }, [])

  useEffect(() => {
    if (currentProgram?.anamnesisId && !anamnesisData) {
      dispatch(getAnamnesisAction(currentProgram.anamnesisId))
    }
  }, [currentProgram])

  const { value: latestHeight = 0 } = latestHeightBodyMeasurement || {}
  const { value: currentWeight } = { ...latestWeightBodyMeasurement }
  const { startValue: startWeight } = { ...currentProgram }

  const shouldDisplayWeightGainWarning = (): boolean => {
    return (
      !!anamnesisData &&
      !!currentWeight &&
      !!startWeight &&
      !!anamnesisData.weightGainThreshold &&
      anamnesisData.activeProgramType === ProgramTypes.KEEP_WEIGHT &&
      !!anamnesisData.shouldDisplayWeightGainWarning &&
      currentWeight - startWeight - anamnesisData.weightGainThreshold >= 0
    )
  }

  const updateWeight = async (newWeightData: BodyMeasurementPoint): Promise<void> => {
    const latestWeight = latestWeightBodyMeasurement?.value
    const wasPreviouslyNormalWeight = Calculator.isUserNormalWeight(
      latestWeight ?? null,
      latestHeightBodyMeasurement?.value ?? null
    )
    const isNowUnderWeight = Calculator.isUserUnderweight(
      newWeightData.value,
      latestHeightBodyMeasurement?.value ?? null
    )
    const isUserLowWeight = Calculator.isUserLowWeight(newWeightData.value, latestHeightBodyMeasurement?.value ?? null)

    const isUserOverweight = Calculator.isUserOverweight(
      newWeightData.value,
      latestHeightBodyMeasurement?.value ?? null
    )

    await updateBodyMeasurmentsBMIBoundary({
      bodyCriterion: 'WEIGHT',
      newValue: newWeightData.value,
      wasPreviouslyNormalWeight,
      isNowUnderWeight,
      isNowLowWeight: isUserLowWeight,
      isNowOverWeight: isUserOverweight,
      currentAnamnesis: anamnesisData,
      newProgramWeight: newWeightData.value,
      openWeightWarningModal: ({ programType }) =>
        setWeightWarningModalProps({
          height: latestHeightBodyMeasurement?.value ?? null,
          weight: newWeightData.value,
          programType,
          isOpen: true
        })
    })
  }
  return (
    <PageLayout theme="smallcircle">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <Panel>
              <div className="equal-masse-panel">
                <WeightGauge
                  shouldDisplayWeightGainWarning={shouldDisplayWeightGainWarning()}
                  latestWeightBodyMeasurement={latestWeightBodyMeasurement}
                  currentProgram={currentProgram}
                />
              </div>
              <BodyMeasurementInput
                value={latestWeightBodyMeasurement ? latestWeightBodyMeasurement.value : 0}
                postBodyMeasurements={updateWeight}
                bodyCriterion="WEIGHT"
                unitLabel={UNITS.KILOGRAM.abbreviation}
              />
            </Panel>
          </div>
          <div className="col-12 col-lg-6">
            <Panel>
              <div className="equal-masse-panel">
                <BellyLineChart bodyMeasurementsBelly={bodyMeasurementsBelly} loading={bellyLoading} />
              </div>
              <BellyInput
                latestBellyBodyMeasurement={latestBellyBodyMeasurement}
                postBodyMeasurements={postBodyMeasurements}
              />
            </Panel>
          </div>
          <div className="col-12">
            <WeightLineChart
              bodyMeasurementsWeight={bodyMeasurementsWeight}
              programs={programs}
              height={latestHeight}
            />
          </div>
          <div className="col-12">
            <InfoPanel currentProgram={currentProgram} />
          </div>
        </div>
      </div>
      <WeightWarningModal
        {...weightWarningModalProps}
        closeModal={() => setWeightWarningModalProps({ isOpen: false })}
      />
    </PageLayout>
  )
}

export default connector(MassPage)
