import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'

import { colors as c } from 'Config/colors'
import { ChartPieData } from 'Models'
import { Formatter } from 'Utils'

// The wrapper exports only a default component class that at the same time is a
// namespace for the related Props interface (HighchartsReact.Props). All other
// interfaces like Options come from the Highcharts module itself.

interface Props {
  /** width & height, defaults to 250 */
  size?: number
  data: ChartPieData
  colors?: Highcharts.ColorString[]
}

const defaultSeries: Highcharts.SeriesPieOptions = {
  type: 'pie',
  name: ''
}

export const ChartPie: React.FC<Props> = (props: Props) => {
  const {
    data,
    size = 200,
    colors = [c.pieChart.breakfast, c.pieChart.lunch, c.pieChart.dinner, c.pieChart.snack]
  } = props

  const options: Highcharts.Options = {
    chart: {
      backgroundColor: undefined,
      plotShadow: false,
      type: 'pie',
      width: size,
      margin: [-10, -10, -10, -10],
      spacing: [0, 0, 0, 0]
    },
    title: {
      text: ''
    },
    series: [defaultSeries],
    plotOptions: {
      pie: {
        borderWidth: 3,
        innerSize: '65%',
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: 'bold',
            color: 'white'
          }
        }
      },
      series: {
        states: {
          hover: {
            brightness: 0,
            halo: null
          }
        }
      }
    },
    colors,
    colorAxis: {
      reversed: true
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.name}</b>: ${Formatter.formatPercentage((this.percentage || 0) / 100)}`
      }
    }
  }

  return data && data.length > 0 ? (
    <HighchartsReact
      containerProps={{
        style: { width: size, height: size }
      }}
      options={{ ...options, series: [{ ...defaultSeries, data }] }}
      {...props}
    />
  ) : null
}
