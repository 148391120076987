import React, { FC, useState } from 'react'
import { IoInformationCircleOutline } from 'react-icons/io5'

import './Tooltip.css'

type TooltipProps = {
  children: string
}

const Tooltip: FC<TooltipProps> = ({ children }: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className="tooltip-container" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      <IoInformationCircleOutline size="1.8rem" className="ml-1 text-gray-2" />
      <div className={`tooltip-content ${!isVisible && 'fade-out'}`}>
        <p className="text-small">{children}</p>
      </div>
    </div>
  )
}

export default Tooltip
