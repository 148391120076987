export type Color = string

const designSystem = {
  // Gray
  gray1: '#222528',
  gray2: '#A1A8AF',
  gray3: '#E3E6E9',
  gray4: '#F7F8F9',
  // Interface
  blue1: '#32B6EF',
  blue2: '#119CD8',
  red: '#E0081B',
  white: '#FFFFFF',
  // Nutritions
  protein: '#FF9233',
  carbs: '#97D154',
  fat: '#FAE269',
  alcohol: '#B3A4A1',
  // Meals
  breakfast: '#69BFA3',
  lunch: '#34828C',
  dinner: '#1D3F43',
  snack: '#9CB8AD'
}

export const colors = {
  designSystem, // this needs to be refactored, using this constant directly is bad for maintenance
  gaugeSvg: {
    primaryColor: designSystem.blue1,
    primaryColorDark: designSystem.blue2,
    inactive: designSystem.gray3,
    pointer: designSystem.gray1
  },
  lineChart: {
    primaryColor: designSystem.gray2,
    gridLabels: designSystem.gray2,
    gridLines: designSystem.gray4,
    shadow: designSystem.gray3,
    programRestart: designSystem.blue1,
    goal: designSystem.blue2,
    legend: designSystem.gray2,
    tooltipBackground: designSystem.gray1,
    tooltipColor: designSystem.white,
    marker: designSystem.white
  },
  pieChart: {
    protein: designSystem.protein,
    carbohydrates: designSystem.carbs,
    fat: designSystem.fat,
    alcohol: designSystem.alcohol,
    breakfast: designSystem.breakfast,
    lunch: designSystem.lunch,
    dinner: designSystem.dinner,
    snack: designSystem.snack
  }
}
