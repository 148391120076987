import Highcharts from 'highcharts'
import _ from 'lodash'

import getCommonTheme from './common'

export type SeriesOptions = Highcharts.SeriesSplineOptions

export function getDefaultSeries(): SeriesOptions {
  return {
    type: 'spline'
  }
}

export default function getOptions(): Highcharts.Options {
  return _.merge({}, getCommonTheme(), {
    chart: {
      type: 'spline'
    },
    xAxis: {
      opposite: true
    },
    yAxis: {
      visible: false
    }
  })
}
