import { constants } from './constants'

/**
 * Builds the full CDN image URL for the given filename or filepath.
 * @param filepath example: `logo.svg`
 * @returns example: `https://cdn.ebalance.ch/design-system/v0.23/img/logo.svg`
 */
export function buildCdnImageUrl(filepath: string): string {
  if (!/\/$/.test(constants.cdnBaseUrl)) {
    constants.cdnBaseUrl += '/'
  }
  return constants.cdnBaseUrl + filepath
}
