import React, { FC } from 'react'
import { UseFormMethods } from 'react-hook-form'

import { FormInputNumberController, FormInputSelect } from 'Components'
import { UNITS, UnitId, foodUnitMappingUnitIds } from 'Models'

type Props = Pick<UseFormMethods, 'register' | 'errors' | 'control' | 'setValue'>

export const FoodUnitMappingInput: FC<Props> = ({ register, control, errors, setValue }: Props) => {
  return (
    <>
      <div className="col-6">
        <FormInputSelect
          name="unit"
          label="Einheit"
          items={foodUnitMappingUnitIds}
          labelExtractor={(id) => UNITS[id as UnitId].name}
          register={register}
          errors={errors}
          isOptional
        />
      </div>
      <div className="col-6">
        <FormInputNumberController
          label="Gramm pro Einheit"
          name="gramsPerUnit"
          register={register}
          setValue={setValue}
          errors={errors}
          control={control}
          maxFractions={1}
        />
      </div>
    </>
  )
}
