import React, { useCallback, useEffect, useState } from 'react'
import ReactDayPicker, { BeforeAfterModifier, DayModifiers } from 'react-day-picker'

import { YearMonthForm, fromMonth, toMonth } from './YearMonthForm'
import { DayJsLocaleUtils } from './helper'

import { today } from 'Config'
import { DayPickerRange } from 'Models'

import 'react-day-picker/lib/style.css'

interface Props {
  date: Date
  rangeAllowed?: DayPickerRange
  isDatePickerDisplayed: boolean
  onDayClick: (date: Date, modifiers: DayModifiers) => void
}

export const DayPicker: React.FC<Props> = ({
  date,
  isDatePickerDisplayed,
  rangeAllowed: { from: rangeFrom = fromMonth, to: rangeTo = toMonth } = {},
  onDayClick
}: Props) => {
  const modifiers = {
    selectedDay: date,
    today: today,
    day: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] }
  }

  const disabledDays: BeforeAfterModifier = {
    before: rangeFrom,
    after: rangeTo
  }

  const [month, setMonth] = useState(date)

  const handleYearMonthChange = useCallback((date: Date) => {
    setMonth(date)
  }, [])

  useEffect(() => setMonth(date), [date])

  return isDatePickerDisplayed ? (
    <ReactDayPicker
      locale="de-ch"
      localeUtils={DayJsLocaleUtils}
      month={month}
      fromMonth={rangeFrom}
      toMonth={toMonth}
      modifiers={modifiers}
      onDayClick={onDayClick}
      firstDayOfWeek={1}
      onMonthChange={handleYearMonthChange}
      selectedDays={date}
      captionElement={({ date, localeUtils }) => (
        <YearMonthForm date={date} localeUtils={localeUtils} onChange={handleYearMonthChange} />
      )}
      disabledDays={disabledDays}
    />
  ) : null
}
