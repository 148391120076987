import React, { BaseSyntheticEvent, FC } from 'react'
import { UseFormMethods } from 'react-hook-form'

import {
  Button,
  FormInputAutocompleteController,
  FormInputNumberController,
  FormInputText,
  ImageUploadController,
  RichTextInputController
} from 'Components'
import { FormInputIngredientsProps } from 'Components/FormInputIngredients/FormInputIngredients'
// eslint-disable-next-line import/no-named-as-default
import FormInputIngredientsController from 'Components/FormInputIngredients/FormInputIngredientsController'
import { constants } from 'Config'
import { EatableSource } from 'Models'
import { ImageUploadingFormProps } from 'Utils/helpers/useImageUploading'

export type RecipeFormProps = Pick<UseFormMethods, 'register' | 'errors' | 'setValue' | 'setError' | 'control'> &
  Pick<FormInputIngredientsProps, 'algoliaSearchClient'> &
  ImageUploadingFormProps & {
    isSubmitting: boolean
    eatableSources: EatableSource[]
    onSubmit: (e?: BaseSyntheticEvent) => Promise<void>
    onCancel?: () => void
  }

export const RecipeForm: FC<RecipeFormProps> = ({
  algoliaSearchClient,
  setTempImageId,
  setIsImageUploading,
  isImageUploading,
  isSubmitting,
  onSubmit,
  setError,
  eatableSources,
  onCancel,
  ...reactHookFormProps
}: RecipeFormProps) => {
  const areButtonsDisabled = isSubmitting || isImageUploading
  const sortAlphabetically = (a: { name: string }, b: { name: string }): number => a.name.localeCompare(b.name)

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className="row mt-0">
        <div className="col-12">
          <h4>Basisdaten</h4>
        </div>
        <div className="col-12">
          <FormInputText label="Name" name="name" {...reactHookFormProps} />
        </div>
        <div className="col-12 col-lg-6">
          <div className="row g-0 align-items-center">
            <div className="col-auto">Portionen</div>
            <div className="col-auto text-center">
              <FormInputNumberController
                name="defaultServingsCount"
                lowerBound={1}
                step={1}
                maxFractions={0}
                {...reactHookFormProps}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row g-0 align-items-center">
            <div className="col-auto">Zubereitungszeit</div>
            <div className="col-auto text-center">
              <FormInputNumberController
                name="preparationTime"
                lowerBound={1}
                step={1}
                maxFractions={0}
                {...reactHookFormProps}
              />
            </div>
            <div className="col-auto">Minuten</div>
          </div>
        </div>
        <FormInputAutocompleteController
          name="source"
          label="Quelle"
          allowNewItem
          items={eatableSources}
          sortItemsFunction={sortAlphabetically}
          {...reactHookFormProps}
        />
        <div className="col-12">
          <FormInputText label="Hinweis" name="hint" {...reactHookFormProps} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h4>Foto</h4>
        </div>
        <div className="col-12">
          <ImageUploadController
            name="imageUrl"
            path={constants.imageServiceFolders.userRecipePictures}
            theme="rectangle"
            onIdChange={setTempImageId}
            onUploadingChange={setIsImageUploading}
            setError={setError}
            {...reactHookFormProps}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h4>Zutaten</h4>
        </div>
        <div className="col-12">
          <FormInputIngredientsController
            name="ingredients"
            algoliaSearchClient={algoliaSearchClient}
            {...reactHookFormProps}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h4>Zubereitung</h4>
        </div>
        <div className="col-12">
          <RichTextInputController name="description" {...reactHookFormProps} />
        </div>
      </div>

      <div className="row">
        <div className="col-6 col-lg-4">
          <Button type="button" theme="secondary" disabled={areButtonsDisabled} onClick={onCancel}>
            Abbrechen
          </Button>
        </div>
        <div className="col-6 col-lg-4 offset-lg-4">
          <Button type="submit" disabled={areButtonsDisabled}>
            Speichern
          </Button>
        </div>
      </div>
    </form>
  )
}
