import dayjs from 'dayjs'
import React, { ReactElement } from 'react'

import { EnergyLevels, EnergyRequirementsPlan } from 'Models/EnergyRequirements'
import { Formatter } from 'Utils'

export const LabelMapping = {
  [EnergyLevels.LOW.name]: { text: 'langsame Reduzierung', textColor: 'text-gray-2' },
  [EnergyLevels.MEDIUM.name]: { text: 'Empfehlung: moderate Reduzierung', textColor: 'text-blue-1' },
  [EnergyLevels.HIGH.name]: { text: 'maximale Reduzierung', textColor: 'text-gray-2' }
}

export const EnergyLevelLabel: React.FC<{ text: string; textColor: string }> = ({ text, textColor }) => (
  <legend className={`px-1 pb-1 ${textColor}`}>{text}</legend>
)

export const EnergyPlanCard: React.FC<{
  cardHighlight?: { border: string }
  energyPlan?: EnergyRequirementsPlan
  label: ReactElement
  onClickCard?: () => void
}> = ({ cardHighlight, energyPlan, label, onClickCard }) => {
  if (!energyPlan) {
    return null
  }
  const goalDate = dayjs().add(energyPlan.days, 'day').toDate()
  return (
    <fieldset
      style={cardHighlight}
      className={`infopanel mt-2 row g-0 justify-content-around align-items-baseline ${onClickCard ? 'cursor' : ''}`}
      onClick={onClickCard}
    >
      {label}
      <span className="mt-0 text-gray-1" style={{ fontSize: '1.1em' }}>
        {Math.round(energyPlan.days / 7)} Wochen
      </span>
      <span className="mt-0 text-gray-2">{Formatter.formatHumanDate(goalDate)}</span>
      <span className="mt-0 text-gray-2">{Math.round(energyPlan.goal)} kcal</span>
    </fieldset>
  )
}
