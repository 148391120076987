import { current } from '@reduxjs/toolkit'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import styles from './FormWizard.module.css'
import { UseFormWizardReturnType } from './useFormWizard'

import { Button } from 'Components/Button'
import { IconColor, IconName } from 'Components/Icon'
import { IconButton } from 'Components/IconButton'
import { StepIndicator, StepIndicatorProps } from 'Components/StepIndicator'
import { routes } from 'Config'

export type FormWizardProps = Pick<
  UseFormWizardReturnType<never>,
  'currentStep' | 'currentStepIndex' | 'canGoBack' | 'isLastStep'
> &
  Pick<StepIndicatorProps, 'knownNumberOfSteps' | 'totalNumberOfSteps'> & {
    isRestart: boolean
    isSubmitting: boolean
    lastStepButtonText?: string
    onSubmit: React.FormEventHandler
    onGoBack: React.MouseEventHandler
    showBackButton: boolean
    showSubmitButton: boolean
    startButtonText?: string
  }

export const FormWizard: FC<FormWizardProps> = ({
  isRestart,
  children,
  currentStep,
  currentStepIndex,
  knownNumberOfSteps,
  totalNumberOfSteps,
  canGoBack,
  isLastStep,
  isSubmitting,
  lastStepButtonText = 'Programm starten',
  onGoBack,
  onSubmit,
  showBackButton,
  showSubmitButton,
  startButtonText
}) => {
  const history = useHistory()

  return (
    <form name={currentStep.id} onSubmit={onSubmit} noValidate>
      {isRestart && (
        <div className={`container max-700 ${styles.closeBtnContainer}`}>
          <div className={styles.closeBtnChild}>
            <IconButton
              name={IconName.close}
              color={IconColor.GRAY_2}
              onClick={() => history.push(routes.journal)}
              linkTitle="Abbrechen"
            />
          </div>
        </div>
      )}

      <StepIndicator
        currentStep={currentStepIndex + 1}
        knownNumberOfSteps={knownNumberOfSteps}
        totalNumberOfSteps={totalNumberOfSteps}
      />

      <div className="container max-700">
        <div className="row">
          <div className="col-12">
            {isRestart && !isLastStep && (
              <>
                <h1>Programm Neustart</h1>
                <p className={'mb-4'}>
                  Sie haben sich für einen Neustart entschieden. Um Ihnen den Prozess zu erleichtern, ist der Fragebogen
                  mit den Werten Ihres letzten Programmes bereits ausgefüllt.
                </p>
              </>
            )}
            {currentStep.infoTextElement ? currentStep.infoTextElement : null}
          </div>
        </div>

        {children}

        <div className="row align-items-center justify-content-between">
          <div className="col-auto col-md-6">
            {canGoBack && showBackButton && (
              <IconButton name={IconName.arrowleft} color={IconColor.GRAY_2} onClick={onGoBack} />
            )}
          </div>

          <div className="col col-md-6">
            {showSubmitButton && (
              <Button type="submit" disabled={isSubmitting}>
                {isLastStep ? lastStepButtonText : startButtonText ?? 'Weiter'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}
