import { createSlice } from '@reduxjs/toolkit'

import { ReduxStoreState } from 'Models'
import { TemporaryAccess } from 'Models/TemporaryAccess'

const initialState = {
  canAccessMembershipConfirmationPage: false,
  canAccessOrderProcessRedirectPageAfterExtendingMembership: false,
  hasTrackedPurchase: false
}

const temporaryAccessSlice = createSlice({
  name: 'temporaryAccess',
  initialState: initialState,
  reducers: {
    grantAccessSuccessPage(state) {
      return { ...state, canAccessMembershipConfirmationPage: true }
    },
    denyAccessSuccessPage(state) {
      return { ...state, canAccessMembershipConfirmationPage: false }
    },
    grantAccessPaymentRedirectPageAfterProlongingMembership(state) {
      return { ...state, canAccessOrderProcessRedirectPageAfterExtendingMembership: true }
    },
    denyAccessPaymentRedirectPageAfterProlongingMembership(state) {
      return { ...state, canAccessOrderProcessRedirectPageAfterExtendingMembership: false }
    },
    purchaseTracked(state) {
      return { ...state, hasTrackedPurchase: true }
    }
  }
})

export const {
  grantAccessSuccessPage,
  denyAccessSuccessPage,
  grantAccessPaymentRedirectPageAfterProlongingMembership,
  denyAccessPaymentRedirectPageAfterProlongingMembership,
  purchaseTracked
} = temporaryAccessSlice.actions

export const temporaryAccessSelector = ({ temporaryAccess }: ReduxStoreState): TemporaryAccess => {
  return temporaryAccess
}

export default temporaryAccessSlice.reducer
