/*eslint-disable @typescript-eslint/no-explicit-any*/
import { AuthClientTokens } from '@react-keycloak/core/lib/types'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import React, { FC, useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { useKeycloakTokenRefresher } from './useKeycloakTokenRefresher'

import MessageToaster from 'App/MessageToaster/MessageToaster'
import { PrivateRoute } from 'App/PrivateRoute/PrivateRoute'
import { RedirectWrapper } from 'App/RedirectWrapper/RedirectWrapper'
import { AccessDeniedModal, PageFooter, PageHeader } from 'Components'
import { DebugBox, DebugProvider } from 'Components/Debug'
import ErrorBoundary from 'Components/ErrorBoundary'
import { appConfig, keycloak, keycloakInitConfig, routes } from 'Config'
import { CoreBackendUserReduxType, KeyCloakUser, ReduxStoreState } from 'Models'
import {
  AnamnesisPage,
  BaseDataPage,
  ContactPage,
  CreateFoodPage,
  CreateRecipePage,
  DevPage,
  EditFoodPage,
  ErrorPage,
  FavoritesOverviewPage,
  HelpAndTourPage,
  ImageUploadPage,
  JournalPage,
  MassPage,
  MemberRecruitPage,
  MenuPlanPage,
  MyEatablesPage,
  NotFoundPage,
  NutritionsPage,
  OrderRegistrationSuccessPage,
  OrderSuccessPage,
  PlanRegistrationPage,
  ProgramPage,
  RecipesInspirationPage,
  UserMasterDataPage,
  WelcomePage
} from 'Pages'
import { EditRecipePage } from 'Pages/RecipeEditorPages'
import 'react-toastify/dist/ReactToastify.css'
import { isProspectiveCustomerSelector, isUserLoadingSelector } from 'ReduxStore/common/combinedSelectors'
import {
  coreBackendUserSelector,
  fetchCoreBackendUserAction,
  fetchCoreBackendUserInfoAction
} from 'ReduxStore/coreBackendUser'
import { customerSelector, fetchCustomer } from 'ReduxStore/customerSlice'
import { fetchAllFavoritesAction } from 'ReduxStore/favorites/favorites'
import { fetchFoodCategoriesAction } from 'ReduxStore/foodCategories'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import {
  fetchKeyCloakProfileAction,
  isActiveCustomerSelector,
  keyCloakUserSelector,
  setKeyCloakPermissionsAction
} from 'ReduxStore/keyCloakUser'
import { resetUserAction } from 'ReduxStore/resetUser'
import { fetchUserFoodsAction } from 'ReduxStore/userFoods'
import { fetchUserRecipesAction } from 'ReduxStore/userRecipes'
import { fetchUserSettingsAction } from 'ReduxStore/userSettings'
import {
  ProtectedBackendService,
  anamnesisService,
  coreBackendService,
  imageService,
  keycloakService,
  memberShipService
} from 'Services'
import { energyCalculatorService } from 'Services/energyCalculatorServiceInstance'
import '../App.css'

const protectedBackendServices: ProtectedBackendService[] = [
  anamnesisService,
  energyCalculatorService,
  coreBackendService,
  memberShipService,
  imageService,
  keycloakService
]

type SelectorProps = {
  keyCloakUser: KeyCloakUser
  isProspectiveCustomer: boolean
  isActiveCustomer: boolean
  coreBackendUser: CoreBackendUserReduxType
  isUserLoading: boolean
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  keyCloakUser: keyCloakUserSelector,
  isProspectiveCustomer: isProspectiveCustomerSelector,
  isActiveCustomer: isActiveCustomerSelector,
  coreBackendUser: coreBackendUserSelector,
  isUserLoading: isUserLoadingSelector
})

const mapDispatchToProps = {
  fetchCoreBackendUser: fetchCoreBackendUserAction,
  fetchCoreBackendUserInfo: fetchCoreBackendUserInfoAction,
  fetchFoodCategories: fetchFoodCategoriesAction,
  fetchUserSettings: fetchUserSettingsAction,
  fetchKeyCloakProfile: fetchKeyCloakProfileAction,
  resetUser: resetUserAction,
  fetchUserFoods: fetchUserFoodsAction,
  fetchUserRecipes: fetchUserRecipesAction,
  setKeyCloakPermissions: setKeyCloakPermissionsAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const App: FC<PropsFromRedux> = ({
  keyCloakUser,
  isProspectiveCustomer,
  isActiveCustomer,
  coreBackendUser,
  isUserLoading,
  fetchCoreBackendUserInfo,
  fetchFoodCategories,
  fetchUserSettings,
  fetchKeyCloakProfile,
  fetchUserFoods,
  fetchUserRecipes,
  resetUser,
  setKeyCloakPermissions
}: PropsFromRedux) => {
  const dispatch = useAppDispatch()
  const { firstName } = useAppSelector(customerSelector)

  useEffect(() => {
    if (keyCloakUser.authenticated && coreBackendUser.isExisting === null) {
      fetchCoreBackendUserInfo()
    }
  }, [keyCloakUser.authenticated, coreBackendUser.isExisting])

  useEffect(() => {
    if (keyCloakUser.authenticated) {
      dispatch(fetchCustomer())
    }

    if (keyCloakUser.authenticated && coreBackendUser.isExisting && coreBackendUser.info.hasPrograms) {
      dispatch(fetchAllFavoritesAction())
      fetchUserSettings()
      fetchFoodCategories()
      fetchUserFoods()
      fetchUserRecipes()
    }
  }, [keyCloakUser.authenticated, coreBackendUser.isExisting, coreBackendUser.info.hasPrograms])

  useEffect(() => (window as any).ebalanceCookieBanner?.(), [])

  const onKeycloakTokens = (tokens: AuthClientTokens): void => {
    protectedBackendServices.forEach((service) => (service.authToken = tokens.token))
  }
  const isCombinedUserLoading = isUserLoading || (keyCloakUser.authenticated && coreBackendUser.isExisting === null)

  const userNeedsToFillAnamnesis = isActiveCustomer && !coreBackendUser.info.hasPrograms

  const userNeedsToRegisterMembership = isProspectiveCustomer && !isActiveCustomer

  const isRefreshingKeycloakToken = useKeycloakTokenRefresher(keycloak)

  const onKeycloakEvent = (event: string): void => {
    switch (event) {
      case 'onReady': // this event fires after onAuthSuccess for whatever reason
        break
      case 'onAuthSuccess':
      case 'onAuthRefreshSuccess':
        coreBackendService.userId = keycloak.subject
        memberShipService.userId = keycloak.subject
        if (!isRefreshingKeycloakToken) {
          // @ts-ignore
          fetchKeyCloakProfile(keycloak).then(() => setKeyCloakPermissions(keycloak))
        } else {
          setKeyCloakPermissions(keycloak)
        }
        break
      case 'onAuthError':
      case 'onAuthRefreshError':
        break
      case 'onTokenExpired':
      case 'onAuthLogout':
        protectedBackendServices.forEach((service) => (service.authToken = undefined))
        coreBackendService.userId = undefined
        memberShipService.userId = undefined
        break
      default:
        break
    }
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitConfig}
      onTokens={onKeycloakTokens}
      onEvent={onKeycloakEvent}
    >
      <Router>
        <DebugProvider>
          <div className="page">
            <PageHeader
              isActiveCustomer={isActiveCustomer}
              firstName={firstName}
              user={keyCloakUser}
              isLoading={isCombinedUserLoading}
              isAnamnesis={userNeedsToFillAnamnesis || userNeedsToRegisterMembership}
              bannerMessage={appConfig.bannerMessage}
              resetUser={resetUser}
              showResetUserButton={appConfig.showDebugInfo}
            />
            <ErrorBoundary>
              <RedirectWrapper
                coreBackendUser={coreBackendUser}
                keyCloakUser={keyCloakUser}
                isLoading={isCombinedUserLoading}
                includeDevRoutes={appConfig.showDebugInfo}
                userNeedsToFillAnamnesis={userNeedsToFillAnamnesis}
                userNeedsToRegisterMembership={userNeedsToRegisterMembership}
              >
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => (keyCloakUser.authenticated ? <WelcomePage firstName={firstName} /> : <div />)}
                  />
                  <Route
                    path={routes.internals.devPage}
                    render={(props: any) => appConfig.showDebugInfo && <DevPage {...props} />}
                  />
                  <Route path={routes.paymentRedirect} component={OrderRegistrationSuccessPage} />

                  <PrivateRoute exact path={routes.register.anamnesis} component={AnamnesisPage} />
                  <PrivateRoute exact path={routes.register.plan} component={PlanRegistrationPage} />
                  <PrivateRoute exact path={routes.register.success} component={OrderSuccessPage} />
                  <PrivateRoute exact path={routes.imageUpload} component={ImageUploadPage} />
                  <PrivateRoute exact path={routes.journal} component={JournalPage} />
                  <PrivateRoute exact path={routes.analytics.mass} component={MassPage} />
                  <PrivateRoute exact path={routes.analytics.nutritions} component={NutritionsPage} />
                  <PrivateRoute exact path={routes.inspiration.recipes} component={RecipesInspirationPage} />
                  <PrivateRoute exact path={routes.myEatables.myFoodView()} component={MyEatablesPage} />
                  <PrivateRoute exact path={routes.myEatables.myRecipeView()} component={MyEatablesPage} />
                  <PrivateRoute exact path={routes.myEatables.myFoodCreate} component={CreateFoodPage} />
                  <PrivateRoute exact path={routes.myEatables.myFoodEdit()} component={EditFoodPage} />
                  <PrivateRoute exact path={routes.myEatables.myRecipeCreate} component={CreateRecipePage} />
                  <PrivateRoute exact path={routes.myEatables.myRecipeEdit()} component={EditRecipePage} />
                  <PrivateRoute exact path={routes.profile.profile} component={UserMasterDataPage} />
                  <PrivateRoute exact path={routes.profile.restartLoseWeight} component={AnamnesisPage} />
                  <PrivateRoute exact path={routes.profile.restartKeepWeight} component={AnamnesisPage} />
                  <PrivateRoute exact path={routes.profile.program} component={ProgramPage} />
                  <PrivateRoute exact path={routes.profile.menuPlan} component={MenuPlanPage} />
                  <PrivateRoute exact path={routes.profile.baseData} component={BaseDataPage} />
                  <PrivateRoute exact path={routes.myEatables.favoritesOverview} component={FavoritesOverviewPage} />
                  <PrivateRoute exact path={routes.recruit} component={MemberRecruitPage} />
                  <Route exact path={routes.contact} component={ContactPage} />
                  <Route exact path={routes.help} render={() => <HelpAndTourPage isHelpPage={true} />} />
                  <Route exact path={routes.tour} render={() => <HelpAndTourPage isHelpPage={false} />} />
                  <Route exact path={routes.error} component={ErrorPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </RedirectWrapper>
            </ErrorBoundary>
            <PageFooter userAuthenticated={keyCloakUser.authenticated} />
          </div>
          {appConfig.showDebugInfo && <DebugBox keyCloakUser={keyCloakUser} coreBackendUser={coreBackendUser} />}
          <MessageToaster />
          <AccessDeniedModal />
        </DebugProvider>
      </Router>
    </ReactKeycloakProvider>
  )
}

export default connector(App)
