import dayjs from 'dayjs'
import React, { useState } from 'react'
import Modal from 'react-modal'

import { Button, DatePicker, IconButton, IconName } from 'Components'
import { MealCategory } from 'Models'
import { registrationDateSelector } from 'ReduxStore/coreBackendUser'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { journalDateSelector, setJournalDateAction } from 'ReduxStore/journalDay'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

type Props = {
  isOpen: boolean
  displayedMealCategory?: MealCategory
  title: string | undefined
  onChange?: (value: MealCategory) => void
  onMove: (date: Date) => void
  onReset: () => void
}

const MoveJournalRecordModal: React.FC<Props> = ({ children, isOpen, title, onMove, onReset }) => {
  const dispatch = useAppDispatch()
  const currentJournalDate = useAppSelector(journalDateSelector).date
  const dayFollowingCurrentJournalDate: Date = dayjs(currentJournalDate).add(1, 'day').toDate()
  const [displayedDay, setDisplayedDay] = useState<Date>(dayFollowingCurrentJournalDate)

  const move = (): void => {
    onMove(displayedDay)
    if (!dayjs(currentJournalDate).isSame(displayedDay, 'day')) {
      dispatch(setJournalDateAction(displayedDay))
    }
    resetAll()
  }

  const resetAll = (): void => {
    onReset()
    setDisplayedDay(dayFollowingCurrentJournalDate)
  }
  const onChangeDate = (date: Date | null): void => {
    if (date) {
      setDisplayedDay(date)
    }
  }
  return (
    <Modal style={modalZIndexPriority} isOpen={isOpen} onRequestClose={resetAll} ariaHideApp={false}>
      <div className="modal is-medium p-5">
        <IconButton name={IconName.close} onClick={resetAll} />
        <div className="row">
          <div className="col-12">
            <h2>{title}</h2>
          </div>
          <div className="col-12 row">
            <div className="col-md-7 col-12">
              <DatePicker
                label={'Verschiebe zu'}
                value={displayedDay}
                onChange={onChangeDate}
                range={{ from: useAppSelector(registrationDateSelector) }}
              />
            </div>
            {children}
          </div>
          <div className="row col-12">
            <div className="col-12">
              <Button disabled={false} onClick={move}>
                Verschieben
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MoveJournalRecordModal
