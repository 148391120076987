import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import React, { useCallback, useMemo } from 'react'
import { Editor } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export type RichTextInputProps = {
  initialValue?: string | null
  disabled?: boolean
  errorText?: string
  onChange?: (value: string) => void
}

const toolbarOptions = {
  options: ['inline', 'list', 'textAlign', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline']
  },
  list: {
    options: ['unordered', 'ordered']
  }
}

export const RichTextInput: React.FC<RichTextInputProps> = ({
  initialValue = '',
  disabled = false,
  errorText,
  onChange
}: RichTextInputProps) => {
  const initialEditorState = useMemo(() => {
    const contentBlock = convertFromHTML(initialValue ?? '')
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    return EditorState.createWithContent(contentState)
  }, [initialValue])

  const handleEditorStateChange = useCallback(
    (newEditorState: EditorState) => {
      onChange && onChange(draftToHtml(convertToRaw(newEditorState.getCurrentContent())))
    },
    [onChange]
  )

  return (
    <div>
      <Editor
        toolbar={toolbarOptions}
        defaultEditorState={initialEditorState}
        onEditorStateChange={handleEditorStateChange}
        readOnly={disabled}
      />
      {errorText && <div className="input-error">{errorText}</div>}
    </div>
  )
}
