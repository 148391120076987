import { AnamnesisStepIds, ProfileValues } from '../../types'

import { StepConfig } from 'Components/FormWizard'
import { anamnesisSchema } from 'yup-schema/Schemas/anamnesis'

export const profileConfig = ({ isRestart }: { isRestart: boolean }): StepConfig<ProfileValues> => {
  return {
    id: AnamnesisStepIds.profile,
    scrollTop: true,
    validationSchema: isRestart
      ? anamnesisSchema.pick(['birthday', 'currentWeight', 'weightToLose'])
      : anamnesisSchema.pick(['birthday', 'currentWeight', 'height', 'bellyCircumference', 'weightToLose'])
  }
}
