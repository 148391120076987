import _ from 'lodash'
import React, { useCallback, useState } from 'react'

import { JournalCardMotion } from 'Components'
import { deleteButton, editButton } from 'Components/IconButton/iconButtons'
import { FavoritesMotionLevel, PromiseAction } from 'Models'
import { removeFavoritesAction, updateFavoriteMotionAction } from 'ReduxStore/favorites/favorites'
import { useAppDispatch } from 'ReduxStore/hooks'

type Props = {
  favorite: FavoritesMotionLevel
  weight: number
}

const FavoriteMotionCardContainer: React.FC<Props> = ({ favorite, weight }) => {
  const dispatch = useAppDispatch()
  const [toggleChildrenIsOn, setToggleChildrenIsOn] = useState(false)
  const [duration, setDuration] = useState(favorite.duration)
  const [motionLevelId, setMotionLevelId] = useState(favorite.motionLevel.id)

  const handleAmountChanged = useCallback(
    _.debounce(
      (durationInSeconds: number, newMotionLevelId: string) =>
        dispatch(
          updateFavoriteMotionAction(
            favorite.id,
            {
              duration: durationInSeconds,
              motionLevelId: favorite.motionLevel.id,
              newMotionLevelId
            },
            favorite
          )
        ),
      1000
    ),
    [favorite.id]
  )

  const onDurationChange = (durationInSeconds: number): void => {
    setDuration(durationInSeconds)
    handleAmountChanged(durationInSeconds, motionLevelId)
  }

  const onMotionLevelIdChange = (motionLevelId: string): void => {
    setMotionLevelId(motionLevelId)
    handleAmountChanged(duration, motionLevelId)
  }

  const handleOnEdit = (): void => setToggleChildrenIsOn(!toggleChildrenIsOn)

  const handleDeleteFavorite = (): PromiseAction<void> => dispatch(removeFavoritesAction(favorite))

  return (
    <JournalCardMotion
      iconButtons={[editButton(handleOnEdit), deleteButton(handleDeleteFavorite)]}
      cardButtons={[]}
      toggleChildrenIsOn={toggleChildrenIsOn}
      motion={favorite.motionLevel.motion}
      weight={weight}
      duration={duration}
      onDurationChange={onDurationChange}
      motionLevelId={motionLevelId}
      onMotionLevelIdChange={onMotionLevelIdChange}
    />
  )
}

export default FavoriteMotionCardContainer
