import React, { FC } from 'react'

import { TableNutrition } from '../TableNutrition'
import { DetailCardChart } from '../common/DetailCardChart'

import RichTextPanel from 'Components/RichTextPanel/RichTextPanel'
import { Food } from 'Models'

interface Props {
  food: Food
  onChangePress?: () => void
  onSavePress?: () => void
}

const DetailCardFoodContent: FC<Props> = ({ food }: Props) => {
  return (
    <>
      <div className="p-5">
        <h2>{food.name}</h2>
      </div>
      <div className="p-5">
        <div className="row">
          <div className="col-12 col-lg-6">
            <TableNutrition nutritionsPer100g={food.nutritions} />
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <DetailCardChart nutritionsPerWeight={food.nutritions || {}} />
          </div>
        </div>
      </div>
      <div className="p-5">
        {food.description && (
          <div className="row">
            <div className="col-12">
              <h2>Beschreibung</h2>
            </div>
            <div className="col-12">
              <RichTextPanel text={food.description} />
            </div>
          </div>
        )}
        {food.ingredients && (
          <div className="row">
            <div className="col-12">
              <h2>Inhaltsstoffe / Zutaten</h2>
            </div>
            <div className="col-12">
              <p>{food.ingredients}</p>
            </div>
          </div>
        )}
        {food.source && (
          <div className="mt-2 col-12">
            <p>Quelle: {food.source.name}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default DetailCardFoodContent
