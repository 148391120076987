import { useKeycloak } from '@react-keycloak/web'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import packageJson from './../../../package.json'
import BackendVersion from './BackendVersion'
import GitCommitHash from './GitCommitHash'
import IsoClock from './IsoClock'
import StartTime from './StartTime'
import Uptime from './Uptime'

import { appConfig } from 'Config'
import { CoreBackendUserReduxType, KeyCloakUser, ReduxStoreState } from 'Models'
import { EbalanceBackendService, anamnesisService, coreBackendService, memberShipService } from 'Services'
import { energyCalculatorService } from 'Services/energyCalculatorServiceInstance'

export const DebugContext = React.createContext({
  displayDebug: false,
  toggleDebug: (): void => undefined
})

export const DebugProvider: React.FC = ({ children }) => {
  if (!appConfig.showDebugInfo) {
    return <>{children}</>
  }

  const [displayDebug, setDisplayDebug] = useState(false)
  const toggleDebug = (): void => setDisplayDebug(!displayDebug)

  return (
    <DebugContext.Provider
      value={{
        displayDebug: displayDebug,
        toggleDebug
      }}
    >
      <div className={`${displayDebug ? 'showDebug' : ''}`}>{children}</div>
    </DebugContext.Provider>
  )
}

interface BackedServiceMetainfoProps {
  backend: EbalanceBackendService
}

const BackedServiceMetainfo: React.FC<BackedServiceMetainfoProps> = ({ backend }: BackedServiceMetainfoProps) => (
  <>
    <a href={backend.baseUrl}>{backend.baseUrl.replace('https://', '').replace(/\/$/, '')}</a>
    <br />
    v <BackendVersion backendService={backend} />
  </>
)

interface DebugProps {
  onToggleDebug?: (displayDebug: boolean) => void
  keyCloakUser: KeyCloakUser
  coreBackendUser: CoreBackendUserReduxType
}

type WindowType = Window &
  typeof globalThis & {
    env?: {
      STARTUP_TIMESTAMP?: string
    }
  }
export const DebugBox: React.FC<DebugProps> = ({ keyCloakUser, coreBackendUser }: DebugProps) => {
  const { keycloak } = useKeycloak()
  const { displayDebug, toggleDebug } = useContext(DebugContext)
  const [toggleSidebar, setToggleSidebar] = useState(false)

  const env = (window as WindowType).env || {}

  const pendingCounter = useSelector(({ pendingCounter }: ReduxStoreState) => pendingCounter)

  return (
    <>
      {coreBackendUser.isExisting === false && (
        <div className="core-backend-404-warning">User is missing in core-backend</div>
      )}
      <div className="debugBox" style={!toggleSidebar ? { left: -250 } : {}}>
        <div>
          <h5 className="mb-2">Debug Infos</h5>
          NODE_ENV: {process.env.NODE_ENV}
          <br />
          <br />
          {keyCloakUser && (
            <div>
              Keycloak ID
              <br />
              <code>{keyCloakUser.id || 'n/a'}</code>
              <br />
              Keycloak Username
              <br />
              <code>{keyCloakUser.username}</code>
              <br />
              Roles:
              <br />
              {keycloak.realmAccess?.roles.map((role, index) => (
                <div key={index} className="pl-1">
                  - {role}
                </div>
              ))}
              <br />
            </div>
          )}
          Frontend
          <br />v {packageJson.version} <GitCommitHash />
          <br />
          <br />
          <BackedServiceMetainfo backend={coreBackendService} />
          <br />
          <br />
          <BackedServiceMetainfo backend={anamnesisService} />
          <br />
          <br />
          <BackedServiceMetainfo backend={memberShipService} />
          <br />
          <br />
          <BackedServiceMetainfo backend={energyCalculatorService} />
          <br />
          <br />
          Uptime: <Uptime timestamp={env.STARTUP_TIMESTAMP} />, since:
          <br />
          <StartTime timestamp={env.STARTUP_TIMESTAMP} />
          <br />
          <br />
          ISO-Date:
          <br />
          <IsoClock />
          <br />
          <br />
          Pending Requests: {pendingCounter}
          <br />
          <br />
          Debug: {'\u00A0'}
          <a onClick={toggleDebug}>{displayDebug ? 'on' : 'off'}</a>
        </div>
        <a className="toggleSidebar" onClick={() => setToggleSidebar(!toggleSidebar)}></a>
      </div>
    </>
  )
}
