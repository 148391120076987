import React from 'react'

import { DatePickerController, RenderStepType } from 'Components'
import { date18YearsAgo } from 'Config'

export const AnamnesisBirthdayQuestion: React.FC<Omit<RenderStepType, 'setError' | 'isRestart'>> = ({
  register,
  setValue,
  errors,
  control,
  defaultValues
}) => {
  return (
    <div className="col-12">
      <DatePickerController
        name="birthday"
        label="Ihr Geburtstag"
        range={{
          to: date18YearsAgo
        }}
        format="[Heute], d.m.Y"
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        defaultValues={defaultValues}
        ignoreInternalErrors
      />
    </div>
  )
}
