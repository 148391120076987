import React, { FC } from 'react'

import { FoodForm, FoodFormProps } from './FoodForm'

import { PageLayout } from 'Components'

type EditFoodPageProps = FoodFormProps

export const EditFoodPage: FC<EditFoodPageProps> = ({ ...foodFormProps }) => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <h1>Nahrungsmittel bearbeiten</h1>
        <p>
          Bearbeiten Sie hier Ihr hinterlegtes Nahrungsmittel. Bitte beachten Sie, dass Ihre Änderungen auch rückwirkend
          für alle bereits ins Tagebuch hinzugefügten Nahrungsmittel übernommen werden.
        </p>
        <FoodForm {...foodFormProps} />
      </div>
    </PageLayout>
  )
}
