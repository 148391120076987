import { AxiosResponse } from 'axios'

import { EbalanceBackendService } from './EbalanceBackendService'

import { KeyCloakProfile } from 'Models'
import { Utils } from 'Utils'

export class KeycloakService extends EbalanceBackendService {
  async updateUserMasterData(
    userId: string,
    { firstName, lastName, attributes }: KeyCloakProfile
  ): Promise<AxiosResponse> {
    const payload = {
      firstName,
      lastName,
      attributes
    }

    const { data } = await this.client.put(`/users/${userId}`, payload)
    return Utils.convertObjectDatestringToDate(data, ['birthday'])
  }
}
