export enum PaymentMethod {
  POSTFINANCE = 'POSTFINANCE',
  TWINT = 'TWINT',
  CREDIT_CARD = 'CARD',
  INVOICE = 'INVOICE'
}

export enum Language {
  DE = 'de-DE',
  CH = 'de-CH'
}

export enum AvailableCountry {
  Deutschland = 'DE',
  Schweiz = 'CH'
}
