import { ActionType } from 'redux-promise-middleware'

// Body Measurements
export const FETCH_BODY_MEASUREMENTS = 'FETCH_BODY_MEASUREMENTS'
export const FETCH_BODY_MEASUREMENTS_PENDING = `${FETCH_BODY_MEASUREMENTS}_${ActionType.Pending}`
export const FETCH_BODY_MEASUREMENTS_FULFILLED = `${FETCH_BODY_MEASUREMENTS}_${ActionType.Fulfilled}`
export const FETCH_BODY_MEASUREMENTS_REJECTED = `${FETCH_BODY_MEASUREMENTS}_${ActionType.Rejected}`

export const FETCH_LATEST_BODY_MEASUREMENT = 'FETCH_LATEST_BODY_MEASUREMENT'
export const FETCH_LATEST_BODY_MEASUREMENT_PENDING = `${FETCH_LATEST_BODY_MEASUREMENT}_${ActionType.Pending}`
export const FETCH_LATEST_BODY_MEASUREMENT_FULFILLED = `${FETCH_LATEST_BODY_MEASUREMENT}_${ActionType.Fulfilled}`
export const FETCH_LATEST_BODY_MEASUREMENT_REJECTED = `${FETCH_LATEST_BODY_MEASUREMENT}_${ActionType.Rejected}`

export const POST_BODY_MEASUREMENTS = 'POST_BODY_MEASUREMENTS'
export const POST_BODY_MEASUREMENTS_PENDING = `${POST_BODY_MEASUREMENTS}_${ActionType.Pending}`
export const POST_BODY_MEASUREMENTS_FULFILLED = `${POST_BODY_MEASUREMENTS}_${ActionType.Fulfilled}`
export const POST_BODY_MEASUREMENTS_REJECTED = `${POST_BODY_MEASUREMENTS}_${ActionType.Rejected}`

export const PATCH_BODY_MEASUREMENTS = 'PATCH_BODY_MEASUREMENTS'
export const PATCH_BODY_MEASUREMENTS_PENDING = `${PATCH_BODY_MEASUREMENTS}_${ActionType.Pending}`
export const PATCH_BODY_MEASUREMENTS_FULFILLED = `${PATCH_BODY_MEASUREMENTS}_${ActionType.Fulfilled}`
export const PATCH_BODY_MEASUREMENTS_REJECTED = `${PATCH_BODY_MEASUREMENTS}_${ActionType.Rejected}`

// Anamnesis
export const POST_ANAMNESIS = 'POST_ANAMNESIS'
export const POST_ANAMNESIS_PENDING = `${POST_ANAMNESIS}_${ActionType.Pending}`
export const POST_ANAMNESIS_FULFILLED = `${POST_ANAMNESIS}_${ActionType.Fulfilled}`
export const POST_ANAMNESIS_REJECTED = `${POST_ANAMNESIS}_${ActionType.Rejected}`

export const UPDATE_ANAMNESIS = 'UPDATE_ANAMNESIS'
export const UPDATE_ANAMNESIS_PENDING = `${UPDATE_ANAMNESIS}_${ActionType.Pending}`
export const UPDATE_ANAMNESIS_FULFILLED = `${UPDATE_ANAMNESIS}_${ActionType.Fulfilled}`
export const UPDATE_ANAMNESIS_REJECTED = `${UPDATE_ANAMNESIS}_${ActionType.Rejected}`

export const GET_ANAMNESIS = 'GET_ANAMNESIS'
export const GET_ANAMNESIS_PENDING = `${GET_ANAMNESIS}_${ActionType.Pending}`
export const GET_ANAMNESIS_FULFILLED = `${GET_ANAMNESIS}_${ActionType.Fulfilled}`
export const GET_ANAMNESIS_REJECTED = `${GET_ANAMNESIS}_${ActionType.Rejected}`

// Body Analytics
export const FETCH_BODY_ANALYTICS = 'FETCH_BODY_ANALYTICS'
export const FETCH_BODY_ANALYTICS_PENDING = `${FETCH_BODY_ANALYTICS}_${ActionType.Pending}`
export const FETCH_BODY_ANALYTICS_FULFILLED = `${FETCH_BODY_ANALYTICS}_${ActionType.Fulfilled}`
export const FETCH_BODY_ANALYTICS_REJECTED = `${FETCH_BODY_ANALYTICS}_${ActionType.Rejected}`

// User Foods
export const ADD_USER_FOOD = 'ADD_USER_FOOD'
export const ADD_USER_FOOD_PENDING = `${ADD_USER_FOOD}_${ActionType.Pending}`
export const ADD_USER_FOOD_FULFILLED = `${ADD_USER_FOOD}_${ActionType.Fulfilled}`
export const ADD_USER_FOOD_REJECTED = `${ADD_USER_FOOD}_${ActionType.Rejected}`

export const UPDATE_USER_FOOD_VISIBILITY = 'UPDATE_USER_FOOD_VISIBILITY'

export const UPDATE_USER_FOOD = 'UPDATE_USER_FOOD'
export const UPDATE_USER_FOOD_PENDING = `${UPDATE_USER_FOOD}_${ActionType.Pending}`
export const UPDATE_USER_FOOD_FULFILLED = `${UPDATE_USER_FOOD}_${ActionType.Fulfilled}`
export const UPDATE_USER_FOOD_REJECTED = `${UPDATE_USER_FOOD}_${ActionType.Rejected}`

export const FETCH_USER_FOODS = 'FETCH_USER_FOODS'
export const FETCH_USER_FOODS_PENDING = `${FETCH_USER_FOODS}_${ActionType.Pending}`
export const FETCH_USER_FOODS_FULFILLED = `${FETCH_USER_FOODS}_${ActionType.Fulfilled}`
export const FETCH_USER_FOODS_REJECTED = `${FETCH_USER_FOODS}_${ActionType.Rejected}`

export const FETCH_USER_FOOD = 'FETCH_USER_FOOD'
export const FETCH_USER_FOOD_PENDING = `${FETCH_USER_FOOD}_${ActionType.Pending}`
export const FETCH_USER_FOOD_FULFILLED = `${FETCH_USER_FOOD}_${ActionType.Fulfilled}`
export const FETCH_USER_FOOD_REJECTED = `${FETCH_USER_FOOD}_${ActionType.Rejected}`

// User Recipes
export const ADD_USER_RECIPE = 'ADD_USER_RECIPE'
export const ADD_USER_RECIPE_PENDING = `${ADD_USER_RECIPE}_${ActionType.Pending}`
export const ADD_USER_RECIPE_FULFILLED = `${ADD_USER_RECIPE}_${ActionType.Fulfilled}`
export const ADD_USER_RECIPE_REJECTED = `${ADD_USER_RECIPE}_${ActionType.Rejected}`

export const UPDATE_USER_RECIPE = 'UPDATE_USER_RECIPE'
export const UPDATE_USER_RECIPE_PENDING = `${UPDATE_USER_RECIPE}_${ActionType.Pending}`
export const UPDATE_USER_RECIPE_FULFILLED = `${UPDATE_USER_RECIPE}_${ActionType.Fulfilled}`
export const UPDATE_USER_RECIPE_REJECTED = `${UPDATE_USER_RECIPE}_${ActionType.Rejected}`

export const FETCH_USER_RECIPES = 'FETCH_USER_RECIPES'
export const FETCH_USER_RECIPES_PENDING = `${FETCH_USER_RECIPES}_${ActionType.Pending}`
export const FETCH_USER_RECIPES_FULFILLED = `${FETCH_USER_RECIPES}_${ActionType.Fulfilled}`
export const FETCH_USER_RECIPES_REJECTED = `${FETCH_USER_RECIPES}_${ActionType.Rejected}`

// Food Categories
export const FETCH_FOOD_CATEGORIES = 'FETCH_FOOD_CATEGORIES'
export const FETCH_FOOD_CATEGORIES_PENDING = `${FETCH_FOOD_CATEGORIES}_${ActionType.Pending}`
export const FETCH_FOOD_CATEGORIES_FULFILLED = `${FETCH_FOOD_CATEGORIES}_${ActionType.Fulfilled}`
export const FETCH_FOOD_CATEGORIES_REJECTED = `${FETCH_FOOD_CATEGORIES}_${ActionType.Rejected}`

// Eatable Sources
export const FETCH_EATABLE_SOURCES = 'FETCH_EATABLE_SOURCES'
export const FETCH_EATABLE_SOURCES_PENDING = `${FETCH_EATABLE_SOURCES}_${ActionType.Pending}`
export const FETCH_EATABLE_SOURCES_FULFILLED = `${FETCH_EATABLE_SOURCES}_${ActionType.Fulfilled}`
export const FETCH_EATABLE_SOURCES_REJECTED = `${FETCH_EATABLE_SOURCES}_${ActionType.Rejected}`

export const ADD_EATABLE_SOURCES = 'ADD_EATABLE_SOURCES'
export const ADD_EATABLE_SOURCES_PENDING = `${ADD_EATABLE_SOURCES}_${ActionType.Pending}`
export const ADD_EATABLE_SOURCES_FULFILLED = `${ADD_EATABLE_SOURCES}_${ActionType.Fulfilled}`
export const ADD_EATABLE_SOURCES_REJECTED = `${ADD_EATABLE_SOURCES}_${ActionType.Rejected}`

// Favorites
export const FETCH_FAVORITES = 'FETCH_FAVORITES'
export const FETCH_FAVORITES_PENDING = `${FETCH_FAVORITES}_${ActionType.Pending}`
export const FETCH_FAVORITES_FULFILLED = `${FETCH_FAVORITES}_${ActionType.Fulfilled}`
export const FETCH_FAVORITES_REJECTED = `${FETCH_FAVORITES}_${ActionType.Rejected}`

export const ADD_FAVORITES = 'ADD_FAVORITES'
export const ADD_FAVORITES_PENDING = `${ADD_FAVORITES}_${ActionType.Pending}`
export const ADD_FAVORITES_FULFILLED = `${ADD_FAVORITES}_${ActionType.Fulfilled}`
export const ADD_FAVORITES_REJECTED = `${ADD_FAVORITES}_${ActionType.Rejected}`

export const REMOVE_FAVORITES = 'REMOVE_FAVORITES'
export const REMOVE_FAVORITES_PENDING = `${REMOVE_FAVORITES}_${ActionType.Pending}`
export const REMOVE_FAVORITES_FULFILLED = `${REMOVE_FAVORITES}_${ActionType.Fulfilled}`
export const REMOVE_FAVORITES_REJECTED = `${REMOVE_FAVORITES}_${ActionType.Rejected}`

export const REMOVE_FAVORITES_MOTION = 'REMOVE_FAVORITES_MOTION'
export const REMOVE_FAVORITES_MOTION_PENDING = `${REMOVE_FAVORITES_MOTION}_${ActionType.Pending}`
export const REMOVE_FAVORITES_MOTION_FULFILLED = `${REMOVE_FAVORITES_MOTION}_${ActionType.Fulfilled}`
export const REMOVE_FAVORITES_MOTION_REJECTED = `${REMOVE_FAVORITES_MOTION}_${ActionType.Rejected}`

export const UPDATE_FAVORITE_MOTION = 'UPDATE_FAVORITE_MOTION'
export const UPDATE_FAVORITE_MOTION_PENDING = `${UPDATE_FAVORITE_MOTION}_${ActionType.Pending}`
export const UPDATE_FAVORITE_MOTION_FULFILLED = `${UPDATE_FAVORITE_MOTION}_${ActionType.Fulfilled}`
export const UPDATE_FAVORITE_MOTION_REJECTED = `${UPDATE_FAVORITE_MOTION}_${ActionType.Rejected}`

export const UPDATE_FAVORITES = 'UPDATE_FAVORITES'
export const UPDATE_FAVORITES_PENDING = `${UPDATE_FAVORITES}_${ActionType.Pending}`
export const UPDATE_FAVORITES_FULFILLED = `${UPDATE_FAVORITES}_${ActionType.Fulfilled}`
export const UPDATE_FAVORITES_REJECTED = `${UPDATE_FAVORITES}_${ActionType.Rejected}`

// Journal Day
export const SET_JOURNAL_DATE = 'SET_JOURNAL_DATE'

// Journal Eatable Records
export const FETCH_JOURNAL_EATABLE_RECORDS = 'FETCH_JOURNAL_EATABLE_RECORDS'
export const FETCH_JOURNAL_EATABLE_RECORDS_PENDING = `${FETCH_JOURNAL_EATABLE_RECORDS}_${ActionType.Pending}`
export const FETCH_JOURNAL_EATABLE_RECORDS_FULFILLED = `${FETCH_JOURNAL_EATABLE_RECORDS}_${ActionType.Fulfilled}`
export const FETCH_JOURNAL_EATABLE_RECORDS_REJECTED = `${FETCH_JOURNAL_EATABLE_RECORDS}_${ActionType.Rejected}`

export const ADD_JOURNAL_EATABLE_RECORD = 'ADD_JOURNAL_EATABLE_RECORD'
export const ADD_JOURNAL_EATABLE_RECORD_PENDING = `${ADD_JOURNAL_EATABLE_RECORD}_${ActionType.Pending}`
export const ADD_JOURNAL_EATABLE_RECORD_FULFILLED = `${ADD_JOURNAL_EATABLE_RECORD}_${ActionType.Fulfilled}`
export const ADD_JOURNAL_EATABLE_RECORD_REJECTED = `${ADD_JOURNAL_EATABLE_RECORD}_${ActionType.Rejected}`

export const REMOVE_JOURNAL_EATABLE_RECORD = 'REMOVE_JOURNAL_EATABLE_RECORD'
export const REMOVE_JOURNAL_EATABLE_RECORD_PENDING = `${REMOVE_JOURNAL_EATABLE_RECORD}_${ActionType.Pending}`
export const REMOVE_JOURNAL_EATABLE_RECORD_FULFILLED = `${REMOVE_JOURNAL_EATABLE_RECORD}_${ActionType.Fulfilled}`
export const REMOVE_JOURNAL_EATABLE_RECORD_REJECTED = `${REMOVE_JOURNAL_EATABLE_RECORD}_${ActionType.Rejected}`

export const UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT = 'UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT'
export const UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT_PENDING = `${UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT}_${ActionType.Pending}`
export const UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT_FULFILLED = `${UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT}_${ActionType.Fulfilled}`
export const UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT_REJECTED = `${UPDATE_JOURNAL_EATABLE_RECORD_AMOUNT}_${ActionType.Rejected}`

export const UPDATE_JOURNAL_EATABLE_RECORD_DATETIME = 'UPDATE_JOURNAL_EATABLE_RECORD_DATETIME'
export const UPDATE_JOURNAL_EATABLE_RECORD_DATETIME_PENDING = `${UPDATE_JOURNAL_EATABLE_RECORD_DATETIME}_${ActionType.Pending}`
export const UPDATE_JOURNAL_EATABLE_RECORD_DATETIME_FULFILLED = `${UPDATE_JOURNAL_EATABLE_RECORD_DATETIME}_${ActionType.Fulfilled}`
export const UPDATE_JOURNAL_EATABLE_RECORD_DATETIME_REJECTED = `${UPDATE_JOURNAL_EATABLE_RECORD_DATETIME}_${ActionType.Rejected}`

// Journal Energy Records
export const FETCH_JOURNAL_ENERGY_RECORDS = 'FETCH_JOURNAL_ENERGY_RECORDS'
export const FETCH_JOURNAL_ENERGY_RECORDS_PENDING = `${FETCH_JOURNAL_ENERGY_RECORDS}_${ActionType.Pending}`
export const FETCH_JOURNAL_ENERGY_RECORDS_FULFILLED = `${FETCH_JOURNAL_ENERGY_RECORDS}_${ActionType.Fulfilled}`
export const FETCH_JOURNAL_ENERGY_RECORDS_REJECTED = `${FETCH_JOURNAL_ENERGY_RECORDS}_${ActionType.Rejected}`

export const ADD_JOURNAL_ENERGY_RECORD = 'ADD_JOURNAL_ENERGY_RECORD'
export const ADD_JOURNAL_ENERGY_RECORD_PENDING = `${ADD_JOURNAL_ENERGY_RECORD}_${ActionType.Pending}`
export const ADD_JOURNAL_ENERGY_RECORD_FULFILLED = `${ADD_JOURNAL_ENERGY_RECORD}_${ActionType.Fulfilled}`
export const ADD_JOURNAL_ENERGY_RECORD_REJECTED = `${ADD_JOURNAL_ENERGY_RECORD}_${ActionType.Rejected}`

export const REMOVE_JOURNAL_ENERGY_RECORD = 'REMOVE_JOURNAL_ENERGY_RECORD'
export const REMOVE_JOURNAL_ENERGY_RECORD_PENDING = `${REMOVE_JOURNAL_ENERGY_RECORD}_${ActionType.Pending}`
export const REMOVE_JOURNAL_ENERGY_RECORD_FULFILLED = `${REMOVE_JOURNAL_ENERGY_RECORD}_${ActionType.Fulfilled}`
export const REMOVE_JOURNAL_ENERGY_RECORD_REJECTED = `${REMOVE_JOURNAL_ENERGY_RECORD}_${ActionType.Rejected}`

export const UPDATE_JOURNAL_ENERGY_RECORD = 'UPDATE_JOURNAL_ENERGY_RECORD'
export const UPDATE_JOURNAL_ENERGY_RECORD_PENDING = `${UPDATE_JOURNAL_ENERGY_RECORD}_${ActionType.Pending}`
export const UPDATE_JOURNAL_ENERGY_RECORD_FULFILLED = `${UPDATE_JOURNAL_ENERGY_RECORD}_${ActionType.Fulfilled}`
export const UPDATE_JOURNAL_ENERGY_RECORD_REJECTED = `${UPDATE_JOURNAL_ENERGY_RECORD}_${ActionType.Rejected}`

// Journal Motion Records
export const FETCH_JOURNAL_MOTION_RECORDS = 'FETCH_JOURNAL_MOTION_RECORDS'
export const FETCH_JOURNAL_MOTION_RECORDS_PENDING = `${FETCH_JOURNAL_MOTION_RECORDS}_${ActionType.Pending}`
export const FETCH_JOURNAL_MOTION_RECORDS_FULFILLED = `${FETCH_JOURNAL_MOTION_RECORDS}_${ActionType.Fulfilled}`
export const FETCH_JOURNAL_MOTION_RECORDS_REJECTED = `${FETCH_JOURNAL_MOTION_RECORDS}_${ActionType.Rejected}`

export const ADD_JOURNAL_MOTION_RECORD = 'ADD_JOURNAL_MOTION_RECORD'
export const ADD_JOURNAL_MOTION_RECORD_PENDING = `${ADD_JOURNAL_MOTION_RECORD}_${ActionType.Pending}`
export const ADD_JOURNAL_MOTION_RECORD_FULFILLED = `${ADD_JOURNAL_MOTION_RECORD}_${ActionType.Fulfilled}`
export const ADD_JOURNAL_MOTION_RECORD_REJECTED = `${ADD_JOURNAL_MOTION_RECORD}_${ActionType.Rejected}`

export const REMOVE_JOURNAL_MOTION_RECORD = 'REMOVE_JOURNAL_MOTION_RECORD'
export const REMOVE_JOURNAL_MOTION_RECORD_PENDING = `${REMOVE_JOURNAL_MOTION_RECORD}_${ActionType.Pending}`
export const REMOVE_JOURNAL_MOTION_RECORD_FULFILLED = `${REMOVE_JOURNAL_MOTION_RECORD}_${ActionType.Fulfilled}`
export const REMOVE_JOURNAL_MOTION_RECORD_REJECTED = `${REMOVE_JOURNAL_MOTION_RECORD}_${ActionType.Rejected}`

export const UPDATE_JOURNAL_MOTION_RECORD = 'UPDATE_JOURNAL_MOTION_RECORD'
export const UPDATE_JOURNAL_MOTION_RECORD_PENDING = `${UPDATE_JOURNAL_MOTION_RECORD}_${ActionType.Pending}`
export const UPDATE_JOURNAL_MOTION_RECORD_FULFILLED = `${UPDATE_JOURNAL_MOTION_RECORD}_${ActionType.Fulfilled}`
export const UPDATE_JOURNAL_MOTION_RECORD_REJECTED = `${UPDATE_JOURNAL_MOTION_RECORD}_${ActionType.Rejected}`

// Journal Energy Motion Records
export const FETCH_JOURNAL_ENERGY_MOTION_RECORDS = 'FETCH_JOURNAL_ENERGY_MOTION_RECORDS'
export const FETCH_JOURNAL_ENERGY_MOTION_RECORDS_PENDING = `${FETCH_JOURNAL_ENERGY_MOTION_RECORDS}_${ActionType.Pending}`
export const FETCH_JOURNAL_ENERGY_MOTION_RECORDS_FULFILLED = `${FETCH_JOURNAL_ENERGY_MOTION_RECORDS}_${ActionType.Fulfilled}`
export const FETCH_JOURNAL_ENERGY_MOTION_RECORDS_REJECTED = `${FETCH_JOURNAL_ENERGY_MOTION_RECORDS}_${ActionType.Rejected}`

export const ADD_JOURNAL_ENERGY_MOTION_RECORD = 'ADD_JOURNAL_ENERGY_MOTION_RECORD'
export const ADD_JOURNAL_ENERGY_MOTION_RECORD_PENDING = `${ADD_JOURNAL_ENERGY_MOTION_RECORD}_${ActionType.Pending}`
export const ADD_JOURNAL_ENERGY_MOTION_RECORD_FULFILLED = `${ADD_JOURNAL_ENERGY_MOTION_RECORD}_${ActionType.Fulfilled}`
export const ADD_JOURNAL_ENERGY_MOTION_RECORD_REJECTED = `${ADD_JOURNAL_ENERGY_MOTION_RECORD}_${ActionType.Rejected}`

export const REMOVE_JOURNAL_ENERGY_MOTION_RECORD = 'REMOVE_JOURNAL_ENERGY_MOTION_RECORD'
export const REMOVE_JOURNAL_ENERGY_MOTION_RECORD_PENDING = `${REMOVE_JOURNAL_ENERGY_MOTION_RECORD}_${ActionType.Pending}`
export const REMOVE_JOURNAL_ENERGY_MOTION_RECORD_FULFILLED = `${REMOVE_JOURNAL_ENERGY_MOTION_RECORD}_${ActionType.Fulfilled}`
export const REMOVE_JOURNAL_ENERGY_MOTION_RECORD_REJECTED = `${REMOVE_JOURNAL_ENERGY_MOTION_RECORD}_${ActionType.Rejected}`

export const UPDATE_JOURNAL_ENERGY_MOTION_RECORD = 'UPDATE_JOURNAL_ENERGY_MOTION_RECORD'
export const UPDATE_JOURNAL_ENERGY_MOTION_RECORD_PENDING = `${UPDATE_JOURNAL_ENERGY_MOTION_RECORD}_${ActionType.Pending}`
export const UPDATE_JOURNAL_ENERGY_MOTION_RECORD_FULFILLED = `${UPDATE_JOURNAL_ENERGY_MOTION_RECORD}_${ActionType.Fulfilled}`
export const UPDATE_JOURNAL_ENERGY_MOTION_RECORD_REJECTED = `${UPDATE_JOURNAL_ENERGY_MOTION_RECORD}_${ActionType.Rejected}`

// Menu Plan
export const FETCH_MENU_PLAN = 'FETCH_MENU_PLAN'
export const FETCH_MENU_PLAN_PENDING = `${FETCH_MENU_PLAN}_${ActionType.Pending}`
export const FETCH_MENU_PLAN_FULFILLED = `${FETCH_MENU_PLAN}_${ActionType.Fulfilled}`
export const FETCH_MENU_PLAN_REJECTED = `${FETCH_MENU_PLAN}_${ActionType.Rejected}`

// My Analytics / Nutritions
export const FETCH_NUTRITION_SUMMARY = 'FETCH_NUTRITION_SUMMARY'
export const FETCH_NUTRITION_SUMMARY_PENDING = `${FETCH_NUTRITION_SUMMARY}_${ActionType.Pending}`
export const FETCH_NUTRITION_SUMMARY_FULFILLED = `${FETCH_NUTRITION_SUMMARY}_${ActionType.Fulfilled}`
export const FETCH_NUTRITION_SUMMARY_REJECTED = `${FETCH_NUTRITION_SUMMARY}_${ActionType.Rejected}`

export const FETCH_ENERGY_HISTORY = 'FETCH_ENERGY_HISTORY'
export const FETCH_ENERGY_HISTORY_PENDING = `${FETCH_ENERGY_HISTORY}_${ActionType.Pending}`
export const FETCH_ENERGY_HISTORY_FULFILLED = `${FETCH_ENERGY_HISTORY}_${ActionType.Fulfilled}`
export const FETCH_ENERGY_HISTORY_REJECTED = `${FETCH_ENERGY_HISTORY}_${ActionType.Rejected}`

export const FETCH_ENERGY_DISTRIBUTION = 'FETCH_ENERGY_DISTRIBUTION'
export const FETCH_ENERGY_DISTRIBUTION_PENDING = `${FETCH_ENERGY_DISTRIBUTION}_${ActionType.Pending}`
export const FETCH_ENERGY_DISTRIBUTION_FULFILLED = `${FETCH_ENERGY_DISTRIBUTION}_${ActionType.Fulfilled}`
export const FETCH_ENERGY_DISTRIBUTION_REJECTED = `${FETCH_ENERGY_DISTRIBUTION}_${ActionType.Rejected}`

// Programs
export const FETCH_PROGRAMS = 'FETCH_PROGRAMS'
export const FETCH_PROGRAMS_PENDING = `${FETCH_PROGRAMS}_${ActionType.Pending}`
export const FETCH_PROGRAMS_FULFILLED = `${FETCH_PROGRAMS}_${ActionType.Fulfilled}`
export const FETCH_PROGRAMS_REJECTED = `${FETCH_PROGRAMS}_${ActionType.Rejected}`

export const FETCH_CURRENT_PROGRAM = 'FETCH_CURRENT_PROGRAM'
export const FETCH_CURRENT_PROGRAM_PENDING = `${FETCH_CURRENT_PROGRAM}_${ActionType.Pending}`
export const FETCH_CURRENT_PROGRAM_FULFILLED = `${FETCH_CURRENT_PROGRAM}_${ActionType.Fulfilled}`
export const FETCH_CURRENT_PROGRAM_REJECTED = `${FETCH_CURRENT_PROGRAM}_${ActionType.Rejected}`

export const POST_PROGRAM = 'POST_PROGRAM'
export const POST_PROGRAM_PENDING = `${POST_PROGRAM}_${ActionType.Pending}`
export const POST_PROGRAM_FULFILLED = `${POST_PROGRAM}_${ActionType.Fulfilled}`
export const POST_PROGRAM_REJECTED = `${POST_PROGRAM}_${ActionType.Rejected}`

// User
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_PENDING = `${FETCH_USER}_${ActionType.Pending}`
export const FETCH_USER_FULFILLED = `${FETCH_USER}_${ActionType.Fulfilled}`
export const FETCH_USER_REJECTED = `${FETCH_USER}_${ActionType.Rejected}`

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_PENDING = `${UPDATE_USER}_${ActionType.Pending}`
export const UPDATE_USER_FULFILLED = `${UPDATE_USER}_${ActionType.Fulfilled}`
export const UPDATE_USER_REJECTED = `${UPDATE_USER}_${ActionType.Rejected}`

export const FETCH_USER_INFO = 'FETCH_USER_INFO'
export const FETCH_USER_INFO_PENDING = `${FETCH_USER_INFO}_${ActionType.Pending}`
export const FETCH_USER_INFO_FULFILLED = `${FETCH_USER_INFO}_${ActionType.Fulfilled}`
export const FETCH_USER_INFO_REJECTED = `${FETCH_USER_INFO}_${ActionType.Rejected}`

export const FETCH_KEYCLOAK_PROFILE = 'FETCH_KEYCLOAK_PROFILE'
export const FETCH_KEYCLOAK_PROFILE_PENDING = `${FETCH_KEYCLOAK_PROFILE}_${ActionType.Pending}`
export const FETCH_KEYCLOAK_PROFILE_FULFILLED = `${FETCH_KEYCLOAK_PROFILE}_${ActionType.Fulfilled}`
export const FETCH_KEYCLOAK_PROFILE_REJECTED = `${FETCH_KEYCLOAK_PROFILE}_${ActionType.Rejected}`

export const FETCH_USER_GOAL_NUTRITIONS = 'FETCH_USER_GOAL_NUTRITIONS'
export const FETCH_USER_GOAL_NUTRITIONS_PENDING = `${FETCH_USER_GOAL_NUTRITIONS}_${ActionType.Pending}`
export const FETCH_USER_GOAL_NUTRITIONS_FULFILLED = `${FETCH_USER_GOAL_NUTRITIONS}_${ActionType.Fulfilled}`
export const FETCH_USER_GOAL_NUTRITIONS_REJECTED = `${FETCH_USER_GOAL_NUTRITIONS}_${ActionType.Rejected}`

export const RESET_GOAL_NUTRITIONS = 'RESET_GOAL_NUTRITIONS'

export const UPDATE_KEYCLOAK_PROFILE = 'UPDATE_KEYCLOAK_PROFILE'
export const UPDATE_KEYCLOAK_PROFILE_PENDING = `${UPDATE_KEYCLOAK_PROFILE}_${ActionType.Pending}`
export const UPDATE_KEYCLOAK_PROFILE_FULFILLED = `${UPDATE_KEYCLOAK_PROFILE}_${ActionType.Fulfilled}`
export const UPDATE_KEYCLOAK_PROFILE_REJECTED = `${UPDATE_KEYCLOAK_PROFILE}_${ActionType.Rejected}`

export const SET_KEYCLOAK_PERMISSIONS = 'FETCH_KEYCLOAK_PERMISSIONS'

// User Settings
export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS'
export const FETCH_USER_SETTINGS_PENDING = `${FETCH_USER_SETTINGS}_${ActionType.Pending}`
export const FETCH_USER_SETTINGS_FULFILLED = `${FETCH_USER_SETTINGS}_${ActionType.Fulfilled}`
export const FETCH_USER_SETTINGS_REJECTED = `${FETCH_USER_SETTINGS}_${ActionType.Rejected}`

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS'
export const UPDATE_USER_SETTINGS_PENDING = `${UPDATE_USER_SETTINGS}_${ActionType.Pending}`
export const UPDATE_USER_SETTINGS_FULFILLED = `${UPDATE_USER_SETTINGS}_${ActionType.Fulfilled}`
export const UPDATE_USER_SETTINGS_REJECTED = `${UPDATE_USER_SETTINGS}_${ActionType.Rejected}`

// Errors
export const ADD_ERROR = 'ADD_ERROR'
export const REMOVE_ERROR = 'REMOVE_ERROR'
export const REMOVE_ERROR_MESSAGES = 'REMOVE_ERROR_MESSAGES'
export const HIDE_ACCESS_DENIED_MODAL = 'HIDE_ACCESS_DENIED_MODAL'

// Toast Message
export const ADD_TOAST_MESSAGE = 'ADD_TOAST_MESSAGE'
export const REMOVE_TOAST_MESSAGE = 'REMOVE_TOAST_MESSAGE'

// Recipes
export const FETCH_RECIPE = 'FETCH_RECIPE'
export const FETCH_RECIPE_FULFILLED = 'FETCH_RECIPE_FULFILLED'

export const FETCH_ENERGY_REQUIREMENTS = 'FETCH_ENERGY_REQUIREMENTS'
export const FETCH_ENERGY_REQUIREMENTS_PENDING = `${FETCH_ENERGY_REQUIREMENTS}_${ActionType.Pending}`
export const FETCH_ENERGY_REQUIREMENTS_FULFILLED = `${FETCH_ENERGY_REQUIREMENTS}_${ActionType.Fulfilled}`
export const FETCH_ENERGY_REQUIREMENTS_REJECTED = `${FETCH_ENERGY_REQUIREMENTS}_${ActionType.Rejected}`

/*    ****************  ActionType Polyfills  ****************
 * Polyfill ActionTypes for Redux Reducers, written with Redux Toolkit.
 * These are needed as long as the webapp relies on the Promise-Middleware.
 * The Promise-Middleware listens for certain events and dispatches actions automatically
 * The Promise-Middleware will be omitted, once the whole Redux Store is written with Redux Toolkit
 */

enum ActionTypePolyFill {
  fulfilled = 'fulfilled',
  pending = 'pending',
  rejected = 'rejected'
}

// Helpers
export const isFailure = (type: string): boolean => {
  return type.indexOf(ActionType.Rejected) !== -1 || type.indexOf(ActionTypePolyFill.rejected) !== -1
}

export const isSuccess = (type: string): boolean => {
  return type.indexOf(ActionType.Fulfilled) !== -1 || type.indexOf(ActionTypePolyFill.fulfilled) !== -1
}

export const getOriginalType = (type: string): string => {
  if (type.indexOf('_') === -1) {
    return type.replace(new RegExp(`/(${Object.values(ActionTypePolyFill).join('|')})`, 'g'), '')
  }
  return type.replace(new RegExp(`_(${Object.values(ActionType).join('|')})`, 'g'), '')
}

// Meta
export const RESET_REDUX_STORE = 'RESET_REDUX_STORE'

export const RESET_USER = 'RESET_USER'
export const RESET_USER_PENDING = `${RESET_USER}_${ActionType.Pending}`
export const RESET_USER_FULFILLED = `${RESET_USER}_${ActionType.Fulfilled}`
export const RESET_USER_REJECTED = `${RESET_USER}_${ActionType.Rejected}`
