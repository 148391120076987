import { TypeOptions as ToastType } from 'react-toastify'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'

import { ReduxStoreState, ToastMessageState } from 'Models'
import { ADD_TOAST_MESSAGE, REMOVE_TOAST_MESSAGE, isSuccess } from 'ReduxStore/actionTypes'

// Actions
export const addToastMessageAction = (message: string, type: ToastType): AsyncAction => ({
  type: ADD_TOAST_MESSAGE,
  meta: { message, type }
})

export const removeToastMessageAction = (): AsyncAction => ({
  type: REMOVE_TOAST_MESSAGE
})

// Reducer and state
export const initialState: ToastMessageState = {
  content: null,
  type: 'default'
}

export type ToastMessageActionMetaData = {
  triggerDefaultSuccessToast?: boolean
  customSuccessToastMessage?: string
}

export type ToastConfigReduxActions = {
  withDefaultSuccessToast: boolean
  withCustomSuccessToast?: string
}

const ToastMessageReducer = (
  state: ToastMessageState = initialState,
  { type, meta = {} }: FluxStandardAction
): ToastMessageState => {
  const { triggerDefaultSuccessToast, customSuccessToastMessage } = meta as ToastMessageActionMetaData

  if (isSuccess(type) && (triggerDefaultSuccessToast || customSuccessToastMessage)) {
    return {
      ...state,
      content: customSuccessToastMessage || 'Gespeichert.',
      type: 'success'
    }
  }

  switch (type) {
    case ADD_TOAST_MESSAGE: {
      return {
        ...state,
        content: meta.message,
        type: meta.type
      }
    }
    case REMOVE_TOAST_MESSAGE: {
      return initialState
    }
    default:
      return state
  }
}

// Selectors
export const toastMessageSelector = ({ toastMessage }: ReduxStoreState): ToastMessageState => toastMessage

export default ToastMessageReducer
