import { EbalanceBackendService } from './EbalanceBackendService'

import { CustomerData, CustomerPlanVariant, GetCustomerResponse, Plan } from 'Models'

export class MembershipService extends EbalanceBackendService {
  async getCustomer(): Promise<GetCustomerResponse[]> {
    const { data } = await this.client.get(`/customers?userId=${this.userId}`)
    return data
  }

  async getSelfServiceUrl(id: string): Promise<{ url: string | undefined; status: number }> {
    let url, responseStatus
    try {
      const { data, status } = await this.client.get(`/customers/${id}/selfservicetoken`)
      url = data.url
      responseStatus = status
    } catch (error: unknown) {
      responseStatus = (error as { response: { status: number } }).response.status
    }

    return { url, status: responseStatus }
  }

  async updateCustomer(newCustomerData: CustomerData): Promise<void> {
    return await this.client.patch(`/customers/${newCustomerData.customerId}`, newCustomerData.customerPayload)
  }

  async fetchActivePlans(): Promise<Plan[]> {
    const { data } = await this.client.get('/plans/active')
    return data.map((plan: Omit<Plan, 'id'>, index: number) => ({ ...plan, id: index }))
  }

  async addOrder(contractId: string): Promise<number> {
    const { status } = await this.client.post('/orders', { contractId })
    return status
  }

  async getTrackingInfo(): Promise<CustomerPlanVariant | undefined> {
    const [customer] = await this.getCustomer()
    if (customer) {
      const { data } = await this.client.get(`/customers/${customer.id}/planvariant`)
      return data as CustomerPlanVariant
    }
    return Promise.resolve(undefined)
  }
}
