import dayjs from 'dayjs'
import React, { FC, useState } from 'react'
import Modal from 'react-modal'
import { ConnectedProps, connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Button, DatePicker, FormInputToggle, IconButton } from 'Components'
import { IconName } from 'Components/Icon'
import { ReduxStoreState } from 'Models'
import { registrationDateSelector } from 'ReduxStore/coreBackendUser'
import { currentJournalDateSelector, setJournalDateAction } from 'ReduxStore/journalDay'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

const mapDispatchToProps = {
  setJournalDate: setJournalDateAction
}

type SelectorProps = {
  currentJournalDate: Date
  registrationDate: Date
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  currentJournalDate: currentJournalDateSelector,
  registrationDate: registrationDateSelector
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  isOpen: boolean
  title: string | undefined
  onCopy: (displayedDay: Date) => void
  children?: React.ReactNode
  onReset: () => void
}

const CopyJournalRecordModal: FC<Props> = ({
  isOpen,
  title,
  setJournalDate,
  currentJournalDate,
  registrationDate,
  onCopy,
  children,
  onReset
}: Props) => {
  const dayFollowingCurrentJournalDate = dayjs(currentJournalDate).add(1, 'day').toDate()
  const [displayedDay, setDisplayedDay] = useState<Date>(dayFollowingCurrentJournalDate)
  const [shouldJump, setShouldJump] = useState(true)

  const onChangeDate = (date: Date | null): void => {
    if (date) {
      setDisplayedDay(date)
    }
  }

  const toggleJump = (): void => setShouldJump(!shouldJump)

  const resetAll = (): void => {
    onReset()
    setDisplayedDay(dayFollowingCurrentJournalDate)
    setShouldJump(true)
  }

  const onCopyRecord = (): void => {
    onCopy(displayedDay)
    if (shouldJump && !dayjs(currentJournalDate).isSame(displayedDay, 'day')) {
      setJournalDate(displayedDay)
    }
    resetAll()
  }

  return (
    <Modal style={modalZIndexPriority} isOpen={isOpen} onRequestClose={resetAll} ariaHideApp={false}>
      <div className="modal is-medium p-5">
        <IconButton name={IconName.close} onClick={resetAll} />
        <div className="row">
          <div className="col-12">
            <h2>{title}</h2>
          </div>
          <div className="col-12 col-sm-7">
            <DatePicker
              label={'Kopiere zu'}
              value={displayedDay}
              onChange={onChangeDate}
              range={{ from: registrationDate }}
            />
          </div>
          <div className="col-12 col-sm-5">{children}</div>
          <div className="col-12 col-sm">Springe zum ausgewählten Tag</div>
          <div className="col-12 col-sm-auto">
            <FormInputToggle value={shouldJump} onValueChange={toggleJump} />
          </div>
          <div className="col-12">
            <Button disabled={false} onClick={onCopyRecord}>
              Kopieren
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default connector(CopyJournalRecordModal)
