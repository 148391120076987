import React, { FC, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'

import CopyJournalRecordModal from './Modals/CopyJournalRecordModal'
import EnergyModal from './Modals/EnergyModal'
import MoveJournalRecordModal from './Modals/MoveJournalRecordModal'

import { FormInputSelect, JournalCardEnergy } from 'Components'
import { copyButton, deleteButton, editButton, moveButton } from 'Components/IconButton/iconButtons'
import { JournalEnergyRecord, MealCategory, allMealCategories } from 'Models'
import { useAppDispatch } from 'ReduxStore/hooks'
import {
  addJournalEnergyRecordAction,
  removeJournalEnergyRecordAction,
  updateJournalEnergyRecordAction
} from 'ReduxStore/journalEnergyRecords'
import { Formatter } from 'Utils'
const mapDispatchToProps = {
  removeJournalEnergyRecord: removeJournalEnergyRecordAction,
  addJournalEnergyRecord: addJournalEnergyRecordAction
}

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  journalEnergyRecord: JournalEnergyRecord
}

const JournalCardEnergyContainer: FC<Props> = ({
  journalEnergyRecord,
  removeJournalEnergyRecord,
  addJournalEnergyRecord
}: Props) => {
  const dispatch = useAppDispatch()
  const { id, energy, description, mealCategory } = journalEnergyRecord
  const [energyModalIsOpen, setEnergyModalIsOpen] = useState(false)
  const [copyModalIsOpen, setCopyModalIsOpen] = useState(false)
  const [displayedMealCategory, setDisplayedMealCategory] = useState<MealCategory>(mealCategory)

  const [moveModalIsOpen, setMoveModalIsOpen] = useState(false)

  const onCopy = (date: Date): void => {
    addJournalEnergyRecord(description, energy, displayedMealCategory, date)
  }

  const onReset = (): void => {
    setCopyModalIsOpen(false)
    setMoveModalIsOpen(false)
    setDisplayedMealCategory(mealCategory)
  }

  const onMove = (date: Date): void => {
    dispatch(
      updateJournalEnergyRecordAction({
        id: id,
        datetime: date,
        mealCategory: displayedMealCategory,
        description: description,
        energy: energy
      })
    )

    setMoveModalIsOpen(false)
  }

  const callbackCopyButton = (): void => setCopyModalIsOpen(!copyModalIsOpen)
  const callbackMoveButton = (): void => setMoveModalIsOpen(!moveModalIsOpen)
  const callbackDeleteButton = (): void => removeJournalEnergyRecord(id)
  const callbackEditButton = (): void => setEnergyModalIsOpen(true)

  return (
    <>
      <EnergyModal
        isOpen={energyModalIsOpen}
        onToggle={() => setEnergyModalIsOpen(!energyModalIsOpen)}
        journalEnergyRecord={journalEnergyRecord}
      />
      <MoveJournalRecordModal
        isOpen={moveModalIsOpen}
        title={description}
        onReset={onReset}
        displayedMealCategory={displayedMealCategory}
        onChange={(value) => setDisplayedMealCategory(value as MealCategory)}
        onMove={onMove}
      >
        <div className="col-md-5 col-12">
          <FormInputSelect
            name="mealCategory"
            items={allMealCategories}
            selectedKey={displayedMealCategory}
            labelExtractor={(value) => Formatter.formatMealCategory(value as MealCategory)}
            onChange={(value) => setDisplayedMealCategory(value as MealCategory)}
          />
        </div>
      </MoveJournalRecordModal>
      <CopyJournalRecordModal isOpen={copyModalIsOpen} title={description} onReset={onReset} onCopy={onCopy}>
        <div className="col-12 col-lg-6">
          <FormInputSelect
            name="mealCategory"
            items={allMealCategories}
            selectedKey={displayedMealCategory}
            labelExtractor={(value) => Formatter.formatMealCategory(value as MealCategory)}
            onChange={(value) => setDisplayedMealCategory(value as MealCategory)}
          />
        </div>
      </CopyJournalRecordModal>
      <JournalCardEnergy
        energyRecord={{ energy, description }}
        cardButtons={[]}
        iconButtons={[
          editButton(callbackEditButton),
          copyButton(callbackCopyButton),
          moveButton(callbackMoveButton),
          deleteButton(callbackDeleteButton)
        ]}
      />
    </>
  )
}
export default connector(JournalCardEnergyContainer)
