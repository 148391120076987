import { uniqueId } from 'lodash'
import React, { FC, useEffect, useMemo } from 'react'
import { FieldValues, UseFormMethods, useWatch } from 'react-hook-form'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { FormInputIngredients, FormInputIngredientsProps } from './FormInputIngredients'

import { Food, ReduxStoreState } from 'Models'
import { userFoodsVisibleSelector } from 'ReduxStore/userFoods'

const mapStateToProps = createStructuredSelector<ReduxStoreState, { userFoods?: Food[] }>({
  userFoods: userFoodsVisibleSelector
})

const connector = connect(mapStateToProps, {})

type Props = Pick<UseFormMethods, 'setValue' | 'control' | 'register' | 'errors'> &
  FormInputIngredientsProps & {
    name: string
    defaultValues?: FieldValues
  }

export const FormInputIngredientsController: FC<Props> = ({
  setValue,
  register,
  errors,
  control,
  name,
  userFoods,
  defaultValues,
  ...props
}: Props) => {
  useEffect(() => {
    register({ name })
  }, [])

  const value = useWatch({
    control,
    name,
    defaultValue: defaultValues ? defaultValues[name] : undefined
  })

  const keyedIngredients = useMemo(() => {
    return value ? value.map((ingredient: Food) => ({ ...ingredient, key: uniqueId() })) : []
  }, [value])

  const errorText = useMemo(() => errors[name] && errors[name].message, [errors[name]])

  return (
    <FormInputIngredients
      userFoods={userFoods}
      onIngredientsChange={(value) => setValue(name, value, { shouldValidate: true })}
      errorText={errorText}
      ingredients={keyedIngredients}
      {...props}
    />
  )
}

export default connector(FormInputIngredientsController)
