import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ConnectedProps, connect } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { EditFoodPage } from './EditFoodPage'
import { FoodFormValuesType, mapFoodToValues, mapValuesToFood } from './foodFormHelpers'
import { foodFormValidationSchema } from './foodFormSchema'

import { routes } from 'Config'
import { EatableSource, Food, FoodCategory, ReduxStoreState } from 'Models'
import { isLoadingSelector } from 'ReduxStore/common'
import { eatableSourcesSelector, fetchEatableSourcesAction } from 'ReduxStore/eatableSources'
import { foodCategoriesSelector } from 'ReduxStore/foodCategories'
import { didLoadUserFoodsSelector, fetchUserFoodAction, updateUserFood, userFoodsSelector } from 'ReduxStore/userFoods'
import { useImageUploading } from 'Utils/helpers/useImageUploading'

type SelectorProps = {
  eatableSources: EatableSource[]
  userFoods: Food[]
  isUserFoodsLoading: boolean
  didLoadUserFoods: boolean
  foodCategoriesMain: FoodCategory[]
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  eatableSources: eatableSourcesSelector,
  userFoods: userFoodsSelector,
  isUserFoodsLoading: (state) => isLoadingSelector(state.userFoods),
  didLoadUserFoods: didLoadUserFoodsSelector,
  foodCategoriesMain: foodCategoriesSelector
})

const mapDispatchToProps = {
  updateUserFood,
  fetchUserFood: fetchUserFoodAction,
  fetchEatableSources: fetchEatableSourcesAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & RouteComponentProps<{ foodId: string }>

const EditFoodPageContainer: FC<Props> = ({
  eatableSources,
  updateUserFood,
  fetchUserFood,
  fetchEatableSources,
  userFoods,
  foodCategoriesMain,
  isUserFoodsLoading,
  didLoadUserFoods,
  match: {
    params: { foodId }
  }
}: Props) => {
  const { isImageUploading, setTempImageId, setIsImageUploading, onImageSubmit, onPageExit } = useImageUploading({})

  const editFood = useMemo(() => {
    return userFoods.find((item) => item.id === foodId)
  }, [foodId, userFoods, eatableSources.map((s) => s.id)])
  const {
    register,
    control,
    errors,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { isSubmitting }
  } = useForm<FoodFormValuesType>({
    defaultValues: editFood ? mapFoodToValues(editFood, foodCategoriesMain) : undefined,
    resolver: yupResolver(foodFormValidationSchema),
    shouldUnregister: false
  })

  useEffect(() => {
    if (didLoadUserFoods && foodCategoriesMain && editFood) {
      reset(mapFoodToValues(editFood, foodCategoriesMain))
    }
  }, [userFoods, foodCategoriesMain, editFood])

  useEffect(() => {
    if (foodId && !isUserFoodsLoading && didLoadUserFoods && !editFood) {
      fetchUserFood(foodId)
    }
  }, [foodId, userFoods, editFood, isUserFoodsLoading])

  useEffect(() => {
    if (eatableSources.length === 0) {
      fetchEatableSources()
    }

    return () => {
      onPageExit()
    }
  }, [])

  const history = useHistory()

  const onSubmit = handleSubmit(async (values) => {
    await onImageSubmit(values.imageUrl)
    updateUserFood(foodId, mapValuesToFood({ values }))
    history.push(routes.myEatables.myFoodView(foodId))
  })

  if (!foodId || isUserFoodsLoading || !userFoods || !editFood) {
    return null
  }

  return (
    <EditFoodPage
      isImageUploading={isImageUploading}
      setIsImageUploading={setIsImageUploading}
      setTempImageId={setTempImageId}
      onSubmit={onSubmit}
      onCancel={history.goBack}
      isSubmitting={isSubmitting}
      setValue={setValue}
      setError={setError}
      register={register}
      control={control}
      errors={errors}
      eatableSources={eatableSources}
    />
  )
}

export default connector(EditFoodPageContainer)
