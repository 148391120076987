import React, { FC, useEffect, useMemo, useRef } from 'react'
import { FieldValues, UseFormMethods, useWatch } from 'react-hook-form'

import FormInputNumber, { FormInputNumberProps } from './FormInputNumber'

import { useFocusOnFirstError } from 'Utils/helpers/useFocusOnFirstError'

type Props = Pick<UseFormMethods, 'setValue' | 'control' | 'register' | 'errors'> &
  FormInputNumberProps & {
    name: string
    defaultValues?: FieldValues
  }

export const FormInputNumberController: FC<Props> = ({
  setValue,
  register,
  errors,
  control,
  name,
  defaultValues,
  ...props
}: Props) => {
  const fieldRef = useRef<HTMLInputElement>(null)
  const errorText = useMemo(() => (errors[name] ? errors[name].message : ''), [errors[name]])

  useEffect(() => {
    register({ name })
  }, [])
  const value = useWatch({
    control,
    name,
    defaultValue: defaultValues ? defaultValues[name] : undefined
  })

  useFocusOnFirstError({ fieldRef, errors, name })

  return (
    <FormInputNumber
      value={value}
      inputRef={fieldRef}
      onChange={(value) => setValue(name, value, { shouldValidate: true })}
      errorText={errorText}
      {...props}
    />
  )
}
