import React, { FC, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'

import CopyJournalRecordModal from './Modals/CopyJournalRecordModal'
import EnergyMotionModal from './Modals/EnergyMotionModal'
import MoveJournalRecordModal from './Modals/MoveJournalRecordModal'

import { JournalCardEnergyMotion } from 'Components'
import { copyButton, deleteButton, editButton, moveButton } from 'Components/IconButton/iconButtons'
import { JournalEnergyMotionRecord } from 'Models'
import { useAppDispatch } from 'ReduxStore/hooks'
import {
  addJournalEnergyMotionRecordAction,
  removeJournalEnergyMotionRecordAction,
  updateJournalEnergyMotionRecordAction
} from 'ReduxStore/journalEnergyMotionRecords'

const mapDispatchToProps = {
  removeJournalEnergyMotionRecord: removeJournalEnergyMotionRecordAction,
  addJournalEnergyMotionRecord: addJournalEnergyMotionRecordAction
}

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  journalEnergyMotionRecord: JournalEnergyMotionRecord
}

const JournalCardEnergyContainer: FC<Props> = ({
  journalEnergyMotionRecord,
  removeJournalEnergyMotionRecord,
  addJournalEnergyMotionRecord
}: Props) => {
  const dispatch = useAppDispatch()
  const { id, energy, description, duration } = journalEnergyMotionRecord

  const [isOpen, setOpen] = useState(false)
  const [copyModalIsOpen, setCopyModalIsOpen] = useState(false)
  const [displayedDuration, setDisplayedDuration] = useState<number | undefined>(duration)

  const [moveModalIsOpen, setMoveModalIsOpen] = useState(false)

  const onCopy = (date: Date): void => {
    if (displayedDuration) {
      addJournalEnergyMotionRecord(description, energy, displayedDuration, date)
    }
  }

  const onReset = (): void => {
    setCopyModalIsOpen(false)
    setMoveModalIsOpen(false)
    setDisplayedDuration(duration)
  }

  const onMove = (date: Date): void => {
    dispatch(
      updateJournalEnergyMotionRecordAction({
        id: journalEnergyMotionRecord.id,
        energy,
        description,
        duration: duration,
        datetime: date
      })
    )

    setMoveModalIsOpen(false)
  }

  const callbackCopyButton = (): void => setCopyModalIsOpen(!copyModalIsOpen)
  const callbackMoveButton = (): void => setMoveModalIsOpen(!moveModalIsOpen)

  const callbackDeleteButton = (): void => removeJournalEnergyMotionRecord(id)
  const callbackEditButton = (): void => setOpen(true)

  return (
    <>
      <EnergyMotionModal
        isOpen={isOpen}
        onToggle={() => setOpen(!isOpen)}
        journalEnergyMotionRecord={journalEnergyMotionRecord}
      />
      <CopyJournalRecordModal isOpen={copyModalIsOpen} onReset={onReset} title={description} onCopy={onCopy} />
      <MoveJournalRecordModal isOpen={moveModalIsOpen} title={description} onReset={onReset} onMove={onMove} />
      <JournalCardEnergyMotion
        energyMotionRecord={{ energy, description, duration }}
        cardButtons={[]}
        iconButtons={[
          editButton(callbackEditButton),
          copyButton(callbackCopyButton),
          moveButton(callbackMoveButton),
          deleteButton(callbackDeleteButton)
        ]}
      />
    </>
  )
}

export default connector(JournalCardEnergyContainer)
