import dayjs from 'dayjs'
import { isNullOrUndefined } from 'node-util'

import { locale, today } from 'Config'
import { Amount, BodyCriterion, FOODCRITERIA, FoodCriteria, MealCategory, Range, UNITS, UnitId } from 'Models'

const nbsp = '\u00A0'
export class Formatter {
  /**
   * Formats a unit based on its id.
   * @param unitId id of unit as defined in the 'Units' module.
   * @param preferName switch between unit name or abbreviation
   */
  static formatUnit(unitId: UnitId, preferName = false): string | undefined {
    const { name, abbreviation } = UNITS[unitId] || {}
    const result = preferName ? name || abbreviation : abbreviation || name
    return result || unitId
  }

  /**
   * Formats a food energy to a human string.
   * @param energy Energy given in kcal.
   */
  static formatEnergy(energy: number | undefined | null, withUnit = true): string {
    if (isNullOrUndefined(energy)) {
      return 'n/a'
    }
    const formattedNumber = Formatter.formatNumber(energy)
    if (!withUnit) {
      return formattedNumber
    }
    return formattedNumber + nbsp + 'kcal'
  }

  static formatNumber(input: number, options: Intl.NumberFormatOptions = {}): string {
    return Number(input).toLocaleString(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
      ...options
    })
  }

  static formatPrice(price = 9999): string {
    return (price / 100).toFixed(2)
  }

  static formatFoodQuantitiy(quantity: number | undefined): string {
    if (isNullOrUndefined(quantity)) {
      return '?'
    }
    if (isNaN(quantity)) {
      return '0'
    }
    return Number(quantity).toLocaleString(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })
  }

  /**
   * Formats a food amount to human string.
   * @param quantity Amount of food.
   * @param unitId The unit of the food given as backend code, e.g. 'GRAM'
   */
  static formatFoodAmount(amount: Amount): string
  static formatFoodAmount(quantity: number | undefined, unitId: UnitId): string
  static formatFoodAmount(amountOrQuantity: Amount | number | undefined, unitId?: UnitId): string {
    let quantity: number | undefined
    if (typeof amountOrQuantity === 'number' || isNullOrUndefined(amountOrQuantity)) {
      quantity = amountOrQuantity as number
    } else {
      quantity = amountOrQuantity.quantity
      unitId = amountOrQuantity.unit
    }

    if (isNullOrUndefined(unitId)) return '???'

    const formattedQuantity = this.formatFoodQuantitiy(quantity)

    // temp. hack until proper impl. >>>
    if (unitId === 'PORTION') {
      return quantity === 1 ? '1 Portion' : `${formattedQuantity} Portionen`
    }
    // <<<

    return `${formattedQuantity}${nbsp}${this.formatUnit(unitId)}`
  }

  static formatNutritionGram(value: number | undefined | null): string {
    if (value !== 0 && !value) {
      return 'n/a'
    }
    return (
      Number(value).toLocaleString(locale, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      }) +
      nbsp +
      'g'
    )
  }

  /**
   * Formats an eatable amount to human string.
   */
  static formatEatableAmount(amount: Amount): string {
    return this.formatFoodAmount(amount.quantity, amount.unit)
  }

  /**
   * Formats a fraction value between 0.0 and 1.0 as percentage
   */
  static formatPercentage(fraction: number, digits = 1): string {
    return isNaN(fraction)
      ? 'n/a'
      : Number(fraction * 100).toLocaleString(locale, {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits
        }) +
          nbsp +
          '%'
  }

  static formatMealCategory(category: MealCategory): string {
    switch (category) {
      case 'BREAKFAST':
        return 'Frühstück'
      case 'LUNCH':
        return 'Mittagessen'
      case 'DINNER':
        return 'Nachtessen'
      case 'SNACK':
        return 'Snack'
    }
  }

  static formatBodyCriterion(bodyCriterion: BodyCriterion): string {
    switch (bodyCriterion) {
      case 'BELLY':
        return 'Bauchumfang'
      case 'HEIGHT':
        return 'Grösse'
      case 'WEIGHT':
        return 'Gewicht'
      default:
        return 'UNDEFINED_BODY_CRITERION'
    }
  }

  static formatRange(range?: Range | null, fractions = 1): { min: string; max: string } {
    const formatRangeBoundary = (boundary: 'min' | 'max'): string => range?.[boundary]?.toFixed(fractions) || 'n/a'
    return {
      min: formatRangeBoundary('min'),
      max: formatRangeBoundary('max')
    }
  }

  /**
   * Formats food criteria with specified id.
   *
   * @param foodCriteriaId id of food criteria
   */
  static formatFoodCriteria(foodCriteriaId: string): string {
    type FoodCriteriaKeyType = keyof FoodCriteria
    const category = Object.keys(FOODCRITERIA)
      .map((key: string) => FOODCRITERIA[key as FoodCriteriaKeyType])
      .find((category) => !!category[foodCriteriaId])

    return category ? category[foodCriteriaId] : ''
  }

  /**
   * Formats a date object to base ISO 8601 string (YYYY-MM-DD)
   * @param date
   */
  static formatIso8601Date(date: Date): string {
    return dayjs(date).format('YYYY-MM-DD')
  }

  /**
   * Formats a date object to a european human readable string
   * @param date
   */
  static formatHumanDate(date: Date): string {
    return dayjs(date).format('DD.MM.YYYY')
  }

  /**
   * Returns specified date formatted with specified dayjs format.
   * For invalid dates an empty string is returned.
   *
   * @param date date to format
   * @param formatToday dayjs valid format
   * @param formatOther dayjs valid format
   *
   * @returns formatted date
   */
  static formatDate(date: Date, formatToday = '[Heute]', formatOther = 'dd D. MMM'): string {
    const dDate = dayjs(date)

    const dateFormat = dDate.isSame(today, 'day') ? formatToday : formatOther

    return date && dDate.isValid() ? dDate.format(dateFormat) : ''
  }

  /**
   * Formats a duration into a string of `** Minuten`
   * @param duration length of a time period given in seconds
   */
  static formatDuration(duration: number): string {
    const durationInMinutes = duration / 60
    return Formatter.formatDurationInMinutes(durationInMinutes)
  }

  /**
   * Formats a duration into a string of `** Minuten`
   * @param duration length of a time period given in minutes
   */
  static formatDurationInMinutes(durationInMinutes: number): string {
    if (!durationInMinutes) return `n/a${nbsp}Minuten`
    if (durationInMinutes < 1.5) return `1${nbsp}Minute`
    return `${Formatter.formatNumber(durationInMinutes)}${nbsp}Minuten`
  }
}
