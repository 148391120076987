import { AxiosResponse } from 'axios'

import { appConfig } from 'Config'
import { BackendErrorData } from 'Models'

export const getDefaultErrorMessage = (status?: number): string => {
  if (!status || status >= 600) {
    return 'Something went very wrong.'
  }
  if (status >= 500) {
    return 'Es ist ein Serverfehler aufgetreten.'
  }
  if (status >= 400) {
    return 'Es ist ein Clientfehler aufgetreten.'
  }

  return 'Es ist ein Fehler aufgetreten.'
}

export const getCustomErrorMessage = (response: AxiosResponse, { code, message }: BackendErrorData): string | null => {
  if (appConfig.showDebugInfo) {
    console.error({ statusCode: response.status, code, message, url: response.request.responseURL })
  }

  return message || getDefaultErrorMessage(response.status)
}

export const getErrorMessagesFromBackendResponse = (response: AxiosResponse | null | undefined): string[] => {
  if (!response) {
    return [getDefaultErrorMessage()]
  }
  if (response.data && response.data.message) {
    return [response.data.message]
  }
  if (response.data && response.data.errors) {
    return response.data.errors.length
      ? response.data.errors.map((error: BackendErrorData) => getCustomErrorMessage(response, error))
      : Object.values(response.data.errors) // keycloak api wrapper errors
  }
  return [getDefaultErrorMessage(response.status)]
}
