/**
 * This redux resource contains all about the user regarding keycloak.
 */
import { KeycloakInstance } from 'keycloak-js'
import { combineReducers } from 'redux'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'
import { createSelector } from 'reselect'

import {
  FETCH_KEYCLOAK_PROFILE,
  FETCH_KEYCLOAK_PROFILE_FULFILLED,
  SET_KEYCLOAK_PERMISSIONS,
  UPDATE_KEYCLOAK_PROFILE,
  UPDATE_KEYCLOAK_PROFILE_FULFILLED
} from './actionTypes'
import { createPendingCounterReducer } from './common'
import { ToastConfigReduxActions } from './toastMessage'

import {
  KeyCloakProfile,
  KeyCloakUser,
  KeyCloakUserReduxState,
  PermissionRedux,
  ReduxStoreState,
  ThunkAction,
  UserRole
} from 'Models'
import { keycloakService } from 'Services'
import { Utils } from 'Utils'

// Helpers
const flattenAttributes = (attributes: Record<string, string[]>): Record<string, string | number | boolean | Date> =>
  Object.keys(attributes).reduce(
    (acc, key) => ({ ...acc, [key]: Array.isArray(attributes[key]) ? attributes[key][0] : attributes[key] }),
    {}
  )

// Actions
export const fetchKeyCloakProfileAction = (keycloak: KeycloakInstance): AsyncAction => ({
  type: FETCH_KEYCLOAK_PROFILE,
  payload: keycloak.loadUserProfile(),
  meta: {
    id: keycloak.subject,
    authenticated: !!keycloak.authenticated,
    accountUrl: keycloak.createAccountUrl()
  }
})

export const updateKeycloakProfileAction = (
  userId: string,
  userData: KeyCloakProfile,
  { withDefaultSuccessToast, withCustomSuccessToast }: ToastConfigReduxActions
): ThunkAction => async (dispatch, getState) => {
  const oldUser = keyCloakUserSelector(getState())

  return dispatch({
    type: UPDATE_KEYCLOAK_PROFILE,
    payload: keycloakService.updateUserMasterData(userId, {
      ...oldUser,
      ...userData,
      attributes: {
        ...oldUser.attributes,
        ...userData.attributes
      }
    }),
    meta: { triggerDefaultSuccessToast: withDefaultSuccessToast, customSuccessToastMessage: withCustomSuccessToast }
  })
}

const convertKeyCloakAccessPermissions = (keycloak: KeycloakInstance): PermissionRedux => {
  const { realmAccess } = keycloak

  return {
    roles: realmAccess ? (realmAccess.roles as UserRole[]) : []
  }
}

// Promise.resolve()
export const setKeyCloakPermissionsAction = (keycloak: KeycloakInstance): FluxStandardAction => ({
  type: SET_KEYCLOAK_PERMISSIONS,
  payload: convertKeyCloakAccessPermissions(keycloak)
})

// Reducer and state
const initialStateUser: KeyCloakUser = {
  id: '',
  email: 'max@max.de',
  username: 'anonymous',
  firstName: '',
  lastName: '',
  authenticated: false,
  attributes: {
    addressStreet: '',
    addressHouse: '',
    addressZipcode: '',
    addressCity: '',
    addressCountry: '',
    gender: null
  }
}

const data = (state: KeyCloakUser = initialStateUser, { type, payload, meta }: FluxStandardAction): KeyCloakUser => {
  switch (type) {
    case FETCH_KEYCLOAK_PROFILE_FULFILLED: {
      const { attributes, ...profileData } = payload
      const { id, accountUrl, authenticated } = meta

      const newAttributes = {
        ...state.attributes,
        ...flattenAttributes(Utils.convertObjectDatestringToDate(attributes, ['birthday']))
      }

      return {
        ...state,
        id,
        authenticated,
        accountUrl,
        ...profileData,
        attributes: newAttributes
      }
    }
    case UPDATE_KEYCLOAK_PROFILE_FULFILLED: {
      const { attributes, ...profileData } = payload

      return {
        ...state,
        ...profileData,
        attributes: flattenAttributes(Utils.convertObjectDatestringToDate(attributes, ['birthday']))
      }
    }
    default:
      return state
  }
}

const initialStatePermissions: PermissionRedux = {
  roles: []
}

const permissions = (state = initialStatePermissions, { type, payload }: FluxStandardAction): PermissionRedux => {
  switch (type) {
    case SET_KEYCLOAK_PERMISSIONS: {
      return {
        ...state,
        ...payload
      }
    }
    default:
      return state
  }
}

// Selectors
export const keyCloakUserSelector = ({ keyCloakUser }: ReduxStoreState): KeyCloakUser => keyCloakUser.data

export const keyCloakPermissionSelector = ({ keyCloakUser }: ReduxStoreState): PermissionRedux =>
  keyCloakUser.permissions

export const keyCloakUserAttributesSelector = createSelector([keyCloakUserSelector], (data) => data.attributes)

export const keyCloakUserRolesSelector = createSelector([keyCloakPermissionSelector], (data) => data.roles)

export const hasBaseCustomerRoleSelector = createSelector([keyCloakUserRolesSelector], (data) =>
  data.includes('BASE_CUSTOMER')
)

export const hasProspectiveCustomerRoleSelector = createSelector([keyCloakUserRolesSelector], (data) =>
  data.includes('PROSPECTIVE_CUSTOMER')
)

export const isActiveCustomerSelector = createSelector([keyCloakUserRolesSelector], (data) =>
  data.includes('ACTIVE_CUSTOMER')
)

const asyncActionTypes = [FETCH_KEYCLOAK_PROFILE, UPDATE_KEYCLOAK_PROFILE]

export default combineReducers<KeyCloakUserReduxState>({
  data,
  permissions,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes),
  fetchKeycloakProfilePendingCounter: createPendingCounterReducer(FETCH_KEYCLOAK_PROFILE)
})
