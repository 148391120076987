/**
 * This redux resource contains all about the user regarding the core-backend.
 */
import { combineReducers } from 'redux'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'
import { createSelector } from 'reselect'

import {
  FETCH_USER,
  FETCH_USER_FULFILLED,
  FETCH_USER_GOAL_NUTRITIONS,
  FETCH_USER_GOAL_NUTRITIONS_FULFILLED,
  FETCH_USER_INFO,
  FETCH_USER_INFO_FULFILLED,
  FETCH_USER_INFO_REJECTED,
  FETCH_USER_REJECTED,
  POST_PROGRAM_FULFILLED,
  RESET_GOAL_NUTRITIONS,
  UPDATE_USER,
  UPDATE_USER_FULFILLED
} from './actionTypes'
import { createPendingCounterReducer } from './common'

import { today } from 'Config'
import {
  CoreBackendUser,
  CoreBackendUserReduxState,
  CoreBackendUserReduxType,
  NutritionsList,
  ReduxStoreState,
  ThunkAction
} from 'Models'
import { currentJournalDateFormattedSelector } from 'ReduxStore/journalDay'
import { coreBackendService } from 'Services'
import { Formatter } from 'Utils'

// Actions
export const fetchCoreBackendUserAction = (): AsyncAction => ({
  type: FETCH_USER,
  payload: coreBackendService.fetchUser()
})

export const updateCoreBackendUserAction = (user: Omit<CoreBackendUser, 'id'>): AsyncAction => ({
  type: UPDATE_USER,
  payload: coreBackendService.updateUser(user)
})

export const fetchCoreBackendUserInfoAction = (): AsyncAction => ({
  type: FETCH_USER_INFO,
  payload: coreBackendService.fetchUserInfo()
})

export const fetchUserGoalNutritionsAction = (date: Date): ThunkAction => async (dispatch, getState) => {
  const {
    coreBackendUser: {
      data: { goalNutritions }
    }
  } = getState()
  if (!goalNutritions || !goalNutritions[Formatter.formatIso8601Date(date)]) {
    return dispatch({
      type: FETCH_USER_GOAL_NUTRITIONS,
      payload: coreBackendService.fetchUserGoalNutritions({ date }),
      meta: { date }
    })
  }
}

export const resetGoalNutritions = (): { type: string } => {
  return {
    type: RESET_GOAL_NUTRITIONS
  }
}

// Reducer and state
const initialState: CoreBackendUserReduxType = {
  id: '',
  isExisting: null,
  info: {
    hasJournalRecords: false,
    hasPrograms: false,
    registrationDate: today
  }
}

const data = (
  state: CoreBackendUserReduxType = initialState,
  { type, payload, meta }: FluxStandardAction
): CoreBackendUserReduxType => {
  switch (type) {
    case UPDATE_USER_FULFILLED:
      return {
        ...state,
        diet: JSON.parse(payload.config.data).diet
      }
    case FETCH_USER_REJECTED:
    case FETCH_USER_INFO_REJECTED:
      return {
        ...initialState,
        isExisting: false
      }
    case FETCH_USER_INFO_FULFILLED:
      return {
        ...state,
        info: payload,
        isExisting: true
      }
    case FETCH_USER_FULFILLED:
      return {
        ...state,
        ...payload,
        isExisting: true
      }
    case FETCH_USER_GOAL_NUTRITIONS_FULFILLED:
      return {
        ...state,
        goalNutritions: {
          ...state.goalNutritions,
          [Formatter.formatIso8601Date(meta.date)]: payload
        }
      }
    case POST_PROGRAM_FULFILLED:
      return {
        ...state,
        info: {
          ...state.info,
          hasPrograms: true,
          registrationDate: state.info.hasPrograms ? state.info.registrationDate : initialState.info.registrationDate
        }
      }
    case RESET_GOAL_NUTRITIONS:
      return {
        ...state,
        goalNutritions: undefined
      }

    default:
      return state
  }
}

// Selectors
export const coreBackendUserSelector = ({ coreBackendUser }: ReduxStoreState): CoreBackendUserReduxType =>
  coreBackendUser.data

export const coreBackendUserInfoSelector = createSelector([coreBackendUserSelector], ({ info }) => info)

export const userGoalNutritionsTodaySelector = createSelector(
  [coreBackendUserSelector],
  ({ goalNutritions = {} as NutritionsList }) => goalNutritions[Formatter.formatIso8601Date(today)] || {}
)

export const userGoalNutritionsCurrentDateSelector = createSelector(
  [coreBackendUserSelector, currentJournalDateFormattedSelector],
  ({ goalNutritions = {} as NutritionsList }, date) => goalNutritions[date] || {}
)

export const registrationDateSelector = createSelector(
  [coreBackendUserInfoSelector],
  (userInfo) => userInfo.registrationDate
)

export const isKeycloakProfileLoadingSelector = ({ keyCloakUser }: ReduxStoreState): boolean =>
  keyCloakUser.fetchKeycloakProfilePendingCounter > 0

const asyncActionTypes = [FETCH_USER, UPDATE_USER, FETCH_USER_INFO, FETCH_USER_GOAL_NUTRITIONS]

export default combineReducers<CoreBackendUserReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
