import React from 'react'
import Modal from 'react-modal'

import { Button, IconButton, IconColor, IconName } from 'Components'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'
import { Calculator } from 'Utils'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

const WeightWarningContent: React.FC<{ height?: number | null; weight?: number; programType?: ProgramTypes }> = ({
  height = null,
  weight = null,
  programType
}) => {
  if (programType === ProgramTypes.KEEP_WEIGHT && Calculator.isUserUnderweight(weight, height)) {
    return (
      <>
        <h2>Ihr BMI liegt im Bereich des Untergewichts</h2>
        <p>
          Gemäss Ihren Angaben befindet sich Ihr BMI im Bereich des Untergewichts. Untergewicht ist aus gesundheitlicher
          Sicht nicht erstrebenswert, muss aber nicht in jedem Fall Grund zur Sorge sein, da es verschiedene Ursachen
          haben kann. Wir möchten Ihnen nahelegen, sich für eine Abklärung an Ihren Arzt / Ihre Ärztin zu wenden.
          eBalance richtet sich vorwiegend an übergewichtige Erwachsene bzw. auch an Personen, die im gesunden Rahmen
          des Normalgewichts ihr Wohlfühlgewicht erreichen möchten. Wenden Sie sich bitte, bei weiteren Fragen an den
          Support unter <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>, dort können Sie auch eine
          Ernährungsberatung anfragen.
        </p>
      </>
    )
  }

  if (programType === ProgramTypes.LOSE_WEIGHT && Calculator.isUserUnderweight(weight, height)) {
    return (
      <>
        <h2>Ihr BMI liegt im Bereich des Untergewichts</h2>
        <p>
          Gemäss Ihren Angaben befindet sich Ihr BMI im Bereich des Untergewichts. Untergewicht ist aus gesundheitlicher
          Sicht nicht erstrebenswert, muss aber nicht in jedem Fall Grund zur Sorge sein, da es verschiedene Ursachen
          haben kann. Daher haben wir Ihnen das Ziel “Gewicht halten” zugewiesen. Wir möchten Ihnen nahelegen, sich für
          eine Abklärung an Ihren Arzt / Ihre Ärztin zu wenden. eBalance richtet sich vorwiegend an übergewichtige
          Erwachsene bzw. auch an Personen, die im gesunden Rahmen des Normalgewichts ihr Wohlfühlgewicht erreichen
          möchten. Wenden Sie sich bitte, bei weiteren Fragen an den Support unter{' '}
          <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>, dort können Sie auch eine Ernährungsberatung anfragen.
        </p>
      </>
    )
  }

  if (Calculator.isUserOverweight(weight, height)) {
    return (
      <>
        <h2>Ihr BMI liegt im Bereich des Übergewichts</h2>
        <p>
          Gemäss Ihren Angaben liegt Ihr BMI derzeit über 40. Das bedeutet nach der Klassifizierung der
          Weltgesundheitsorganisation (WHO) Adipositas Grad 3. Ab diesem BMI ist es wichtig, sich im Abnehmprozess
          begleiten zu lassen und eine geeignete Therapie zu finden. eBalance sollte in diesem Fall nur als begleitende
          Massnahme genutzt werden.
        </p>
        <p>
          Haben Sie bereits mit Ihrem Arzt oder Ihrer Ärztin diesbezüglich Kontakt aufgenommen? Diese haben auch die
          Möglichkeit, Verordnungen für Ernährungstherapien usw. auszustellen. Gern bieten wir auch an, für Ihren Arzt
          oder die zuständige Ernährungsberatung Auszüge aus Ihrem Profil bereitzustellen, falls dies gewünscht ist.
          Diese Auszüge umfassen beispielsweise das Ernährungstagebuch oder auch den Gewichtsverlauf. Wenden Sie sich
          bei Bedarf jederzeit an unseren Support unter <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>.
        </p>
      </>
    )
  }

  return (
    <>
      <h2>Sie benötigen keine Gewichtsabnahme</h2>
      <p>
        Gemäss Ihren Angaben befindet sich Ihr BMI im unteren Bereich des Normalgewichts. eBalance richtet sich
        vorwiegend an übergewichtige Erwachsene bzw. auch an Personen, die im gesunden Rahmen Ihr Wohlfühlgewicht
        erreichen möchten. Eine Abnahme würde in Ihrem Fall nicht zur Förderung der Gesundheit beitragen. Daher haben
        wir Ihnen das Ziel “Gewicht halten” zugewiesen. Neben dem Gewicht gibt es weitere Faktoren, um sich wohler zu
        fühlen. Profitieren Sie hierfür von der Möglichkeit, Ihr Essverhalten unter die Lupe zu nehmen, wie auch von
        unseren Magazinartikeln und der Beratung. Wenden Sie sich bitte, bei weiteren Fragen an den Support unter{' '}
        <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>, dort können Sie auch eine Ernährungsberatung anfragen.
      </p>
    </>
  )
}

const WeightWarningModal: React.FC<{
  isOpen: boolean
  closeModal: () => void
}> = ({ isOpen, closeModal, ...props }) => {
  return (
    <Modal style={modalZIndexPriority} isOpen={isOpen} onRequestClose={closeModal} ariaHideApp={false}>
      <div className="modal is-small">
        <IconButton name={IconName.close} color={IconColor.GRAY_1} onClick={closeModal} />
        <WeightWarningContent {...props} />

        <div className="row col-12 mt-1 justify-content-center">
          <div className="col-6">
            <Button onClick={closeModal} theme="small">
              Weiter
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WeightWarningModal
