import { ReduxNullableArrayState } from '../types'

import { nullableStateToArray } from './nullableStateToArray'

import { Identifiable } from 'Models'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const findItemAndIndexForUpdateActionMetaData = <E extends Identifiable>(
  state: ReduxNullableArrayState<E>,
  itemId: string
) => {
  const items = nullableStateToArray(state)
  const item = items.find((f) => f.id === itemId)
  if (!item) return
  return {
    data: { id: itemId },
    itemBefore: item,
    indexBefore: items.indexOf(item)
  }
}
