import React, { FC } from 'react'

export type StepIndicatorProps = {
  /**
   * The number of the current step. Not the index, so start counting from 1.
   */
  currentStep: number

  /**
   * If known, the total number of steps.
   * When undefined, the StepIndicator renders a `…` item at the end to indicate more steps may follow.
   */
  totalNumberOfSteps?: number

  /**
   * In case the total number of step is not known yet, but knowing a minimum number of steps already.
   * Based on this number known upcoming step will be rendered before the final `…` item.
   *
   * Has no effect, if the totalNumberOfSteps is set.
   */
  knownNumberOfSteps?: number
}

export const StepIndicator: FC<StepIndicatorProps> = ({ currentStep, totalNumberOfSteps, knownNumberOfSteps }) => {
  const steps = []

  const getStepsCount = (): number => {
    if (totalNumberOfSteps !== undefined) return totalNumberOfSteps
    if (knownNumberOfSteps === undefined) return currentStep + 1
    return knownNumberOfSteps + 1
  }

  const stepsCount = getStepsCount()

  const getStepLabel = (i: number): string | number => {
    if (totalNumberOfSteps === undefined) {
      const lastKnownStep = knownNumberOfSteps ?? currentStep
      if (i > lastKnownStep) {
        return '…'
      }
    }
    return i
  }

  for (let i = 1; i <= stepsCount; i++) {
    steps.push(
      <div
        key={i}
        data-testid="stepindicator"
        className={`${i <= currentStep ? 'is-visited' : ''} ${i === currentStep ? 'is-current' : ''}`}
      >
        {getStepLabel(i)}
      </div>
    )
  }

  return (
    <div
      className="stepindicator2 mb-3"
      role="progressbar"
      aria-valuemin={1}
      aria-valuemax={totalNumberOfSteps}
      aria-valuenow={currentStep}
    >
      {steps}
    </div>
  )
}
