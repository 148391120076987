import React, { useCallback, useState } from 'react'

import { FormInputNumber } from '../FormInputNumber'
import { FormInputUnitpicker } from '../FormInputUnitpicker'

import { Amount, UnitId } from 'Models'
import { Calculator } from 'Utils'

const MAX_DECIMAL_PLACES = 2

export type FormInputFoodAmountProps = {
  label?: string
  amount: Amount
  availableUnits?: UnitId[]
  decimalSeparator?: string
  onAmountChange?: (amount: Amount) => void
  onChangeDelay?: number
}

export const FormInputFoodAmount: React.FC<FormInputFoodAmountProps> = ({
  label,
  amount,
  availableUnits = ['GRAM', 'PORTION', 'MILLILITER'],
  decimalSeparator,
  onAmountChange,
  onChangeDelay = 1000
}: FormInputFoodAmountProps) => {
  const [quantityUndefined, setQuantityUndefined] = useState(() => amount.quantity === undefined)

  const handleNumberInputChange = useCallback(
    (quantity?: number) => {
      if (!quantity) {
        return
      }
      const newQuantityUndefined = quantity === undefined

      if (quantityUndefined !== newQuantityUndefined) {
        setQuantityUndefined(newQuantityUndefined)
      }

      onAmountChange &&
        onAmountChange({ ...amount, quantity: newQuantityUndefined ? 0 : quantity === undefined ? 0 : quantity })
    },
    [onAmountChange, amount]
  )

  const handleUnitChange = useCallback(
    (unit: UnitId) => {
      onAmountChange &&
        onAmountChange({
          quantity: Calculator.round(quantityUndefined ? 0 : amount.quantity, MAX_DECIMAL_PLACES),
          unit
        })
    },
    [onAmountChange, amount]
  )

  return (
    <div className="foodamount">
      <FormInputNumber
        label={label}
        value={quantityUndefined ? undefined : amount.quantity}
        maxFractions={MAX_DECIMAL_PLACES}
        decimalSeparator={decimalSeparator}
        onChange={handleNumberInputChange}
        onChangeDelay={onChangeDelay}
      />
      <FormInputUnitpicker items={availableUnits} selectedKey={amount.unit} onChange={handleUnitChange} />
    </div>
  )
}
