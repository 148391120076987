/*
  Please note:

  The routes config is also processed from dockerfiles/web/buildNginxLocations.ts.
  Thus making changes to the data structure of `routes` may require an update to it as well.

  Run `yarn build-nginx-locations` to verify it is still working.

*/
export type RoutePathTemplateFunction = (id?: string) => string

const highlightId = ':highlightId?'

export const routePlaceholders = {
  foodId: ':foodId'
}

/**
 * Generates the path to view a recipe provided by it or the equivalent template pathname when omitting the id parameter.
 */
const myRecipeView: RoutePathTemplateFunction = (id: string = highlightId) => '/mein-essen/eigenes-rezept-ansehen/' + id

/**
 * Generates the path to edit a food provided by it or the equivalent template pathname when omitting the id parameter.
 */
const myRecipeEdit: RoutePathTemplateFunction = (id = ':recipeId') => '/mein-essen/eigenes-rezept-bearbeiten/' + id

/**
 * Generates the path to view a food provided by it or the equivalent template pathname when omitting the id parameter.
 */
const myFoodView: RoutePathTemplateFunction = (id: string = highlightId) => '/mein-essen/' + id

/**
 * Generates the path to edit a food provided by it or the equivalent template pathname when omitting the id parameter.
 */
const myFoodEdit: RoutePathTemplateFunction = (id = ':foodId') => '/mein-essen/eigenes-nahrungsmittel-bearbeiten/' + id

export const routes = {
  paymentRedirect: '/bezahlvorgang',
  journal: '/tagebuch',
  contact: '/kontakt',
  help: '/hilfe',
  recruit: '/mitglieder-werben-mitglieder',
  tour: '/tour',
  notFound: 'not-found',
  error: '/error',
  imageUpload: '/mein-profilbild',

  register: {
    plan: '/registrierung/mitgliedschaft',
    anamnesis: '/registrierung/anamnese',
    success: '/registrierung/mitgliedschaft/vielen-dank'
  },

  myEatables: {
    index: '/mein-essen',
    myFoodView,
    myFoodCreate: '/eigenes-nahrungsmittel-erstellen',
    myFoodEdit,
    myRecipeCreate: '/eigenes-rezept-erstellen',
    myRecipeEdit,
    myRecipeView,
    favoritesOverview: '/favoriten'
  },

  analytics: {
    nutritions: '/naehrwerte',
    mass: '/masse'
  },

  profile: {
    profile: '/meine-kundendaten',
    restartKeepWeight: '/mein-programm/neustart/gewicht-halten',
    restartLoseWeight: '/mein-programm/neustart/gewicht-reduzieren',
    baseData: '/mein-programm/basisdaten',
    menuPlan: '/mein-programm/menuplan',
    program: '/mein-programm'
  },

  internals: {
    devPage: '/dev'
  },

  inspiration: {
    recipes: '/rezept-inspiration'
  }
}
