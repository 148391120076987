import React, { useCallback } from 'react'

import { IconButton } from '../IconButton'

import { Icon, IconColor, IconName } from 'Components/Icon'

interface Props {
  isFavorite: boolean
  label?: string
  onChange?: (value: boolean) => void
}

export const FavoriteIcon: React.FC<Props> = ({ isFavorite, label, onChange }: Props) => {
  const handleClickFavorite = useCallback(() => {
    onChange && onChange(!isFavorite)
  }, [isFavorite, onChange])

  if (isFavorite && !onChange) {
    return <Icon name={IconName.heartfilled} color={IconColor.GREEN} />
  }

  if (!onChange) {
    return null
  }

  return (
    <IconButton
      name={IconName.heartfilled}
      color={isFavorite ? IconColor.GREEN : IconColor.WHITE}
      onClick={handleClickFavorite}
      label={label}
    />
  )
}
