import _ from 'lodash'
import { combineReducers } from 'redux'
import { FluxStandardAction } from 'redux-promise-middleware'

import {
  EatableSourcedChangeSet,
  UpdateUserEatableActionMetaData,
  createUserEatablesReduxItems,
  getSourceIdFromEatableUpdate
} from './userEatables'

import {
  CoreBackendRecipePatchData,
  Food,
  IngredientReadData,
  IngredientWriteData,
  PromiseAction,
  ReduxRecipe,
  ReduxRecipeAddData,
  ReduxStoreState,
  ThunkAction,
  UserRecipesReduxState
} from 'Models'
import { createPendingCounterReducer } from 'ReduxStore/common'
import { coreBackendService } from 'Services'
import { Utils } from 'Utils'

// Types

export type UserRecipesState = ReduxRecipe[] | null
export type RecipeChangeSet = EatableSourcedChangeSet & Omit<CoreBackendRecipePatchData, 'id'>
export type UpdateUserRecipeActionMetaData = UpdateUserEatableActionMetaData<ReduxRecipe, RecipeChangeSet>

// Helpers

export const initialState: UserRecipesState = null

export const mapIngredientsToWriteData = (ingredients: IngredientReadData[]): IngredientWriteData[] =>
  ingredients.map(({ food, ...ingredient }) => ({ foodId: food.id, ...ingredient }))

export const mapIngredientsToReadData = (
  ingredients: IngredientWriteData[],
  foods: Food[] = []
): IngredientReadData[] => {
  const foodsById: Record<string, Food> = _.keyBy(foods, (f) => f.id)
  return ingredients.map((ingredient) => {
    const { foodId, ...rest } = ingredient
    return {
      ...rest,
      food: {
        id: foodId,
        name: foodsById?.[foodId]?.name ?? `unknown food #${foodId}`
      } as Food
    }
  })
}
const buildUpdatedRecipe = (recipe: ReduxRecipe, changeSet: RecipeChangeSet): ReduxRecipe => {
  const sourceId = getSourceIdFromEatableUpdate(recipe, changeSet)

  return {
    ...recipe,
    ...changeSet,
    sourceId,
    ingredients: changeSet.ingredients
      ? mapIngredientsToReadData(
          changeSet.ingredients,
          recipe.ingredients.map((ingredient) => ingredient.food)
        )
      : recipe.ingredients
  }
}

const {
  actionTypes,
  fetchListAction,
  fetchItemAction,
  addAction,
  hideAction,
  updateAction,
  reducer: eatablesBaseReducer,
  didLoadOnceSelector,
  itemsSelector
} = createUserEatablesReduxItems<ReduxRecipe, ReduxRecipeAddData, RecipeChangeSet>(
  'RECIPE',
  initialState,
  buildUpdatedRecipe
)

// Actions

export const fetchUserRecipeAction = (id: string): PromiseAction<ReduxRecipe> =>
  fetchItemAction(id, (id) => coreBackendService.fetchRecipe(id))

export const fetchUserRecipesAction = (): PromiseAction<ReduxRecipe[]> =>
  fetchListAction(() => coreBackendService.fetchRecipes())

export const addUserRecipeAction = (recipeData: ReduxRecipeAddData): ThunkAction =>
  addAction(recipeData, (postData) => coreBackendService.addRecipe(postData))

export const hideUserRecipeAction = (id: string): ThunkAction =>
  hideAction(
    id,
    (state) => state.userRecipes.data,
    (id) => coreBackendService.updateRecipeVisibility(id, true),
    'Rezept gelöscht.'
  )

export const updateUserRecipe = (id: string, changeSet: RecipeChangeSet): ThunkAction => {
  return updateAction<ReduxRecipe>(
    id,
    changeSet,
    (state) => state.userRecipes,
    async (data) => {
      await coreBackendService.updateRecipe(data)
      if (data.ingredients?.length) {
        /*
         When updating a recipe's ingredients,
         it includes new ingredients as IngredientWriteData[], which means they only come with `foodId`.
         Therefore the recipe needs to be loaded again to the get the full ingredients as IngredientWriteData[]
         featuring rich food objects.
         
         Futhermore, please note that this entire function under the async-await-hood results in as a single promise.
         Thus, from redux point of view both CoreBackned calls are combined in to a single oparation or ThunkAction. 
        */
        return await coreBackendService.fetchRecipe(id)
      }
    },
    (changeSet, reduxRecipe) => {
      /*
        Custom Filter to address the following:
        In redux recipes are stored with ingredients as IngredientReadData, 
        which means in ingredients comes with a full food object. 
        When updating recipes on that other hand, changeSet uses IngredientWriteData, 
        which only come with a `foodId`. 
        Therefore the ingredients if of present redux recipe need to be mapped to IngredientWriteData
        in order to filter the changeSet properly.  
      */
      const { ingredients, ...recipeData } = reduxRecipe
      const reduxRecipeWithIngredientsAsWriteData = {
        ...recipeData,
        ingredients: mapIngredientsToWriteData(ingredients)
      }
      changeSet = Utils.filterObjectChanges(changeSet, reduxRecipeWithIngredientsAsWriteData, {
        filterArrayValues: false,
        filterNestedObjects: false
      })
      return changeSet
    }
  )
}

// Reducer & initialState

export const userRecipesReducer = (
  state: UserRecipesState = initialState,
  action: FluxStandardAction
): UserRecipesState => {
  const { type } = action
  switch (type) {
    default:
      return eatablesBaseReducer(state, action)
  }
}

// Selectors

export const didLoadUserRecipesSelector = ({ userRecipes }: ReduxStoreState): boolean =>
  didLoadOnceSelector(userRecipes.data)

export const userRecipesSelector = ({ userRecipes }: ReduxStoreState): ReduxRecipe[] => itemsSelector(userRecipes.data)

// Wrapping up

const asyncActionTypes = [...Object.values(actionTypes)]

export default combineReducers<UserRecipesReduxState>({
  data: userRecipesReducer,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
