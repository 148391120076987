import { AnamnesisStepIds, ExperienceValues } from '../../types'

import { StepConfig } from 'Components/FormWizard'
import { anamnesisSchema } from 'yup-schema/Schemas/anamnesis'

// @ts-ignore
const validationSchema = anamnesisSchema.pick(['previousFailureCount', 'parentsOverweight', 'overweightForTwoYears'])

export const experienceConfig: StepConfig<ExperienceValues> = {
  id: AnamnesisStepIds.experience,
  scrollTop: true,
  validationSchema
}
