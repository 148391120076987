import Keycloak, { KeycloakInitOptions, KeycloakPromise } from 'keycloak-js'

import { default as appConfig } from './config'

export const keycloak = Keycloak(appConfig.keycloak)

const initConfig: KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
}

export const onLogin = (): KeycloakPromise<void, void> => keycloak.login()
export const onLogout = (): KeycloakPromise<void, void> => keycloak.logout()
export const onRegister = (): KeycloakPromise<void, void> => keycloak.register()

export const keycloakInitConfig = process.env.NODE_ENV === 'production' ? initConfig : {}
