import React, { FC } from 'react'

import { PageLayout } from 'Components'

const ContactPage: FC = () => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container max-500 col-12">
        <article>
          <h3>Sie möchten uns Feedback geben?</h3>
          <p>
            Auf der linken Seite des Bildschirms finden Sie einen Feedback-Tab. Klicken Sie einfach darauf und schreiben
            Sie uns! Sie können auch Bereiche des Bildschirms markieren und gezielt dazu Feedback geben.
          </p>
          <p>
            Wir freuen uns sehr über Ihre Rückmeldung, da wir eBalance noch weiter verbessern und nach Ihren Wünschen
            gestalten möchten.
          </p>
          <h3>Hilfe per Mail</h3>
          <p>
            Sie haben konkrete Fragen oder ein Problem? Schreiben Sie eine E-Mail an&nbsp;
            <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>. Wir sind gern für Sie da.
          </p>
          <h3>Post</h3>
          <p>
            eBalance-Help-Center
            <br />
            Ernst-Nobs-Platz 1
            <br />
            8004 Zürich
          </p>
          <p>
            Besten Dank
            <br />
            Ihr eBalance Team
          </p>
        </article>
      </div>
    </PageLayout>
  )
}

export default ContactPage
