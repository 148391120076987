import React from 'react'

import { ResponsiveCardBase } from '../ResponsiveCardBase/ResponsiveCardBase'

import { CardIconButton } from 'Components/IconButton'
import { Formatter } from 'Utils'

export interface EnergyMotionRecord {
  description: string
  energy: number
  duration: number
}

type Props = {
  energyMotionRecord: EnergyMotionRecord
  iconButtons: CardIconButton[]
  cardButtons: JSX.Element[]
}

export const JournalCardEnergyMotion: React.FC<Props> = ({
  energyMotionRecord: { description, energy, duration },
  ...responsiveCardBaseProps
}: Props) => {
  return (
    <ResponsiveCardBase
      title={description}
      details={Formatter.formatEnergy(energy) + ' / ' + Formatter.formatDuration(duration)}
      className="is-motion"
      {...responsiveCardBaseProps}
    />
  )
}
