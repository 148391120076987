import React, { useCallback, useRef } from 'react'
import { LocaleUtils } from 'react-day-picker'

import { today } from 'Config'

interface Props {
  date: Date
  localeUtils: LocaleUtils
  onChange?: (value: Date) => void
}

export const fromMonth = new Date(1900, 0)
export const toMonth = new Date(today.getFullYear() + 2, 11, 31)

const yearsList: number[] = []
for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
  yearsList.push(i)
}

export const YearMonthForm: React.FC<Props> = ({ date, localeUtils, onChange }: Props) => {
  const months = localeUtils.getMonths()

  const selectMonthRef = useRef<HTMLSelectElement>(null)
  const selectYearRef = useRef<HTMLSelectElement>(null)

  const handleChange = useCallback(() => {
    const year = selectYearRef && parseInt(`${selectYearRef.current?.value}`)
    const month = selectMonthRef && parseInt(`${selectMonthRef.current?.value}`)
    onChange && onChange(new Date(year, month))
  }, [onChange])

  return (
    <div className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()} ref={selectMonthRef}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()} ref={selectYearRef}>
        {yearsList.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  )
}
