import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { ImageUrl } from 'Models'
import { imageService } from 'Services'

export type ImageUploadingReturnType = {
  isImageUploading: boolean
  imageId?: number
  tempImageId?: number
  setImageId: Dispatch<SetStateAction<number | undefined>>
  setTempImageId: Dispatch<SetStateAction<number | undefined>>
  setIsImageUploading: Dispatch<SetStateAction<boolean>>
  deleteOldImage: () => Promise<void>
  onImageSubmit: (newImageUrl: ImageUrl) => Promise<void>
  onPageExit: () => void
}

export type ImageUploadingFormProps = Pick<
  ImageUploadingReturnType,
  'setTempImageId' | 'setIsImageUploading' | 'isImageUploading'
>

type Props = {
  imageUrl?: ImageUrl
}

export const useImageUploading = ({ imageUrl }: Props): ImageUploadingReturnType => {
  const [imageId, setImageId] = useState<number>()
  const [tempImageId, setTempImageId] = useState<number>()
  const [isImageUploading, setIsImageUploading] = useState(false)
  const uploadedImage = useRef<number>()

  const deleteOldImage = async (): Promise<void> => {
    if (!imageId) return
    await imageService.deleteImage(imageId)
    setImageId(undefined)
  }

  const onPageExit = async (): Promise<void> => {
    uploadedImage.current && (await imageService.deleteImage(uploadedImage.current))
  }

  const onImageSubmit = async (newImageUrl: ImageUrl): Promise<void> => {
    const userHasNewImage = !!uploadedImage.current
    const userHasNoImage = !newImageUrl
    if (userHasNewImage || userHasNoImage) {
      await deleteOldImage()
    }
    // after saving the new uploaded image becomes the current image
    // and we clean the slot for uploading another new image
    setImageId(uploadedImage.current)
    setTempImageId(undefined)
  }

  const fetchCurrentImageId = async (): Promise<void> => {
    if (!imageUrl) return
    const imageId = (await imageService.fetchImageIdByUrl(imageUrl)) || undefined
    setImageId(imageId)
  }

  useEffect(() => {
    uploadedImage.current = tempImageId
  }, [tempImageId])

  useEffect(() => {
    fetchCurrentImageId()
  }, [imageUrl])

  return {
    isImageUploading,
    imageId,
    tempImageId,
    setIsImageUploading,
    setImageId,
    setTempImageId,
    deleteOldImage,
    onImageSubmit,
    onPageExit
  }
}
