import { Anamnesis, ByIdMap } from '../../Models'

import { AnamnesisFormValuesByStepId, AnamnesisStepIds, ProgramTypes } from './types'

export const defaultValuesFirstAnamnesis: Omit<AnamnesisFormValuesByStepId, AnamnesisStepIds.programType> = {
  profile: {
    weightToLose: 5,
    shouldDisplayWeightGainWarning: true,
    weightGainThreshold: 3
  },
  activity: {
    workingHours: 20
  },
  experience: {
    previousFailureCount: 0,
    parentsOverweight: false,
    overweightForTwoYears: false
  },
  personalMenuPlan: {
    longTermMotivated: false,
    isMenuPlanEnabled: true
  },
  energyPlan: {
    energyLevelName: 'MEDIUM'
  }
}

export const anamnesisRestartLoseWeightDefaultValues = (
  anamnesis: Anamnesis,
  { currentBellyCircumference, currentWeight, height }: ByIdMap<number>
): AnamnesisFormValuesByStepId => {
  return {
    programType: { activeProgramType: ProgramTypes.LOSE_WEIGHT },
    profile: {
      bellyCircumference: currentBellyCircumference || undefined,
      birthday: new Date(anamnesis.birthday),
      currentWeight,
      height,
      weightToLose: anamnesis.weightToLose === 0 ? 5 : anamnesis.weightToLose,
      weightGainThreshold: undefined,
      shouldDisplayWeightGainWarning: false
    },
    ...anamnesisRestartGeneralDefaultValues(anamnesis)
  }
}

export const anamnesisRestartKeepWeightDefaultValues = (
  anamnesis: Anamnesis,
  { currentBellyCircumference, currentWeight, height }: ByIdMap<number>
): AnamnesisFormValuesByStepId => {
  return {
    programType: { activeProgramType: ProgramTypes.KEEP_WEIGHT },
    profile: {
      bellyCircumference: currentBellyCircumference || undefined,
      birthday: new Date(anamnesis.birthday),
      currentWeight,
      height,
      weightToLose: 0,
      weightGainThreshold: anamnesis.weightGainThreshold ?? 3,
      shouldDisplayWeightGainWarning:
        anamnesis.activeProgramType === ProgramTypes.LOSE_WEIGHT ? true : !!anamnesis.shouldDisplayWeightGainWarning
    },
    ...anamnesisRestartGeneralDefaultValues(anamnesis)
  }
}

export const anamnesisRestartGeneralDefaultValues = (
  anamnesis: Anamnesis
): Pick<
  AnamnesisFormValuesByStepId,
  | AnamnesisStepIds.activity
  | AnamnesisStepIds.experience
  | AnamnesisStepIds.personalMenuPlan
  | AnamnesisStepIds.energyPlan
> => {
  return {
    activity: {
      professionalMotionLevel: anamnesis.professionalMotionLevel,
      workingHours: anamnesis.workingHours
    },
    experience: {
      parentsOverweight: anamnesis.parentsOverweight,
      previousFailureCount: anamnesis.previousFailureCount,
      overweightForTwoYears: anamnesis.overweightForTwoYears ?? false
    },
    personalMenuPlan: {
      diet: anamnesis.diet,
      longTermMotivated: anamnesis.longTermMotivated,
      isMenuPlanEnabled: anamnesis.isMenuPlanEnabled
    },
    energyPlan: {
      energyLevelName: anamnesis.energyLevelName
    }
  }
}
