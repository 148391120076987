import { useKeycloak } from '@react-keycloak/web'
import React, { FC, ReactNode } from 'react'

import { PageLayout, Table } from 'Components'
import { CoreBackendUserReduxType, userRoleTypes } from 'Models'

type Props = {
  user: CoreBackendUserReduxType
}

const KeycloakDebug: FC<Props> = ({ user }: Props) => {
  const { keycloak } = useKeycloak()

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <ul>
                <li>{`User is ${!keycloak.authenticated ? 'NOT ' : ''}authenticated`}</li>
                <li>
                  user id: <code>{keycloak.subject}</code>
                </li>
              </ul>
            </div>
            <div className="col-3">
              <button
                className="btn btn-sm"
                style={{ width: '100px' }}
                type="button"
                onClick={() => (keycloak.authenticated ? keycloak.logout() : keycloak.login())}
              >
                {keycloak.authenticated ? 'Logout' : 'Login'}
              </button>
            </div>
            <div className="col-3">
              <button
                className="btn btn-sm"
                style={{ width: '180px' }}
                type="button"
                onClick={() => keycloak.updateToken(-1)}
              >
                Refresh Token
              </button>
            </div>
          </div>
        </div>
        <div className="col-3">
          <Table
            titleRow={['Role', 'hasRole']}
            rows={userRoleTypes.map((role) => [role, keycloak.hasRealmRole(role) ? 'yes' : 'no'])}
          />
        </div>
        <div className="col-3">
          <Table titleRow={['keycloak.realmRoles']} rows={(keycloak.realmAccess?.roles ?? []).map((role) => [role])} />
        </div>
      </div>

      <h3 className="my-4">profile</h3>
      <pre style={{ padding: '5px', border: '1px solid #ccc' }}>{JSON.stringify(keycloak.profile, null, 2)}</pre>

      <h3 className="my-4">Access-Token raw</h3>
      <pre style={{ padding: '5px', border: '1px solid #ccc' }}>
        <code>{keycloak.token}</code>
      </pre>

      <h3 className="my-4">Access-Token parsed</h3>
      <pre style={{ padding: '5px', border: '1px solid #ccc' }}>
        <code>{JSON.stringify(keycloak.tokenParsed, null, 2)}</code>
      </pre>

      <h3 className="my-4">ID-Token parsed</h3>
      <pre style={{ padding: '5px', border: '1px solid #ccc' }}>
        <code>{JSON.stringify(keycloak.idTokenParsed, null, 2)}</code>
      </pre>

      <h3 className="my-4">user</h3>
      <pre style={{ padding: '5px', border: '1px solid #ccc' }}>
        <code>{JSON.stringify(user, null, 2)}</code>
      </pre>
    </>
  )
}

const DevPage: FC<Props> = ({ user }: Props) => {
  const renderContent = (): ReactNode => {
    return <KeycloakDebug user={user} />
  }

  return (
    <PageLayout theme="smallcircle">
      <div className="container">{renderContent()}</div>
    </PageLayout>
  )
}

export default DevPage
