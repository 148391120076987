import { combineReducers } from 'redux'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'

import { FETCH_BODY_ANALYTICS, FETCH_BODY_ANALYTICS_FULFILLED, FETCH_BODY_ANALYTICS_REJECTED } from './actionTypes'
import { createPendingCounterReducer } from './common'

import { BodyAnalytics, BodyAnalyticsReduxState, BodyAnalyticsSection, ReduxStoreState } from 'Models'
import { anamnesisService } from 'Services'

// Actions
export const fetchBodyAnalyticsAction = ({
  anamnesisId,
  currentWeight,
  currentGoalEnergy,
  currentBellyCircumference,
  currentHeight,
  currentActiveMetabolicRate
}: BodyAnalytics): AsyncAction => ({
  type: FETCH_BODY_ANALYTICS,
  payload: anamnesisService.fetchBodyAnalytics({
    anamnesisId,
    currentWeight,
    currentGoalEnergy,
    ...(currentBellyCircumference ? { currentBellyCircumference } : {}),
    currentHeight,
    currentActiveMetabolicRate
  })
})

// Reducer
const data = (
  state: BodyAnalyticsSection[] | null = null,
  { type, payload }: FluxStandardAction
): BodyAnalyticsSection[] | null => {
  switch (type) {
    case FETCH_BODY_ANALYTICS_REJECTED:
      return null
    case FETCH_BODY_ANALYTICS_FULFILLED:
      return payload
    default:
      return state
  }
}

// Selectors
export const bodyAnalyticsSelector = (state: ReduxStoreState): BodyAnalyticsSection[] | null => state.bodyAnalytics.data

export const isbodyAnalyticsCombinedLoadingSelector = ({ bodyAnalytics, bodyMeasurements }: ReduxStoreState): boolean =>
  bodyAnalytics.pendingCounter + bodyMeasurements.pendingCounter > 0

export default combineReducers<BodyAnalyticsReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(FETCH_BODY_ANALYTICS)
})
