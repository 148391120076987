import { AnyAction, combineReducers } from 'redux'

import { FETCH_RECIPE, FETCH_RECIPE_FULFILLED } from './actionTypes'
import { createPendingCounterReducer } from './common'

import { PromiseAction, Recipe, ReduxStoreState, ResourceReduxState } from 'Models'
import { coreBackendService } from 'Services'

export const fetchRecipeAction = (id: string): PromiseAction<Recipe> => {
  return {
    type: FETCH_RECIPE,
    payload: coreBackendService.fetchRecipe(id)
  }
}

const data = (state: Recipe[] = [], { type, payload }: AnyAction): Recipe[] => {
  switch (type) {
    case FETCH_RECIPE_FULFILLED:
      return [...state, payload]
    default:
      return state
  }
}

export const recipesSelector = ({ recipes }: ReduxStoreState): Recipe[] => recipes.data

export default combineReducers<ResourceReduxState<Recipe[]>>({
  data,
  pendingCounter: createPendingCounterReducer(...FETCH_RECIPE_FULFILLED)
})
