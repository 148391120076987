import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, PageLayout } from 'Components'
import { KeyCloakUser, Plan, PlanPeriod } from 'Models'
import { useAppSelector } from 'ReduxStore/hooks'
import { isActiveCustomerSelector, keyCloakUserSelector } from 'ReduxStore/keyCloakUser'
import { memberShipService } from 'Services'

const configureSelectedPlanUrl = (
  planUrl: string | undefined,
  { id, firstName, lastName, email }: KeyCloakUser
): string => {
  const params = new URLSearchParams({
    tag: id,
    fname: firstName,
    lname: lastName,
    email: email
  })

  return `${planUrl}?${params}`
}

const PlanRegistrationPage: FC = () => {
  const history = useHistory()
  const keycloakUser = useAppSelector(keyCloakUserSelector)
  const isActiveCustomer = useAppSelector(isActiveCustomerSelector)

  const [isPlanSubmitted, setisPlanSubmitted] = useState(false)
  const [plans, setPlans] = useState<undefined | Plan[]>()
  const [selectedPlan, setSelectedPlan] = useState<undefined | Plan>()

  useEffect(() => {
    let mounted = true

    const getActivePlans = async (): Promise<void> => {
      const activePlans = await memberShipService.fetchActivePlans()

      if (mounted) {
        setPlans(activePlans)
      }
    }

    getActivePlans()

    if (isActiveCustomer) {
      history.push('/')
    } else {
      getActivePlans()
    }

    return () => {
      mounted = false
    }
  }, [isActiveCustomer])

  return (
    <>
      {isPlanSubmitted ? (
        <PageLayout>
          <div className="row justify-content-center">
            <iframe
              src={configureSelectedPlanUrl(selectedPlan?.url, keycloakUser)}
              style={{ height: '75vh', width: '100vw', border: 0 }}
            ></iframe>
          </div>
        </PageLayout>
      ) : (
        <PageLayout theme="smallcircle">
          <div className="container max-700">
            <div className="row">
              <h1>Ihre Mitgliedschaft</h1>
              <div className="col-12">
                {plans?.map((plan) => {
                  return (
                    <label key={plan.id} className="panepicker">
                      <input
                        type="radio"
                        checked={plan.id === selectedPlan?.id}
                        onChange={() => setSelectedPlan(plan)}
                      />
                      <div className="panepicker-inner">
                        {plan.title}
                        <span className="panepicker-price">
                          {plan.currency}
                          <span className="panepicker-number">{plan.price.toFixed(2)}</span>/{' '}
                          {PlanPeriod[plan.periodUnit]}
                        </span>
                      </div>
                    </label>
                  )
                })}
              </div>
              <div className="col-12 col-lg-6 offset-lg-6">
                <Button type="button" onClick={() => setisPlanSubmitted(true)}>
                  Weiter
                </Button>
              </div>
            </div>
          </div>
        </PageLayout>
      )}
    </>
  )
}

export default PlanRegistrationPage
