import React, { ReactElement, useEffect, useMemo, useRef } from 'react'
import { UseFormMethods, useWatch } from 'react-hook-form'

import { AutocompleteItemType, FormInputAutocomplete, FormInputAutocompleteProps } from './FormInputAutocomplete'

import { useFocusOnFirstError } from 'Utils/helpers/useFocusOnFirstError'

type Props<ItemType> = Pick<UseFormMethods, 'register' | 'errors' | 'control' | 'setValue'> &
  FormInputAutocompleteProps<ItemType> & {
    name: string
    onChangeValueMapper?: (value: ItemType | null | undefined, isNew: boolean) => unknown
    sortItemsFunction?: (a: ItemType, b: ItemType) => number
  }

export const FormInputAutocompleteController = <ItemType extends AutocompleteItemType>({
  name,
  items,
  register,
  control,
  itemIdKey = 'id',
  onChangeValueMapper,
  sortItemsFunction,
  errors,
  setValue,

  ...props
}: Props<ItemType>): ReactElement | null => {
  const fieldRef = useRef<HTMLInputElement>(null)
  const errorText = useMemo(() => (errors[name] ? errors[name].message : ''), [errors[name]])

  useEffect(() => {
    register({ name })
  }, [])

  const value = useWatch({
    control,
    name,
    defaultValue: undefined
  })

  const selectedItemFitler: (item: ItemType) => boolean = onChangeValueMapper
    ? (item) => onChangeValueMapper(item, false) === value
    : (item) => item[itemIdKey] === value?.[itemIdKey]

  const selectedItem = items.find(selectedItemFitler)

  useFocusOnFirstError({ fieldRef, errors, name })

  if (Object.keys(items).length === 0 && !props.allowNewItem) {
    return null
  }

  return (
    <>
      <div className="col-12">
        <FormInputAutocomplete
          inputRef={fieldRef}
          items={items}
          itemIdKey={itemIdKey}
          selectedItemId={selectedItem?.[itemIdKey]}
          onChange={(value, isNew) =>
            setValue(name, onChangeValueMapper ? onChangeValueMapper(value, isNew) : value, { shouldValidate: true })
          }
          sortItemsFunction={sortItemsFunction}
          errorText={errorText}
          {...props}
        />
      </div>
    </>
  )
}
