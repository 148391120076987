import React from 'react'

type StepHeadingProps = {
  title: string
  infotext?: string
}
export const StepHeading: React.FC<StepHeadingProps> = ({ title, infotext }) => {
  return (
    <>
      {title && <h1>{title}</h1>}
      {infotext && <p className="my-3">{infotext}</p>}
    </>
  )
}
