import React, { FC } from 'react'

import { Icon, IconColor, IconName } from '../Icon'

interface Props {
  icon?: IconName
  text: string
}

export const InfoPlaceholder: FC<Props> = ({ icon = IconName.search, text }: Props) => {
  return (
    <div className="infoplaceholder mt-2">
      <Icon name={icon} color={IconColor.GRAY_2} />
      <h2>Noch nichts zu finden</h2>
      <p>{text}</p>
    </div>
  )
}
