import React, { FC } from 'react'
import { HighlightResult } from 'react-instantsearch-core'
import { connectSearchBox, connectStats } from 'react-instantsearch-dom'

import { SearchBar } from './SearchBar'

import { Food, Recipe } from 'Models'

type SearchBoxProps = {
  currentRefinement: string
  isSearchStalled: boolean
  refine: (text: string) => void
  onChange?: (text: string) => void
}

const SearchBox: FC<SearchBoxProps> = ({ refine, onChange }) => (
  <SearchBar
    onChangeText={(text) => {
      refine(text)
      onChange && onChange(text)
    }}
  />
)

type StatsProps = { nbHits: number }

export const Stats: FC<StatsProps> = ({ nbHits }) => <span>{nbHits} Einträge gefunden</span>

export const CustomStats = connectStats(Stats)

export const CustomSearchBox = connectSearchBox(SearchBox)

// type HighlightResult

export type UserEatable = Food | Recipe

export type UserEatableSearchHit<E extends UserEatable> = E & {
  _highlightResult: HighlightResult<E>
}
