import getDay from 'date-fns/getDay'

import {
  CategoryGroupedJournalEatableContainerMapping,
  CategoryJournalEatableContainerMapping,
  JournalEatableContainerObject,
  JournalEatableRecord,
  MealCategory,
  MenuPlanDays,
  QuantifiedEatable
} from 'Models'
import { Utils } from 'Utils'

const WeekDay = {
  0: 'SUNDAY',
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY'
}

const sortByPosition = <T extends { position: number }>(a: T, b: T): number => a.position - b.position

export const menuPlanContainersForDate = ({
  menuPlanDays,
  journalEatableRecordsForDate,
  date
}: {
  menuPlanDays: MenuPlanDays[]
  journalEatableRecordsForDate: JournalEatableRecord[]
  date: Date
}): CategoryGroupedJournalEatableContainerMapping => {
  const journalEatableContainers: CategoryGroupedJournalEatableContainerMapping = {
    BREAKFAST: [],
    LUNCH: [],
    DINNER: [],
    SNACK: []
  }

  const menuPlanMealCategories = menuPlanDays.find((menuPlan) => menuPlan.weekday === WeekDay[getDay(date)])
    ?.mealCategories

  if (menuPlanMealCategories) {
    for (const category of menuPlanMealCategories) {
      const sortedGroups = category.groups.sort(sortByPosition)
      for (const group of sortedGroups) {
        if (group.slots.length === 0) {
          break
        }

        const sortedSlots = group.slots.sort(sortByPosition)

        const categorySlots = sortedSlots.map((slot) => {
          const journalRecordMatch = journalEatableRecordsForDate.find((card) => {
            return Utils.menuPlanSlotIdFromJournalSource(card.source) === slot.id
          })
          const { food, recipe, quantity, unit } = journalRecordMatch || slot

          const eatableCardObjects: { eatable: QuantifiedEatable; menuPlanSlotId: string }[] = [
            {
              eatable: {
                food: food,
                foodId: food?.id,
                recipe: recipe,
                recipeId: recipe?.id,
                quantity: quantity,
                unit: unit
              },
              menuPlanSlotId: slot.id
            }
          ]

          const matchSourceId = journalRecordMatch
            ? Utils.menuPlanSlotIdFromJournalSource(journalRecordMatch.source)
            : undefined

          const eatableCardsActiveIndex = journalRecordMatch
            ? 0
            : Math.max(
                eatableCardObjects.findIndex(({ menuPlanSlotId }) => {
                  return menuPlanSlotId && menuPlanSlotId === matchSourceId
                }),
                0
              )

          return {
            menuPlanSlotId: slot.id,
            mealCategory: category.name,
            hint: slot.hint,
            eatableCardObjects,
            eatableCardsActiveIndex,
            ...(journalRecordMatch ? { journalRecordId: journalRecordMatch.id } : {})
          }
        })

        journalEatableContainers[category.name].push(categorySlots)
      }
    }
  }

  return journalEatableContainers
}

const createJournalEatableContainerObject = ({
  id,
  datetime,
  mealCategory,
  source,
  ...eatable
}: JournalEatableRecord): JournalEatableContainerObject => {
  return {
    journalRecordId: id,
    datetime,
    source,
    mealCategory: mealCategory as MealCategory,
    eatableCardObjects: [{ eatable }],
    eatableCardsActiveIndex: 0
  }
}

const distributeToCategories = (
  categories: CategoryJournalEatableContainerMapping,
  containerObject: JournalEatableContainerObject
): CategoryJournalEatableContainerMapping => {
  if (containerObject.mealCategory) {
    return {
      ...categories,
      [containerObject.mealCategory]: [...categories[containerObject.mealCategory], containerObject]
    }
  }
  return categories
}

export const journalEatableRecordContainersForDate = ({
  journalEatableRecordsForDate
}: {
  journalEatableRecordsForDate: JournalEatableRecord[]
}): CategoryJournalEatableContainerMapping => {
  const categories: CategoryJournalEatableContainerMapping = {
    BREAKFAST: [],
    LUNCH: [],
    DINNER: [],
    SNACK: []
  }

  const journalEatableRecordContainers = journalEatableRecordsForDate
    .map(createJournalEatableContainerObject)
    .reduce(distributeToCategories, categories)

  return journalEatableRecordContainers
}

export const getFlattenedMenuPlanContainers = ({
  menuPlanContainers
}: {
  menuPlanContainers: CategoryGroupedJournalEatableContainerMapping
}): JournalEatableContainerObject[] => {
  let category: MealCategory
  let flattenedContainers: JournalEatableContainerObject[] = []
  for (category in menuPlanContainers) {
    flattenedContainers = [
      ...flattenedContainers,
      ...menuPlanContainers[category].reduce((acc, curr) => [...acc, ...curr], [])
    ]
  }
  return flattenedContainers
}

export const getFlattenedJournalEatableRecordsContainers = ({
  journalEatableRecordContainers
}: {
  journalEatableRecordContainers: CategoryJournalEatableContainerMapping
}): JournalEatableContainerObject[] => {
  let category: MealCategory
  let flattenedContainers: JournalEatableContainerObject[] = []

  for (category in journalEatableRecordContainers) {
    flattenedContainers = [...flattenedContainers, ...journalEatableRecordContainers[category]]
  }
  return flattenedContainers
}
