import React from 'react'

import { GaugePointer } from './GaugePointer'

import { colors } from 'Config'
import { Utils } from 'Utils'

interface Props {
  initialWeight: number
  currentWeight: number
  targetWeight: number
  shouldDisplayWeightGainWarning?: boolean
}

export const GaugeGoal: React.FC<Props> = ({
  initialWeight,
  currentWeight,
  targetWeight,
  shouldDisplayWeightGainWarning = false
}: Props) => {
  let relativeChange = Math.max(0, Math.min((initialWeight - currentWeight) / (initialWeight - targetWeight), 1))

  // check for NaN because of possible 0/0 calculation above
  if (Number.isNaN(relativeChange)) {
    relativeChange = 1
  }

  const angle = 180 * relativeChange - 90
  const percent = parseInt((100 * relativeChange).toFixed())

  return (
    <div
      className={`
        gaugegoal
        ${percent > 40 ? 'is-over-40' : ''}
        ${percent > 60 ? 'is-over-60' : ''}
      `}
    >
      <svg viewBox="0 0 100 50">
        <defs>
          <linearGradient id="linear_gradient">
            <stop offset="0%" stopColor={colors.gaugeSvg.primaryColor} />
            <stop offset="100%" stopColor={colors.gaugeSvg.primaryColorDark} />
          </linearGradient>
        </defs>
        <path
          d={Utils.describeSvgArc(50, 50, 34, -90, 90)}
          strokeWidth="31"
          stroke="url(#linear_gradient)"
          fillOpacity="0"
        />
        <path
          d={Utils.describeSvgArc(50, 50, 34, angle, 90.1)}
          strokeWidth="31.2"
          stroke={colors.gaugeSvg.inactive}
          fillOpacity="0"
        />
        <GaugePointer rotationAngle={angle} />
      </svg>
      <div className="gaugegoal-values">
        <div>Start: {initialWeight} kg</div>
        <div>Ziel: {targetWeight} kg</div>
      </div>
      <div className="gaugegoal-current">{currentWeight} kg</div>
      <div className="gaugegoal-current-text">Mein aktuelles Gewicht</div>
      {shouldDisplayWeightGainWarning && (
        <div className="gaugegoal-current-text text-warning">Sie haben Ihre festgelegte Gewichtszunahme erreicht.</div>
      )}
      <div className="gaugegoal-percent">{percent}%</div>
    </div>
  )
}
