import { combineReducers } from 'redux'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'
import { createSelector } from 'reselect'

import {
  GET_ANAMNESIS,
  GET_ANAMNESIS_FULFILLED,
  POST_ANAMNESIS,
  POST_ANAMNESIS_FULFILLED,
  UPDATE_ANAMNESIS,
  UPDATE_ANAMNESIS_FULFILLED
} from './actionTypes'
import { createPendingCounterReducer } from './common'
import { hasActionFailedSelector } from './error'

import { Anamnesis, AnamnesisData, AnamnesisReduxState, ReduxStoreState } from 'Models'
import { anamnesisService } from 'Services'

// Actions
export const postAnamnesisAction = (data: AnamnesisData): AsyncAction => ({
  type: POST_ANAMNESIS,
  payload: anamnesisService.addAnamnesis(data)
})

export const updateAnamnesisAction = (payload: Anamnesis): AsyncAction => ({
  type: UPDATE_ANAMNESIS,
  payload: anamnesisService.updateAnamnesis(payload)
})

export const getAnamnesisAction = (id: string): AsyncAction => {
  return {
    type: GET_ANAMNESIS,
    payload: anamnesisService.fetchAnamnesisById(id)
  }
}

// Reducer
const data = (state: Anamnesis | null = null, { type, payload }: FluxStandardAction): Anamnesis | null => {
  switch (type) {
    case POST_ANAMNESIS_FULFILLED:
    case UPDATE_ANAMNESIS_FULFILLED:
    case GET_ANAMNESIS_FULFILLED:
      return payload
    default:
      return state
  }
}

// Selectors
export const getAnamnesisSelector = ({ anamnesis }: ReduxStoreState): AnamnesisReduxState => anamnesis

export const getAnamnesisDataSelector = createSelector([getAnamnesisSelector], ({ data }) => data)

export const isAnamnesisErrorSelector = (state: ReduxStoreState): boolean => hasActionFailedSelector(state, 'ANAMNESIS')

const asyncActionTypes = [POST_ANAMNESIS]

export default combineReducers<AnamnesisReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
