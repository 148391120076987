import React from 'react'

import { AnamnesisBirthdayQuestion } from './AnamnesisBirthdayQuestion'
import { AnamnesisCurrentWeightQuestion, AnamnesisHeightAndBellyQuestions } from './BodyMassQuestions'

import { RenderStepType } from 'Components'

export const FirstAnamnesisQuestions: React.FC<Omit<RenderStepType, 'setError' | 'isRestart'>> = ({
  register,
  setValue,
  errors,
  control,
  defaultValues
}) => {
  return (
    <>
      <AnamnesisBirthdayQuestion
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        defaultValues={defaultValues}
      />
      <AnamnesisCurrentWeightQuestion
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        defaultValues={defaultValues}
      />
      <AnamnesisHeightAndBellyQuestions
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        defaultValues={defaultValues}
      />
    </>
  )
}
