import { ChartType } from 'Models'
import { Calculator } from 'Utils'

export const calculateChartPointValue = (chartType: ChartType, value = 0, height = 0): number => {
  if (chartType === 'BMI') {
    return height ? Calculator.round(Calculator.calculateBMI(value, height)) : 0
  }

  return value
}

export const calculateChartValues = (
  chartType: ChartType,
  values?: [number, number][] | null,
  height = 0
): [number, number][] | null | undefined => {
  if (chartType === 'BMI') {
    return values ? values.map((data) => [data[0], calculateChartPointValue(chartType, data[1], height)]) : values
  }

  return values
}
