import React, { ReactNode } from 'react'

import ErrorBox from './ErrorBox'

interface State {
  error?: Error
}

export default class ErrorBoundary extends React.Component<unknown, State> {
  constructor(props: unknown) {
    super(props)
    this.state = { error: undefined }
  }

  static getDerivedStateFromError(error: Error): { error: Error } {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  // componentDidCatch(error: Error, info: any): void {
  // You can also log the error to an error reporting service
  // logErrorToMyService(error, info);
  // }

  render(): ReactNode {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <ErrorBox error={this.state.error} />
    }

    return this.props.children
  }
}
