import React, { FC } from 'react'

import { TextPicker } from 'Components'
import { ChartType, ChartTypesMap } from 'Models'

interface Props {
  onChange?: (chartType: ChartType) => void
  selected?: ChartType
}

const items = Object.keys(ChartTypesMap)

const ChartTypeSwitch: FC<Props> = ({ onChange, selected }: Props) => (
  <TextPicker
    items={items}
    selectedKey={selected}
    labelExtractor={(chartType) => ChartTypesMap[chartType as ChartType]}
    onChange={(selectedKey) => {
      onChange && onChange(selectedKey as ChartType)
    }}
  />
)

export default ChartTypeSwitch
