export type FoodCriteria = {
  specials: { [key: string]: string }
  normal: { [key: string]: string }
}

export const FOODCRITERIA: FoodCriteria = {
  specials: {
    DAIRY_GOODS: 'Milchprodukte',
    DIABETES_SUITABLE: 'für Diabetiker geeignet',
    DIABTES_CRITICAL: 'für Diabetiker kritisch',
    GLUTENFREE: 'glutenfrei',
    LACTOSEFREE: 'lactosefrei',
    NUTFREE: 'nussfrei'
  },
  normal: {
    BEEF: 'Rindfleisch',
    CALF: 'Kalbsfleisch',
    CONDIMENTS: 'Gewürze',
    FISH: 'Fisch',
    FRUIT: 'Früchte',
    FUNGI: 'Pilze',
    GLUTEN: 'enthält Gluten',
    HORSE: 'Pferdefleisch',
    LACTOSE: 'enthält Lactose',
    LAMB: 'Lammfleisch',
    LEGUMES: 'Hülsenfrüchte',
    NUTS: 'enthält Nüsse',
    PORK: 'Schweinefleisch',
    POULTRY: 'Geflügelfleisch',
    RABBIT: 'Kaninchenfleisch',
    SHELLFISH: 'Meeresfrüchte',
    VEGETABLE: 'Gemüse',
    VENISON: 'Wildfleisch'
  }
}
