import { SearchClient } from '@algolia/client-search'
import _ from 'lodash'
import React, { FC, useCallback, useState } from 'react'
import { Index, InstantSearch, connectInfiniteHits, connectSearchBox } from 'react-instantsearch-dom'

import { IngredientSearchBox } from './IngredientSearchBox'
import { IngredientSearchInfiniteHits } from './IngredientSearchInfiniteHits'

import { useUserEatablesSearch } from 'Components/Search/useUserEatablesSearch'
import { AlgoliaFood, Food } from 'Models'
import { AlgoliaIndexName, getIndexName } from 'Utils/algoliaSearchHelpers'

type AlgoliaSearchClient = SearchClient

export type IngredientSearchProps = {
  userFoods?: Food[]
  algoliaSearchClient: AlgoliaSearchClient
  selectedFoodIds?: string[]
  onFoodClick?: (food: AlgoliaFood) => void
  /** Only use this in IngredientSearch.test.js! */
  _skipDebounce?: boolean
}

const SearchBox = connectSearchBox(IngredientSearchBox)
const InfiniteHits = connectInfiniteHits(IngredientSearchInfiniteHits)

export const IngredientSearch: FC<IngredientSearchProps> = ({
  algoliaSearchClient,
  userFoods = [],
  selectedFoodIds = [],
  onFoodClick,
  _skipDebounce = false
}: IngredientSearchProps) => {
  const { searchResult: userFoodHits, setSearchQuery: setSearchQueryUserFoods } = useUserEatablesSearch(userFoods)
  const [hasFocus, setHasFocus] = useState(false)

  const handleOnFocus = (): void => {
    setHasFocus(true)
  }

  const setHasFocusFalse = (): void => setHasFocus(false)
  const handleOnBlur = useCallback(
    _skipDebounce
      ? /*
        Martin Stemmle: This is workaround.  For the time being I was not able properly mock debounce in IngredientSearch.test.tsx.
      */
        setHasFocusFalse
      : /*
      debounce to wait for the onHitClick event which otherwise might get lost
      due to the hits being no longer rendered after losing focus
     */
        _.debounce(setHasFocusFalse, 600),
    []
  )

  const handleOnHitClick = (hit: AlgoliaFood): void => {
    onFoodClick?.(hit)
    setHasFocus(false)
  }

  const indexName = getIndexName(AlgoliaIndexName.FOODS)

  const renderIndex = (): JSX.Element | undefined => {
    if (!hasFocus) return
    return (
      <Index indexName={indexName}>
        <InfiniteHits selectedFoodIds={selectedFoodIds} onHitClick={handleOnHitClick} userFoodHits={userFoodHits} />
      </Index>
    )
  }

  return (
    <InstantSearch indexName={indexName} searchClient={algoliaSearchClient}>
      <SearchBox onBlur={handleOnBlur} onFocus={handleOnFocus} onChange={setSearchQueryUserFoods} />
      {renderIndex()}
    </InstantSearch>
  )
}
