import _ from 'lodash'
import { combineReducers } from 'redux'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'

import { FETCH_ENERGY_REQUIREMENTS, FETCH_ENERGY_REQUIREMENTS_FULFILLED } from './actionTypes'
import { createPendingCounterReducer } from './common'

import { ReduxStoreState } from 'Models'
import {
  EnergyLevels,
  EnergyRequirementsPlan,
  EnergyRequirementsReduxState,
  EnergyRequirementsReduxType,
  FetchEnergyRequirementsPayload
} from 'Models/EnergyRequirements'
import { energyCalculatorService } from 'Services/energyCalculatorServiceInstance'

const sortEnergyPlans = (unsortedPlans: EnergyRequirementsPlan[]): EnergyRequirementsPlan[] => {
  const sortedEnergyPlans = []
  const sortedEnergylevels = Object.values(EnergyLevels).sort((firstLevel, secondLevel) => {
    if (firstLevel.position < secondLevel.position) {
      return -1
    } else if (firstLevel.position > secondLevel.position) {
      return 1
    }
    return 0
  })

  for (const sortedEnergyLevel of sortedEnergylevels) {
    const energyPlan = unsortedPlans.find((plan) => plan.level.name === sortedEnergyLevel.name)
    sortedEnergyPlans.push(energyPlan)
  }
  return _.compact(sortedEnergyPlans)
}

export const fetchEnergyRequirementsAction = (payload: FetchEnergyRequirementsPayload): AsyncAction => ({
  type: FETCH_ENERGY_REQUIREMENTS,
  payload: energyCalculatorService.getEnergyRequirements(payload)
})

const initialState: EnergyRequirementsReduxType = null
const data = (
  state: EnergyRequirementsReduxType = initialState,
  { type, payload }: FluxStandardAction
): EnergyRequirementsReduxType => {
  switch (type) {
    case FETCH_ENERGY_REQUIREMENTS_FULFILLED:
      return {
        ...payload,
        energyPlans: sortEnergyPlans(payload.energyPlans)
      }
    default:
      return state
  }
}

export const energyRequirementsSelector = ({ energyRequirements }: ReduxStoreState): EnergyRequirementsReduxType =>
  energyRequirements.data

const asyncActionTypes = [FETCH_ENERGY_REQUIREMENTS]

export default combineReducers<EnergyRequirementsReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
