import React from 'react'

import { StepHeading } from '../StepHeading'

import { AnamnesisCurrentWeightQuestion } from './BodyMassQuestions'
import { FirstAnamnesisQuestions } from './FirstAnamnesisQuestion'
import { AnamnesisKeepWeightQuestions, AnamnesisLoseWeightQuestion } from './ProgramTypeQuestions'

import { RenderStepType } from 'Components/FormWizard'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'

const ProfileStep: React.FC<RenderStepType> = ({
  register,
  errors,
  control,
  setValue,
  formValues,
  defaultValues = {},
  isRestart
}: RenderStepType) => {
  const hasLoseWeightProgram = formValues?.programType.activeProgramType === ProgramTypes.LOSE_WEIGHT

  return (
    <>
      <StepHeading
        title={`Ihr ${hasLoseWeightProgram ? 'Abnehm-' : 'eBalance-'}Profil`}
        infotext="Um zuallererst Ihr persönliches Kalorienziel sowie Ihren BMI ermitteln zu können, benötigen wir einige Angaben
        zu Ihrem Körper und Ihren Zielen."
      />

      <div className="row">
        <div className="col-12">
          <h4>Persönliches</h4>
        </div>
        {isRestart ? (
          <AnamnesisCurrentWeightQuestion
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            defaultValues={defaultValues}
          />
        ) : (
          <FirstAnamnesisQuestions
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            defaultValues={defaultValues}
          />
        )}
      </div>
      <div className="row">
        {hasLoseWeightProgram ? (
          <AnamnesisLoseWeightQuestion register={register} errors={errors} defaultValues={defaultValues} />
        ) : (
          <AnamnesisKeepWeightQuestions register={register} errors={errors} defaultValues={defaultValues} />
        )}
      </div>
    </>
  )
}

export default ProfileStep

export * from './config'
