import { ContentState, Editor, EditorState, convertFromHTML } from 'draft-js'
import React, { FC, useMemo } from 'react'

export type RichTextPanelProps = {
  text?: string | null
}

const RichTextPanel: FC<RichTextPanelProps> = ({ text }) => {
  const renderedRichText = useMemo(() => {
    const contentBlock = convertFromHTML(text || '')
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    const editorState = EditorState.createWithContent(contentState)
    return (
      <div className="draftjs-display-readonly">
        <Editor editorState={editorState} readOnly={true} onChange={() => null} />
      </div>
    )
  }, [text])

  return renderedRichText
}

export default RichTextPanel
