import { FormInputIngredientsItem } from 'Components/FormInputIngredients/IngredientAmountInput'
import { ReduxRecipe, ReduxRecipeAddData } from 'Models'

export type RecipeFormValuesType = Omit<ReduxRecipeAddData, 'ingredients'> & {
  ingredients: FormInputIngredientsItem[]
}

export const mapValuesToRecipeData = (values: RecipeFormValuesType): ReduxRecipeAddData => {
  const { ingredients, ...recipeData } = values
  return {
    ...recipeData,
    ingredients: ingredients.map((ingredient, index) => ({
      foodId: ingredient.food.id,
      position: index,
      ...ingredient.amount
    }))
  }
}

export const mapReduxRecipeToFormValues = (data: ReduxRecipe): RecipeFormValuesType => {
  const { ingredients, sourceId, ...recipeData } = data
  return {
    ...recipeData,
    ingredients: ingredients.map(({ food, quantity, unit }) => ({
      food,
      amount: {
        quantity,
        unit
      }
    })),
    // ToDo: Resolving of sourceId to a source object should rather be handled by the redux layer
    source: sourceId
      ? {
          id: sourceId,
          name: '' // only the id is relevant for th InputAutocomplete to find the default value in the food form
        }
      : undefined
  }
}
