import React, { FC, ReactElement, useState } from 'react'

export interface TabProps {
  name: string
  title: string
  renderContent: ReactElement | ReactElement[]
}

export interface TabSwitcherProps {
  onTabSwitch?: (tabName: string) => void
  tabs: TabProps[]
}

const TabSwitcher: FC<TabSwitcherProps> = ({ tabs, onTabSwitch }) => {
  const [tabContent, setTabContent] = useState(tabs[0].name)

  const handleTabSwitch = (tabName: string): void => {
    setTabContent(tabName)
    onTabSwitch && onTabSwitch(tabName)
  }
  return (
    <div className="col-12 mt-1">
      <div className="mt-3 mb-3 ml-2 mr-2 d-flex">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            onClick={() => handleTabSwitch(tab.name)}
            className={'text-gray-2 col-12 col-lg-6 pb-1 d-flex justify-content-center'}
            style={{
              cursor: 'pointer',
              borderBottomWidth: tabContent === tab.name ? '4px' : '2px',
              borderColor: tabContent === tab.name ? '#32B6EF' : '#E3E6E9',
              borderBottomStyle: 'solid'
            }}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {tabs.find((tab) => tabContent === tab.name)?.renderContent}
    </div>
  )
}

export default TabSwitcher
