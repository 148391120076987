import React, { FC, useEffect, useState } from 'react'

import { PageLayout } from 'Components'
import { ErrorMessage } from 'Pages/ErrorPage'
import { customerSelector } from 'ReduxStore/customerSlice'
import { useAppSelector } from 'ReduxStore/hooks'
import { memberShipService } from 'Services'

type Response = undefined | { url: undefined | string; status: number }

export const UserMasterDataPage: FC = () => {
  const { id: customerId } = useAppSelector(customerSelector)
  const [response, setResponse] = useState<Response>()

  useEffect(() => {
    let mounted = true

    memberShipService.getSelfServiceUrl(customerId).then((response) => {
      if (mounted) {
        setResponse(response)
      }
    })

    return () => {
      mounted = false
    }
  }, [customerId])

  return (
    <PageLayout>
      <UserMasterDataContent response={response} />
    </PageLayout>
  )
}

const UserMasterDataContent: FC<{ response: Response }> = ({ response }) => {
  if (!response) {
    return null
  }

  if (response.url) {
    return <iframe src={response.url} style={{ height: '75vh', width: '100vw', border: 0 }}></iframe>
  }

  return (
    <div className="container max-700">
      {response.status === 404 ? (
        <p>
          Wir führen Umbauarbeiten durch, daher können Sie Ihre Kunden- und Mitgliedsdaten vorübergehend nicht selbst
          abrufen. Unser Support-Team gibt Ihnen in der Zwischenzeit jedoch gern Auskunft über Ihre Daten. Schreiben Sie
          uns einfach eine E-Mail: <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>
        </p>
      ) : (
        <ErrorMessage />
      )}
    </div>
  )
}
