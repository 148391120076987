import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, PageLayout } from 'Components'
import { routes } from 'Config'
import { useFetchLatestAnamnesis } from 'Pages/AnamnesisPage/AnamnesisPage'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'
import { getAnamnesisDataSelector } from 'ReduxStore/anamnesis'
import { coreBackendUserSelector } from 'ReduxStore/coreBackendUser'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { keyCloakUserSelector } from 'ReduxStore/keyCloakUser'
import { denyAccessSuccessPage, purchaseTracked, temporaryAccessSelector } from 'ReduxStore/temporaryAccessSlice'
import { memberShipService } from 'Services'

type DataLayerItems = {
  item_id: string
  item_name: string
  currency: 'CHF'
  index: number
  price: number
  quantity: number
}

type DataLayer = {
  event?: 'purchase'
  ecommerce: null | {
    transaction_id: string
    value: number
    tax: number
    shipping: number
    currency: 'CHF'
    items: DataLayerItems[]
  }
}

declare global {
  interface Window {
    dataLayer: DataLayer[]
  }
}

type PushToDataLayerArgs = {
  transaction_id: string
  totalPrice: number
  planVariantId: string
  planVariantName: string
}

const { dataLayer } = window
const pushToDataLayer = (values: PushToDataLayerArgs): void => {
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: values.transaction_id,
      value: values.totalPrice,
      tax: Number((values.totalPrice * 0.081).toFixed(2)),
      shipping: 0,
      currency: 'CHF',
      items: [
        {
          item_id: values.planVariantId,
          item_name: values.planVariantName,
          currency: 'CHF',
          index: 0,
          price: values.totalPrice,
          quantity: 1
        }
      ]
    }
  })
}
const OrderSuccessPage: FC = () => {
  const dispatch = useAppDispatch()
  const { hasTrackedPurchase } = useAppSelector(temporaryAccessSelector)
  const keycloakUser = useAppSelector(keyCloakUserSelector)
  const coreBackendUser = useAppSelector(coreBackendUserSelector)
  useFetchLatestAnamnesis()

  const redirect = (): void => {
    dispatch(denyAccessSuccessPage())
  }

  useEffect(() => {
    const addTracking = async (): Promise<void> => {
      const response = await memberShipService.getTrackingInfo()
      if (response && !hasTrackedPurchase) {
        pushToDataLayer({
          transaction_id: `${keycloakUser.id}_${Date.now()}`,
          totalPrice: response.recurringFee,
          planVariantId: response.planVariantId,
          planVariantName: response.planVariantName
        })

        dispatch(purchaseTracked())
      }
    }
    if (!hasTrackedPurchase) {
      addTracking()
    }
  }, [])

  return (
    <PageLayout theme="smallcircle">
      <div className="container max-500 text-center">
        {coreBackendUser.info.hasPrograms ? (
          <BaseCustomerText onClick={redirect} />
        ) : (
          <ProspectiveCustomerText onClick={redirect} />
        )}
      </div>
    </PageLayout>
  )
}

type Props = {
  onClick: () => void
}

const BaseCustomerText: FC<Props> = ({ onClick }) => {
  const history = useHistory()
  const anamnesis = useAppSelector(getAnamnesisDataSelector)
  return (
    <>
      <h1>Willkommen zurück</h1>
      <p>
        Sie haben Ihre Mitgliedschaft bei eBalance erfolgreich reaktiviert. Wir freuen uns, Sie wieder bei uns begrüssen
        zu dürfen, und wünschen Ihnen viel Erfolg beim Erreichen Ihrer Gewichtsziele!
      </p>
      <p className="mb-3">
        Starten Sie das Programm neu, um Ihre aktuellen Körperwerte zu erfassen. Wenn sich Ihre Werte nicht wesentlich
        geändert haben, können Sie auch ohne Neustart weitermachen.
      </p>
      <Button
        onClick={() => {
          onClick()
          history.push(
            anamnesis?.activeProgramType === ProgramTypes.LOSE_WEIGHT
              ? routes.profile.restartLoseWeight
              : routes.profile.restartKeepWeight
          )
        }}
      >
        Neustart
      </Button>
    </>
  )
}

const ProspectiveCustomerText: FC<Props> = ({ onClick }) => {
  const history = useHistory()
  return (
    <>
      <h1>Willkommen bei eBalance</h1>
      <p>Ihr Abschluss war erfolgreich. Sie haben eine eBalance Mitgliedschaft gebucht.</p>
      <p className="mb-3">
        Als nächstes müssen Sie Ihr Konto vervollständigen. Bitte klicken Sie auf den Button nächster Schritt, um die
        Anamnese auszufüllen.
      </p>
      <Button
        onClick={() => {
          onClick()
          history.push(routes.register.anamnesis)
        }}
      >
        Nächster Schritt
      </Button>
    </>
  )
}

export default OrderSuccessPage
