import React, { useCallback } from 'react'

import { FormInputFoodAmount } from '../FormInputFoodAmount'
import { ResponsiveCardBase, ResponsiveCardBaseProps } from '../ResponsiveCardBase/ResponsiveCardBase'

import { buildEatableDetails } from 'Components/JournalCardSearchEatable'
import { Amount, FavoritesMapping, QuantifiedEatable } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAppSelector } from 'ReduxStore/hooks'
import { Utils } from 'Utils'

export type JournalCardEatableProps = Pick<
  ResponsiveCardBaseProps,
  'showCheckbox' | 'onClick' | 'iconButtons' | 'cardButtons' | 'toggleChildrenIsOn' | 'className'
> & {
  eatable: QuantifiedEatable
  isEditable?: boolean
  isAccepted: boolean
  onChange?: (eatable: QuantifiedEatable) => void
}

export const JournalCardEatable: React.FC<JournalCardEatableProps> = ({
  eatable,
  isAccepted,
  isEditable = false,
  onChange,
  ...responsiveCardBaseProps
}: JournalCardEatableProps) => {
  const item = eatable.recipe || eatable.food
  const amount: Amount = {
    quantity: eatable.quantity !== undefined ? eatable.quantity : 1,
    unit: eatable.unit || 'PORTION'
  }

  const favoritesKey = eatable.recipe ? FavoritesMapping.RECIPES : FavoritesMapping.FOODS

  const isFavorite = !!useAppSelector(favoriteSelector(favoritesKey, item!.id))

  const details = buildEatableDetails(eatable, amount, 'normal')

  const handleFoodAmountChange = useCallback(
    (amount: Amount) => {
      onChange && onChange({ ...eatable, ...amount })
    },
    [eatable, onChange]
  )

  return (
    <ResponsiveCardBase
      title={item && item.name}
      details={details}
      source={item?.source?.name}
      imageUrl={item && item.imageUrl}
      svgUrl={item && item.source && item.source.imageUrl}
      {...responsiveCardBaseProps}
      isFavorite={isAccepted && isFavorite}
    >
      {isEditable && (
        <div className="standardcard-options">
          <div>
            <FormInputFoodAmount
              label=" "
              amount={amount}
              onAmountChange={handleFoodAmountChange}
              availableUnits={Utils.availableUnits(eatable)}
            />
          </div>
        </div>
      )}
    </ResponsiveCardBase>
  )
}
