import React, { FC, memo, useMemo } from 'react'

import { ChartPie, InfoPlaceholder, Panel, Table } from 'Components'
import { DebugPanel } from 'Components/Debug'
import { ChartArrayCombinedType, MacronutrientSet, Nutritions, nutritionsConfig } from 'Models'
import { Utils } from 'Utils'
import { mergeMacronutrientSetsToTableArray } from 'Utils/helpers/nutritionHelper'

interface Props {
  nutritionSummaryRecommended?: MacronutrientSet
  nutritionSummaryTracked?: MacronutrientSet
}

export const nutritionsTableHeader = ['', 'Nährwerte', 'Prozent', 'Gramm']

const displayNutritionsSuggestion: Array<keyof Nutritions> = ['protein', 'carbohydrates', 'fat', 'salt', 'alcohol']

const NutritionsSuggestion: FC<Props> = ({ nutritionSummaryRecommended, nutritionSummaryTracked }: Props) => {
  const trackedRows = useMemo(
    () =>
      nutritionSummaryTracked
        ? mergeMacronutrientSetsToTableArray(nutritionSummaryTracked, displayNutritionsSuggestion)
        : [],
    [nutritionSummaryTracked]
  )

  const recommendedRows = useMemo(
    () =>
      nutritionSummaryRecommended
        ? mergeMacronutrientSetsToTableArray(nutritionSummaryRecommended, displayNutritionsSuggestion)
        : [],
    [nutritionSummaryRecommended]
  )

  const { data: recommendedChartData, colors: recommendedChartColors } = useMemo(
    () =>
      nutritionSummaryRecommended
        ? Utils.convertObjectToPieChartArrays(
            nutritionSummaryRecommended.distribution,
            nutritionsConfig,
            displayNutritionsSuggestion
          )
        : ({} as ChartArrayCombinedType),
    [nutritionSummaryRecommended]
  )

  const { data: trackedChartData, colors: trackedChartColors } = useMemo(
    () =>
      nutritionSummaryTracked
        ? Utils.convertObjectToPieChartArrays(
            nutritionSummaryTracked.distribution,
            nutritionsConfig,
            displayNutritionsSuggestion
          )
        : ({} as ChartArrayCombinedType),
    [nutritionSummaryTracked]
  )

  return (
    <>
      <DebugPanel>
        <code>{JSON.stringify({ nutritionSummaryRecommended, nutritionSummaryTracked }, null, 2)}</code>
      </DebugPanel>
      <div className="col-12 col-lg-6">
        <Panel>
          {nutritionSummaryRecommended && !!recommendedChartData.length ? (
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-lg d-lg-flex align-items-center">
                      <h2>Unsere Empfehlung</h2>
                    </div>
                    <div className="col-12 col-lg-auto">
                      <ChartPie data={recommendedChartData} colors={recommendedChartColors} />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <Table theme="nutrition" titleRow={nutritionsTableHeader} rows={recommendedRows} />
                </div>
              </div>
            </div>
          ) : (
            <InfoPlaceholder text="Auf dieser Seite werden Ihnen Analysen vom Tagebuch angezeigt, wie die Nährwerteverteilung und die Kalorienbilanz. Aktuell gibt es, noch keinen Eintrag im Tagebuch daher wird Ihnen noch keine Analyse angezeigt. Fügen Sie jetzt über die Suche ein Nahrungsmittel, ein Rezept oder eine Aktivität zum Tagebuch hinzu. Dazu klicken Sie in das Suchfeld über dem Dashboard und suchen die einzelnen Nahrungsmittel oder Rezepte und fügen diese über “Hinzufügen” und “Speichern” zu Ihrem Tagebuch hinzu." />
          )}
        </Panel>
      </div>
      <div className="col-12 col-lg-6">
        <Panel>
          {nutritionSummaryTracked && !!trackedChartData.length ? (
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-lg d-lg-flex align-items-center">
                      <h2>Ihr Konsum</h2>
                    </div>
                    <div className="col-12 col-lg-auto">
                      <ChartPie data={trackedChartData} colors={trackedChartColors} />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <Table theme="nutrition" titleRow={nutritionsTableHeader} rows={trackedRows} />
                </div>
              </div>
            </div>
          ) : (
            <InfoPlaceholder text="Auf dieser Seite werden Ihnen Analysen vom Tagebuch angezeigt, wie die Nährwerteverteilung und die Kalorienbilanz. Aktuell gibt es, noch keinen Eintrag im Tagebuch daher wird Ihnen noch keine Analyse angezeigt. Fügen Sie jetzt über die Suche ein Nahrungsmittel, ein Rezept oder eine Aktivität zum Tagebuch hinzu. Dazu klicken Sie in das Suchfeld über dem Dashboard und suchen die einzelnen Nahrungsmittel oder Rezepte und fügen diese über “Hinzufügen” und “Speichern” zu Ihrem Tagebuch hinzu." />
          )}
        </Panel>
      </div>
    </>
  )
}

export default memo(NutritionsSuggestion)
