import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ConnectedProps, connect } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { EditRecipePage } from './EditRecipePage'
import { RecipeFormValuesType, mapReduxRecipeToFormValues, mapValuesToRecipeData } from './recipeFormHelpers'
import { recipeFormValidationSchema } from './recipeFormSchema'

import { routes } from 'Config'
import { EatableSource, Recipe, ReduxStoreState } from 'Models'
import { isLoadingSelector } from 'ReduxStore/common'
import { eatableSourcesSelector, fetchEatableSourcesAction } from 'ReduxStore/eatableSources'
import {
  didLoadUserRecipesSelector,
  fetchUserRecipeAction,
  updateUserRecipe,
  userRecipesSelector
} from 'ReduxStore/userRecipes'
import { searchClient } from 'Utils/algoliaSearchHelpers'
import { useImageUploading } from 'Utils/helpers/useImageUploading'

type SelectorProps = {
  eatableSources: EatableSource[]
  userRecipes: Recipe[]
  isUserRecipesLoading: boolean
  didLoadUserRecipes: boolean
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  eatableSources: eatableSourcesSelector,
  userRecipes: userRecipesSelector,
  isUserRecipesLoading: (state) => isLoadingSelector(state.userRecipes),
  didLoadUserRecipes: didLoadUserRecipesSelector
})

const mapDispatchToProps = {
  updateUserRecipe,
  fetchUserRecipe: fetchUserRecipeAction,
  fetchEatableSources: fetchEatableSourcesAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & RouteComponentProps<{ recipeId: string }>

const EditRecipePageContainer: FC<Props> = ({
  eatableSources,
  updateUserRecipe,
  fetchUserRecipe,
  fetchEatableSources,
  userRecipes,
  isUserRecipesLoading,
  didLoadUserRecipes,
  match: {
    params: { recipeId }
  }
}: Props) => {
  const { isImageUploading, setTempImageId, setIsImageUploading, onImageSubmit, onPageExit } = useImageUploading({})

  const editRecipe = useMemo(() => {
    return userRecipes.find((item) => item.id === recipeId)
  }, [recipeId, userRecipes, eatableSources.map((s) => s.id)])

  const {
    register,
    control,
    errors,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { isSubmitting }
  } = useForm<RecipeFormValuesType>({
    defaultValues: editRecipe ? mapReduxRecipeToFormValues(editRecipe) : undefined,
    resolver: yupResolver(recipeFormValidationSchema),
    shouldUnregister: false
  })

  useEffect(() => {
    if (didLoadUserRecipes && editRecipe) {
      reset(mapReduxRecipeToFormValues(editRecipe))
    }
  }, [userRecipes, editRecipe])

  useEffect(() => {
    if (recipeId && !isUserRecipesLoading && didLoadUserRecipes && !editRecipe) {
      fetchUserRecipe(recipeId)
    }
  }, [recipeId, userRecipes, editRecipe, isUserRecipesLoading])

  useEffect(() => {
    if (eatableSources.length === 0) {
      fetchEatableSources()
    }

    return () => {
      onPageExit()
    }
  }, [])

  const history = useHistory()

  const onSubmit = handleSubmit(async (values) => {
    await onImageSubmit(values.imageUrl)
    updateUserRecipe(recipeId, mapValuesToRecipeData(values))
    history.push(routes.myEatables.myRecipeView(recipeId))
  })

  if (!recipeId || isUserRecipesLoading || !userRecipes || !editRecipe) {
    return null
  }

  return (
    <EditRecipePage
      algoliaSearchClient={searchClient}
      isImageUploading={isImageUploading}
      setIsImageUploading={setIsImageUploading}
      setTempImageId={setTempImageId}
      onSubmit={onSubmit}
      onCancel={history.goBack}
      isSubmitting={isSubmitting}
      setValue={setValue}
      setError={setError}
      register={register}
      control={control}
      errors={errors}
      eatableSources={eatableSources}
    />
  )
}

export default connector(EditRecipePageContainer)
