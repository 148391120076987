import React, { FC } from 'react'

import { PageLayout } from 'Components'

const NotFoundPage: FC = () => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container text-center max-600">
        <h1>Wir haben die Seite nicht gefunden.</h1>
        <p>
          Entschuldigung, die Seite wurde nicht gefunden. Bitte laden Sie die Seite neu, oder melden sich bei Support
          mit dem Link der gesuchten Seite.
        </p>
        <p>Fehlercode: 404</p>
      </div>
    </PageLayout>
  )
}

export default NotFoundPage
