import { EbalanceBackendService } from './EbalanceBackendService'

import { Query } from 'Models'
import { EnergyRequirements, FetchEnergyRequirementsPayload } from 'Models/EnergyRequirements'

export class EnergyCalculatorService extends EbalanceBackendService {
  async getEnergyRequirements(query: FetchEnergyRequirementsPayload): Promise<EnergyRequirements> {
    const queryString = this.buildUrlQuery((query as unknown) as Query)

    const res = await this.client.get(`/energy-requirements?${queryString}`)
    return res.data
  }
}
