import React, { memo, useMemo } from 'react'
import { UseFormMethods } from 'react-hook-form'

export type FormInputToggleProps = Partial<Pick<UseFormMethods, 'register' | 'errors'>> & {
  name?: string
  value?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  onValueChange?: (value: boolean) => void
  errorText?: string
}

const FormInputToggle: React.FC<FormInputToggleProps> = ({
  name,
  value,
  defaultChecked,
  disabled,
  onValueChange,
  errorText,
  register,
  errors = []
}: FormInputToggleProps) => {
  const handleValueChange = (): void => onValueChange && onValueChange(!value)

  const errorMessage = useMemo(() => (name && errors[name] && errors[name].message) || errorText, [
    name,
    errors,
    errorText
  ])
  return (
    <>
      <label className="toggle">
        <input
          name={name}
          ref={register}
          type="checkbox"
          checked={value}
          defaultChecked={defaultChecked}
          onChange={handleValueChange}
          disabled={disabled}
        />
        <span className="label-left">Nein</span>
        <span className="label-right">Ja</span>
      </label>
      {errorMessage && <div className="input-error">{errorMessage}</div>}
    </>
  )
}

export default memo(FormInputToggle)
