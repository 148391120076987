/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react'

import { StepConfig, WizardStepType } from './types'

export const createStep = (
  Component: FC<any>,
  { id, className, title, infoText, scrollTop, validationSchema, defaultValues }: StepConfig
): WizardStepType => ({
  id,
  className,
  title,
  infoText,
  scrollTop,
  validationSchema,
  defaultValues,
  Component
})
