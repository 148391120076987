import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'

dayjs.extend(duration)
dayjs.extend(relativeTime)

interface Props {
  timestamp?: string
}
const Uptime: React.FC<Props> = ({ timestamp }: Props) => {
  const date = dayjs(timestamp)
  const formatted = date.isValid() ? dayjs.duration(date.diff(dayjs())).humanize(true) : 'n/a'

  return <span>{formatted}</span>
}

export default Uptime
