import { MutableRefObject, useEffect, useRef } from 'react'

export const usePreviousState = <T>(prevState: T): MutableRefObject<T | undefined>['current'] => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = prevState
  }, [prevState])

  return ref.current
}
