import React, { FC, FormEvent, useCallback, useRef, useState } from 'react'

import { Button } from 'Components/Button'
import { Icon, IconColor, IconName } from 'Components/Icon'
import { ImageUrl } from 'Models'
import { ImageReturnType, ImageService, imageService } from 'Services'

type ThemeType = 'rectangle' | 'circle'

export type ImageUploadHookProps = {
  defaultImageUrl?: ImageUrl
  path: string
  theme: ThemeType
  errorText?: string
  onUrlChange?: (url: string) => void
  onIdChange?: (id?: number) => void
  onUploadingChange?: (isUploading: boolean) => void
  onError?: (error?: string) => void
}

export const ImageUploadHook: FC<ImageUploadHookProps> = ({
  defaultImageUrl = '',
  path,
  theme,
  errorText,
  onUrlChange,
  onIdChange,
  onError,
  onUploadingChange: onUploading
}: ImageUploadHookProps) => {
  const [image, setImage] = useState<ImageReturnType>()
  const [isLoading, setIsLoading] = useState(false)
  const [hash, setHash] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const isRectangle = theme === 'rectangle'
  const isCircle = theme === 'circle'

  const handleImageUpload = useCallback(
    async (e: FormEvent<HTMLInputElement>): Promise<void> => {
      e.preventDefault()

      const eventTarget = e.currentTarget // Cache the value of e.currentTarget because SyntheticEvent cannot be used asynchronously

      if (eventTarget.files && eventTarget.files[0]) {
        setIsLoading(true)
        onUploading && onUploading(true)
        onError && onError()

        try {
          const { fileName, url, id } = await imageService.uploadImage(eventTarget.files[0], path, null)
          image && (await imageService.deleteImage(image.id))
          setHash(Math.random().toString())
          setImage({ id, url, fileName })
          onUrlChange && onUrlChange(url)
          onIdChange && onIdChange(id)
        } catch (e) {
          setIsLoading(false)
          onError && onError('Hochladen fehlgeschlagen.')
          console.error(e)
        } finally {
          onUploading && onUploading(false)
          eventTarget.value = ''
        }
      }
    },
    [onUrlChange, onIdChange, onUploading, onError, image, path]
  )

  const handleDeleteImage = async (): Promise<void> => {
    onUrlChange && onUrlChange('')
    if (!image) return
    onError && onError()
    try {
      await imageService.deleteImage(image.id)
      setImage(undefined)
      onIdChange && onIdChange(undefined)
    } catch (e) {
      console.error(e)
    }
  }

  const handleReplaceImages = (): void => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleImageLoad = (): void => {
    setIsLoading(false)
  }

  const handleImageError = useCallback(() => {
    setIsLoading(false)
    onError && onError('Laden fehlgeschlagen.')
  }, [onError])

  const imageUrl = (image && image.url) || defaultImageUrl

  return (
    <>
      <div
        className={`
          imageupload
          ${isRectangle ? 'is-rectangle' : ''}
          ${isCircle ? 'is-circle' : ''}
        `}
      >
        <div className="imageupload-input">
          <input ref={inputRef} type="file" accept={ImageService.acceptedTypes} onChange={handleImageUpload} />
          {imageUrl && !isLoading ? null : (
            <>
              <Icon name={IconName.camera} color={IconColor.GRAY_2} />
              <span className="label">{isLoading ? 'Lädt …' : 'Bild auswählen'}</span>
            </>
          )}
          <img
            style={{
              display: imageUrl && !isLoading ? 'block' : 'none'
            }}
            src={imageUrl ? `${imageUrl}${imageUrl.indexOf('?') === -1 ? '?' : '&'}width=350&${hash}` : undefined}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        </div>
        {imageUrl && !isLoading && (
          <div className="d-flex justify-content-center">
            <Button theme="secondary-small" onClick={handleDeleteImage} type="button">
              Löschen
            </Button>
            <Button theme="small" onClick={handleReplaceImages} type="button">
              Ersetzen
            </Button>
          </div>
        )}
      </div>
      {errorText && <div className="input-error text-center">{errorText}</div>}
    </>
  )
}
