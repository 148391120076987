import React, { FC } from 'react'

import { RecipeForm, RecipeFormProps } from './RecipeForm'

import { PageLayout } from 'Components'

type EditRecipePageProps = RecipeFormProps

export const EditRecipePage: FC<EditRecipePageProps> = ({ ...recipeFormProps }) => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <h1>Rezept bearbeiten</h1>
        <p>
          Bearbeiten Sie hier Ihr hinterlegtes Rezept. Bitte beachten Sie, dass Ihre Änderungen auch rückwirkend für
          alle bereits ins Tagebuch hinzugefügten Rezepte übernommen werden.
        </p>
        <RecipeForm {...recipeFormProps} />
      </div>
    </PageLayout>
  )
}
