import React, { FC } from 'react'

import { formAnswerOptionKeyExtractor, formAnswerOptionLabelExtractor } from '../../helpers'
import { StepHeading } from '../StepHeading'

import { dietCriteriaOptions } from './config'

import { FormInputRadio, FormInputToggle } from 'Components'
import { RenderStepType } from 'Components/FormWizard'

const PersonalMenuPlanStep: FC<RenderStepType> = ({ register, errors }: RenderStepType) => (
  <>
    <StepHeading
      title="Ihr Menüplan"
      infotext="Fast geschafft! Damit wir Ihnen später einen individuellen Menüplan erstellen können, benötigen wir ein paar letzte Angaben zu Ihrer aktuellen Ernährung."
    />
    <div className="row">
      <div className="col-12">
        <h4 className="mb-4">Was essen Sie grundsätzlich – und was nicht?</h4>
        <FormInputRadio
          name="diet"
          items={dietCriteriaOptions}
          keyExtractor={formAnswerOptionKeyExtractor}
          labelExtractor={formAnswerOptionLabelExtractor}
          register={register}
          errors={errors}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <h4 className="mb-4">Sind Sie an einer langfristigen Ernährungsumstellung interessiert?</h4>
        <FormInputToggle name="longTermMotivated" register={register} errors={errors} />
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <h4 className="mb-4">Möchten Sie einen Menüplan angezeigt bekommen?</h4>
        <FormInputToggle name="isMenuPlanEnabled" register={register} errors={errors} />
      </div>
    </div>
  </>
)

export default PersonalMenuPlanStep

export * from './config'
