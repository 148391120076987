import { useCallback } from 'react'
import { useHistory } from 'react-router'

import { RoutePathTemplateFunction } from 'Config/routes'

type ItemCallback = (id: string) => void
type VoidCallback = () => void

type UseEatableCardHookParameters = {
  itemId: string
  editRoute: RoutePathTemplateFunction
  hideItem: ItemCallback
}

export const useManageEatable = ({
  itemId,
  editRoute,
  hideItem
}: UseEatableCardHookParameters): {
  handleOnEdit: VoidCallback
  handleOnDelete: VoidCallback
} => {
  const history = useHistory()

  const handleOnEdit = useCallback(() => {
    history.push(editRoute(itemId))
  }, [itemId])

  const handleOnDelete = useCallback(() => hideItem(itemId), [hideItem, itemId])

  return {
    handleOnDelete,
    handleOnEdit
  }
}
