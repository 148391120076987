import { RefObject, useEffect } from 'react'
import { UseFormMethods } from 'react-hook-form'

type Props = Pick<UseFormMethods, 'errors'> & {
  fieldRef: RefObject<HTMLElement>
  name: string
}

export const useFocusOnFirstError = ({ fieldRef, errors, name }: Props): void => {
  useEffect(() => {
    const firstErrorKey = Object.keys(errors)[0]

    if (errors && firstErrorKey === name && fieldRef.current) {
      fieldRef.current.focus()
    }
  }, [errors])
}
