import React, { FC, useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { createStructuredSelector } from 'reselect'

import { ReduxStoreState, ToastMessageState } from 'Models'
import { errorMessagesSelector, removeErrorMessagesAction } from 'ReduxStore/error'
import { removeToastMessageAction, toastMessageSelector } from 'ReduxStore/toastMessage'

type SelectorProps = {
  customMessage: ToastMessageState
  errorMessages: string[]
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  customMessage: toastMessageSelector,
  errorMessages: errorMessagesSelector
})

const mapDispatchToProps = {
  removeToastMessage: removeToastMessageAction,
  removeErrorMessages: removeErrorMessagesAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const MessageToaster: FC<PropsFromRedux> = ({
  customMessage: { content, type },
  errorMessages,
  removeToastMessage,
  removeErrorMessages
}: PropsFromRedux) => {
  useEffect(() => {
    if (content) {
      toast(content, { type, onOpen: removeToastMessage })
    }
    if (errorMessages.length > 0) {
      errorMessages.forEach((message) => toast.error(message))
      removeErrorMessages()
    }
  }, [content, type, errorMessages])

  return <ToastContainer position="bottom-left" autoClose={3500} transition={Slide} />
}

export default connector(MessageToaster)
