import React, { FC, useCallback, useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { EnergyDistribution, EnergyHistory, NutritionsSuggestion, PanelTextPicker } from './components'

import { InfoPlaceholder, PageLayout } from 'Components'
import {
  DateRangeString,
  EnergyDistributionType,
  EnergyHistoryItem,
  MacronutrientSet,
  ReduxStoreState,
  UserSettingsDynamic,
  analyticsEnergyDistributionDateRanges,
  analyticsEnergyHistoryDateRanges,
  analyticsNutritionsDateRanges
} from 'Models'
import { registrationDateSelector } from 'ReduxStore/coreBackendUser'
import {
  energyDistributionSelector,
  energyHistorySelector,
  fetchEnergyDistributionAction,
  fetchEnergyHistoryAction,
  fetchNutritionSummaryAction,
  isEnergyDistributionLoadingSelector,
  isEnergyHistoryLoadingSelector,
  isNutritionSummaryLoadingSelector,
  nutritionSummaryRecommendedSelector,
  nutritionSummaryTrackedSelector
} from 'ReduxStore/myAnalytics'
import { currentProgramStartDateSelector, fetchProgramsAction, programStartDatesSelector } from 'ReduxStore/programs'
import {
  analyticsEnergyDistributionDateRangeSelector,
  analyticsEnergyHistoryDateRangeSelector,
  analyticsNutritionsDateRangeSelector,
  updateUserSettingsAction
} from 'ReduxStore/userSettings'
import { Utils } from 'Utils'
import { createEnergyHistoryChartData } from 'Utils/helpers/nutritionHelper'

type SelectorProps = {
  analyticsNutritionsDateRange?: DateRangeString
  analyticsEnergyHistoryDateRange?: DateRangeString
  analyticsEnergyDistributionDateRange?: DateRangeString
  nutritionSummaryRecommended?: MacronutrientSet
  nutritionSummaryTracked?: MacronutrientSet
  energyHistory: EnergyHistoryItem[]
  energyDistribution?: EnergyDistributionType
  programStartDates?: Array<{ value: number }>
  registrationDate: Date
  currentProgramStartDate: Date
  isNutritionSummaryLoading: boolean
  isEnergyHistoryLoading: boolean
  isEnergyDistributionLoading: boolean
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  analyticsNutritionsDateRange: analyticsNutritionsDateRangeSelector,
  analyticsEnergyHistoryDateRange: analyticsEnergyHistoryDateRangeSelector,
  analyticsEnergyDistributionDateRange: analyticsEnergyDistributionDateRangeSelector,
  nutritionSummaryRecommended: nutritionSummaryRecommendedSelector,
  nutritionSummaryTracked: nutritionSummaryTrackedSelector,
  energyHistory: energyHistorySelector,
  energyDistribution: energyDistributionSelector,
  programStartDates: programStartDatesSelector,
  registrationDate: registrationDateSelector,
  currentProgramStartDate: currentProgramStartDateSelector,
  isNutritionSummaryLoading: isNutritionSummaryLoadingSelector,
  isEnergyHistoryLoading: isEnergyHistoryLoadingSelector,
  isEnergyDistributionLoading: isEnergyDistributionLoadingSelector
})

const mapDispatchToProps = {
  fetchNutritionSummary: fetchNutritionSummaryAction,
  fetchEnergyHistory: fetchEnergyHistoryAction,
  fetchEnergyDistribution: fetchEnergyDistributionAction,
  updateUserSettings: updateUserSettingsAction,
  fetchPrograms: fetchProgramsAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const NutritionsPage: FC<PropsFromRedux> = ({
  fetchNutritionSummary,
  fetchEnergyHistory,
  fetchEnergyDistribution,
  updateUserSettings,
  fetchPrograms,
  analyticsNutritionsDateRange,
  analyticsEnergyHistoryDateRange,
  analyticsEnergyDistributionDateRange,
  nutritionSummaryRecommended,
  nutritionSummaryTracked,
  energyHistory,
  energyDistribution,
  programStartDates,
  registrationDate,
  currentProgramStartDate,
  isNutritionSummaryLoading,
  isEnergyHistoryLoading,
  isEnergyDistributionLoading
}: PropsFromRedux) => {
  const fetchNutritionSummaryCall = useCallback(
    () =>
      analyticsNutritionsDateRange &&
      fetchNutritionSummary(
        Utils.dateRangeFromString(analyticsNutritionsDateRange, registrationDate, currentProgramStartDate)
      ),

    [fetchNutritionSummary, analyticsNutritionsDateRange]
  )

  const fetchEnergyHistoryCall = useCallback(
    () =>
      analyticsEnergyHistoryDateRange &&
      fetchEnergyHistory(
        Utils.dateRangeFromString(analyticsEnergyHistoryDateRange, registrationDate, currentProgramStartDate)
      ),
    [fetchEnergyHistory, analyticsEnergyHistoryDateRange]
  )

  const fetchEnergyDistributionCall = useCallback(
    () =>
      analyticsEnergyDistributionDateRange &&
      fetchEnergyDistribution(
        Utils.dateRangeFromString(analyticsEnergyDistributionDateRange, registrationDate, currentProgramStartDate)
      ),
    [fetchEnergyDistribution, analyticsEnergyDistributionDateRange]
  )

  const averageEnergy = Math.round(
    energyHistory.reduce((totalEnergy, day) => totalEnergy + day.acceptedEnergy, 0) / energyHistory.length
  )

  useEffect(() => {
    fetchPrograms()
  }, [])

  useEffect(() => {
    fetchNutritionSummaryCall()
  }, [fetchNutritionSummaryCall])

  useEffect(() => {
    fetchEnergyHistoryCall()
  }, [fetchEnergyHistoryCall])

  useEffect(() => {
    fetchEnergyDistributionCall()
  }, [fetchEnergyDistributionCall])

  return (
    <PageLayout theme="smallcircle">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <PanelTextPicker
              onChange={(item) => updateUserSettings({ analyticsNutritionsDateRange: item } as UserSettingsDynamic)}
              items={analyticsNutritionsDateRanges}
              selectedKey={analyticsNutritionsDateRange}
              loading={isNutritionSummaryLoading}
              title="Nährwertdiagramm"
            >
              {(nutritionSummaryRecommended && !!Object.keys(nutritionSummaryRecommended).length) ||
              (nutritionSummaryTracked && !!Object.keys(nutritionSummaryTracked).length) ? (
                <NutritionsSuggestion
                  nutritionSummaryRecommended={nutritionSummaryRecommended}
                  nutritionSummaryTracked={nutritionSummaryTracked}
                />
              ) : (
                <InfoPlaceholder text="Auf dieser Seite werden Ihnen Analysen vom Tagebuch angezeigt, wie die Nährwerteverteilung und die Kalorienbilanz. Aktuell gibt es, noch keinen Eintrag im Tagebuch daher wird Ihnen noch keine Analyse angezeigt. Fügen Sie jetzt über die Suche ein Nahrungsmittel, ein Rezept oder eine Aktivität zum Tagebuch hinzu. Dazu klicken Sie in das Suchfeld über dem Dashboard und suchen die einzelnen Nahrungsmittel oder Rezepte und fügen diese über “Hinzufügen” und “Speichern” zu Ihrem Tagebuch hinzu." />
              )}
            </PanelTextPicker>
          </div>
        </div>
        <div className="row mt-6">
          <div className="col-12">
            <PanelTextPicker
              onChange={(item) => updateUserSettings({ analyticsEnergyHistoryDateRange: item } as UserSettingsDynamic)}
              items={analyticsEnergyHistoryDateRanges}
              selectedKey={analyticsEnergyHistoryDateRange}
              loading={isEnergyHistoryLoading}
              title="Kalorienbilanz"
              additionalInfo={`Durchschnittliche Kalorien pro Tag: ${averageEnergy} kcal`}
            >
              {energyHistory && energyHistory.length > 0 ? (
                <EnergyHistory
                  energyHistoryChartData={createEnergyHistoryChartData(energyHistory)}
                  programStartDates={programStartDates}
                />
              ) : (
                <InfoPlaceholder text="Auf dieser Seite werden Ihnen Analysen vom Tagebuch angezeigt, wie die Nährwerteverteilung und die Kalorienbilanz. Aktuell gibt es, noch keinen Eintrag im Tagebuch daher wird Ihnen noch keine Analyse angezeigt. Fügen Sie jetzt über die Suche ein Nahrungsmittel, ein Rezept oder eine Aktivität zum Tagebuch hinzu. Dazu klicken Sie in das Suchfeld über dem Dashboard und suchen die einzelnen Nahrungsmittel oder Rezepte und fügen diese über “Hinzufügen” und “Speichern” zu Ihrem Tagebuch hinzu." />
              )}
            </PanelTextPicker>
          </div>
        </div>
        <div className="row mt-6">
          <div className="col-12">
            <PanelTextPicker
              onChange={(item) =>
                updateUserSettings({ analyticsEnergyDistributionDateRange: item } as UserSettingsDynamic)
              }
              items={analyticsEnergyDistributionDateRanges}
              selectedKey={analyticsEnergyDistributionDateRange}
              loading={isEnergyDistributionLoading}
              title="Mahlzeitenverteilung"
            >
              {energyDistribution ? (
                <EnergyDistribution energyDistribution={energyDistribution} />
              ) : (
                <InfoPlaceholder text="Auf dieser Seite werden Ihnen Analysen vom Tagebuch angezeigt, wie die Nährwerteverteilung und die Kalorienbilanz. Aktuell gibt es, noch keinen Eintrag im Tagebuch daher wird Ihnen noch keine Analyse angezeigt. Fügen Sie jetzt über die Suche ein Nahrungsmittel, ein Rezept oder eine Aktivität zum Tagebuch hinzu. Dazu klicken Sie in das Suchfeld über dem Dashboard und suchen die einzelnen Nahrungsmittel oder Rezepte und fügen diese über “Hinzufügen” und “Speichern” zu Ihrem Tagebuch hinzu." />
              )}
            </PanelTextPicker>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default connector(NutritionsPage)
