import { AsyncAction, FluxStandardAction, ActionType as PromiseActionType } from 'redux-promise-middleware'

import { BackendError, ErrorState, ErrorType, ReduxStoreState } from 'Models'
import {
  HIDE_ACCESS_DENIED_MODAL,
  REMOVE_ERROR,
  REMOVE_ERROR_MESSAGES,
  getOriginalType,
  isFailure,
  isSuccess
} from 'ReduxStore/actionTypes'
import { createDeepEqualitySelector } from 'ReduxStore/common'
import { EbalanceBackendService } from 'Services'
import { getErrorMessagesFromBackendResponse } from 'Utils/helpers/errorMessages'

// Actions

export const addErrorAction = (error: Error): FluxStandardAction => ({
  type: `SOMETHING_CUSTOM_${PromiseActionType.Rejected}`,
  payload: error
})

export const removeErrorAction = (type: string): AsyncAction => ({
  type: REMOVE_ERROR,
  meta: { type: getOriginalType(type) }
})

export const removeErrorMessagesAction = (): AsyncAction => ({
  type: REMOVE_ERROR_MESSAGES
})

export const hideAccessDeniedModalAction = (): AsyncAction => ({
  type: HIDE_ACCESS_DENIED_MODAL
})

// Reducer and state
export const initialState: ErrorState = {
  messages: [],
  showAccessDeniedModal: false,
  list: {}
}

const ErrorReducer = (state = initialState, { type, payload, meta = {} }: FluxStandardAction): ErrorState => {
  const originalType = getOriginalType(type)
  const response = payload?.response || null

  // runs on any access permission (HTTP 403)
  if (response && response.status === 403) {
    return {
      ...state,
      showAccessDeniedModal: true
    }
  }

  // runs on any REJECTED action type
  if (isFailure(type)) {
    const error = payload as BackendError
    const messages =
      error.userErrorMessages || // first check for custom error messages provided by the backend services class
      EbalanceBackendService.getBackendErrorMessages(error) || // then check for common error messages that are services independent
      getErrorMessagesFromBackendResponse(response) // finally check default / fallback error handling

    return {
      ...state,
      messages,
      list: {
        ...state.list,
        [originalType]: {
          reduxType: type,
          response
        }
      }
    }
  }

  // runs on any FULFILLED action type
  // clears all errors related to the original action type
  if (isSuccess(type)) {
    const newList = { ...state.list }
    delete newList[originalType]

    return {
      ...state,
      ...(state.list[originalType] ? { list: newList } : {})
    }
  }

  switch (type) {
    case REMOVE_ERROR: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [meta.type]: _, ...list } = state.list
      return {
        ...state,
        messages: [],
        list
      }
    }
    case REMOVE_ERROR_MESSAGES:
      return {
        ...state,
        messages: []
      }
    case HIDE_ACCESS_DENIED_MODAL:
      return {
        ...state,
        showAccessDeniedModal: false
      }
    default:
      return state
  }
}

// Selector
export const errorSelector = ({ error }: ReduxStoreState): ErrorState => error

export const errorListSelector = createDeepEqualitySelector([errorSelector], ({ list }) => Object.values(list))

export const errorMessagesSelector = createDeepEqualitySelector([errorSelector], ({ messages }) => {
  return messages
})

export const showAccessDeniedModalSelector = createDeepEqualitySelector(
  [errorSelector],
  ({ showAccessDeniedModal }) => showAccessDeniedModal
)

export const actionTypeErrorSelector = ({ error }: ReduxStoreState, actionType: string): ErrorType =>
  error.list[actionType]

export const hasActionFailedSelector = ({ error }: ReduxStoreState, actionType: string): boolean =>
  Object.keys(error.list).some((type) => type.indexOf(actionType) !== -1)

export default ErrorReducer
