import React, { FC } from 'react'

import { FormInputText } from 'Components/FormInputText'

type IngredientSearchBoxProps = {
  refine: (text: string) => void
  onChange: (text: string) => void
  onFocus?: () => void
  onBlur?: () => void
}

export const IngredientSearchBox: FC<IngredientSearchBoxProps> = ({ refine, onChange, onBlur, onFocus }) => (
  <FormInputText
    label="Zutaten suchen"
    role="combobox"
    onBlur={onBlur}
    onFocus={onFocus}
    onChange={(text) => {
      refine(text)
      onChange(text)
    }}
  />
)
