import React, { FC } from 'react'

export type IngredientSearchHitProps = {
  title?: string
  foodId?: string
  checked?: boolean
  renderTitle?: () => JSX.Element
}

export const IngredientSearchHit: FC<IngredientSearchHitProps> = ({
  title,
  foodId,
  renderTitle,
  checked = false
}: IngredientSearchHitProps) => {
  return (
    <li role="option" data-food-id={foodId}>
      <span>{renderTitle?.() ?? title ?? 'n/a'}</span>
      <span className={checked ? 'icon-added' : 'icon-add'}></span>
    </li>
  )
}
