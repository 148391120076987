import React from 'react'

import { Notification } from 'Components'

interface Props {
  error?: Error
  message?: string
}

const ErrorBox: React.FC<Props> = ({ error, message }) => {
  return (
    <Notification theme="error">
      <strong>{message || (error && error.message)}</strong>
      {error ? <code>{JSON.stringify(error, null, 2)}</code> : null}
    </Notification>
  )
}

export default ErrorBox
