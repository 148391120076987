import { foodUnitIds } from 'Models'
import { yupNumberTransformer } from 'Utils'
import yup from 'yup-schema'

const ingredientSchema = yup.object({
  food: yup
    .object({
      id: yup.string().required()
    })
    .required(),
  amount: yup
    .object({
      quantity: yup.number().transform(yupNumberTransformer).required(),
      unit: yup.string().oneOf(foodUnitIds).required()
    })
    .required()
})

/*
 yup…min() & yup…required() evaluate differently. 
 yup…require() acts on `undefined` values while 
 yup…min() acts on array values, which might be empty. 
 To achieve consistent behavior from the user's perspective, 
 the custom error message needs to be passed to both schema definitions.
*/
const ingredientsErrorMessage = 'Es muss mindestens 1 Zutat vorhanden sein.'

export const recipeFormValidationSchema = yup.object({
  name: yup.string().required(),
  defaultServingsCount: yup.number().transform(yupNumberTransformer).required(),
  preparationTime: yup.number().transform(yupNumberTransformer).required(),
  source: yup
    .object({
      id: yup.string().nullable(),
      name: yup.string()
    })
    .optional(),
  hint: yup.string().nullable(),
  imageUrl: yup.string().nullable(),
  ingredients: yup.array().of(ingredientSchema).min(1, ingredientsErrorMessage).required(ingredientsErrorMessage),
  description: yup.string().nullable()
})
