import { ActionType, FluxStandardAction } from 'redux-promise-middleware'

import { findItemAndIndexForUpdateActionMetaData, nullableStateToArray } from './helpers'
import { HideActionCreator, createHidableReduxItems } from './hidable'
import { ToastMessageActionMetaData } from './toastMessage'
import { ReduxNullableArrayState } from './types'

import {
  EatableSourcePost,
  Hidable,
  Identifiable,
  NullableEatableSource,
  NullableResourceReduxState,
  PromiseAction,
  ReduxStoreState,
  ThunkAction
} from 'Models'
import { CoreBackendEatable } from 'Models/CoreBackendEatable'
import { ADD_EATABLE_SOURCES_FULFILLED } from 'ReduxStore/actionTypes'
import { coreBackendService } from 'Services'
import { Utils } from 'Utils'

// Types

export type ReduxEatable = Identifiable & Hidable

type UserEatableReduxState<E> = NullableResourceReduxState<E[]>

export type EatableSourcedChangeSet = {
  sourceId?: string | null
  source?: NullableEatableSource
}

export type UpdateUserEatableActionMetaData<
  E,
  C extends EatableSourcedChangeSet = EatableSourcedChangeSet
> = ToastMessageActionMetaData & {
  data: {
    id: string
  }
  itemBefore: E
  changeSet: C
}

// Helpers

export const getEatableSourceId = async (
  source: NullableEatableSource | null | undefined,
  dispatch: (action: FluxStandardAction) => void
): Promise<string | undefined> => {
  if (!source) return undefined
  if (source.id) return source.id
  if (!source.name) return undefined

  // when no id is present we assume facing a new entered eatable-source that needs to be created
  const newSource = await coreBackendService.addEatableSources(source as EatableSourcePost)

  // store the new source in redux
  dispatch({
    type: ADD_EATABLE_SOURCES_FULFILLED,
    payload: newSource
  })

  return newSource.id
}

export const getSourceIdFromEatableUpdate = <E extends CoreBackendEatable>(
  eatable: E,
  changeSet: EatableSourcedChangeSet
): string | null | undefined => {
  let sourceId: string | null | undefined = changeSet.sourceId ?? changeSet.source?.id ?? eatable.sourceId
  if ('source' in changeSet && changeSet.source === undefined) {
    sourceId = null
  }
  return sourceId
}

// Reducer & initialState

export type UserEatableReduxStateSelector<E extends ReduxEatable> = (state: ReduxStoreState) => UserEatableReduxState<E>
export type EatableActionType = 'FOOD' | 'RECIPE'
export type UserEatablesReducer<E extends ReduxEatable> = (
  state: ReduxNullableArrayState<E>,
  action: FluxStandardAction
) => ReduxNullableArrayState<E>

export const createUserEatablesReduxItems = <
  E extends ReduxEatable,
  A extends EatableSourcedChangeSet, // AddData
  C extends EatableSourcedChangeSet // ChangeSetData
>(
  eatableActionType: EatableActionType,
  initialState: ReduxNullableArrayState<E>,
  buildUpdatedItem: (item: E, changeSet: C) => E
): {
  actionTypes: { Add: string; FetchItem: string; FetchList: string }
  fetchListAction: <E extends ReduxEatable>(coreBackendFunction: () => Promise<E[]>) => PromiseAction<E[]>
  fetchItemAction: <E>(id: string, coreBackendFunction: (id: string) => Promise<E>) => PromiseAction<E>
  addAction: <E extends ReduxEatable>(
    data: A,
    coreBackendFunction: (data: Omit<A, 'source'>) => Promise<E>
  ) => ThunkAction
  updateAction: <E extends ReduxEatable>(
    id: string,
    changeSet: C,
    getEatableState: UserEatableReduxStateSelector<E>,
    coreBackendFunction: (data: Omit<C, 'source'> & Identifiable) => Promise<void | E>,
    changeSetFilter?: (changeSet: C, item: E) => C
  ) => ThunkAction
  hideAction: HideActionCreator<E>
  reducer: UserEatablesReducer<E>
  didLoadOnceSelector: (state: ReduxNullableArrayState<E>) => boolean
  itemsSelector: (state: ReduxNullableArrayState<E>) => E[]
} => {
  const { actionTypes: hidableActionTypes, hideAction, reducer: hidableReducer } = createHidableReduxItems<E>(
    eatableActionType
  )

  const actionTypes = {
    Add: `ADD_USER_${eatableActionType}`,
    FetchItem: `FETCH_USER_${eatableActionType}`,
    FetchList: `FETCH_USER_${eatableActionType}S`,
    Update: `UPDATE_USER_${eatableActionType}`,
    ...hidableActionTypes
  }

  // Action

  const fetchListAction = <E>(coreBackendFunction: () => Promise<E[]>): PromiseAction<E[]> => ({
    type: actionTypes.FetchList,
    payload: coreBackendFunction()
  })

  const fetchItemAction = <E>(id: string, coreBackendFunction: (id: string) => Promise<E>): PromiseAction<E> => ({
    type: actionTypes.FetchItem,
    payload: coreBackendFunction(id)
  })

  const addAction = <E extends ReduxEatable>(
    { source, ...data }: A,
    coreBackendFunction: (data: Omit<A, 'source'>) => Promise<E>
  ): ThunkAction => async (dispatch) => {
    const sourceId = await getEatableSourceId(source, dispatch)

    return dispatch({
      type: actionTypes.Add,
      payload: coreBackendFunction({ ...data, sourceId }),
      meta: { triggerDefaultSuccessToast: true }
    })
  }

  const updateAction = <E extends ReduxEatable>(
    id: string,
    changeSet: C,
    getEatableState: UserEatableReduxStateSelector<E>,
    coreBackendFunction: (data: Omit<C, 'source'> & Identifiable) => Promise<void | E>,
    changeSetFilter?: (changeSet: C, item: E) => C
  ): ThunkAction => async (dispatch, getState) => {
    const state = getState()
    const eatableState = getEatableState(state)
    const _meta = findItemAndIndexForUpdateActionMetaData(eatableState.data, id)
    if (!_meta) return

    let sourceId: string | undefined | null = await getEatableSourceId(changeSet.source, dispatch)
    if ('source' in changeSet && !sourceId) {
      sourceId = null
    }

    changeSet =
      changeSetFilter?.(changeSet, _meta.itemBefore) ??
      Utils.filterObjectChanges(changeSet, _meta.itemBefore, {
        filterArrayValues: false,
        filterNestedObjects: false
      })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { source: remove1, ...changeSetForCoreBackend } = changeSet
    // eslint-disable-next-line prefer-const, @typescript-eslint/no-unused-vars
    let { sourceId: remove2, ...changeSetForActionMetaData } = changeSet

    if (changeSetForActionMetaData?.source?.id === null && sourceId) {
      changeSetForActionMetaData = {
        ...changeSetForActionMetaData,
        source: {
          ...changeSetForActionMetaData.source,
          id: sourceId
        }
      }
    }

    const meta: UpdateUserEatableActionMetaData<E> = {
      ..._meta,
      changeSet: changeSetForActionMetaData as EatableSourcedChangeSet,
      triggerDefaultSuccessToast: true
    }

    return dispatch({
      type: actionTypes.Update,
      payload: coreBackendFunction({
        id,
        ...changeSetForCoreBackend,
        sourceId
      }),
      meta
    })
  }

  const reducer = (state: ReduxNullableArrayState<E>, action: FluxStandardAction): ReduxNullableArrayState<E> => {
    const { type, payload, meta } = action
    const eatables = nullableStateToArray(state)
    switch (type) {
      case `${actionTypes.Add}_${ActionType.Fulfilled}`: {
        const newEatable = payload as E
        return [...eatables, newEatable]
      }

      case `${actionTypes.FetchItem}_${ActionType.Fulfilled}`:
        return [...eatables, payload]

      case `${actionTypes.FetchList}_${ActionType.Fulfilled}`:
        return payload || []

      case `${actionTypes.Update}_${ActionType.Pending}`: {
        const {
          data: { id },
          changeSet
        } = meta as UpdateUserEatableActionMetaData<E, C>
        return eatables.map((e) => (e.id === id ? buildUpdatedItem(e, changeSet) : e))
      }

      case `${actionTypes.Update}_${ActionType.Fulfilled}`: {
        if (payload === undefined) {
          // update with Promise<void>, which happens on 204 responses from CoreBackend
          return state
        }
        const {
          data: { id }
        } = meta as UpdateUserEatableActionMetaData<E, C>
        const coreBackendItem = payload as E
        return eatables.map((e) => (e.id === id ? coreBackendItem : e))
      }

      case `${actionTypes.Update}_${ActionType.Rejected}`: {
        const { itemBefore } = meta as UpdateUserEatableActionMetaData<E>
        return eatables.map((e) => (e.id === itemBefore.id ? itemBefore : e))
      }

      default:
        return hidableReducer(state, action)
    }
  }

  return {
    actionTypes,
    fetchListAction,
    fetchItemAction,
    addAction,
    updateAction,
    hideAction,
    reducer,
    didLoadOnceSelector: (state) => state !== initialState,
    itemsSelector: (state) => state ?? []
  }
}
