import React, { FC, useMemo } from 'react'

import { FormInputIngredientsItem, IngredientAmountInput } from './IngredientAmountInput'

import { IngredientSearch, IngredientSearchProps } from 'Components/IngredientSearch'
import { AlgoliaFood, Amount } from 'Models'
import { Utils } from 'Utils'

type KeyedFormInputIngredientsItem = FormInputIngredientsItem & { key?: string }
export type FormInputIngredientsProps = Pick<IngredientSearchProps, 'algoliaSearchClient' | 'userFoods'> & {
  ingredients?: KeyedFormInputIngredientsItem[]
  errorText?: string
  onIngredientsChange?: (ingredients: FormInputIngredientsItem[]) => void
}

/**
 This is a controlled component.
 This means the state of of `ingredients` needs to be managed my this component's parent.
 */
export const FormInputIngredients: FC<FormInputIngredientsProps> = ({
  userFoods = [],
  ingredients = [],
  errorText,
  onIngredientsChange,
  ...ingredientSearchProps
}) => {
  const selectedFoodIds = useMemo(() => ingredients.map((ingredient) => ingredient.food.id), [ingredients])

  const handleAdd = (food: AlgoliaFood): void => {
    onIngredientsChange?.([
      ...ingredients,
      {
        food,
        amount: Utils.defaultEatableAmountForFood(food)
      }
    ])
  }

  const handleRemove = (ingredientKey?: string): void => {
    onIngredientsChange?.(ingredients.filter((ingredient) => ingredient.key !== ingredientKey))
  }

  const handleChangeAmount = (amount: Amount, ingredientKey?: string): void => {
    onIngredientsChange?.(
      ingredients.map((ingredient) => {
        if (ingredient.key === ingredientKey) {
          return { ...ingredient, amount }
        }
        return ingredient
      })
    )
  }

  const renderIngredients = (): JSX.Element | undefined => {
    if (ingredients.length === 0) return
    return (
      <div className="col-12">
        <ul className="inlinesearch-items">
          {ingredients.map((ingredient) => (
            <IngredientAmountInput
              formInputIngredientItem={ingredient}
              key={ingredient.key}
              amount={ingredient.amount}
              onRemoveClick={() => handleRemove(ingredient.key)}
              onAmountChange={(amount) => handleChangeAmount(amount, ingredient.key)}
            />
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <IngredientSearch
          {...ingredientSearchProps}
          selectedFoodIds={selectedFoodIds}
          onFoodClick={handleAdd}
          userFoods={userFoods}
        />
      </div>
      {renderIngredients()}
      {errorText && !ingredients.length && <div className="input-error">{errorText}</div>}
    </div>
  )
}
