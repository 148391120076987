import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { SeriesLineOptions } from 'highcharts'
import React, { FC, useState } from 'react'

import ChartTypeSwitch from '../ChartTypeSwitch'
import { calculateChartPointValue, calculateChartValues, createChartData, createChartTuple } from '../utils'

import { ChartLine, TextPicker } from 'Components'
import { getOrangeSeries } from 'Components/ChartLine/themes/exact'
import { colors } from 'Config'
import {
  BodyMeasurementPoint,
  ChartType,
  ChartTypesMap,
  Program,
  TimePeriod,
  allTimePeriods,
  timePeriodsMap
} from 'Models'

dayjs.extend(isBetween)

interface Props {
  bodyMeasurementsWeight: BodyMeasurementPoint[]
  programs: Program[] | null
  height: number
}

const programFilter = (timePeriod: TimePeriod) => (program: Program) => {
  if (timePeriod === '30DAYS') {
    return dayjs(program.startDate).isAfter(dayjs().add(-30, 'day'))
  }
  if (timePeriod === 'PROGRAMM') {
    return program.active
  }

  return true
}

const getSeries = (
  { bodyMeasurementsWeight, programs, height }: Props,
  timePeriod: TimePeriod,
  chartType: ChartType
): SeriesLineOptions[] => {
  const currentProgram =
    programs &&
    programs.find((program) => {
      return program.active
    })

  let filterStartDate = dayjs(0)
  let filterEndDate = dayjs()

  if (timePeriod === '30DAYS') {
    filterStartDate = dayjs().add(-30, 'day')
  }

  if (timePeriod === 'PROGRAMM' && currentProgram) {
    filterStartDate = dayjs(currentProgram.startDate)
    if (currentProgram?.goalDate) {
      filterEndDate = dayjs(currentProgram.goalDate)
    }
  }

  const filteredMeasurements = bodyMeasurementsWeight.filter((measurement) =>
    dayjs(measurement.datetime).isBetween(filterStartDate, filterEndDate, 'day', '[]')
  )

  const weightData = filteredMeasurements && createChartData(filteredMeasurements)
  let programsChartData: SeriesLineOptions[] = []
  if (programs) {
    programsChartData = programs
      .filter(programFilter(timePeriod))
      .map(({ startDate, startValue, goalDate, goalValue, terminationDate }, index) => {
        let chartGoalDate = goalDate
        let chartGoalValue = goalValue
        if (terminationDate && startValue && goalValue && startDate) {
          if (terminationDate) {
            chartGoalDate = terminationDate
          }
          if (goalDate) {
            chartGoalValue = Math.round(
              startValue +
                (dayjs(terminationDate).diff(startDate, 'day') * (goalValue - startValue)) /
                  dayjs(goalDate).diff(startDate, 'day')
            )
          }
        }

        return {
          ...getOrangeSeries(),
          name: 'Ziel',
          dashStyle: 'Dot',
          showInLegend: index === 0,
          data: [
            createChartTuple(startDate, calculateChartPointValue(chartType, chartGoalValue, height)),
            createChartTuple(chartGoalDate, calculateChartPointValue(chartType, chartGoalValue, height))
          ]
        }
      })
  }

  return [
    {
      name: ChartTypesMap[chartType],
      data: calculateChartValues(chartType, weightData, height)
    } as SeriesLineOptions,
    ...programsChartData,
    {
      // fake series for legend
      name: 'Programmneustart',
      color: colors.lineChart.programRestart,
      marker: {
        enabled: false
      },
      data: [],
      type: 'line'
    } as SeriesLineOptions
  ]
}

const WeightLineChart: FC<Props> = (props: Props) => {
  const [timePeriod, setTimePeriod] = useState<TimePeriod>('PROGRAMM')
  const [chartType, setChartType] = useState<ChartType>('WEIGHT')

  const programStartPlotLines = (props.programs || []).filter(programFilter(timePeriod)).map(({ startDate }) => {
    return {
      color: colors.lineChart.programRestart,
      width: 1,
      zIndex: 1,
      value: dayjs(startDate).startOf('day').valueOf()
    }
  })

  return (
    <div className="row justify-content-between">
      <div className="col-12">
        <h1 className="mt-6">Mein Verlauf</h1>
      </div>
      <div className="col-12 col-lg-auto">
        <ChartTypeSwitch selected={chartType} onChange={setChartType} />
      </div>
      <div className="col-12 col-lg-auto">
        <TextPicker
          items={allTimePeriods}
          selectedKey={timePeriod}
          onChange={(item) => setTimePeriod(item as TimePeriod)}
          labelExtractor={(item) => timePeriodsMap[item as TimePeriod]}
        />
      </div>
      <div className="col-12">
        <ChartLine
          dataType="datetime"
          series={getSeries(props, timePeriod, chartType)}
          xAxisPlotLines={programStartPlotLines}
          animated={false}
        />
      </div>
    </div>
  )
}

export default WeightLineChart
