import React, { FC, useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { CoreBackendUserReduxType, ReduxStoreState } from '../../Models'

import { FormInputRadio, FormInputToggle, PageLayout } from 'Components'
import { routes } from 'Config'
import { dietCriteriaOptions } from 'Pages/AnamnesisPage/steps/PersonalMenuPlanStep'
import {
  coreBackendUserSelector,
  fetchCoreBackendUserAction,
  updateCoreBackendUserAction
} from 'ReduxStore/coreBackendUser'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { updateUserSettingsAction } from 'ReduxStore/userSettings'
import { Diet } from 'yup-schema/Schemas/anamnesis'

type SelectorProps = {
  coreBackendUser: CoreBackendUserReduxType
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  coreBackendUser: coreBackendUserSelector
})

const mapDispatchToProps = {
  fetchCoreBackendUser: fetchCoreBackendUserAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const MenuPlanPage: FC<PropsFromRedux> = ({ fetchCoreBackendUser }: PropsFromRedux) => {
  const userSettings = useAppSelector((state) => state.userSettings)
  const userDiet = useAppSelector(coreBackendUserSelector)?.diet
  const dispatch = useAppDispatch()

  const toggleMenuPlanHidden = (): void => {
    dispatch(updateUserSettingsAction({ menuPlanHidden: !userSettings.data.menuPlanHidden }))
  }

  const handleDietSelection = (diet: Diet): void => {
    dispatch(updateCoreBackendUserAction({ diet }))
  }

  useEffect(() => {
    fetchCoreBackendUser()
  }, [])

  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <div className="mt-1">
          <h1>Menüplan Einstellungen</h1>
          <h4>Was essen Sie grundsätzlich - und was nicht?</h4>
          <div className="mt-4">
            <FormInputRadio
              name="diet"
              items={dietCriteriaOptions}
              onChange={(diet) => handleDietSelection(diet.value)}
              selectedKey={userDiet}
            />
          </div>
        </div>
        <div className="row mt-1">
          <h4>Menüplan anzeigen</h4>
          <div className={'flexColumn ml-6'}>
            <FormInputToggle
              name="menuPlanHidden"
              value={!userSettings.data.menuPlanHidden}
              onValueChange={toggleMenuPlanHidden}
            />
          </div>
        </div>
        <div className={'d-flex justify-content-center mt-6'}>
          <div className="col-md-6">
            <Link to={routes.profile.program}>
              <button type="submit" className={'btn is-small'}>
                {'Zurück'}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default connector(MenuPlanPage)
