import { AddFavoritesUnion, FavoritesMapping, FavoritesStateObjectsUnion, FavoritesUnion } from 'Models'
import { Formatter } from 'Utils'

export const getFavoritesMapping = (favorite: FavoritesUnion): FavoritesMapping => {
  if ('food' in favorite || 'foodId' in favorite) {
    return FavoritesMapping.FOODS
  } else if ('recipe' in favorite || 'recipeId' in favorite) {
    return FavoritesMapping.RECIPES
  } else {
    return FavoritesMapping.MOTIONS
  }
}

export const getAddToastMessage = (favorite: AddFavoritesUnion): string => {
  if ('foodId' in favorite || 'recipeId' in favorite) {
    return `Favorit gespeichert mit ${Formatter.formatFoodAmount(favorite.quantity, favorite.unit)}`
  } else {
    return `Favorit gespeichert mit ${Formatter.formatDuration(favorite.duration)}`
  }
}

export const getDeleteToastMessage = (favorite: FavoritesStateObjectsUnion): string => {
  let message: string
  if ('food' in favorite) {
    message = `Favorit ${favorite.food.name} entfernt`
  } else if ('recipe' in favorite) {
    message = `Favorit ${favorite.recipe.name} entfernt`
  } else {
    message = `Favorit ${favorite.motionLevel.motion.name} entfernt`
  }
  return message
}
