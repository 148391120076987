import { KeycloakInstance } from 'keycloak-js'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { isActiveCustomerSelector } from 'ReduxStore/keyCloakUser'

export const useKeycloakTokenRefresher = (keycloak: KeycloakInstance): boolean => {
  const isActiveCustomer = useSelector(isActiveCustomerSelector)
  const hasUserActiveCustomerRole = keycloak.hasRealmRole('ACTIVE_CUSTOMER')
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [counter, setCounter] = useState(0)

  const needsTokenUpdate = isActiveCustomer && !hasUserActiveCustomerRole

  // this effect will retry the token update every 2 seconds
  useEffect(() => {
    if (!needsTokenUpdate) return
    const timer = setTimeout(() => setCounter(counter + 1), 2000)
    return () => clearTimeout(timer)
  })

  useEffect(() => {
    if (!needsTokenUpdate) return
    if (isRefreshing) return
    console.log('Fetching new Keycloak Access-Token …')
    setIsRefreshing(true)
    keycloak.updateToken(-1).finally(() => setIsRefreshing(false))
  }, [needsTokenUpdate, counter])

  return isRefreshing
}
