import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ThunkAPI } from './types'

import { CustomerData, MembershipCustomerReduxState, ReduxStoreState } from 'Models'
import { memberShipService } from 'Services'
import { AvailableCountry } from 'enums/payment'

const initialState: MembershipCustomerReduxState = {
  id: '',
  userId: '',
  email: '',
  gender: null,
  firstName: '',
  lastName: '',
  street: '',
  house: '',
  city: '',
  zipcode: '',
  country: AvailableCountry.Schweiz
}

export const fetchCustomer = createAsyncThunk('memberships/fetchCustomer', async (_, { rejectWithValue }) => {
  try {
    const [customer] = await memberShipService.getCustomer()

    if (customer) {
      return {
        id: customer.id,
        street: customer.street,
        house: customer.house,
        city: customer.city,
        zipcode: customer.zipcode,
        country: customer.country,
        firstName: customer.firstName,
        lastName: customer.lastName,
        gender: customer.gender,
        email: customer.email,
        userId: customer.userId
      }
    }
    return initialState
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const patchCustomer = createAsyncThunk<CustomerData['customerPayload'], CustomerData, ThunkAPI>(
  'memberships/patchCustomer',
  async (newCustomerData: CustomerData, { fulfillWithValue, rejectWithValue }) => {
    try {
      await memberShipService.updateCustomer(newCustomerData)
      return fulfillWithValue(newCustomerData.customerPayload, { triggerDefaultSuccessToast: true })
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const customerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomer.fulfilled, (_, { payload }) => {
      return payload
    }),
      builder.addCase(patchCustomer.fulfilled, (state, { payload }) => {
        return { ...state, ...payload }
      })
  }
})

export const customerSelector = ({ customer }: ReduxStoreState): MembershipCustomerReduxState => {
  return customer
}

export default customerSlice.reducer
