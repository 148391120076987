import React, { FC, useEffect } from 'react'

import BodyAnalytics from './BodyAnalytics'
import Tables from './Tables'

import { getAge } from 'Config'
import { Program } from 'Models'
import { useFetchLatestAnamnesis } from 'Pages/AnamnesisPage/AnamnesisPage'
import { getAnamnesisDataSelector } from 'ReduxStore/anamnesis'
import { bodyAnalyticsSelector, fetchBodyAnalyticsAction } from 'ReduxStore/bodyAnalytics'
import { currentBodyMeasurementValuesSelector } from 'ReduxStore/bodyMeasurements'
import { isLoadingSelector } from 'ReduxStore/common'
import { userGoalNutritionsTodaySelector } from 'ReduxStore/coreBackendUser'
import { energyRequirementsSelector, fetchEnergyRequirementsAction } from 'ReduxStore/energyRequirements'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'

export type Props = {
  currentProgram?: Program | null
}

const InfoPanel: FC<Props> = ({ currentProgram }: Props) => {
  const dispatch = useAppDispatch()
  const goalNutritions = useAppSelector(userGoalNutritionsTodaySelector)
  const currentGoalEnergy = goalNutritions.energy ?? 0
  const bodyAnalytics = useAppSelector(bodyAnalyticsSelector)
  const bodyAnalyticsLoading = useAppSelector((state) => isLoadingSelector(state.bodyAnalytics))
  const energyRequirements = useAppSelector(energyRequirementsSelector)
  const anamnesis = useAppSelector(getAnamnesisDataSelector)
  const { height, currentWeight, currentBellyCircumference, startWeight, startBelly, programBelly } = useAppSelector(
    currentBodyMeasurementValuesSelector
  )

  useFetchLatestAnamnesis()

  useEffect(() => {
    if (anamnesis && currentWeight) {
      dispatch(
        fetchEnergyRequirementsAction({
          age: getAge(anamnesis.birthday),
          gender: anamnesis.gender,
          height,
          overweightForTwoYears: anamnesis.overweightForTwoYears,
          parentsOverweight: anamnesis.parentsOverweight,
          previousFailureCount: anamnesis.previousFailureCount,
          professionalMotionLevel: anamnesis.professionalMotionLevel,
          weight: currentWeight,
          weightToLose: anamnesis.weightToLose,
          workingHours: anamnesis.workingHours
        })
      )
    }
  }, [anamnesis])

  useEffect(() => {
    if (energyRequirements && currentProgram && currentWeight !== null && currentGoalEnergy > 0) {
      dispatch(
        fetchBodyAnalyticsAction({
          anamnesisId: currentProgram.anamnesisId,
          currentWeight: currentWeight,
          currentGoalEnergy,
          currentBellyCircumference,
          currentHeight: height,
          currentActiveMetabolicRate: energyRequirements.activeMetabolicRate
        })
      )
    }
  }, [currentProgram, currentWeight, currentBellyCircumference, currentGoalEnergy, energyRequirements])
  return (
    <div className="row">
      <div className="col-12">
        <h1 className="mt-6">Mein Gewichtsprofil</h1>
      </div>
      <div className="col-12 col-lg-6 order-lg-last">
        <Tables
          currentProgram={currentProgram}
          currentGoalEnergy={currentGoalEnergy}
          height={height}
          currentWeight={currentWeight}
          currentBellyCircumference={currentBellyCircumference}
          startWeight={startWeight}
          startBelly={startBelly}
          programBelly={programBelly}
        />
      </div>
      <div className="col-12 col-lg-6">
        <BodyAnalytics loading={bodyAnalyticsLoading} bodyAnalytics={bodyAnalytics} />
      </div>
    </div>
  )
}

export default InfoPanel
