export enum PlanPeriod {
  'DAY' = 'Tag',
  'WEEK' = 'Woche',
  'MONTH' = 'Monat',
  'YEAR' = 'Jahr',
  'HOUR' = 'Stunde',
  'MINUTE' = 'Minute',
  'SECOND' = 'Sekunde'
}

export type Plan = {
  id: number
  currency: string
  periodUnit: keyof typeof PlanPeriod
  price: number
  title: string
  url: string
}
