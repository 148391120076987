import React, { FC, useState } from 'react'

type Props = {
  onChangeText?: (text: string) => void
}

export const SearchBar: FC<Props> = ({ onChangeText }: Props) => {
  const [value, setValue] = useState('')

  function handleValueChange(newValue: string): void {
    onChangeText && onChangeText(newValue)
    setValue(newValue)
  }

  return (
    <>
      <div className="input">
        <input
          type="text"
          value={value}
          onChange={(e) => handleValueChange(e.target.value)}
          autoFocus
          placeholder=" "
        />
        <label className="text-gray-1">Jetzt suchen...</label>
      </div>
    </>
  )
}
