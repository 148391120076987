import _ from 'lodash'
import React, { FC, ReactNode } from 'react'

type ThemeType =
  | 'small'
  | 'tiny'
  | 'secondary'
  | 'secondary-small'
  | 'secondary-tiny'
  | 'outlined'
  | 'outlined-small'
  | 'outlined-tiny'

type Props = {
  children: ReactNode
  type?: 'button' | 'reset' | 'submit'
  theme?: ThemeType
  disabled?: boolean
  onClick?: () => void
  name?: string
  id?: string
}

export const Button: FC<Props> = ({ children, theme, type, disabled, onClick, name, id }: Props) => {
  const isSmall = theme === 'small' || theme === 'secondary-small'
  const isTiny = theme === 'tiny' || theme === 'secondary-tiny'
  const isSecondary = theme === 'secondary' || theme === 'secondary-small' || theme === 'secondary-tiny'
  const isOutlined = theme === 'outlined' || theme === 'outlined-small' || theme === 'outlined-tiny'

  const classNames = _.compact([
    'btn',
    name ? `btn-${name}` : undefined,
    isSmall ? 'is-small' : undefined,
    isTiny ? 'is-tiny' : undefined,
    isSecondary ? 'is-secondary' : undefined,
    isOutlined ? 'is-outlined' : undefined
  ]).join(' ')

  return (
    <button className={classNames} onClick={onClick} disabled={disabled} type={type} id={id}>
      {children}
    </button>
  )
}
