import React from 'react'

import { ResponsiveCardBase } from '../ResponsiveCardBase/ResponsiveCardBase'

import { CardIconButton } from 'Components/IconButton'
import { Formatter } from 'Utils'

export interface EnergyRecord {
  description: string
  energy: number
}

type Props = {
  energyRecord: EnergyRecord
  iconButtons: CardIconButton[]
  cardButtons: JSX.Element[]
}

export const JournalCardEnergy: React.FC<Props> = ({
  energyRecord: { description, energy },
  ...responsiveCardBaseProps
}: Props) => {
  return (
    <ResponsiveCardBase
      title={description}
      details={Formatter.formatEnergy(energy)}
      className="is-eatable"
      {...responsiveCardBaseProps}
    />
  )
}
