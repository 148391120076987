import React, { Fragment, ReactNode } from 'react'

import { Color, colors } from 'Config/colors'
import {
  ByIdMap,
  EnergyHistoryChartData,
  EnergyHistoryItem,
  MacronutrientSet,
  Nutritions,
  nutritionsConfig
} from 'Models'
import { Calculator, Formatter } from 'Utils/index'

export const mealConfig: { [x: string]: { color: Color; label: string } } = {
  BREAKFAST: {
    label: 'Frühstück',
    color: colors.pieChart.breakfast
  },
  LUNCH: {
    label: 'Mittagessen',
    color: colors.pieChart.lunch
  },
  DINNER: {
    label: 'Nachtessen',
    color: colors.pieChart.dinner
  },
  SNACK: {
    label: 'Snack',
    color: colors.pieChart.snack
  }
}

const WHITESPACE_FOR_ONE_LINE = '2rem'
const WHITESPACE_FOR_TWO_LINES = '3.45rem'

/**
 * Converts a MacronutrientSet object into a table-row array with formatted label and values
 * @param MacronutrientSet - input object
 * @param nutritionKeys - this defines both, which nutritions should be displayed and their order
 */
export const mergeMacronutrientSetsToTableArray = (
  { distribution, amount }: MacronutrientSet,
  nutritionKeys: Array<keyof Nutritions>
): ReactNode[][] =>
  nutritionKeys.map((key) => {
    if (key === 'carbohydrates') {
      return [
        <div key={key} className="table-nutrition-indicator" style={{ background: nutritionsConfig[key].color }} />,
        <Fragment key={key}>
          {nutritionsConfig[key].label}
          <span className="table-nutrition-fibre">{nutritionsConfig['sugar'].label}</span>
          <span className="table-nutrition-fibre">{nutritionsConfig['fibre'].label}</span>
        </Fragment>,
        <Fragment key={key}>
          {Formatter.formatPercentage(Number(((distribution as unknown) as ByIdMap<number>)[key]))}
          <span className="table-nutrition-fibre">&nbsp;</span>
        </Fragment>,
        <Fragment key={key}>
          {Formatter.formatNutritionGram(Number(((amount as unknown) as ByIdMap<number>)[key]))}
          <span className="table-nutrition-fibre" style={{ marginBottom: WHITESPACE_FOR_ONE_LINE }}>
            {Formatter.formatNutritionGram(Number(((amount as unknown) as ByIdMap<number>)['sugar']))}
          </span>
          <span className="table-nutrition-fibre">
            {Formatter.formatNutritionGram(Number(((amount as unknown) as ByIdMap<number>)['fibre']))}
          </span>
        </Fragment>
      ]
    }

    if (key === 'salt') {
      return [
        [],
        <span className="table-nutrition-fibre" key={key}>
          {nutritionsConfig[key].label}
        </span>,
        [],
        <span className="table-nutrition-fibre" key={key}>
          {Formatter.formatNutritionGram(Number(((amount as unknown) as ByIdMap<number>)[key]))}
        </span>
      ]
    }

    return [
      <div key={key} className="table-nutrition-indicator" style={{ background: nutritionsConfig[key].color }} />,
      nutritionsConfig[key].label,
      Formatter.formatPercentage(Number(((distribution as unknown) as ByIdMap<number>)[key])),
      Formatter.formatNutritionGram(Number(((amount as unknown) as ByIdMap<number>)[key]))
    ]
  })

export const displayNutritionsTable: Array<keyof Nutritions> = [
  'energy',
  'protein',
  'carbohydrates',
  'fat',
  'salt',
  'alcohol'
]

export const displayNutritionsChart: Array<keyof Nutritions> = ['protein', 'carbohydrates', 'fat', 'alcohol']

export const convertNutritionsToTableArray = (
  nutritionsPerWeight?: Nutritions,
  nutritionsPerPortion?: Nutritions
): ReactNode[][] =>
  displayNutritionsTable.map((key) => {
    if (key === 'carbohydrates') {
      const displayNutritionValuesPerWeight = (nutritionsPerWeight: Nutritions | undefined): JSX.Element[] => {
        if (nutritionsPerWeight) {
          return [
            <Fragment key={key}>
              {Formatter.formatNutritionGram(Number(nutritionsPerWeight[key]))}
              <span className="table-nutrition-fibre" style={{ marginBottom: WHITESPACE_FOR_TWO_LINES }}>
                {Formatter.formatNutritionGram(nutritionsPerWeight['sugar'])}
              </span>

              <span className="table-nutrition-fibre">
                {Formatter.formatNutritionGram(nutritionsPerWeight['fibre'])}
              </span>
            </Fragment>
          ]
        }
        return []
      }

      const displayNutritionValuesPerPortion = (nutritionsPerPortion: Nutritions | undefined): JSX.Element[] => {
        if (nutritionsPerPortion) {
          return [
            <Fragment key={key}>
              {Formatter.formatNutritionGram(Number(nutritionsPerPortion[key]))}
              <span className="table-nutrition-fibre" style={{ marginBottom: WHITESPACE_FOR_TWO_LINES }}>
                {Formatter.formatNutritionGram(nutritionsPerPortion['sugar'])}
              </span>

              <span className="table-nutrition-fibre">
                {Formatter.formatNutritionGram(nutritionsPerPortion['fibre'])}
              </span>
            </Fragment>
          ]
        }
        return []
      }

      return [
        <div key={key} className="table-nutrition-indicator" style={{ background: nutritionsConfig[key].color }} />,
        <Fragment key={key}>
          {nutritionsConfig[key].label}
          <span className="table-nutrition-fibre">{nutritionsConfig['sugar'].label}</span>
          <span className="table-nutrition-fibre">{nutritionsConfig['fibre'].label}</span>
        </Fragment>,
        ...displayNutritionValuesPerWeight(nutritionsPerWeight),
        ...displayNutritionValuesPerPortion(nutritionsPerPortion)
      ]
    }

    if (key === 'salt') {
      const displayNutritionValuesPerWeight = (nutritionsPerWeight: Nutritions | undefined): JSX.Element[] => {
        if (nutritionsPerWeight) {
          return [
            <span className="table-nutrition-fibre" key={key}>
              {Formatter.formatNutritionGram(nutritionsPerWeight[key])}
            </span>
          ]
        }
        return []
      }

      const displayNutritionValuesPerPortion = (nutritionsPerPortion: Nutritions | undefined): JSX.Element[] => {
        if (nutritionsPerPortion) {
          return [
            <span className="table-nutrition-fibre" key={key}>
              {Formatter.formatNutritionGram(nutritionsPerPortion[key])}
            </span>
          ]
        }
        return []
      }

      return [
        [],
        <span className="table-nutrition-fibre" key={key}>
          {nutritionsConfig[key].label}
        </span>,
        ...displayNutritionValuesPerWeight(nutritionsPerWeight),
        ...displayNutritionValuesPerPortion(nutritionsPerPortion)
      ]
    }

    const displayNutritionValuesPerWeight = (
      nutritionsPerWeight: Nutritions | undefined,
      key: keyof Nutritions
    ): Formatter[] => {
      if (nutritionsPerWeight) {
        return [
          key === 'energy'
            ? Formatter.formatEnergy(Number(nutritionsPerWeight[key]))
            : Formatter.formatNutritionGram(nutritionsPerWeight[key])
        ]
      }
      return []
    }

    const displayNutritionValuesPerPortion = (
      nutritionsPerPortion: Nutritions | undefined,
      key: keyof Nutritions
    ): Formatter[] => {
      if (nutritionsPerPortion) {
        return [
          key === 'energy'
            ? Formatter.formatEnergy(Number(nutritionsPerPortion[key]))
            : Formatter.formatNutritionGram(nutritionsPerPortion[key])
        ]
      }
      return []
    }

    return [
      <div key={key} className="table-nutrition-indicator" style={{ background: nutritionsConfig[key].color }} />,
      nutritionsConfig[key].label,
      ...displayNutritionValuesPerWeight(nutritionsPerWeight, key),
      ...displayNutritionValuesPerPortion(nutritionsPerPortion, key)
    ]
  })

const sortDateNumbers = ([dateA]: number[], [dateB]: number[]): number => dateA - dateB

/**
 * splits goalEnergy and acceptedEnergy from 1 dataset into 2 seperate arrays for highcharts series
 * @param data EnergyHistory
 */
export const createEnergyHistoryChartData = (data: EnergyHistoryItem[]): EnergyHistoryChartData => ({
  goal: data
    .map(({ date, goalEnergy }: EnergyHistoryItem) => [date.getTime(), Calculator.round(goalEnergy, 0)])
    .sort(sortDateNumbers),
  accepted: data
    .map(({ date, acceptedEnergy }: EnergyHistoryItem) => [date.getTime(), Calculator.round(acceptedEnergy, 0)])
    .sort(sortDateNumbers)
})
