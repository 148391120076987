import React, { FC } from 'react'

interface Props {
  title: string
  progressText: string
  progressAsPercentage: number
}

export const ProgressBar: FC<Props> = ({ title, progressText, progressAsPercentage }: Props) => {
  return (
    <div
      className={`
        progressbar
        ${progressAsPercentage > 100 ? 'is-over100' : ''}
      `}
    >
      {title} {progressText}
      <div className="progressbar-bar">
        <div className="progressbar-bar-percent" style={{ width: `${progressAsPercentage}%` }}></div>
      </div>
    </div>
  )
}
