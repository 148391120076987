import dayjs from 'dayjs'
import React from 'react'

interface Props {
  timestamp?: string
}

const StartTime: React.FC<Props> = ({ timestamp }: Props) => {
  const date = dayjs(timestamp)
  const formatted = date.isValid() ? date.format('LLLL') : 'n/a'

  return <span>{formatted}</span>
}

export default StartTime
