import EnergyPlanStep, { energyPlanConfig } from './EnergyPlanStep'
import ExperienceStep, { experienceConfig } from './ExperienceStep'
import MotionStep, { motionConfig } from './MotionStep'
import PersonalMenuPlanStep, { personalMenuPlanConfig } from './PersonalMenuPlanStep'
import ProfileStep, { profileConfig } from './ProfileStep'
import ProgramTypeStep, { programTypeConfig } from './ProgramTypeStep'

import { WizardStepType } from 'Components'
import { createStep } from 'Components/FormWizard'

export const firstAnamnesisSteps = (): WizardStepType[] => {
  return [
    createStep(ProgramTypeStep, programTypeConfig),
    createStep(ProfileStep, profileConfig({ isRestart: false })),
    createStep(MotionStep, motionConfig),
    createStep(ExperienceStep, experienceConfig),
    createStep(PersonalMenuPlanStep, personalMenuPlanConfig),
    createStep(EnergyPlanStep, energyPlanConfig)
  ]
}

export const restartAnamnesisSteps = (): WizardStepType[] => {
  return [
    createStep(ProfileStep, profileConfig({ isRestart: true })),
    createStep(MotionStep, motionConfig),
    createStep(ExperienceStep, experienceConfig),
    createStep(EnergyPlanStep, energyPlanConfig)
  ]
}
