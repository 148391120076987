import React, { FC, useEffect, useMemo } from 'react'
import { UseFormMethods, useWatch } from 'react-hook-form'

import { ImageUploadHook, ImageUploadHookProps } from './ImageUploadHook'

type Props = Pick<UseFormMethods, 'setValue' | 'setError' | 'control' | 'register' | 'errors'> &
  ImageUploadHookProps & {
    name: string
  }

export const ImageUploadController: FC<Props> = ({
  setValue,
  setError,
  register,
  errors,
  control,
  name,
  ...props
}: Props) => {
  useEffect(() => {
    register({ name })
  }, [])

  const value = useWatch({
    control,
    name,
    defaultValue: undefined
  })

  const errorText = useMemo(() => errors[name] && errors[name].message, [errors[name]])

  return (
    <ImageUploadHook
      defaultImageUrl={value}
      onUrlChange={(url) => setValue('imageUrl', url, { shouldValidate: true })}
      onError={(message) =>
        setError('imageUrl', {
          type: 'manual',
          message
        })
      }
      errorText={errorText}
      {...props}
    />
  )
}
