import React from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'

import { RecipesInspirationFilter } from './RecipesInspirationFilter'
import { RecipesInspirationResult } from './RecipesInspirationResult'

import { PageLayout } from 'Components'
import { AlgoliaIndexName, getIndexName, searchClient } from 'Utils/algoliaSearchHelpers'
import { ToggleModalProps, useToggleModal } from 'hooks/useToggleModal'

const RecipesInspirationPage: React.FC = () => {
  const { toggleModal, ...modalProps } = useToggleModal()
  return (
    <PageLayout theme="smallcircle">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3"></div>
          <h1 className="col-3 col-lg-9 mb-5">Rezepte</h1>
        </div>
        <Filter toggleMobileFilter={toggleModal} />
        <PageContent modalProps={modalProps} />
      </div>
    </PageLayout>
  )
}

const Filter: React.FC<{ toggleMobileFilter: () => void }> = ({ toggleMobileFilter }) => {
  return (
    <div className="d-lg-none filter-mobile-trigger filter-toggle mb-4" onClick={toggleMobileFilter}>
      <div className="icon icon-filter-gray-1"></div>
      <span>Filter öffnen</span>
    </div>
  )
}

const PageContent: React.FC<{ modalProps: ToggleModalProps }> = ({ modalProps }) => {
  return (
    <div className="row">
      <InstantSearch indexName={getIndexName(AlgoliaIndexName.RECIPES_INSPIRATION)} searchClient={searchClient}>
        <Configure hitsPerPage={100} />
        <RecipesInspirationFilter modalProps={modalProps} />
        <RecipesInspirationResult />
      </InstantSearch>
    </div>
  )
}

export default RecipesInspirationPage
