import { RefObject, useEffect, useRef, useState } from 'react'

type ToggleModalHookType = {
  ref: RefObject<HTMLDivElement>
  isModalOpen: boolean
  toggleModal: () => void
  closeModal: () => void
}

export type ToggleModalProps = {
  ref: RefObject<HTMLDivElement>
  isModalOpen: boolean
  closeModal: () => void
}

export const useToggleModal = (initialState = false): ToggleModalHookType => {
  const ref = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalFilterOpen] = useState(initialState)
  const toggleModal = (): void => setIsModalFilterOpen(!isModalOpen)
  const closeModal = (): void => setIsModalFilterOpen(false)

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      closeModal()
    }
    document.addEventListener('touchstart', listener)
    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('touchstart', listener)
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, closeModal])

  return {
    ref,
    isModalOpen,
    toggleModal,
    closeModal
  }
}
