const normalizeUmlaut = (name: string): string =>
  name.toUpperCase().replace(/Ä/g, 'A').replace(/Ü/g, 'U').replace(/Ö/g, 'O').replace(/[ÈÉ]/gi, 'E')

export const sortAlphabetically = (a: string, b: string): number => {
  return normalizeUmlaut(a).localeCompare(normalizeUmlaut(b))
}

export interface NormalizedName {
  normalizedName: string
}

export const addNormalizedNameProp = <T>(item: T, name: string): T & { normalizedName: string } => ({
  ...item,
  normalizedName: name.replace(/[^\wéèäüö]/gi, '').toUpperCase()
})

export const sortByNormalizedNameProp = <T extends NormalizedName>(items: T[]): T[] => {
  return items.sort((a, b) => sortAlphabetically(a.normalizedName, b.normalizedName))
}
