/* eslint-disable import/default */
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { combineReducers } from 'redux'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'
import { createSelector } from 'reselect'

import {
  FETCH_BODY_MEASUREMENTS,
  FETCH_BODY_MEASUREMENTS_FULFILLED,
  FETCH_BODY_MEASUREMENTS_REJECTED,
  FETCH_LATEST_BODY_MEASUREMENT,
  FETCH_LATEST_BODY_MEASUREMENT_FULFILLED,
  PATCH_BODY_MEASUREMENTS,
  PATCH_BODY_MEASUREMENTS_FULFILLED,
  POST_BODY_MEASUREMENTS,
  POST_BODY_MEASUREMENTS_FULFILLED
} from './actionTypes'
import { createPendingCounterReducer } from './common'
import { currentProgramSelector } from './programs'
import { ToastConfigReduxActions } from './toastMessage'

import {
  BodyCriterion,
  BodyMeasurementCriterionList,
  BodyMeasurementPoint,
  BodyMeasurementStore,
  BodyMeasurementsReduxState,
  ByIdMap,
  ReduxStoreState,
  ThunkAction
} from 'Models'
import { currentJournalDateSelector } from 'ReduxStore/journalDay'
import { coreBackendService } from 'Services'
import { Formatter, Utils } from 'Utils'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

// Helper functions
/**
 * Filters axios payload by bodyCriterion and sorts all BodyMeasurementPoint by date from latest to oldest
 * @param data axios payload from API
 * @param criterion
 */
const prepareBodyMeasurements = (
  data: BodyMeasurementPoint[],
  criterion: BodyCriterion
): BodyMeasurementCriterionList =>
  data
    .filter((b) => b.bodyCriterion === criterion)
    .sort((b1, b2) => b2.datetime.getTime() - b1.datetime.getTime())
    .reduce(
      (acc, val) => ({
        ...acc,
        [Formatter.formatIso8601Date(val.datetime)]: Utils.convertObjectDatestringToDate(val)
      }),
      {}
    )

const findEarliestBodyMeasurementPointValue = (data: BodyMeasurementPoint[]): number | null =>
  data.length ? data[data.length - 1].value : null

const findLatestBodyMeasurementPointValue = (data: BodyMeasurementPoint[]): number | null =>
  data.length ? data[0].value : null

// Selectors
export const bodyMeasurementsSelector = (state: ReduxStoreState): BodyMeasurementStore => state.bodyMeasurements.data

export const bodyMeasurementsWeightSelector = createSelector(
  [bodyMeasurementsSelector],
  ({ WEIGHT }) => Object.values(WEIGHT) as BodyMeasurementPoint[]
)

export const bodyMeasurementsHeightSelector = createSelector(
  [bodyMeasurementsSelector],
  ({ HEIGHT }) => Object.values(HEIGHT) as BodyMeasurementPoint[]
)

export const bodyMeasurementsBellySelector = createSelector(
  [bodyMeasurementsSelector],
  ({ BELLY }) => Object.values(BELLY) as BodyMeasurementPoint[]
)

export const bodyMeasurementCriterionSelector = (
  state: ReduxStoreState,
  criterion: BodyCriterion
): BodyMeasurementCriterionList => state.bodyMeasurements.data[criterion]

export const latestBodyMeasurementSelector = (
  { bodyMeasurements: { data } }: ReduxStoreState,
  criterion: BodyCriterion
): BodyMeasurementPoint | undefined =>
  Object.keys(data[criterion]).length ? Object.values(data[criterion])[0] : undefined

export const currentBodyMeasurementSelector = (
  { bodyMeasurements: { data } }: ReduxStoreState,
  criterion: BodyCriterion,
  date: string
): BodyMeasurementPoint | undefined => data[criterion][date]

export const currentWeightSelector = createSelector(
  [currentJournalDateSelector, bodyMeasurementsWeightSelector],
  (date, data) => {
    const weightsBodyMeasurments = data.filter((b) => dayjs(date).isSameOrAfter(b.datetime, 'day'))
    return weightsBodyMeasurments.length ? weightsBodyMeasurments[0].value : 0
  }
)

export const currentBodyMeasurementValuesSelector = createSelector(
  [
    currentProgramSelector,
    bodyMeasurementsWeightSelector,
    bodyMeasurementsBellySelector,
    bodyMeasurementsHeightSelector
  ],
  (currentProgram, weightMeasurementPoints, bellyMeasurementPoints, heightMeasurementPoints) => {
    if (!weightMeasurementPoints || !bellyMeasurementPoints || !heightMeasurementPoints) {
      return {}
    }

    const height = findLatestBodyMeasurementPointValue(heightMeasurementPoints)

    const latestWeightMeasurement = findLatestBodyMeasurementPointValue(weightMeasurementPoints)
    const earliestWeightMeasurement = findEarliestBodyMeasurementPointValue(weightMeasurementPoints)

    const latestBellyMeasurement = findLatestBodyMeasurementPointValue(bellyMeasurementPoints)
    const earliestBellyMeasurement = findEarliestBodyMeasurementPointValue(bellyMeasurementPoints)

    const programBellyMeasurement: BodyMeasurementPoint | undefined =
      currentProgram && currentProgram.startDate
        ? bellyMeasurementPoints
            .filter(
              ({ datetime }) =>
                datetime && currentProgram.startDate && dayjs(datetime).isSameOrBefore(currentProgram.startDate, 'day')
            )
            .shift()
        : undefined

    return {
      height,
      currentWeight: latestWeightMeasurement,
      currentBellyCircumference: latestBellyMeasurement,
      startWeight: earliestWeightMeasurement,
      startBelly: earliestBellyMeasurement,
      programBelly: programBellyMeasurement ? programBellyMeasurement.value : null
    } as ByIdMap<number>
  }
)

// Actions
export const fetchBodyMeasurementsAction = (bodyCriterion?: BodyCriterion): AsyncAction => ({
  type: FETCH_BODY_MEASUREMENTS,
  payload: coreBackendService.fetchBodyMeasurements({ bodyCriterion })
})

export const fetchLatestBodyMeasurementsAction = ({ type }: { type: BodyCriterion }): AsyncAction => ({
  type: FETCH_LATEST_BODY_MEASUREMENT,
  payload: coreBackendService.fetchLatestBodyMeasurement({ type })
})

export const createOrUpdateBodyMeasurementsAction = (
  { datetime, value, bodyCriterion }: BodyMeasurementPoint,
  { withDefaultSuccessToast, withCustomSuccessToast }: ToastConfigReduxActions
): ThunkAction => async (dispatch, getState) => {
  const { id } = currentBodyMeasurementSelector(getState(), bodyCriterion, Formatter.formatIso8601Date(datetime)) || {}
  if (id) {
    await dispatch({
      type: PATCH_BODY_MEASUREMENTS,
      payload: coreBackendService.updateBodyMeasurements({ id, value }),
      meta: {
        data: { id, datetime, value, bodyCriterion },
        triggerDefaultSuccessToast: withDefaultSuccessToast,
        customSuccessToastMessage: withCustomSuccessToast
      }
    })
  } else {
    await dispatch({
      type: POST_BODY_MEASUREMENTS,
      payload: coreBackendService.postBodyMeasurements({ datetime, value, bodyCriterion }),
      meta: {
        data: { bodyCriterion },
        triggerDefaultSuccessToast: withDefaultSuccessToast,
        customSuccessToastMessage: withCustomSuccessToast
      }
    })
  }
}

// Reducer and state
export const initialState = {
  WEIGHT: {},
  HEIGHT: {},
  BELLY: {}
}

const data = (
  state: BodyMeasurementStore = initialState,
  { type, payload, meta }: FluxStandardAction
): BodyMeasurementStore => {
  switch (type) {
    case FETCH_BODY_MEASUREMENTS_REJECTED:
      return initialState
    case FETCH_BODY_MEASUREMENTS_FULFILLED:
      return {
        ...state,
        WEIGHT: prepareBodyMeasurements(payload, 'WEIGHT'),
        HEIGHT: prepareBodyMeasurements(payload, 'HEIGHT'),
        BELLY: prepareBodyMeasurements(payload, 'BELLY')
      }
    case PATCH_BODY_MEASUREMENTS_FULFILLED:
      return {
        ...state,
        [meta.data.bodyCriterion]: {
          ...state[meta.data.bodyCriterion as BodyCriterion],
          [Formatter.formatIso8601Date(meta.data.datetime)]: meta.data
        }
      }
    case FETCH_LATEST_BODY_MEASUREMENT_FULFILLED:
    case POST_BODY_MEASUREMENTS_FULFILLED:
      return {
        ...state,
        [payload.bodyCriterion]: {
          [Formatter.formatIso8601Date(payload.datetime)]: payload,
          ...state[payload.bodyCriterion as BodyCriterion]
        }
      }
    default:
      return state
  }
}

const asyncActionTypes = [
  FETCH_BODY_MEASUREMENTS,
  FETCH_LATEST_BODY_MEASUREMENT,
  POST_BODY_MEASUREMENTS,
  PATCH_BODY_MEASUREMENTS
]

export default combineReducers<BodyMeasurementsReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
