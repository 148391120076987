/* eslint-disable react/display-name */
import React, { FC } from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight, InfiniteHits } from 'react-instantsearch-dom'

import { JournalCardSearchEatable } from '../JournalCardSearchEatable'
import { JournalCardSearchMotion } from '../JournalCardSearchMotion'

import { EatableType, MealCategory, Motion, MotionLevelObject, QuantifiedEatable } from 'Models'

type EatableHitComponentProps = {
  hit: QuantifiedEatable
}
interface SearchHitJournalProps {
  hitComponent: FC<EatableHitComponentProps> | FC<MotionHitProps>
  hits?: Hit[]
}

export const SearchJournalHit: FC<SearchHitJournalProps> = (props) => (
  <InfiniteHits
    {...props}
    translations={{
      loadMore: 'Mehr anzeigen …'
    }}
  />
)

type AddJournalEatableRecordHandler = (
  quantifiedEatable: QuantifiedEatable,
  mealCategory: MealCategory,
  date: Date,
  source: string
) => void

export type EatableProps = {
  eatableType: EatableType
  initialDate: Date
  addJournalEatableRecord: AddJournalEatableRecordHandler
  onSaveSearchResult: () => void
}

export const eatableHit = ({ eatableType, initialDate, addJournalEatableRecord, onSaveSearchResult }: EatableProps) => (
  props: EatableHitComponentProps
) => (
  <JournalCardSearchEatable
    initialDate={initialDate}
    quantifiedEatable={{ [eatableType.toLowerCase()]: props.hit }}
    renderTitle={() => <Highlight attribute="name" tagName="mark" hit={props.hit} />}
    onSave={(eatable, date, mealCategory) => {
      addJournalEatableRecord(eatable, mealCategory, date, 'search')
      onSaveSearchResult()
    }}
  />
)

type AddJournalMotionRecordHandler = (motion: Motion, duration: number, motionlevelId: string, date?: Date) => void

type MotionHitProps = {
  hit: Motion
}

type PropsMotion = {
  addJournalMotionRecord: AddJournalMotionRecordHandler
  weight: number
  date: Date
  onSaveSearchResult: () => void
}

export const motionHit = ({
  addJournalMotionRecord,
  weight,
  date,
  onSaveSearchResult
}: PropsMotion): FC<MotionHitProps> => (props: MotionHitProps) => (
  <JournalCardSearchMotion
    motion={props.hit}
    initialDate={date}
    weight={weight}
    renderTitle={() => <Highlight attribute="name" tagName="mark" hit={props.hit} />}
    onSave={(motion, duration, date, category) => {
      addJournalMotionRecord(motion, duration, (category || ({} as MotionLevelObject)).id, date)
      onSaveSearchResult()
    }}
  />
)
