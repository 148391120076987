import { SeriesColumnOptions, SeriesLineOptions } from 'highcharts'
import React, { FC, memo } from 'react'

import { ChartLine } from 'Components'
import { DebugPanel } from 'Components/Debug'
import { colors } from 'Config'
import { EnergyHistoryChartData } from 'Models'

interface Props {
  energyHistoryChartData: EnergyHistoryChartData
  programStartDates?: Array<{ value: number }>
}

const EnergyHistory: FC<Props> = ({ energyHistoryChartData, programStartDates }: Props) =>
  energyHistoryChartData.accepted &&
  energyHistoryChartData.accepted.length > 0 &&
  energyHistoryChartData.goal &&
  energyHistoryChartData.goal.length > 0 ? (
    <>
      <DebugPanel>
        <code>{JSON.stringify({ energyHistoryChartData, programStartDates }, null, 2)}</code>
      </DebugPanel>
      <div className="col-12">
        <ChartLine
          dataType="datetime"
          theme="exact"
          series={[
            {
              type: 'column',
              color: colors.lineChart.primaryColor,
              name: 'Kalorien',
              data: energyHistoryChartData.accepted
            } as SeriesColumnOptions,
            {
              type: 'line',
              color: colors.lineChart.goal,
              marker: {
                enabled: false
              },
              name: 'Ziel',
              data: energyHistoryChartData.goal
            } as SeriesLineOptions,
            ...([
              programStartDates
                ? {
                    // fake series for legend
                    type: 'line',
                    name: 'Programmstart',
                    color: colors.lineChart.programRestart,
                    marker: {
                      enabled: false,
                      fillColor: colors.lineChart.programRestart,
                      lineColor: colors.lineChart.programRestart
                    },
                    data: []
                  }
                : []
            ] as SeriesLineOptions[])
          ]}
          xAxisPlotLines={
            programStartDates &&
            programStartDates.map((program) => ({
              ...program,
              color: colors.lineChart.programRestart,
              width: 1
            }))
          }
        />
      </div>
    </>
  ) : null

export default memo(EnergyHistory)
