import React, { FC } from 'react'

import { LoadingIndicator, TextPicker } from 'Components'
import { SelectItemType } from 'Components/common/SelectPickerProps'

const renderDateRangeTranslations = (item: SelectItemType): string => {
  switch (item) {
    case '1DAY':
      return '1 Tag'
    case '1WEEK':
      return '1 Woche'
    case '2WEEKS':
      return '2 Wochen'
    case '1MONTH':
      return '1 Monat'
    case '1YEAR':
      return '1 Jahr'
    case 'REGISTRATION':
      return 'Registrierung'
    case 'PROGRAMSTART':
      return 'Programmstart'
    default:
      return 'n/a'
  }
}

interface Props {
  onChange: (item: SelectItemType) => void
  selectedKey?: number | string
  items: SelectItemType[]
  loading?: boolean
  title?: string
  additionalInfo?: string
}

const PanelTextPicker: FC<Props> = ({ onChange, items, selectedKey, loading, children, title, additionalInfo }) => (
  <>
    <div className="row justify-content-between">
      <div className="col-auto">
        <h1>{title}</h1>
        {additionalInfo && <div className="mt-1">{additionalInfo}</div>}
      </div>
      <div className="col-auto">
        <TextPicker
          onChange={onChange}
          items={items}
          selectedKey={selectedKey}
          labelExtractor={renderDateRangeTranslations}
          disabled={loading}
        />
      </div>
    </div>
    <div className="row mt-4">{children}</div>
    {loading && (
      <div className="panel-loading-overlay">
        <LoadingIndicator />
      </div>
    )}
  </>
)

export default PanelTextPicker
