/**
 * This redux resource manages the reset user button and its loading state.
 */
import { RESET_USER } from './actionTypes'
import { createPendingCounterReducer } from './common'

import { appConfig } from 'Config'
import { ReduxStoreState, ThunkAction } from 'Models'
import { UserDataImporterService } from 'Services'

export const resetUserAction = (): ThunkAction => async (dispatch, getState) => {
  const {
    keyCloakUser: { data: keyCloakUser }
  } = getState()

  const userDataImporter = new UserDataImporterService(
    { baseURL: appConfig.userDataImportService.baseUrl },
    keyCloakUser.id
  )

  return dispatch({
    type: RESET_USER,
    payload: userDataImporter.resetUser()
  })
}

export const isResetUserLoadingSelector = ({ resetUser: counter }: ReduxStoreState): boolean => counter > 0

export default createPendingCounterReducer(RESET_USER)
