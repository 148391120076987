import React, { FC } from 'react'

import { GaugeGoal } from 'Components'
import { BodyMeasurementPoint, Program } from 'Models'

interface Props {
  latestWeightBodyMeasurement?: BodyMeasurementPoint
  currentProgram?: Program | null
  shouldDisplayWeightGainWarning: boolean
}

const WeightGauge: FC<Props> = ({
  latestWeightBodyMeasurement,
  currentProgram,
  shouldDisplayWeightGainWarning
}: Props) => {
  const { value: currentWeight } = { ...latestWeightBodyMeasurement }
  const { startValue: startWeight, goalValue: goalWeight } = { ...currentProgram }

  return (
    <div className="row">
      <div className="col-12">
        <h1>Gewicht</h1>
      </div>
      <div className="col-12">
        <GaugeGoal
          initialWeight={startWeight || 0}
          currentWeight={currentWeight || 0}
          targetWeight={goalWeight || 0}
          shouldDisplayWeightGainWarning={shouldDisplayWeightGainWarning}
        />
      </div>
    </div>
  )
}

export default WeightGauge
