import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { LoadingIndicator, PageLayout } from 'Components'
import { ErrorMessage } from 'Pages/ErrorPage'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { isActiveCustomerSelector } from 'ReduxStore/keyCloakUser'
import { grantAccessSuccessPage } from 'ReduxStore/temporaryAccessSlice'
import { memberShipService } from 'Services'

const POLLING_INTERVAL_MILLISECONDS = 5000
const POLLING_MAX_DURATION_MILLISECONDS = 60000

let keycloakPoll: NodeJS.Timeout

const OrderRegistrationSuccessRedirectPage: React.FC = () => {
  const { search } = useLocation()
  const { keycloak } = useKeycloak()
  const dispatch = useAppDispatch()

  const contractId = useMemo(() => new URLSearchParams(search).get('contractId'), [])
  const [pollingCountMilliseconds, setPollingCountMilliseconds] = useState(0)
  const [pollingHasStarted, setPollingHasStarted] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const isActiveCustomer = useAppSelector(isActiveCustomerSelector)

  useEffect(() => {
    let isMounted = true
    const addOrder = async (): Promise<void> => {
      const statusCode = await memberShipService.addOrder(contractId || '')
      if (isMounted && statusCode === 204) {
        dispatch(grantAccessSuccessPage())
        keycloak.updateToken(9999999)
        setPollingHasStarted(true)
      }
    }

    if (contractId && !isActiveCustomer) {
      addOrder()
    }
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (pollingHasStarted) {
      keycloakPoll = setInterval(() => {
        if (pollingCountMilliseconds < POLLING_MAX_DURATION_MILLISECONDS) {
          setPollingCountMilliseconds((prevState) => prevState + POLLING_INTERVAL_MILLISECONDS)
          keycloak.updateToken(9999999)
        }
      }, POLLING_INTERVAL_MILLISECONDS)
    }

    return () => {
      clearInterval(keycloakPoll)
    }
  }, [pollingHasStarted])

  useEffect(() => {
    if (pollingCountMilliseconds >= POLLING_MAX_DURATION_MILLISECONDS) {
      clearInterval(keycloakPoll)
      setShowErrorMessage(true)
    }
  }, [pollingCountMilliseconds])

  return (
    <PageLayout>
      <div className="container max-700">{showErrorMessage ? <ErrorMessage /> : <LoadingIndicator />}</div>
    </PageLayout>
  )
}

export default OrderRegistrationSuccessRedirectPage
