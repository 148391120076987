import React, { FC, useState } from 'react'
import Modal from 'react-modal'

import { IconButton } from '../IconButton'

import { IconName } from 'Components/Icon'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

export const BellyInputTutorial: FC = () => {
  const [bellyModalOpen, setBellyModalOpen] = useState(false)

  const handleToggleBellyModal = (): void => setBellyModalOpen(!bellyModalOpen)

  return (
    <>
      <a className="d-block text-blue-2 text-small text-center" onClick={handleToggleBellyModal}>
        Hier erfahren Sie, wie Sie Ihren Bauchumfang messen.
      </a>
      <Modal
        style={modalZIndexPriority}
        isOpen={bellyModalOpen}
        onRequestClose={handleToggleBellyModal}
        ariaHideApp={false}
      >
        <div className="modal is-small">
          <IconButton name={IconName.close} onClick={handleToggleBellyModal} />
          <article>
            <h2>Bauchumfang messen</h2>
            <p>
              Einmal im Monat wird bei eBalance der Bauchumfang gemessen. Dies ist ein gutes Mass, um die Entwicklung
              der Gewichtsreduktion zu verfolgen. Denn die reine Zahl der (nicht) abgenommenen Kilo entspricht nicht
              immer dem persönlichen Erfolg. Wer zum Beispiel mit einem intensiven Sporttraining beginnt, beobachtet
              nicht selten, dass das Gewicht stagniert, die Kleidergrössen aber trotzdem schrumpfen. Somit ist das
              Massband manchmal der bessere Indikator als die Waage.
            </p>
            <p>Doch wie misst man richtig?</p>
            <ul className="text-list">
              <li>Ohne Bekleidung.</li>
              <li>Direkt über dem Bauchnabel. Das Massband verdeckt den Bauchnabel.</li>
              <li>Waagrecht rund um den Körper.</li>
              <li>Das Band sitzt locker angespannt, Bauch weder einziehen noch aktiv hängen lassen.</li>
              <li>
                Immer bei etwa gleichen Bedingungen messen, idealerweise morgens nach dem ersten Toilettengang und vor
                dem Frühstück. So haben kurzfristige Faktoren wie grössere Mahlzeiten oder Blähungen keinen Einfluss.
              </li>
            </ul>
          </article>
        </div>
      </Modal>
    </>
  )
}
