import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'

import styles from './JournalCardHydrationContainer.module.css'

import { Icon, IconColor, IconName } from 'Components'
import { UNITS } from 'Models'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { journalDateSelector } from 'ReduxStore/journalDay'
import {
  addJournalHydrationRecord,
  deleteJournalHydrationRecord,
  selectAmountDrunk,
  selectJournalHydrationGoal,
  selectJournalHydrationRecords
} from 'ReduxStore/journalHydrationRecordsSlice'

const MAX_AMOUNT_DISPLAYED = 5000
const DEFAULT_GLASS_SIZE = 250

const militliterToLiter = (amount: number): number => {
  if (UNITS.LITER.baseUnit) {
    return amount / UNITS.LITER.baseUnit.factor
  }
  return amount
}

const JournalCardHydrationContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const hydrationRecords = useAppSelector(selectJournalHydrationRecords)
  const hydrationGoal = useAppSelector(selectJournalHydrationGoal)
  const amountDrunk = useAppSelector(selectAmountDrunk)
  const displayedDay = useAppSelector(journalDateSelector).date

  const hydrationGoalGlassesAmount = useMemo(() => hydrationGoal / DEFAULT_GLASS_SIZE, [hydrationGoal])
  const shouldDisplayPlusGlass = (): boolean => {
    return hydrationGoal !== 0 && hydrationGoal <= amountDrunk && amountDrunk < MAX_AMOUNT_DISPLAYED
  }

  const hydrationRecordsAmountDrunk = useMemo(() => hydrationRecords.reduce((acc, cur) => acc + cur.amount, 0), [
    hydrationRecords
  ])

  const addGlass = (): void => {
    dispatch(addJournalHydrationRecord({ datetime: displayedDay, amount: DEFAULT_GLASS_SIZE, source: 'journal' }))
  }

  const isGoalReached = (index: number): boolean => {
    return index === hydrationGoalGlassesAmount - 1
  }

  const renderFullGlasses = (): JSX.Element[] => {
    return hydrationRecords.map((record, i) => {
      return (
        <span
          onClick={() => dispatch(deleteJournalHydrationRecord(record.id))}
          key={uniqueId()}
          className={'cursor pr-0 pl-1'}
        >
          <Icon name={IconName.waterfull} color={IconColor.BLUE_2} />
          {isGoalReached(i) && <div className={styles.checkmarkIcon}></div>}
        </span>
      )
    })
  }

  const renderEmptyGlasses = (): JSX.Element[] => {
    const amountEmptyGlasses = Math.max(hydrationGoalGlassesAmount - hydrationRecords.length, 0)
    return Array(amountEmptyGlasses)
      .fill(undefined)
      .map((_, i) => (
        <span
          {...(i === 0 && { onClick: addGlass })}
          key={uniqueId()}
          className={`${i === 0 ? 'cursor' : ''} pr-0 pl-1`}
        >
          <Icon name={IconName.waterempty} color={IconColor.GRAY_2} />
        </span>
      ))
  }

  const renderPlusGlass = (): JSX.Element => {
    return (
      <span onClick={addGlass} className={'cursor pr-0 pl-1'}>
        <Icon name={IconName.wateremptyplus} color={IconColor.GRAY_2} />
      </span>
    )
  }

  return (
    <div>
      <div className="standardcard-sectiontitle mt-3 mb-1">
        <span>Flüssigkeitszufuhr</span>
        <span>
          {militliterToLiter(hydrationRecordsAmountDrunk)} / {militliterToLiter(hydrationGoal)} Liter
        </span>
      </div>
      <div className="standardcard">
        <div className="standardcard-content">
          <span className="pt-1">Empfohlene Trinkmenge</span>
          <div className="row p-1">
            {renderFullGlasses()}
            {shouldDisplayPlusGlass() && renderPlusGlass()}
            {renderEmptyGlasses()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JournalCardHydrationContainer
