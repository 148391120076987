import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { isNull, isNullOrUndefined, isUndefined } from 'node-util'
import { parse } from 'tinyduration'

import {
  Amount,
  BaseIngredient,
  Food,
  JournalEatableContainerObject,
  JournalMotionRecord,
  Macronutrients,
  Nutritions,
  QuantifiedEatable,
  Recipe,
  UNITS,
  UnitId,
  UnitMapping
} from 'Models'
import { eatableType } from 'Utils/helpers/eatableType'

dayjs.extend(duration)

interface HasEnergy {
  energy: number
}

type NutritionKeyType = keyof Nutritions

export class Calculator {
  static sumNutritions(...nutritions: Nutritions[]): Nutritions {
    if (!nutritions || !nutritions.length) {
      return {} as Nutritions
    }

    return nutritions.reduce((pref, cur) => {
      if (!pref) return cur
      const result: Nutritions = {
        energy: 0,
        protein: 0,
        carbohydrates: 0,
        fat: 0,
        alcohol: 0,
        fibre: 0,
        salt: 0,
        sugar: 0
      }
      Object.keys(pref).forEach((key) => {
        result[key as NutritionKeyType] = (pref[key as NutritionKeyType] || 0) + (cur[key as NutritionKeyType] || 0)
      })
      return result
    })
  }

  static calculateEnergy(eatable: QuantifiedEatable): number | null {
    if (isNullOrUndefined(eatable.quantity) || isNaN(eatable.quantity) || eatable.quantity === 0) {
      return 0
    }

    const multipliedNutritions = this.calculateNutritions(eatable)
    if (multipliedNutritions === null) {
      return null
    }
    return (multipliedNutritions && multipliedNutritions.energy) || 0
  }

  /**
   * Calculates the nutritions of an QuantifiedEatable from the quantitiy + unit for it's food or recipes.
   * For example a recipe with 2 PORTION it returns double the nutritionsPerPortion of the recipe.
   * It handles conversion of foods amounts based on unit mappings if needed.
   * @param eatable
   */
  static calculateNutritions(eatable: QuantifiedEatable): Nutritions | null {
    const type = eatableType(eatable)
    if (!type) {
      return null
    }

    const unit = eatable.unit && UNITS[eatable.unit]
    if (unit && unit.baseUnit) {
      const nutritions = this.calculateNutritions({ ...eatable, unit: unit.baseUnit.id })
      if (isNull(nutritions)) {
        return null
      }
      return this.multiplyNutritions(nutritions, unit.baseUnit.factor)
    }

    if (isUndefined(eatable.quantity) || isNaN(eatable.quantity)) {
      return null
    }

    const baseNutritions: Nutritions | undefined =
      type === 'RECIPE' ? (eatable.recipe as Recipe).nutritionsPerPortion : (eatable.food as Food).nutritions
    if (!baseNutritions) return null

    let factor = 1

    switch (eatable.unit) {
      case 'PORTION':
        if (eatable.food && eatable.food.unitMappings) {
          const mappingPortion = eatable.food.unitMappings.find((mapping) => mapping.unit === 'PORTION')
          if (mappingPortion?.gramsPerUnit && eatable.food && eatable.food.nutritions) {
            factor = (mappingPortion.gramsPerUnit / 100) * eatable.quantity
            break
          }
        }
        factor = eatable.quantity
        break
      case 'GRAM':
        if (type === 'FOOD') {
          factor = eatable.quantity / 100
        } else if (eatable.recipe && !isUndefined(eatable.recipe.portionAmount)) {
          factor = eatable.quantity / eatable.recipe.portionAmount
        }
        break
      default: {
        const quantitiyInGram = this.foodAmountInGram(eatable)
        if (quantitiyInGram) {
          factor = quantitiyInGram / 100
        }
        break
      }
    }

    return this.multiplyNutritions(baseNutritions, factor)
  }

  /**
   * Calclates the distribution of nutritions based on the amount of energy each nutritions has.
   */
  static calculateEnergyBasedNutritionsDistribution(nutritions: Nutritions): Macronutrients {
    const carbohydrates = nutritions.carbohydrates * 4.1
    const protein = nutritions.protein * 4.1
    const fat = nutritions.fat * 9.1
    const alcohol = (nutritions.alcohol ?? 0) * 7.1

    const sumEnergy = carbohydrates + protein + fat + alcohol
    return {
      carbohydrates: this.calculatePercentage(carbohydrates, sumEnergy),
      fat: this.calculatePercentage(fat, sumEnergy),
      protein: this.calculatePercentage(protein, sumEnergy),
      alcohol: this.calculatePercentage(alcohol, sumEnergy),
      fibre: 0
    }
  }

  static calculateEatableWeight(eatable: QuantifiedEatable): Amount | null {
    let unitId: UnitId = 'GRAM'
    let quantity = 0
    const type = eatableType(eatable)
    if (!type) {
      return null
    }
    const unit = eatable.unit && UNITS[eatable.unit]
    if (isNullOrUndefined(eatable.quantity) || isNaN(eatable.quantity)) {
      return { quantity: 0, unit: unitId }
    }

    if (unit && unit.baseUnit) {
      const weight = this.calculateEatableWeight({ ...eatable, unit: unit.baseUnit.id })
      if (isNull(weight)) {
        return null
      }
      quantity = weight.quantity * unit.baseUnit.factor
      unitId = weight.unit
    }

    if (eatable.unit === unitId) {
      quantity = eatable.quantity
    } else if (
      type === 'RECIPE' &&
      eatable.unit === 'PORTION' &&
      eatable.recipe &&
      !isUndefined(eatable.recipe.portionAmount)
    ) {
      quantity = eatable.recipe.portionAmount * eatable.quantity
    } else if (type === 'FOOD') {
      const quantitiyInGram = this.foodAmountInGram(eatable)
      if (!isNull(quantitiyInGram)) {
        quantity = quantitiyInGram
      }
    }

    if (isNull(quantity) || isNaN(quantity)) {
      quantity = 0
    }

    return { quantity, unit: unitId }
  }

  static multiplyNutritions(nutritions: Nutritions, multiplier: number): Nutritions {
    const result = {} as Nutritions
    if (nutritions) {
      Object.keys(nutritions).forEach((key) => {
        result[key as NutritionKeyType] = (nutritions[key as NutritionKeyType] as number) * multiplier
      })
    }
    return result
  }

  static sumNutritionsOfQuantfiedEatables(...eatables: QuantifiedEatable[]): Nutritions {
    return this.sumNutritions(...eatables.map((e) => this.calculateNutritions(e) || ({} as Nutritions)))
  }

  static sumEnergy(energyRecords: HasEnergy[] | null): number {
    return (energyRecords || []).reduce((accumulator, current) => accumulator + current.energy, 0)
  }

  static sumEnergyFromEatableContainerObjects(container: JournalEatableContainerObject[]): number {
    return container
      .filter((card) => card.eatableCardObjects.length > 0)
      .reduce(
        (acc, val) => acc + (this.calculateEnergy(val.eatableCardObjects[val.eatableCardsActiveIndex].eatable) || 0),
        0
      )
  }

  /**
   * Calculates Nutrition percentage from fraction (current) of total Nutritions
   * @param fraction
   * @param total
   * @returns Nutritions - object as fraction percentage for each nutrition item (0-1)
   */
  static calculateNutritionPercentage(fraction: Nutritions, total: Nutritions): Nutritions {
    return Object.keys(total).reduce(
      (acc, key) => ({
        ...acc,
        [key as NutritionKeyType]: (fraction[key as NutritionKeyType] || 0) / (total[key as NutritionKeyType] || 0)
      }),
      {} as Nutritions
    )
  }

  /**
   * Finds the unit mapping for the used unit of a quantified food.
   * @param eatable
   */
  static findFoodUnitsMappings(eatable: QuantifiedEatable): UnitMapping | null {
    const food = eatable.food
    if (!food) return null
    return (food.unitMappings || []).find((mapping: UnitMapping) => mapping.unit === eatable.unit) || null
  }

  /**
   * Returns how much the amount of a quanfiied food is in GRAM.
   * @param eatable
   * @returns either the amount as number in GRAM or `null` if unit mappings to convert it into GRAM is lacking
   */
  static foodAmountInGram(eatable: QuantifiedEatable): number | null {
    const food = eatable.food
    if (!food) return null
    if (isUndefined(eatable.quantity)) return null
    if (eatable.quantity === 0) return 0
    if (eatable.unit === 'GRAM') return eatable.quantity

    const mapping = this.findFoodUnitsMappings(eatable)
    if (!mapping) return null

    return eatable.quantity * (mapping.gramsPerUnit || 0)
  }

  /**
   * Calculate the BMI from a given weight (in kg) and height in (cm)
   * @param weight in kg
   * @param height in cm
   */
  static calculateBMI(weight: number | null, height: number | null): number {
    return (weight || 0) / ((height || 0) / 100) ** 2
  }

  static isUserUnderweight(weight: number | null, height: number | null): boolean {
    if (weight === null || height === null) {
      return false
    }

    const MINIMUM_HEALTHY_BMI = 18.5
    return this.calculateBMI(weight, height) < MINIMUM_HEALTHY_BMI
  }

  static isUserLowWeight(weight: number | null, height: number | null): boolean {
    if (weight === null || height === null) {
      return false
    }

    const MINIMUM_MODERATE_BMI = 20
    return this.calculateBMI(weight, height) < MINIMUM_MODERATE_BMI
  }

  static isUserOverweight(weight: number | null, height: number | null): boolean {
    if (weight === null || height === null) {
      return false
    }

    const MAXIMUM_ALLOWED_BMI = 40
    return this.calculateBMI(weight, height) >= MAXIMUM_ALLOWED_BMI
  }

  static isUserNormalWeight(weight: number | null, height: number | null): boolean {
    return !this.isUserUnderweight(weight, height) && !this.isUserOverweight(weight, height)
  }

  /**
   * Calculate the energy a user burns doing a motion for a certain amount of time
   * @param metFactor metabolic equivalent task (MET) factor of the motion
   * @param weight weight of the user kg
   * @param duration duration in seconds the user performed the motion
   * @returns energy in kcal (rounded to the nearest integer)
   */
  static calculateMotionEnergy(metFactor: number, weight: number, duration: number): number {
    const durationInMinutes = duration / 60
    return (metFactor * weight * durationInMinutes) / 60
  }

  /**
   * Calculate the energy a user burned during a tracked motion
   * @param record
   * @param weight weight of the user in kg
   * @returns energy in kcal (rounded to the nearest integer)
   */
  static calculateJournalMotionRecordEnergy(record: JournalMotionRecord, weight: number): number {
    const level = record.motion.levels.filter((l) => l.id === record.motionLevelId)[0]
    if (!level) return 0
    return this.calculateMotionEnergy(level.metFactor, weight, record.duration)
  }

  /**
   * Calculate the quantity of a recipe ingredient for the specified servings count
   * @param recipe recipe to which the ingredient belongs
   * @param ingredient the ingredient definition in the recipe
   * @param servingsCount count of servings for which to calculate the quatity
   */
  static calculateRecipeIngredientQty(recipe: Recipe, ingredient: BaseIngredient, servingsCount = 1): number {
    return (ingredient.quantity * servingsCount) / (recipe.defaultServingsCount || 1)
  }

  /**
   * Round a decimal to a certain number of decimal places
   * @param val Number to be rounded
   * @param fractionDigits Number of decimal places
   *
   * @returns Rounded Number
   */
  static round(val: number, fractionDigits = 1): number {
    if (fractionDigits < 0) throw new Error(`negative fraction: ${fractionDigits}`)

    const factor = Math.pow(10, fractionDigits)
    return val ? Math.round((val + Number.EPSILON) * factor) / factor : 0
  }

  /**
   * Calculates simple percentage of ValueA from ValueB
   * @param valueA
   * @param valueB
   * @returns number percentage as fractions between 0-1 (also above 1)
   */
  static calculatePercentage(valueA = 0, valueB = 0): number {
    if (!valueA || !valueB) return 0
    return valueA / valueB
  }

  /**
   * Rounds a decimal number to the nearest decimal step (defined by step parameter)
   * @param input - number to be calculated
   * @param step
   */
  static roundNumberStep(input: number, step = 0.5): number {
    const inv = 1 / step
    return this.round(input * inv, 0) / inv
  }

  /**
   * Returns the price per month for the given duration.
   *
   * The duration must be at least 1 day, otherwise zero will be returned.
   *
   * @param isoDuration ISO 8601 duration format as string
   * @param price the price for the entire specified duration
   * @returns the price per month for valid inputs, else zero
   */
  static calculatePricePerMonth(isoDuration?: string | null, price?: number | null): number {
    if (!isoDuration || !price) return 0
    const durationComponents = parse(isoDuration)
    let durationInMonth = durationComponents.months || 0
    durationInMonth += (durationComponents.years || 0) * 12
    durationInMonth += durationComponents.weeks ? dayjs.duration(durationComponents.weeks, 'weeks').asMonths() : 0
    durationInMonth += durationComponents.days ? dayjs.duration(durationComponents.days, 'days').asMonths() : 0
    // we ignore hours, minutes and seconds
    if (!durationInMonth) return 0
    return price / durationInMonth
  }
}
