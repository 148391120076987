import React, { useCallback, useEffect, useState } from 'react'

type Props = {
  changeCurrentValue: () => void
  disabled?: boolean
}

export const StepperButton: React.FC<Props> = ({ changeCurrentValue, disabled }: Props) => {
  const [holding, setHolding] = useState(false)
  const [repeatPeriod, setRepeatPeriod] = useState(500)

  useEffect(() => {
    if (holding) {
      changeCurrentValue && changeCurrentValue()

      setTimeout(() => {
        setRepeatPeriod(0.8 * repeatPeriod)
      }, repeatPeriod)
    }
  }, [holding, repeatPeriod])

  const handleMouseDown = useCallback(() => {
    setHolding(true)
    setRepeatPeriod(500)
  }, [])

  const handleMouseUp = useCallback(() => {
    setHolding(false)
  }, [])

  return <a className={`${disabled ? 'is-disabled' : ''}`} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}></a>
}
