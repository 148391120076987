import { createSelector } from 'reselect'

import { isKeycloakProfileLoadingSelector } from 'ReduxStore/coreBackendUser'
import { journalEatableRecordsSelector } from 'ReduxStore/journalEatableRecords'
import { journalEnergyRecordsSelector } from 'ReduxStore/journalEnergyRecords'
import { journalMotionRecordsSelector } from 'ReduxStore/journalMotionRecords'
import { hasProspectiveCustomerRoleSelector } from 'ReduxStore/keyCloakUser'
import { isResetUserLoadingSelector } from 'ReduxStore/resetUser'

export const dayJournalCardsCombinedLoadingSelector = createSelector(
  [journalEatableRecordsSelector, journalEnergyRecordsSelector, journalMotionRecordsSelector],
  (journalEatableRecords, journalEnergyRecords, journalMotionRecords) =>
    journalEatableRecords.pendingCounter + journalEnergyRecords.pendingCounter + journalMotionRecords.pendingCounter > 0
)

export const isUserLoadingSelector = createSelector(
  [isKeycloakProfileLoadingSelector, isResetUserLoadingSelector],
  (isKeycloakProfileLoading, isResetUserLoading) => isKeycloakProfileLoading || isResetUserLoading
)

export const isProspectiveCustomerSelector = hasProspectiveCustomerRoleSelector
