import React, { FC } from 'react'

import { RecipeForm, RecipeFormProps } from './RecipeForm'

import { PageLayout } from 'Components'

type CreateRecipePageProps = RecipeFormProps

export const CreateRecipePage: FC<CreateRecipePageProps> = ({ ...recipeFormProps }) => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <h1>Rezept erstellen</h1>
        <p>
          Hier können Sie Ihr eigenes Rezept hinterlegen. Füllen Sie einfach die Felder mit den Informationen zu Ihrem
          Rezept aus und klicken Sie anschliessend auf «SPEICHERN». Nun können Sie das neue Rezept über die Suche Ihrem
          Tagebuch hinzufügen.
        </p>
        <RecipeForm {...recipeFormProps} />
      </div>
    </PageLayout>
  )
}
