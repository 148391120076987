import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { UseFormMethods } from 'react-hook-form'

import styles from './FormInputRange.module.css'

const rangeToArray = (start: number, end: number): number[] => Array.from({ length: end - start + 1 }, (_, i) => i)

export type FormInputRangeProps = Partial<Pick<UseFormMethods, 'register' | 'errors'>> & {
  name?: string
  minimumValue: number
  maximumValue: number
  defaultValue?: number
  renderValue?: (value?: number) => ReactNode
  onChange?: (value: number) => void
  errorText?: string
  isDisabled?: boolean
  inputStep?: string
}

export const FormInputRange: FC<FormInputRangeProps> = ({
  name,
  minimumValue: min,
  maximumValue: max,
  defaultValue,
  inputStep = '1',
  renderValue = (val) => `${val}`,
  onChange,
  errorText,
  register,
  isDisabled = false,
  errors = []
}: FormInputRangeProps) => {
  const [value, setValue] = useState(() => defaultValue || min)

  const handleChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(Number(value))
      setValue(Number(value))
    },
    [onChange]
  )
  const stepsArray = useMemo(() => rangeToArray(min, max), [min, max])

  const rangeSteps = useCallback(
    () => (
      <>
        {stepsArray.map((step) => {
          const isTenthStep = step % 10 === 0
          if (stepsArray.length >= 100 && !isTenthStep) return
          return <div className={`range-step ${isDisabled ? styles.grayBackground : ''}`} key={step} />
        })}
        <div className={`range-step ${isDisabled ? styles.grayBackground : ''}`} key={stepsArray.length} />
      </>
    ),
    [stepsArray, min, max]
  )

  const rangeClipStyle = useMemo(
    () => ({
      clipPath: `inset(0 -5px 0 calc((100% / calc(${max} - ${min})) * calc(${value} - ${min})))`
    }),
    [value, min, max]
  )

  const rangeMarkerStyle = useMemo(() => ({ left: `calc((100% / calc(${max} - ${min})) * calc(${value} - ${min}))` }), [
    value,
    min,
    max
  ])

  const errorMessage = useMemo(() => (name && errors[name] ? errors[name].message : '') || errorText, [
    name,
    errors,
    errorText
  ])

  const renderGrayInputRange = (): JSX.Element => {
    return (
      <>
        <div className="range-ui">
          {rangeSteps()}
          <div className={`range-track ${styles.grayBackground}`}></div>
        </div>
        <div className="range-ui is-empty" style={rangeClipStyle}>
          {rangeSteps()}
          <div className={`range-track ${styles.grayBackground}`}></div>
        </div>
        <div style={rangeMarkerStyle} className={`range-marker ${styles.grayBoxShadow}`}>
          {renderValue && <span className={styles.grayTextColor}>{renderValue(value)}</span>}
        </div>
      </>
    )
  }

  const renderBlueInputRange = (): JSX.Element => {
    return (
      <>
        <div className="range-ui">
          {rangeSteps()}
          <div className="range-track"></div>
        </div>
        <div className="range-ui is-empty" style={rangeClipStyle}>
          {rangeSteps()}
          <div className="range-track"></div>
        </div>
        <div style={rangeMarkerStyle} className="range-marker">
          {renderValue && <span>{renderValue(value)}</span>}
        </div>
      </>
    )
  }

  return (
    <div>
      <div className="range">
        {isDisabled ? renderGrayInputRange() : renderBlueInputRange()}
        <input
          name={name}
          value={value}
          ref={register}
          min={min}
          max={max}
          disabled={isDisabled}
          onChange={handleChange}
          className="range-input"
          type="range"
          step={inputStep}
        />
      </div>

      <div className="input-error mt-3">{errorMessage}</div>
    </div>
  )
}
