import React, { FC, useMemo } from 'react'

import { IconButton } from '../../IconButton'
import { FavoriteIcon } from '../FavoriteIcon'

import { IconColor, IconName } from 'Components/Icon'
import { EatableSource, ImageUrl } from 'Models'
import { Formatter } from 'Utils'

interface Props {
  imageUrl?: ImageUrl
  energy: number
  isFavorite?: boolean
  source?: EatableSource | null
  portionAmount?: number
  onFavoriteChange?: (value: boolean) => void
  onClose?: () => void
  extension: string
}

const DetailCardHeader: FC<Props> = ({
  energy,
  imageUrl,
  isFavorite = false,
  onClose,
  onFavoriteChange,
  source,
  portionAmount,
  extension
}: Props) => {
  const portionLabel = useMemo(() => {
    return portionAmount ? `${Formatter.formatFoodAmount(portionAmount, 'GRAM')} ${extension}` : ''
  }, [portionAmount])

  const energyLabel = useMemo(() => {
    return `${Formatter.formatEnergy(energy)} ${extension}`
  }, [energy])

  return (
    <>
      <div className="detailcard-img">
        {imageUrl && <img src={imageUrl} />}
        <div className="detailcard-img-kcal">
          {energyLabel}
          <br />
          {portionLabel}
        </div>
      </div>
      <FavoriteIcon isFavorite={isFavorite} onChange={onFavoriteChange} />
      <IconButton name={IconName.close} color={IconColor.WHITE} onClick={onClose} />
      {source && source.imageUrl && (
        <div className="detailcard-logo">
          <img src={source.imageUrl} />
        </div>
      )}
    </>
  )
}

export default DetailCardHeader
