import React, { FC } from 'react'
import { ConnectedProps, connect } from 'react-redux'

import { useManageEatable } from './useManageEatable'

import { CardFood } from 'Components/CardFood/CardFood'
import { deleteButton, editButton, favoriteButton } from 'Components/IconButton/iconButtons'
import { routes } from 'Config'
import { FavoritesMapping, Food } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAddOrRemoveFavorite } from 'ReduxStore/favorites/useAddOrRemoveFavorite'
import { useAppSelector } from 'ReduxStore/hooks'
import { hideUserFood } from 'ReduxStore/userFoods'
import { Utils } from 'Utils'

const mapDispatchToProps = {
  hideItem: hideUserFood
}

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type FoodCardContainerProps = PropsFromRedux & {
  food: Food
  onClick: () => void
}

const FoodCardContainer: FC<FoodCardContainerProps> = ({ food, onClick, hideItem }: FoodCardContainerProps) => {
  const { id: itemId } = food
  const favoritesFood = useAppSelector(favoriteSelector(FavoritesMapping.FOODS, food.id))
  const { onFavoriteChange } = useAddOrRemoveFavorite()

  const { handleOnEdit, handleOnDelete } = useManageEatable({
    itemId,
    editRoute: routes.myEatables.myFoodEdit,
    hideItem
  })

  const handleFavorite = (): void => {
    const { quantity, unit } = Utils.defaultEatableAmountForFood(food)
    onFavoriteChange(favoritesFood, {
      quantity,
      unit,
      foodId: food.id
    })
  }

  return (
    <CardFood
      food={food}
      onClick={onClick}
      cardButtons={[]}
      iconButtons={[
        editButton(handleOnEdit),
        favoriteButton(handleFavorite, !!favoritesFood),
        deleteButton(handleOnDelete)
      ]}
    />
  )
}

export default connector(FoodCardContainer)
