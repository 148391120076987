import React, { FC, ReactNode, memo, useMemo } from 'react'

import { ChartPie, Table } from 'Components'
import { DebugPanel } from 'Components/Debug'
import { ByIdMap, EnergyDistributionType, allMealCategories } from 'Models'
import { Formatter, Utils } from 'Utils'
import { mealConfig } from 'Utils/helpers/nutritionHelper'

interface Props {
  energyDistribution?: EnergyDistributionType
}

const mealsTableHeader = ['', 'Mahlzeiten', 'Verteilung']

const EnergyDistribution: FC<Props> = ({ energyDistribution }: Props) => {
  const rows: ReactNode[][] = useMemo(
    () =>
      energyDistribution
        ? allMealCategories.map((category) => [
            <div
              key={category}
              className="table-nutrition-indicator"
              style={{ background: mealConfig[category].color }}
            />,
            mealConfig[category].label,
            Formatter.formatPercentage(Number((energyDistribution as ByIdMap<number>)[category]))
          ])
        : [],
    [energyDistribution]
  )

  const { data: energyDistributionPieChartData, colors: mealColors } = useMemo(
    () => Utils.convertObjectToPieChartArrays(energyDistribution, mealConfig, allMealCategories),
    [energyDistribution]
  )

  return (
    <>
      <DebugPanel>
        <code>{JSON.stringify({ energyDistribution, energyDistributionPieChartData }, null, 2)}</code>
      </DebugPanel>
      <div className="col-12 col-lg-6">
        <ChartPie data={energyDistributionPieChartData} colors={mealColors} />
      </div>
      <div className="col-12 col-lg-6">
        <Table theme="nutrition" titleRow={mealsTableHeader} rows={rows} />
      </div>
    </>
  )
}

export default memo(EnergyDistribution)
