import React, { Fragment, useCallback } from 'react'
import { Highlight } from 'react-instantsearch-dom'

import { Stats, UserEatableSearchHit } from './searchHelpers'

import { JournalCardSearchEatable } from 'Components/JournalCardSearchEatable'
import { EatableType, Food, Identifiable, MealCategory, QuantifiedEatable, Recipe } from 'Models'
import { usePagination } from 'Utils/helpers/usePagination'

type AddJournalEatableRecordHandler = (
  quantifiedEatable: QuantifiedEatable,
  mealCategory: MealCategory,
  date: Date,
  source: string
) => void

type SearchHitsUserEatablesProps<H extends Identifiable & (Food | Recipe)> = {
  headline: string
  hits: UserEatableSearchHit<H>[]
  eatableType: EatableType
  initialDate: Date
  addJournalEatableRecord: AddJournalEatableRecordHandler
  onSaveSearchResult: () => void
}

export function SearchHitsUserEatables<E extends Identifiable & (Food | Recipe)>({
  headline,
  hits,
  eatableType,
  initialDate,
  addJournalEatableRecord,
  onSaveSearchResult
}: SearchHitsUserEatablesProps<E>): JSX.Element {
  const { currentItems, loadMoreItems, currentPage, maxPage } = usePagination<E>(hits, 5)

  const renderHits = useCallback(
    () =>
      currentItems.map((hit) => (
        <Fragment key={hit.id}>
          <JournalCardSearchEatable
            initialDate={initialDate}
            quantifiedEatable={{ [eatableType.toLowerCase()]: hit }}
            renderTitle={() => <Highlight attribute="name" tagName="mark" hit={hit} />}
            onSave={(eatable, date, mealCategory) => {
              addJournalEatableRecord(eatable, mealCategory, date, 'search')
              onSaveSearchResult()
            }}
          />
        </Fragment>
      )),
    [currentItems]
  )

  return (
    <>
      <div className="search-headline">
        {headline} <Stats nbHits={hits.length} />
      </div>
      {renderHits()}
      {currentPage < maxPage && (
        <button className="ais-InfiniteHits-loadMore" onClick={loadMoreItems}>
          Mehr anzeigen …
        </button>
      )}
    </>
  )
}
