import { useKeycloak } from '@react-keycloak/web'
import React, { FC, ReactElement } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { LoadingIndicator } from 'Components'
import { onLogin, routes } from 'Config'
import { CoreBackendUserReduxType, KeyCloakUser } from 'Models'
import { useAppSelector } from 'ReduxStore/hooks'
import { isActiveCustomerSelector } from 'ReduxStore/keyCloakUser'
import { temporaryAccessSelector } from 'ReduxStore/temporaryAccessSlice'

const publicRoutes: string[] = [routes.contact, routes.help, routes.error, routes.paymentRedirect]

const devRoutes: string[] = Object.values(routes.internals)

type Props = {
  coreBackendUser: CoreBackendUserReduxType
  keyCloakUser: KeyCloakUser
  isLoading: boolean
  includeDevRoutes: boolean
  userNeedsToFillAnamnesis: boolean
  userNeedsToRegisterMembership: boolean
  children: ReactElement
}

export const RedirectWrapper: FC<Props> = ({
  coreBackendUser,
  keyCloakUser,
  isLoading,
  includeDevRoutes,
  userNeedsToFillAnamnesis,
  userNeedsToRegisterMembership,
  children
}: Props) => {
  const location = useLocation()
  const isSuccessPage = location.pathname === routes.register.success

  const isActiveCustomer = useAppSelector(isActiveCustomerSelector)
  const {
    canAccessMembershipConfirmationPage,
    canAccessOrderProcessRedirectPageAfterExtendingMembership
  } = useAppSelector(temporaryAccessSelector)

  const { initialized } = useKeycloak()

  const isPrivateRoute = !publicRoutes.includes(location.pathname)

  const isDevRoute = devRoutes.includes(location.pathname)

  if (includeDevRoutes && isDevRoute) {
    return children
  }

  if (!initialized || isLoading) {
    return <LoadingIndicator />
  }

  if (!keyCloakUser.authenticated) {
    onLogin()
  }
  // the error case of missing core-backend user should be handled ASAP
  if (
    keyCloakUser.authenticated &&
    !coreBackendUser.isExisting &&
    isPrivateRoute &&
    location.pathname !== routes.error
  ) {
    return <Redirect to={routes.error} />
  }
  if (
    keyCloakUser.authenticated &&
    ((location.pathname === routes.register.anamnesis && !userNeedsToFillAnamnesis) || // redirect after finished anamnesis
      (coreBackendUser.isExisting && location.pathname === routes.error)) // redirect from error page when coreBackendUser exists
  ) {
    return <Redirect to="/" />
  }

  if (
    keyCloakUser.authenticated &&
    coreBackendUser.info.hasPrograms &&
    coreBackendUser.info.hasJournalRecords &&
    location.pathname === '/'
  ) {
    return <Redirect to={routes.journal} />
  }

  if (!keyCloakUser.authenticated && location.pathname === routes.paymentRedirect) {
    return <Redirect to={'/'} />
  }

  if (isActiveCustomer && location.pathname === routes.paymentRedirect && canAccessMembershipConfirmationPage) {
    return <Redirect to={routes.register.success} />
  }

  if (
    isActiveCustomer &&
    location.pathname === routes.paymentRedirect &&
    !canAccessOrderProcessRedirectPageAfterExtendingMembership
  ) {
    return <Redirect to={'/'} />
  }

  if (userNeedsToFillAnamnesis && isPrivateRoute && location.pathname !== routes.register.anamnesis && !isSuccessPage) {
    return <Redirect to={routes.register.anamnesis} />
  }

  if (
    userNeedsToRegisterMembership &&
    isPrivateRoute &&
    location.pathname !== routes.register.plan &&
    !canAccessMembershipConfirmationPage
  ) {
    return <Redirect to={routes.register.plan} />
  }

  if (isSuccessPage && !canAccessMembershipConfirmationPage) {
    return <Redirect to={'/'} />
  }

  return children
}
