import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DayModifiers } from 'react-day-picker'

import { DayPicker, fromMonth, toMonth } from '../DatePicker'
import { IconButton } from '../IconButton'

import { IconColor, IconName } from 'Components/Icon'
import { DayPickerRange } from 'Models'
import { Formatter } from 'Utils'

type Props = {
  currentDate: Date
  rangeAllowed?: DayPickerRange
  onChange: (value: Date) => void
}

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const DatePickerWithNavigation: React.FC<Props> = ({
  currentDate,
  rangeAllowed,
  rangeAllowed: { from: rangeFrom = fromMonth, to: rangeTo = toMonth } = {},
  onChange
}: Props) => {
  const [showDatePicker, setShowDatePicker] = useState(false)

  const DayPickerRef = useRef<HTMLDivElement>(null)

  const handleHideDatePicker = useCallback(
    (e) => {
      if (e && e.target && !(DayPickerRef.current && DayPickerRef.current.contains(e.target))) {
        setShowDatePicker(false)
      }
    },
    [showDatePicker]
  )

  useEffect(() => {
    if (showDatePicker) {
      window.addEventListener('click', handleHideDatePicker, true)
    } else {
      window.removeEventListener('click', handleHideDatePicker, true)
    }
    return () => window.removeEventListener('click', handleHideDatePicker, true)
  }, [showDatePicker])

  const handleDayClick = useCallback(
    (date: Date, dayModifiers: DayModifiers) => {
      if (!dayModifiers.disabled) {
        setShowDatePicker(false)
        onChange(date)
      }
    },
    [onChange, rangeAllowed]
  )

  const handleDisplayDatePicker = useCallback(() => {
    setShowDatePicker(!showDatePicker)
  }, [showDatePicker])

  const handlePreviousDate = useCallback(() => {
    onChange(dayjs(currentDate).subtract(1, 'day').toDate())

    if (showDatePicker) {
      setShowDatePicker(false)
    }
  }, [showDatePicker, onChange, currentDate, rangeFrom, rangeTo])

  const handleNextDate = useCallback(() => {
    onChange(dayjs(currentDate).add(1, 'day').toDate())

    if (showDatePicker) {
      setShowDatePicker(false)
    }
  }, [currentDate, showDatePicker, onChange, rangeFrom, rangeTo])

  const disallowPreviousDate = dayjs(currentDate).isSameOrBefore(rangeFrom, 'day')

  const disallowNextDate = dayjs(currentDate).isSameOrAfter(rangeTo, 'day')

  return (
    <div className="datepicker2-nav-wrapper mt-5 mb-1">
      <div className="datepicker2-nav">
        <IconButton
          name={IconName.arrowleft}
          color={IconColor.GRAY_2}
          onClick={handlePreviousDate}
          disabled={disallowPreviousDate}
        />
        <div className="datepicker2-nav-text" onClick={handleDisplayDatePicker}>
          {Formatter.formatDate(currentDate, '[Heute], D MMM', 'D MMM')}
        </div>
        <IconButton
          name={IconName.arrowright}
          color={IconColor.GRAY_2}
          onClick={handleNextDate}
          disabled={disallowNextDate}
        />
      </div>
      <div ref={DayPickerRef}>
        <DayPicker
          date={currentDate}
          isDatePickerDisplayed={showDatePicker}
          onDayClick={handleDayClick}
          rangeAllowed={rangeAllowed}
        />
      </div>
    </div>
  )
}
