import dayjs from 'dayjs'
import React, { FC } from 'react'

import { createChartData } from '../utils'

import { ChartLine, InfoPlaceholder, LoadingIndicator } from 'Components'
import { BodyMeasurementPoint } from 'Models'

interface Props {
  bodyMeasurementsBelly: BodyMeasurementPoint[]
  loading: boolean
}

const BellyLineChart: FC<Props> = ({ bodyMeasurementsBelly, loading }: Props) => {
  const bellyChartData = createChartData(
    bodyMeasurementsBelly.filter((measurement) => dayjs(measurement.datetime).isAfter(dayjs().add(-7, 'month')))
  )

  let content = <ChartLine dataType="datetime" xAxisTickInterval={30} data={bellyChartData} height={260} />

  if (loading) {
    content = <LoadingIndicator />
  }

  if (bellyChartData && bellyChartData.length < 2) {
    content = (
      <InfoPlaceholder text="Sobald Sie mehr als einmal Ihren Bauchumfang eingetragen haben, sehen Sie hier die Veränderung über die Zeit. Sie sollten Ihren Bauchumfang einmal im Monat messen. Wie misst man ihn? Ohne Bekleidung, auf Höhe das Bachnabels, das Massband verdeckt den Bauchnabel, waagerecht rund um den Körper." />
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <h1 className="mt-6 mt-lg-0">Bauchumfang</h1>
      </div>
      <div className="col-12">{content}</div>
    </div>
  )
}

export default BellyLineChart
