import { useCallback, useMemo, useState } from 'react'

type ReturnType<T> = {
  setNextPage: () => void
  setPrevPage: () => void
  setPage: (page: number) => void
  loadMoreItems: () => void
  currentItems: T[]
  currentPage: number
  maxPage: number
}

export const usePagination = <T>(itemList: T[], itemsPerPage = 1, initialPage = 0, loop = true): ReturnType<T> => {
  const [currentPage, setCurrentPage] = useState(initialPage)
  const [showItems, setItemsPerPage] = useState(itemsPerPage)

  const maxPage = Math.ceil(itemList.length / showItems) - 1

  const loadMoreItems = (): void => setItemsPerPage(showItems + itemsPerPage)

  const getCurrentItems = useCallback((): T[] => {
    const begin = currentPage * showItems
    const end = begin + showItems
    return itemList.slice(begin, end)
  }, [currentPage, showItems, itemList])

  const setNextPage = (): void => {
    const nextPage = Math.min(currentPage + 1, maxPage)
    setCurrentPage(loop && currentPage == maxPage ? 0 : nextPage)
  }

  const setPrevPage = (): void => {
    const prevPage = Math.max(currentPage - 1, 1)
    setCurrentPage(loop && currentPage === 0 ? maxPage : prevPage)
  }

  const setPage = (page: number): void => {
    const pageNumber = Math.max(0, page)
    setCurrentPage(Math.min(pageNumber, maxPage))
  }

  const currentItems = useMemo(() => getCurrentItems(), [getCurrentItems])

  return { setNextPage, setPrevPage, setPage, loadMoreItems, currentItems, currentPage, maxPage }
}
