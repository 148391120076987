import React, { useMemo } from 'react'
import { UseFormMethods } from 'react-hook-form'

import {
  SelectItemType,
  SingleSelectProps,
  defaultKeyExtractor,
  defaultLabelExtractor
} from '../common/SelectPickerProps'
import { useRandomName } from '../common/useRandom'
import { useSingleSelect } from '../common/useSingleSelect'

export type FormInputRadioProps<T extends SelectItemType> = Partial<Pick<UseFormMethods, 'register' | 'errors'>> &
  SingleSelectProps<T> & {
    name?: string
    errorText?: string
    itemLabelClassName?: string
    renderItem?: (item: T) => JSX.Element
  }

export function FormInputRadio<T extends SelectItemType>({
  itemLabelClassName = 'radio',
  name,
  items,
  selectedKey,
  keyExtractor = defaultKeyExtractor,
  labelExtractor = defaultLabelExtractor,
  renderItem,
  onChange,
  errorText,
  errors = [],
  register
}: FormInputRadioProps<T>): JSX.Element {
  const handleSelectChange = useSingleSelect<T>(items, keyExtractor, onChange)
  const randomName = useRandomName('radios')

  const fieldName = name || randomName

  const errorMessage = useMemo(() => (errors[fieldName] && errors[fieldName].message) || errorText, [
    errors[fieldName],
    errorText
  ])

  return (
    <>
      {items.map((current) => {
        const key = keyExtractor(current)
        const selected = key === selectedKey

        const renderedItem = renderItem?.(current) ?? <span className="label">{labelExtractor(current, selected)}</span>

        return (
          <label key={key} className={itemLabelClassName}>
            <input
              name={fieldName}
              ref={register}
              type="radio"
              value={key}
              checked={selectedKey ? selected : undefined}
              onChange={handleSelectChange}
            />
            {renderedItem}
          </label>
        )
      })}
      {errorMessage && <div className="input-error">{errorMessage}</div>}
    </>
  )
}
