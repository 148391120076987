import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import AnamnesisWizard from './AnamnesisWizard'
import { anamnesisRestartKeepWeightDefaultValues, anamnesisRestartLoseWeightDefaultValues } from './defaultValues'
import { AnamnesisFormValuesByStepId, ProgramTypes } from './types'

import { LoadingIndicator, PageLayout } from 'Components'
import { routes } from 'Config'
import { getAnamnesisAction, getAnamnesisDataSelector } from 'ReduxStore/anamnesis'
import { currentBodyMeasurementValuesSelector, fetchLatestBodyMeasurementsAction } from 'ReduxStore/bodyMeasurements'
import { coreBackendUserInfoSelector } from 'ReduxStore/coreBackendUser'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { currentProgramSelector, fetchCurrentProgramAction } from 'ReduxStore/programs'

export const useFetchLatestAnamnesis = (): void => {
  const dispatch = useAppDispatch()
  const coreBackendUserInfo = useAppSelector(coreBackendUserInfoSelector)
  const anamnesisId = useAppSelector(currentProgramSelector)?.anamnesisId

  useEffect(() => {
    if (!anamnesisId && coreBackendUserInfo.hasPrograms) {
      dispatch(fetchCurrentProgramAction())
    }
  }, [])

  useEffect(() => {
    if (anamnesisId) {
      dispatch(getAnamnesisAction(anamnesisId))
    }
  }, [anamnesisId])
}

const AnamnesisPage: FC = () => {
  const dispatch = useAppDispatch()
  const pathname = useLocation().pathname
  const restartType =
    pathname === routes.profile.restartKeepWeight
      ? ProgramTypes.KEEP_WEIGHT
      : pathname === routes.profile.restartLoseWeight
      ? ProgramTypes.LOSE_WEIGHT
      : undefined

  if (!restartType) {
    return (
      <PageLayout theme="smallcircle">
        <AnamnesisWizard isRestart={false} />
      </PageLayout>
    )
  }

  useFetchLatestAnamnesis()
  const [restartDefaultValues, setRestartDefaultValues] = useState<AnamnesisFormValuesByStepId | undefined>()
  const currentAnamnesis = useAppSelector(getAnamnesisDataSelector)
  const currentBodyMeasurments = useAppSelector(currentBodyMeasurementValuesSelector)

  useEffect(() => {
    if (!currentBodyMeasurments.currentWeight) {
      dispatch(fetchLatestBodyMeasurementsAction({ type: 'WEIGHT' }))
    }

    if (!currentBodyMeasurments.height) {
      dispatch(fetchLatestBodyMeasurementsAction({ type: 'HEIGHT' }))
    }
  }, [])

  useEffect(() => {
    if (currentAnamnesis && currentBodyMeasurments.currentWeight && currentBodyMeasurments.height) {
      setRestartDefaultValues(
        restartType === ProgramTypes.LOSE_WEIGHT
          ? anamnesisRestartLoseWeightDefaultValues(currentAnamnesis, currentBodyMeasurments)
          : anamnesisRestartKeepWeightDefaultValues(currentAnamnesis, currentBodyMeasurments)
      )
    }
  }, [currentAnamnesis, currentBodyMeasurments])

  if (restartDefaultValues) {
    return (
      <PageLayout theme="smallcircle">
        <AnamnesisWizard isRestart={true} defaultValuesForRestart={restartDefaultValues} />
      </PageLayout>
    )
  }

  return <LoadingIndicator />
}

export default AnamnesisPage
