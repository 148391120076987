import React from 'react'

import {
  SelectItemType,
  SingleSelectProps,
  defaultKeyExtractor,
  defaultLabelExtractor
} from '../common/SelectPickerProps'
import { useRandomName } from '../common/useRandom'
import { useSingleSelect } from '../common/useSingleSelect'

export type TextPickerProps<T extends SelectItemType> = SingleSelectProps<T> & {
  errorText?: string
  disabled?: boolean
}

export function TextPicker<T extends SelectItemType>({
  items,
  selectedKey,
  keyExtractor = defaultKeyExtractor,
  labelExtractor = defaultLabelExtractor,
  onChange,
  errorText,
  disabled
}: TextPickerProps<T>): JSX.Element {
  const handleSelectChange = useSingleSelect(items, keyExtractor, onChange)
  const name = useRandomName('radios')

  return (
    <>
      {items.map((current) => {
        const key = keyExtractor(current)
        const selected = key === selectedKey

        return (
          <label
            key={key}
            className={`
              textpicker
              ${disabled ? 'is-disabled' : ''}
            `}
          >
            <input
              type="radio"
              name={name}
              checked={selected}
              value={key}
              onChange={handleSelectChange}
              disabled={disabled}
            />
            <span className="label">{labelExtractor(current, selected)}</span>
          </label>
        )
      })}
      {errorText && <div className="input-error">{errorText}</div>}
    </>
  )
}
