import React, { FC, useCallback, useMemo } from 'react'
import { Configure, Index, InstantSearch } from 'react-instantsearch-dom'
import { ConnectedProps, connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { SearchJournalHit, eatableHit, motionHit } from './SearchHits'
import { SearchHitsUserEatables } from './SearchHitsUserEatables'
import { CustomSearchBox, CustomStats } from './searchHelpers'
import { useUserEatablesSearch } from './useUserEatablesSearch'

import { Food, Recipe, ReduxStoreState } from 'Models'
import { currentWeightSelector } from 'ReduxStore/bodyMeasurements'
import { currentJournalDateSelector } from 'ReduxStore/journalDay'
import { postOrPatchJournalEatableRecordAction } from 'ReduxStore/journalEatableRecords'
import { addJournalMotionRecordAction } from 'ReduxStore/journalMotionRecords'
import { userFoodsSelector } from 'ReduxStore/userFoods'
import { userRecipesSelector } from 'ReduxStore/userRecipes'
import { AlgoliaIndexName, getIndexName, searchClient } from 'Utils/algoliaSearchHelpers'

type SelectorProps = {
  date: Date
  weight: number
  userFoods: Food[]
  userRecipes: Recipe[]
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  date: currentJournalDateSelector,
  weight: currentWeightSelector,
  userFoods: userFoodsSelector,
  userRecipes: userRecipesSelector
})

const mapDispatchToProps = {
  addJournalEatableRecord: postOrPatchJournalEatableRecordAction,
  addJournalMotionRecord: addJournalMotionRecordAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  onSaveSearchResult: () => void
}

const Search: FC<Props> = ({
  addJournalEatableRecord,
  addJournalMotionRecord,
  date,
  weight,
  userFoods,
  userRecipes,
  onSaveSearchResult
}: Props) => {
  const { searchResult: userFoodHits, setSearchQuery: setSearchQueryFoods } = useUserEatablesSearch(userFoods)
  const { searchResult: userRecipeHits, setSearchQuery: setSearchQueryRecipes } = useUserEatablesSearch(userRecipes)

  const FoodHit = useMemo(
    () => eatableHit({ eatableType: 'FOOD', initialDate: date, addJournalEatableRecord, onSaveSearchResult }),
    [addJournalEatableRecord, date, onSaveSearchResult]
  )

  const RecipeHit = useMemo(
    () => eatableHit({ eatableType: 'RECIPE', addJournalEatableRecord, initialDate: date, onSaveSearchResult }),
    [addJournalEatableRecord, date, onSaveSearchResult]
  )

  const MotionHit = useMemo(() => motionHit({ addJournalMotionRecord, weight, date, onSaveSearchResult }), [
    addJournalMotionRecord,
    date,
    onSaveSearchResult
  ])

  const handleOnChange = useCallback(
    (searchQuery: string) => {
      setSearchQueryFoods(searchQuery)
      setSearchQueryRecipes(searchQuery)
    },
    [userFoods, userRecipes]
  )

  return (
    <InstantSearch indexName={getIndexName(AlgoliaIndexName.RECIPES)} searchClient={searchClient}>
      <Configure hitsPerPage={5} />

      <CustomSearchBox onChange={handleOnChange} />

      <SearchHitsUserEatables
        headline="Meine Nahrungsmittel"
        hits={userFoodHits}
        eatableType="FOOD"
        initialDate={date}
        addJournalEatableRecord={addJournalEatableRecord}
        onSaveSearchResult={onSaveSearchResult}
      />

      <SearchHitsUserEatables
        headline="Meine Rezepte"
        hits={userRecipeHits}
        eatableType="RECIPE"
        initialDate={date}
        addJournalEatableRecord={addJournalEatableRecord}
        onSaveSearchResult={onSaveSearchResult}
      />

      <Index indexName={getIndexName(AlgoliaIndexName.FOODS)}>
        <div className="search-headline">
          Nahrungsmittel <CustomStats />
        </div>
        <SearchJournalHit hitComponent={FoodHit} />
      </Index>

      <Index indexName={getIndexName(AlgoliaIndexName.RECIPES)}>
        <div className="search-headline">
          Rezepte <CustomStats />
        </div>
        <SearchJournalHit hitComponent={RecipeHit} />
      </Index>

      <Index indexName={getIndexName(AlgoliaIndexName.MOTIONS)}>
        <div className="search-headline">
          Aktivitäten <CustomStats />
        </div>
        <SearchJournalHit hitComponent={MotionHit} />
      </Index>
    </InstantSearch>
  )
}

export default connector(Search)
