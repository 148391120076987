import React from 'react'
import { UseFormMethods } from 'react-hook-form'

import {
  SelectItemType,
  SingleSelectProps,
  defaultKeyExtractor,
  defaultLabelExtractor
} from '../common/SelectPickerProps'
import { useSingleSelect } from '../common/useSingleSelect'

export type FormInputSelectProps<T extends SelectItemType> = Partial<Pick<UseFormMethods, 'register' | 'errors'>> &
  SingleSelectProps<T> & {
    name?: string
    label?: string
    errorText?: string
    isOptional?: boolean
  }

export function FormInputSelect<T extends SelectItemType>({
  name,
  label,
  items,
  selectedKey,
  keyExtractor = defaultKeyExtractor,
  labelExtractor = defaultLabelExtractor,
  onChange,
  register,
  errors,
  errorText,
  isOptional
}: FormInputSelectProps<T>): JSX.Element {
  const handleSelectChange = useSingleSelect<T>(items, keyExtractor, onChange)
  const selectValue = register ? undefined : selectedKey === undefined ? '' : selectedKey
  const errorMessage = (errors && name && errors[name] && errors[name].message) || errorText

  return (
    <div className="input">
      <select name={name} value={selectValue} onChange={handleSelectChange} ref={register}>
        {isOptional ? (
          <option value="">-</option>
        ) : (
          <option value="" disabled>
            Bitte wählen
          </option>
        )}
        {items.map((current, index) => {
          const key = keyExtractor(current)
          const selected = key === selectedKey

          return (
            <option key={index} value={key}>
              {labelExtractor(current, selected)}
            </option>
          )
        })}
      </select>
      {label && <label>{label}</label>}
      {errorMessage && <div className="input-error">{errorMessage}</div>}
    </div>
  )
}
