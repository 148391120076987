import { ImageService } from './ImageService'

import { appConfig } from 'Config'

const imageService = new ImageService(
  {
    baseURL: appConfig.imageServer.baseUrl,
    auth: appConfig.imageServer.apiLogin
  },
  appConfig.imageServer.baseImagePath
)

export { imageService }
