import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CopyJournalRecordModal from './Modals/CopyJournalRecordModal'
import MoveJournalRecordModal from './Modals/MoveJournalRecordModal'

import { JournalCardMotion } from 'Components'
import { copyButton, deleteButton, editButton, favoriteButton, moveButton } from 'Components/IconButton/iconButtons'
import { FavoritesMapping, JournalMotionRecord, ReduxStoreState } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAddOrRemoveFavorite } from 'ReduxStore/favorites/useAddOrRemoveFavorite'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { currentJournalDateSelector } from 'ReduxStore/journalDay'
import {
  copyJournalMotionRecordAction,
  removeJournalMotionRecordAction,
  updateJournalMotionRecordAction
} from 'ReduxStore/journalMotionRecords'

type SelectorProps = {
  currentDate: Date
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  currentDate: currentJournalDateSelector
})

const mapDispatchToProps = {
  updateJournalMotionRecord: updateJournalMotionRecordAction,
  removeJournalMotionRecord: removeJournalMotionRecordAction,
  copyJournalMotionRecord: copyJournalMotionRecordAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  journalMotionRecord: JournalMotionRecord
  weight: number
}

const JournalCardMotionContainer: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch()
  const {
    updateJournalMotionRecord,
    removeJournalMotionRecord,
    copyJournalMotionRecord,
    journalMotionRecord,
    currentDate,
    journalMotionRecord: { id, duration: initialDuration, motion, motionLevelId: initialMotionLevelId }
  } = props
  const [duration, setDuration] = useState(initialDuration)
  const [motionLevelId, setMotionLevelId] = useState(initialMotionLevelId)

  const motionRecordLevelIds = motion.levels.map((level) => level.id)
  const favoriteMotionLevel = useAppSelector(favoriteSelector(FavoritesMapping.MOTIONS, motionRecordLevelIds))
  const { onFavoriteChange } = useAddOrRemoveFavorite()

  useEffect(() => {
    setDuration(initialDuration)
  }, [initialDuration])

  const [copyModalIsOpen, setCopyModalIsOpen] = useState(false)
  const [moveModalIsOpen, setMoveModalIsOpen] = useState(false)

  const [toggleChildrenIsOn, setToggleChildrenIsOn] = useState(false)

  const handleAmountChanged = useCallback(
    _.debounce((duration: number, motionLevelId: string) => {
      updateJournalMotionRecord(id, duration, motionLevelId, currentDate)
    }, 1000),
    [updateJournalMotionRecord]
  )

  const onCopy = (date: Date): void => {
    copyJournalMotionRecord(motion, duration, motionLevelId, date)
  }

  const onReset = (): void => {
    setCopyModalIsOpen(false)
    setMoveModalIsOpen(false)
  }

  const onMove = (date: Date): void => {
    dispatch(updateJournalMotionRecordAction(id, duration, motionLevelId, date))

    setMoveModalIsOpen(false)
  }

  const onDurationChange = (duration: number): void => {
    setDuration(duration)
    handleAmountChanged(duration, motionLevelId)
  }

  const onMotionLevelIdChange = (motionLevelId: string): void => {
    setMotionLevelId(motionLevelId)
    handleAmountChanged(duration, motionLevelId)
  }

  const callbackCopyButton = (): void => setCopyModalIsOpen(!copyModalIsOpen)
  const callbackMoveButton = (): void => setMoveModalIsOpen(!moveModalIsOpen)
  const callbackDeleteButton = (): void => {
    removeJournalMotionRecord(id, journalMotionRecord)
  }
  const callbackEditButton = (): void => setToggleChildrenIsOn(!toggleChildrenIsOn)

  const callBackFavoriteButton = (): void => {
    onFavoriteChange(favoriteMotionLevel, { duration, motionLevelId })
  }

  return (
    <>
      <MoveJournalRecordModal isOpen={moveModalIsOpen} title={motion.name} onReset={onReset} onMove={onMove} />
      <CopyJournalRecordModal isOpen={copyModalIsOpen} onReset={onReset} title={motion.name} onCopy={onCopy} />
      <JournalCardMotion
        motion={motion}
        duration={duration}
        motionLevelId={motionLevelId}
        onDurationChange={onDurationChange}
        onMotionLevelIdChange={onMotionLevelIdChange}
        toggleChildrenIsOn={toggleChildrenIsOn}
        cardButtons={[]}
        iconButtons={[
          editButton(callbackEditButton),
          copyButton(callbackCopyButton),
          moveButton(callbackMoveButton),
          favoriteButton(callBackFavoriteButton, !!favoriteMotionLevel),
          deleteButton(callbackDeleteButton)
        ]}
        {...props}
      />
    </>
  )
}

export default connector(JournalCardMotionContainer)
