import React, { FC } from 'react'

import { StepHeading } from '../StepHeading'

import { FormInputNumberController, FormInputToggle } from 'Components'
import { RenderStepType } from 'Components/FormWizard'

const ExperienceStep: FC<RenderStepType> = ({
  register,
  errors,
  setValue,
  control,
  defaultValues,
  formValues
}: RenderStepType) => {
  const heightInMeter = formValues?.profile.height / 100
  const isBMIGreaterThan25 = formValues?.profile.currentWeight / (heightInMeter * heightInMeter) >= 25

  return (
    <>
      <StepHeading
        title="Ihre Erfahrungen"
        infotext="Damit aus Ihrem Wunschgewicht Wirklichkeit wird, würden wir gerne mehr zu Ihren Abnehmerfahrungen wissen. So können wir bei unseren Berechnungen optimal auf Ihre individuellen Bedürfnisse eingehen."
      />
      <div className="row">
        <div className="col-12">
          <h4 className="mb-4">
            Haben Sie schon mal 10 Kilo oder mehr abgenommen und danach wieder (fast) vollständig zugenommen?
            <br />
            <span className="text-default text-gray-2">Stichwort: Jo-Jo-Effekt</span>
          </h4>
          <div className="row g-0 align-items-center">
            <div className="col-auto">Ja, ungefähr</div>
            <div className="col-auto text-center">
              <FormInputNumberController
                name="previousFailureCount"
                label=" "
                step={1}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                defaultValues={defaultValues}
              />
            </div>
            <div className="col-auto">Mal.</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h4 className="mb-4">
            Keine Anamnese ohne Frage zur Familie: Sind Ihre Mutter und/oder Ihr Vater übergewichtig (gewesen)?
          </h4>
          <FormInputToggle name="parentsOverweight" register={register} errors={errors} />
        </div>
      </div>
      {isBMIGreaterThan25 && (
        <div className="row">
          <div className="col-12">
            <h4 className="mb-4">Haben Sie ihr aktuelles Gewicht schon länger als 2 Jahre?</h4>
            <FormInputToggle name="overweightForTwoYears" register={register} errors={errors} />
          </div>
        </div>
      )}
    </>
  )
}

export default ExperienceStep

export * from './config'
