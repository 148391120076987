import { useCallback, useEffect, useState } from 'react'
import { HighlightResult } from 'react-instantsearch-core'

import { UserEatable, UserEatableSearchHit } from './searchHelpers'

import { unicodeNormalization } from 'Utils/helpers/unicodeNormalization'

const mapEatableToHighlightSearchHit = <E extends UserEatable>(
  eatable: E,
  searchQuery = ''
): UserEatableSearchHit<E> => {
  const matchedWords =
    eatable.name && searchQuery
      ? unicodeNormalization(eatable.name).match(new RegExp(unicodeNormalization(searchQuery), 'i'))
      : null

  let highlightedValue: string
  if (matchedWords) {
    const searchQueryFirstCharIndex = matchedWords.index ?? 0
    const searchQueryLastCharPlusOneIndex = searchQueryFirstCharIndex + searchQuery.length
    const substringStart = eatable.name.slice(0, searchQueryFirstCharIndex)
    const substringHighlight = eatable.name.slice(searchQueryFirstCharIndex, searchQueryLastCharPlusOneIndex)
    const substringEnd = eatable.name.slice(searchQueryLastCharPlusOneIndex)
    highlightedValue = `${substringStart}<ais-highlight-0000000000>${substringHighlight}</ais-highlight-0000000000>${substringEnd}`
  } else {
    highlightedValue = eatable.name
  }

  return {
    ...eatable,
    _highlightResult: {
      name: {
        value: highlightedValue,
        fullyHighlighted: false,
        matchLevel: 'none',
        matchedWords: [...(matchedWords || [])]
      }
    } as HighlightResult<E>
  }
}

export const useUserEatablesSearch = <E extends UserEatable>(
  eatables: E[]
): {
  searchResult: UserEatableSearchHit<E>[]
  setSearchQuery: (searchQuery: string) => void
} => {
  const [searchHits, setSearchHits] = useState(() => [] as UserEatableSearchHit<E>[])

  useEffect(() => {
    if (eatables.length > 0) {
      setSearchHits(eatables.map((e) => mapEatableToHighlightSearchHit(e)))
    }
  }, [eatables])

  const setSearchQuery = useCallback(
    (searchQuery: string) => {
      const searchResult = eatables
        .filter((e) => unicodeNormalization(e.name).includes(unicodeNormalization(searchQuery.trim())))
        .map((e) => mapEatableToHighlightSearchHit(e, searchQuery.trim()))

      setSearchHits(searchResult)
    },
    [eatables]
  )

  return {
    searchResult: searchHits,
    setSearchQuery
  }
}
