import { yupNumberTransformer } from 'Utils'
import yup from 'yup-schema'

export const foodFormValidationSchema = yup.object({
  name: yup.string().required(),
  sourceId: yup.string(),
  barcodes: yup.string(),
  foodCategoryId: yup.string().required(),
  foodSubCategoryId: yup.string().required(),
  energy: yup.number().transform(yupNumberTransformer).required(),
  protein: yup.number().transform(yupNumberTransformer).required(),
  carbohydrates: yup.number().transform(yupNumberTransformer).required(),
  fat: yup.number().transform(yupNumberTransformer).required(),
  salt: yup.number().transform(yupNumberTransformer),
  sugar: yup.number().transform(yupNumberTransformer),
  fibre: yup.number().transform(yupNumberTransformer),
  alcohol: yup.number().transform(yupNumberTransformer),
  unit: yup.string(),
  gramsPerUnit: yup.number().transform(yupNumberTransformer)
})
