import { AnyAction, combineReducers } from 'redux'
import { Selector, createSelector } from 'reselect'

import { getAddToastMessage, getDeleteToastMessage, getFavoritesMapping } from './favoritesHelpers'

import {
  AddFavoritesMotionLevel,
  AddFavoritesUnion,
  FavoritesCategories,
  FavoritesFood,
  FavoritesMapping,
  FavoritesMotionLevel,
  FavoritesRecipe,
  FavoritesReduxState,
  FavoritesStateObjectsUnion,
  PromiseAction,
  ReduxStoreState,
  ThunkAction,
  UnitId
} from 'Models'
import {
  ADD_FAVORITES,
  ADD_FAVORITES_FULFILLED,
  FETCH_FAVORITES,
  FETCH_FAVORITES_FULFILLED,
  REMOVE_FAVORITES,
  REMOVE_FAVORITES_FULFILLED,
  REMOVE_FAVORITES_MOTION,
  UPDATE_FAVORITES,
  UPDATE_FAVORITES_FULFILLED,
  UPDATE_FAVORITE_MOTION,
  UPDATE_FAVORITE_MOTION_FULFILLED
} from 'ReduxStore/actionTypes'
import { createPendingCounterReducer } from 'ReduxStore/common'
import { coreBackendService } from 'Services'

export const fetchAllFavoritesAction = (): PromiseAction<FavoritesCategories> => {
  return {
    type: FETCH_FAVORITES,
    payload: coreBackendService.fetchAllFavorites()
  }
}

export const addFavoritesAction = (payload: AddFavoritesUnion): PromiseAction<FavoritesStateObjectsUnion> => {
  const favoritesMapping = getFavoritesMapping(payload)
  return {
    type: ADD_FAVORITES,
    payload: coreBackendService.addFavorite(payload, favoritesMapping),
    meta: {
      favoritesMapping,
      triggerDefaultSuccessToast: false,
      customSuccessToastMessage: getAddToastMessage(payload)
    }
  }
}
export const removeFavoritesAction = (favorite: FavoritesStateObjectsUnion): PromiseAction<void> => {
  const favoritesMapping = getFavoritesMapping(favorite)

  return {
    type: REMOVE_FAVORITES,
    payload: coreBackendService.removeFavorite(favorite.id, favoritesMapping),
    meta: {
      favoritesMapping,
      id: favorite.id,
      triggerDefaultSuccessToast: false,
      customSuccessToastMessage: getDeleteToastMessage(favorite)
    }
  }
}

export const updateFavoritesAction = (id: string, payload: AddFavoritesUnion): PromiseAction<void> => {
  const favoritesMapping = getFavoritesMapping(payload)
  return {
    type: UPDATE_FAVORITES,
    payload: coreBackendService.updateFavorite(id, favoritesMapping, payload),
    meta: {
      id,
      ...payload,
      favoritesMapping,
      triggerDefaultSuccessToast: false,
      customSuccessToastMessage: getAddToastMessage(payload)
    }
  }
}

export const updateFavoriteMotionAction = (
  id: string,
  { duration, motionLevelId, newMotionLevelId }: AddFavoritesMotionLevel & { newMotionLevelId: string },
  favorite: FavoritesMotionLevel
): ThunkAction => async (dispatch) => {
  const favoritesMapping = getFavoritesMapping({ duration, motionLevelId })
  if (newMotionLevelId === motionLevelId) {
    return dispatch({
      type: UPDATE_FAVORITES,
      payload: coreBackendService.updateFavorite(id, favoritesMapping, { duration, motionLevelId }),
      meta: {
        id,
        duration,
        favoritesMapping,
        triggerDefaultSuccessToast: false,
        customSuccessToastMessage: getAddToastMessage({ duration, motionLevelId })
      }
    })
  } else {
    await dispatch({
      type: REMOVE_FAVORITES_MOTION,
      payload: coreBackendService.removeFavorite(id, favoritesMapping),
      meta: {
        favoritesMapping,
        id: favorite.id
      }
    })
    dispatch({
      type: UPDATE_FAVORITE_MOTION,
      payload: coreBackendService.addFavorite({ motionLevelId: newMotionLevelId, duration }, favoritesMapping),
      meta: {
        id: favorite.id,
        favoritesMapping,
        triggerDefaultSuccessToast: false,
        customSuccessToastMessage: getAddToastMessage({ motionLevelId: newMotionLevelId, duration })
      }
    })
  }
}

const initialState: FavoritesCategories = {
  [FavoritesMapping.FOODS]: [],
  [FavoritesMapping.RECIPES]: [],
  [FavoritesMapping.MOTIONS]: []
}

const isFoodsMapping = (mapping: FavoritesMapping): mapping is FavoritesMapping.FOODS => {
  return mapping === FavoritesMapping.FOODS
}

const isRecipeMapping = (mapping: FavoritesMapping): mapping is FavoritesMapping.RECIPES => {
  return mapping === FavoritesMapping.RECIPES
}

const isMotionsMapping = (mapping: FavoritesMapping): mapping is FavoritesMapping.MOTIONS => {
  return mapping === FavoritesMapping.MOTIONS
}

type Meta = { favoritesMapping: FavoritesMapping; id: string; quantity: number; unit: UnitId; duration: number }

const removeFulfilledState = (state: FavoritesCategories, { favoritesMapping, id }: Meta): FavoritesCategories => {
  if (isFoodsMapping(favoritesMapping)) {
    return {
      ...state,
      [favoritesMapping]: state[favoritesMapping].filter((favorite) => favorite.id !== id)
    }
  } else if (isRecipeMapping(favoritesMapping)) {
    return {
      ...state,
      [favoritesMapping]: state[favoritesMapping].filter((favorite) => favorite.id !== id)
    }
  } else if (isMotionsMapping(favoritesMapping)) {
    return {
      ...state,
      [favoritesMapping]: state[favoritesMapping].filter((favorite) => favorite.id !== id)
    }
  } else {
    return { ...state }
  }
}

const updateFulfilledState = (
  state: FavoritesCategories,
  { favoritesMapping, id, unit, quantity, duration }: Meta
): FavoritesCategories => {
  if (isFoodsMapping(favoritesMapping)) {
    return {
      ...state,
      [favoritesMapping]: state[favoritesMapping].map((favorite) => {
        if (favorite.id !== id) {
          return favorite
        }
        return { ...favorite, quantity: quantity, unit: unit }
      })
    }
  } else if (isRecipeMapping(favoritesMapping)) {
    return {
      ...state,
      [favoritesMapping]: state[favoritesMapping].map((favorite) => {
        if (favorite.id !== id) {
          return favorite
        }
        return { ...favorite, quantity: quantity, unit: unit }
      })
    }
  } else if (isMotionsMapping(favoritesMapping)) {
    return {
      ...state,
      [favoritesMapping]: state[favoritesMapping].map((favorite) => {
        if (favorite.id !== id) {
          return favorite
        }
        return { ...favorite, duration: duration }
      })
    }
  } else {
    return { ...state }
  }
}

const data = (
  state: FavoritesCategories = initialState,
  {
    type,
    payload,
    meta
  }: AnyAction & {
    meta: Meta
  }
): FavoritesCategories => {
  switch (type) {
    case FETCH_FAVORITES_FULFILLED:
      return payload
    case ADD_FAVORITES_FULFILLED:
      return { ...state, [meta.favoritesMapping]: [...state[meta.favoritesMapping], payload] }
    case REMOVE_FAVORITES_FULFILLED:
      return removeFulfilledState(state, meta)
    case UPDATE_FAVORITES_FULFILLED:
      return updateFulfilledState(state, meta)
    case UPDATE_FAVORITE_MOTION_FULFILLED:
      return {
        ...state,
        [FavoritesMapping.MOTIONS]: state[FavoritesMapping.MOTIONS].map((favorite) => {
          if (favorite.id === meta.id) {
            return payload
          }
          return favorite
        })
      }
    default:
      return state
  }
}

export const favoritesSelector = <T extends FavoritesStateObjectsUnion>(favoritesMapping: FavoritesMapping) => ({
  favorites
}: ReduxStoreState): T[] => {
  if (isFoodsMapping(favoritesMapping)) {
    return favorites.data.foods as T[]
  }
  if (isRecipeMapping(favoritesMapping)) {
    return favorites.data.recipes as T[]
  }
  return favorites.data[FavoritesMapping.MOTIONS] as T[]
}

export const favoriteSelector = <T extends FavoritesStateObjectsUnion>(
  favoritesMapping: FavoritesMapping,
  id: string | string[] | undefined
): Selector<ReduxStoreState, T | undefined> =>
  createSelector([favoritesSelector<T>(favoritesMapping)], (favorites) => {
    if (!id) {
      return undefined
    }

    if (isFoodsMapping(favoritesMapping)) {
      return (favorites as FavoritesFood[]).find((favorite) => favorite.food.id === id) as T | undefined
    } else if (isRecipeMapping(favoritesMapping)) {
      return (favorites as FavoritesRecipe[]).find((favorite) => favorite.recipe.id === id) as T | undefined
    } else if (isMotionsMapping(favoritesMapping)) {
      return (favorites as FavoritesMotionLevel[]).find((favorite) =>
        favorite.motionLevel?.motion.levels.find((motionLevel) => id.includes(motionLevel.id))
      ) as T | undefined
    }
  })

type SystemEatableParams = {
  favoritesMapping: FavoritesMapping
  getSystemEatables: boolean
}

const favoriteEatablesSelector = <T extends FavoritesFood | FavoritesRecipe>({
  favoritesMapping,
  getSystemEatables
}: SystemEatableParams): Selector<ReduxStoreState, T[]> =>
  createSelector([favoritesSelector<T>(favoritesMapping)], (favorites) => {
    if (isFoodsMapping(favoritesMapping)) {
      return (favorites as FavoritesFood[]).filter((favorite) => favorite.food.system === getSystemEatables) as T[]
    }
    return (favorites as FavoritesRecipe[]).filter((favorite) => favorite.recipe.system === getSystemEatables) as T[]
  })

export const favoriteSystemEatablesSelector = <T extends FavoritesFood | FavoritesRecipe>(
  favoritesMapping: FavoritesMapping
): Selector<ReduxStoreState, T[]> =>
  createSelector(
    [favoriteEatablesSelector<T>({ favoritesMapping, getSystemEatables: true })],
    (favorites) => {
      return favorites
    }
  )
export const favoriteUserEatablesSelector = createSelector(
  [
    favoriteEatablesSelector<FavoritesFood>({ favoritesMapping: FavoritesMapping.FOODS, getSystemEatables: false }),
    favoriteEatablesSelector<FavoritesRecipe>({ favoritesMapping: FavoritesMapping.RECIPES, getSystemEatables: false })
  ],
  (favoriteFoods, favoriteRecipes) => {
    return [...favoriteFoods, ...favoriteRecipes]
  }
)

export const isLoadingFavoritesSelector = ({ favorites }: ReduxStoreState): boolean => !!favorites.pendingCounter

const asyncActionTypes = [FETCH_FAVORITES, ADD_FAVORITES, REMOVE_FAVORITES, UPDATE_FAVORITE_MOTION]

export default combineReducers<FavoritesReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
