import { combineReducers } from 'redux'
import { FluxStandardAction } from 'redux-promise-middleware'

import { UpdateVisibilityActionMetaData } from './hidable'
import {
  EatableSourcedChangeSet,
  UpdateUserEatableActionMetaData,
  createUserEatablesReduxItems,
  getSourceIdFromEatableUpdate
} from './userEatables'

import {
  CoreBackendFoodPatchData,
  Food,
  PromiseAction,
  ReduxFood,
  ReduxFoodAddData,
  ReduxStoreState,
  ThunkAction,
  UserFoodsReduxState
} from 'Models'
import { createPendingCounterReducer } from 'ReduxStore/common'
import { coreBackendService } from 'Services'

type UserFoodsState = ReduxFood[] | null

// Types

export type FoodChangeSet = EatableSourcedChangeSet & Omit<CoreBackendFoodPatchData, 'id'>

export type UpdateUserFoodActionMetaData = UpdateUserEatableActionMetaData<ReduxFood, FoodChangeSet>

// Helpers

export const initialState: UserFoodsState = null

const buildUpdatedFood = (food: ReduxFood, updates: FoodChangeSet): ReduxFood => {
  const sourceId = getSourceIdFromEatableUpdate(food, updates)
  const hasNutritions = !!food.nutritions || !!updates.nutritions
  return {
    ...food,
    ...updates,
    nutritions: hasNutritions
      ? {
          ...food.nutritions,
          ...updates.nutritions
        }
      : undefined,
    sourceId
  } as ReduxFood
}

const {
  actionTypes,
  fetchListAction,
  fetchItemAction,
  addAction,
  hideAction,
  updateAction,
  reducer: eatablesBaseReducer,
  didLoadOnceSelector,
  itemsSelector
} = createUserEatablesReduxItems<ReduxFood, ReduxFoodAddData, FoodChangeSet>('FOOD', initialState, buildUpdatedFood)

// Actions

export const fetchUserFoodAction = (id: string): PromiseAction<ReduxFood> =>
  fetchItemAction(id, (id) => coreBackendService.fetchFood(id))

export const fetchUserFoodsAction = (): PromiseAction<ReduxFood[]> =>
  fetchListAction(() => coreBackendService.fetchFoods())

export type UpdateUserFoodVisibilityActionMetaData = UpdateVisibilityActionMetaData<ReduxFood>

export const hideUserFood = (id: string): ThunkAction =>
  hideAction(
    id,
    (state) => state.userFoods.data,
    (id) => coreBackendService.updateFoodVisibility(id, true),
    'Nahrungsmittel gelöscht.'
  )

export const addUserFoodAction = (foodData: ReduxFoodAddData): ThunkAction =>
  addAction(foodData, (postData) => coreBackendService.addFood(postData))

export const updateUserFood = (id: string, foodUpdates: FoodChangeSet): ThunkAction => {
  return updateAction(
    id,
    foodUpdates,
    (state) => state.userFoods,
    (data) => coreBackendService.updateFood(data)
  )
}

// Reducer

export const userFoodsReducer = (state: UserFoodsState = initialState, action: FluxStandardAction): UserFoodsState => {
  return eatablesBaseReducer(state, action)
}

// Selectors

export const didLoadUserFoodsSelector = ({ userFoods }: ReduxStoreState): boolean => didLoadOnceSelector(userFoods.data)

export const userFoodsSelector = ({ userFoods }: ReduxStoreState): Food[] => itemsSelector(userFoods.data)

export const userFoodsVisibleSelector = ({ userFoods }: ReduxStoreState): Food[] =>
  userFoods.data?.filter((food) => !food.hidden) ?? []

export const userFoodsHiddenSelector = ({ userFoods }: ReduxStoreState): Food[] =>
  userFoods.data?.filter((food) => food.hidden) ?? []

const asyncActionTypes = [...Object.values(actionTypes)] // ToDo: Add updates as well

export default combineReducers<UserFoodsReduxState>({
  data: userFoodsReducer,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
