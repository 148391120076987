import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'

import { StepHeading } from '../StepHeading'

import { EnergyLevelLabel, EnergyPlanCard, LabelMapping } from './EnergyPlanCard'

import { RenderStepType } from 'Components'
import { getAge } from 'Config'
import { FetchEnergyRequirementsPayload } from 'Models/EnergyRequirements'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'
import { energyRequirementsSelector, fetchEnergyRequirementsAction } from 'ReduxStore/energyRequirements'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { keyCloakUserSelector } from 'ReduxStore/keyCloakUser'
import { Calculator } from 'Utils'

const EnergyPlanStep: React.FC<RenderStepType> = ({ setValue, control, formValues }) => {
  const isMounted = useRef(false)
  const dispatch = useAppDispatch()
  const { attributes } = useAppSelector(keyCloakUserSelector)
  const energyRequirements = useAppSelector(energyRequirementsSelector)
  const [isEnergyRequirementsFetched, setIsEnergyRequirementsFetched] = useState(false)
  const chosenEnergyLevelName = useWatch({ name: 'energyLevelName', control })

  const payload: FetchEnergyRequirementsPayload = {
    age: getAge(formValues?.profile.birthday),
    gender: attributes.gender,
    height: formValues?.profile.height,
    overweightForTwoYears: formValues?.experience.overweightForTwoYears,
    parentsOverweight: formValues?.experience.parentsOverweight,
    previousFailureCount: formValues?.experience.previousFailureCount,
    professionalMotionLevel: formValues?.activity.professionalMotionLevel,
    weight: formValues?.profile.currentWeight,
    weightToLose: formValues?.profile.weightToLose,
    workingHours: formValues?.activity.workingHours
  }
  useEffect(() => {
    dispatch(fetchEnergyRequirementsAction(payload))
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      return setIsEnergyRequirementsFetched(true)
    }
    isMounted.current = true
  }, [energyRequirements])

  useEffect(() => {
    if (
      formValues?.programType.activeProgramType === ProgramTypes.KEEP_WEIGHT ||
      Calculator.isUserLowWeight(payload.weight, payload.height)
    ) {
      return
    }
    const chosenEnergyPlan = energyRequirements?.energyPlans.find(
      (energyPlan) => energyPlan.level.name === chosenEnergyLevelName
    )

    if (chosenEnergyPlan) {
      setValue('goalDate', dayjs().add(chosenEnergyPlan.days, 'day').toDate())
    }
  }, [energyRequirements, chosenEnergyLevelName])

  if (Calculator.isUserUnderweight(payload.weight, payload.height)) {
    return (
      <>
        <div className={'infopanel'}>
          <p>
            Gemäss Ihren Angaben befindet sich Ihr BMI im Bereich des Untergewichts. Untergewicht ist aus
            gesundheitlicher Sicht nicht erstrebenswert, muss aber nicht in jedem Fall Grund zur Sorge sein, da es
            verschiedene Ursachen haben kann. Wir möchten Ihnen nahelegen, sich für eine Abklärung an Ihren Arzt / Ihre
            Ärztin zu wenden.
          </p>
          <p>
            eBalance richtet sich vorwiegend an übergewichtige Erwachsene bzw. auch an Personen, die im gesunden Rahmen
            des Normalgewichts ihr Wohlfühlgewicht erreichen möchten. Aus diesem Grund müssen wir Sie zu Ihrem Schutz
            mit einem BMI von unter 18,5 von unserem Programm ausschliessen. Melden Sie sich bitte, auch wenn Sie sich
            ggf. vertippt haben, bei unserem Support unter <a href={'mailto:info@eBalance.ch'}>info@eBalance.ch</a>.
          </p>
        </div>
      </>
    )
  }

  if (
    formValues?.programType.activeProgramType === ProgramTypes.KEEP_WEIGHT ||
    Calculator.isUserLowWeight(payload.weight, payload.height)
  ) {
    return (
      <>
        <StepHeading title="Ihr Ziel" infotext="Ihr Kalorienbedarf um Ihr aktuelles Gewicht zu halten." />
        <span>Gewicht: </span>
        <span>{Math.round(formValues?.profile.currentWeight)} kg</span>
        <fieldset
          style={{ border: '2px solid #32B6EF' }}
          className="infopanel my-2 row g-0 justify-content-center align-items-baseline"
        >
          <EnergyLevelLabel text={'Ihr Kalorienbedarf'} textColor={'text-blue-1'} />
          {energyRequirements && <span>{Math.round(energyRequirements.activeMetabolicRate)} kcal</span>}
        </fieldset>
        {formValues?.programType.activeProgramType === ProgramTypes.LOSE_WEIGHT &&
          Calculator.isUserLowWeight(payload.weight, payload.height) &&
          !Calculator.isUserUnderweight(payload.weight, payload.height) && (
            <div className="infopanel my-2">
              <p>Sie benötigen keine Gewichtsabnahme</p>
              <p>
                Gemäss Ihren Angaben befindet sich Ihr BMI im unteren Bereich des Normalgewichts. eBalance richtet sich
                vorwiegend an übergewichtige Erwachsene bzw. auch an Personen, die im gesunden Rahmen Ihr
                Wohlfühlgewicht erreichen möchten. Eine Abnahme würde in Ihrem Fall nicht zur Förderung der Gesundheit
                beitragen. Daher haben wir Ihnen das Ziel “Gewicht halten” zugewiesen. Vielleicht überlegen Sie sich Ihr
                Ziel nochmals. Ist es realistisch? Brauchen Sie wirklich eine Gewichtsreduktion, um sich fitter und
                wohler zu fühlen? Neben dem Gewicht gibt es weitere Faktoren, um sich wohler zu fühlen. Profitieren Sie
                hierfür von der Möglichkeit, Ihr Essverhalten unter die Lupe zu nehmen, wie auch von unseren
                Magazinartikeln und der Beratung. Haben Sie sich ggf. bei Ihren Eingaben vertippt, wenden Sie sich bitte
                an den Support unter <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>.
              </p>
            </div>
          )}
      </>
    )
  }

  return (
    <>
      <StepHeading title="Ihr Ziel" infotext="Wie schnell wollen Sie Ihr Wunschgewicht erreichen?" />
      <div className="row mb-2">
        <div>
          <p>Zielgewicht:</p>
          <p>Aktueller Kalorienbedarf:</p>
        </div>
        <div>
          <p>{Math.round(formValues?.profile.currentWeight - formValues?.profile.weightToLose)} kg</p>
          <p>{Math.round(energyRequirements?.activeMetabolicRate || 0)} kcal pro Tag</p>
        </div>
      </div>
      <div className="mb-5">
        {isEnergyRequirementsFetched &&
          energyRequirements?.energyPlans.map((energyPlan, index) => (
            <EnergyPlanCard
              key={index}
              energyPlan={energyPlan}
              onClickCard={() => setValue('energyLevelName', energyPlan.level.name)}
              cardHighlight={
                energyPlan.level.name === chosenEnergyLevelName ? { border: '2px solid #32B6EF' } : { border: '0' }
              }
              label={
                <EnergyLevelLabel
                  text={LabelMapping[energyPlan.level.name].text}
                  textColor={LabelMapping[energyPlan.level.name].textColor}
                />
              }
            />
          ))}
      </div>
    </>
  )
}

export default EnergyPlanStep
export * from './config'
