import Highcharts from 'highcharts'
import _ from 'lodash'

import getCommonTheme from './common'

import { colors } from 'Config'

export type SeriesOptions = Highcharts.SeriesLineOptions

export function getDefaultSeries(): SeriesOptions {
  return {
    type: 'line'
  }
}

export function getOrangeSeries(): SeriesOptions {
  return {
    type: 'line',
    color: colors.lineChart.goal,
    marker: {
      enabled: false,
      symbol: 'circle',
      radius: 4,
      fillColor: colors.lineChart.goal,
      lineColor: colors.lineChart.goal,
      states: {
        hover: {
          fillColor: colors.lineChart.goal
        }
      }
    }
  }
}

export default function getOptions(): Highcharts.Options {
  return _.merge({}, getCommonTheme(), {
    chart: {
      type: 'line'
    },
    xAxis: {
      gridLineWidth: 0,
      tickWidth: 0
    },
    yAxis: {
      gridLineColor: colors.lineChart.gridLines,
      title: {
        enabled: false
      }
    },
    legend: {
      enabled: true,
      align: 'right',
      itemStyle: {
        color: colors.lineChart.legend,
        fontWeight: 400
      }
    }
  })
}
