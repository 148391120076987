import { StepConfig } from 'Components'
import { ActiveProgramType, AnamnesisStepIds } from 'Pages/AnamnesisPage/types'
import { anamnesisSchema } from 'yup-schema/Schemas/anamnesis'

// @ts-ignore
const validationSchema = anamnesisSchema.pick(['activeProgramType'])

export const programTypeConfig: StepConfig<ActiveProgramType> = {
  id: AnamnesisStepIds.programType,
  validationSchema
}
