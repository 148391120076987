import { AnamnesisStepIds, PersonalMenuPlanValues } from '../../types'

import { StepConfig } from 'Components/FormWizard'
import { OptionType } from 'Models'
import yup from 'yup-schema'
import { Diet, anamnesisSchema } from 'yup-schema/Schemas/anamnesis'

const validationSchema = yup
  .object({
    isMenuPlanEnabled: yup.boolean().required()
  })
  .concat(
    // @ts-ignore
    anamnesisSchema.pick(['diet', 'longTermMotivated', 'isMenuplanEnabled'])
  )

export const personalMenuPlanConfig: StepConfig<PersonalMenuPlanValues> = {
  id: AnamnesisStepIds.personalMenuPlan,
  scrollTop: true,
  validationSchema
}

export const dietCriteriaOptions: OptionType<Diet>[] = [
  { value: 'OMNIVOR', label: 'Ich esse alles.' },
  { value: 'VEGETARIAN', label: 'Ich esse vegetarisch.' },
  { value: 'VEGAN', label: 'Ich esse vegan.' }
]
