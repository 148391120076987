import React, { FC } from 'react'
import { IoMdArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'

import { PageLayout } from 'Components'
import { routes } from 'Config'

const ProgramCard: FC<{ title: string; content: string; onClick: string }> = ({ title, content, onClick }) => {
  const arrowStyle = {
    margin: 'auto',
    height: '46px',
    width: '46px',
    backgroundColor: 'white',
    borderRadius: '10px'
  }
  return (
    <Link to={onClick}>
      <div className="infopanel mt-3 row g-0" style={{ borderRadius: '10px' }}>
        <div className="col-12 col-lg-11 pr-4">
          <h4 className="mt-0">{title}</h4>
          <p className="mt-2 mb-1">{content}</p>
        </div>
        <div className="col-12 col-lg-1 text-gray-2" style={arrowStyle}>
          <IoMdArrowForward size={'3rem'} color="#gray-2" />
        </div>
      </div>
    </Link>
  )
}

const ProgramPage: FC = () => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <h1>Mein Programm</h1>

        <ProgramCard
          title="Gewicht abnehmen"
          content="Sie haben sich für einen Neustart entschieden. Um Ihnen den Prozess zu erleichtern,
          ist der Fragebogen mit den Werten Ihres letzten Programmes bereits ausgefüllt."
          onClick={routes.profile.restartLoseWeight}
        />
        <ProgramCard
          title="Gewicht halten"
          content="Sie haben sich für einen Neustart entschieden. Um Ihnen den Prozess zu erleichtern,
          ist der Fragebogen mit den Werten Ihres letzten Programmes bereits ausgefüllt."
          onClick={routes.profile.restartKeepWeight}
        />

        <ProgramCard
          title="Basisdaten ändern"
          content="Ändern Sie Ihr Geburtsdatum oder Ihre Körpergrösse. Die Änderungen verändern möglicherweise Ihre Kalorienbilanz."
          onClick={routes.profile.baseData}
        />

        <ProgramCard
          title="Menüplan Einstellungen"
          content="Ändern Sie Ihren Menüplan. Die Änderungen werden erst mit dem nächsten Menüplan sichtbar."
          onClick={routes.profile.menuPlan}
        />
      </div>
    </PageLayout>
  )
}

export default ProgramPage
