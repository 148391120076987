import { EnergyLevelName } from 'Models/EnergyRequirements'
import { Diet, ProfessionalMotionLevel } from 'yup-schema/Schemas/anamnesis'

export enum ProgramTypes {
  'LOSE_WEIGHT' = 'LOSE_WEIGHT',
  'KEEP_WEIGHT' = 'KEEP_WEIGHT'
}

export type ActiveProgramType = {
  activeProgramType: ProgramTypes
}

export type ProfileValues = {
  birthday: Date
  currentWeight: number
  height: number
  bellyCircumference?: number
  weightToLose: number
  shouldDisplayWeightGainWarning: boolean | undefined
  weightGainThreshold: number | undefined
}

export type MotionValues = {
  workingHours: number
  professionalMotionLevel: ProfessionalMotionLevel
}

export type ExperienceValues = {
  previousFailureCount: number
  parentsOverweight: boolean
  overweightForTwoYears: boolean
}

export type PersonalMenuPlanValues = {
  diet?: Diet
  longTermMotivated: boolean
  isMenuPlanEnabled: boolean
}

export type EnergyPlan = {
  energyLevelName: EnergyLevelName
  goalDate?: Date | undefined
}

export type AnamnesisFormValues = ProfileValues &
  ActiveProgramType &
  MotionValues &
  ExperienceValues &
  PersonalMenuPlanValues &
  EnergyPlan

export enum AnamnesisStepIds {
  programType = 'programType',
  profile = 'profile',
  activity = 'activity',
  experience = 'experience',
  personalMenuPlan = 'personalMenuPlan',
  energyPlan = 'energyPlan'
}

export type AnamnesisFormValuesByStepId = {
  [AnamnesisStepIds.programType]: ActiveProgramType
  [AnamnesisStepIds.profile]: Partial<ProfileValues>
  [AnamnesisStepIds.activity]: Partial<MotionValues>
  [AnamnesisStepIds.experience]: ExperienceValues
  [AnamnesisStepIds.personalMenuPlan]: Partial<PersonalMenuPlanValues>
  [AnamnesisStepIds.energyPlan]: EnergyPlan
}
