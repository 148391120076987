import { combineReducers } from 'redux'
import { AsyncAction, FluxStandardAction } from 'redux-promise-middleware'
import { createSelector } from 'reselect'

import {
  FETCH_USER_SETTINGS,
  FETCH_USER_SETTINGS_FULFILLED,
  FETCH_USER_SETTINGS_REJECTED,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_PENDING,
  UPDATE_USER_SETTINGS_REJECTED
} from './actionTypes'
import { createPendingCounterReducer } from './common'

import { ReduxStoreState, ThunkAction, UserSettings, UserSettingsDynamic, UserSettingsReduxState } from 'Models'
import { coreBackendService } from 'Services'

// Reducer and state
export const initialState: UserSettings = {
  menuPlanHidden: true,
  cockpitIncludeMotion: true
}

export const defaultState: UserSettings = {
  analyticsNutritionsDateRange: '1DAY',
  analyticsEnergyHistoryDateRange: '1MONTH',
  analyticsEnergyDistributionDateRange: '1WEEK'
}

const data = (state: UserSettings = initialState, { type, payload, meta }: FluxStandardAction): UserSettings => {
  switch (type) {
    case UPDATE_USER_SETTINGS_PENDING:
      return {
        ...state,
        ...meta.newSettings
      }
    case FETCH_USER_SETTINGS_FULFILLED:
      return {
        ...defaultState,
        ...state,
        ...payload
      }
    case UPDATE_USER_SETTINGS_REJECTED:
      return {
        ...state,
        ...meta.oldSettings
      }
    case FETCH_USER_SETTINGS_REJECTED:
      return initialState
    default:
      return state
  }
}

// Selectors
export const userSettingsSelector = (state: ReduxStoreState): UserSettingsReduxState => state.userSettings

export const userSettingsDataSelector = (state: ReduxStoreState): UserSettings => state.userSettings.data

export const analyticsNutritionsDateRangeSelector = createSelector(
  [userSettingsDataSelector],
  ({ analyticsNutritionsDateRange }) => analyticsNutritionsDateRange
)

export const analyticsEnergyHistoryDateRangeSelector = createSelector(
  [userSettingsDataSelector],
  ({ analyticsEnergyHistoryDateRange }) => analyticsEnergyHistoryDateRange
)

export const analyticsEnergyDistributionDateRangeSelector = createSelector(
  [userSettingsDataSelector],
  ({ analyticsEnergyDistributionDateRange }) => analyticsEnergyDistributionDateRange
)

// Actions
export const fetchUserSettingsAction = (): AsyncAction => ({
  type: FETCH_USER_SETTINGS,
  payload: coreBackendService.fetchUserSettings()
})

export const updateUserSettingsAction = (newSettings: UserSettingsDynamic): ThunkAction => async (
  dispatch,
  getState
) => {
  const oldSettings = userSettingsDataSelector(getState())

  return dispatch({
    type: UPDATE_USER_SETTINGS,
    payload: coreBackendService.updateUserSettings(newSettings),
    meta: { newSettings, oldSettings }
  })
}

const asyncActionTypes = [FETCH_USER_SETTINGS, UPDATE_USER_SETTINGS]

export default combineReducers<UserSettingsReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
