import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Button, ImageUploadController, PageLayout } from 'Components'
import { constants } from 'Config'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { keyCloakUserSelector, updateKeycloakProfileAction } from 'ReduxStore/keyCloakUser'
import { useImageUploading } from 'Utils/helpers/useImageUploading'

const ImageUploadPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(keyCloakUserSelector)
  const { isImageUploading, setTempImageId, setIsImageUploading, onImageSubmit, onPageExit } = useImageUploading({
    imageUrl: user.attributes.imageUrl
  })

  useEffect(() => {
    return () => {
      onPageExit()
    }
  }, [])

  const {
    register,
    control,
    errors,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting }
  } = useForm<{ imageUrl: string }>({
    defaultValues: { imageUrl: user.attributes.imageUrl }
  })

  const onSubmit = handleSubmit((values): void => {
    const legacyImageUrlUploadPayload = {
      ...user,
      attributes: { ...user.attributes, imageUrl: values.imageUrl }
    }

    dispatch(updateKeycloakProfileAction(user.id, legacyImageUrlUploadPayload, { withDefaultSuccessToast: true })) //@ts-ignore
      .then(async () => {
        await onImageSubmit(values.imageUrl)
      })
  })

  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <form onSubmit={onSubmit} noValidate>
          <div className="row col-12 justify-content-center">
            <div className="col-12 mb-5">
              <ImageUploadController
                name="imageUrl"
                path={constants.imageServiceFolders.userPictures}
                theme="circle"
                onIdChange={setTempImageId}
                onUploadingChange={setIsImageUploading}
                setError={setError}
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
              />
            </div>
            <div className="col-6">
              <Button type="submit" disabled={isSubmitting || isImageUploading}>
                SPEICHERN
              </Button>
            </div>
          </div>
        </form>
      </div>
    </PageLayout>
  )
}

export default ImageUploadPage
