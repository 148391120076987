import { Color } from 'Config/colors'
import { ByIdMap } from 'Models/Common'

export type EatableType = 'FOOD' | 'RECIPE'
export type FoodCriteriaType = 'normal' | 'specials'

export type DateRangeString = '1DAY' | '1WEEK' | '2WEEKS' | '1MONTH' | '1YEAR' | 'PROGRAMSTART'
export type Weekday = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'
export type FoodCriterion =
  | 'ANIMAL_GOODS'
  | 'ANIMAL_PRODUCT'
  | 'FRUIT'
  | 'LEGUMES'
  | 'VEGETABLE'
  | 'CONDIMENTS'
  | 'DAIRY_GOODS'
  | 'FISH'
  | 'SHELLFISH'
  | 'CALF'
  | 'PORK'
  | 'BEEF'
  | 'LAMB'
  | 'POULTRY'
  | 'HORSE'
  | 'RABBIT'
  | 'VENISON'
  | 'FUNGI'
  | 'LACTOSE'
  | 'LACTOSEFREE'
  | 'GLUTEN'
  | 'GLUTENFREE'
  | 'NUTS'
  | 'NUTFREE'
  | 'DIABTES_CRITICAL'
  | 'DIABETES_SUITABLE'

export type MealCategory = 'BREAKFAST' | 'LUNCH' | 'DINNER' | 'SNACK'
export const allMealCategories: MealCategory[] = ['BREAKFAST', 'LUNCH', 'DINNER', 'SNACK']

export type MealCategoryMap<T> = {
  [key in MealCategory]?: T
}

export const analyticsNutritionsDateRanges: DateRangeString[] = ['1DAY', '1WEEK', '2WEEKS', '1MONTH']
export const analyticsEnergyHistoryDateRanges: DateRangeString[] = ['1WEEK', '1MONTH', 'PROGRAMSTART']
export const analyticsEnergyDistributionDateRanges: DateRangeString[] = ['1WEEK', '2WEEKS', '1MONTH', '1YEAR']

export type TimePeriod = 'PROGRAMM' | '30DAYS' | 'REGISTRATION'
export const timePeriodsMap: { [key in TimePeriod]: string } = {
  REGISTRATION: 'Anmeldung',
  PROGRAMM: 'Programmstart',
  '30DAYS': '30 Tage'
}
export const allTimePeriods: string[] = Object.keys(timePeriodsMap)

export type ChartType = 'WEIGHT' | 'BMI'
export const ChartTypesMap: { [key in ChartType]: string } = {
  WEIGHT: 'Gewicht',
  BMI: 'BMI'
}
export const allChartTypes: string[] = Object.values(ChartTypesMap)

export type DateRange = {
  dateFrom: Date
  dateTo: Date
}

export type DayPickerRange = {
  from?: Date
  to?: Date
}

export type ConfigType = { color?: Color; label: string }

export type Config = ByIdMap<ConfigType>

export type Coordinates = {
  x: number
  y: number
}

export type Identifiable = {
  id: string
}

export type Hidable = {
  hidden: boolean
}

export type OptionType<T> = {
  value: T
  label: string
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export type Nullable<T> = T | null

export type NullablePartial<T> = {
  [P in keyof T]?: Nullable<T[P]>
}
