import dayjs, { ConfigType } from 'dayjs'

import { BodyMeasurementPoint } from 'Models'

export const createChartTuple = (date?: Date, value?: number): [number, number] => {
  return [dayjs(date).startOf('day').valueOf(), value || 0]
}

export default (data: BodyMeasurementPoint[]): [number, number][] => {
  return data
    .sort((point1, point2) => {
      if (point1.datetime && point2.datetime) {
        return (point1.datetime as Date).getTime() - (point2.datetime as Date).getTime()
      }
      return 0
    })
    .filter((point, index, array) => {
      return index + 1 === array.length || !dayjs(point.datetime).isSame(array[index + 1].datetime as ConfigType, 'day')
    })
    .map((point) => createChartTuple(point.datetime, point.value))
}
