import React, { FC, useCallback, useState } from 'react'

import { FormInputNumber } from 'Components'
import { today } from 'Config'
import { BodyCriterion, BodyMeasurementPoint } from 'Models'
import { ToastConfigReduxActions } from 'ReduxStore/toastMessage'
import yup from 'yup-schema'
import { bodyMeasurementsRangeSchema } from 'yup-schema/Schemas/common'

interface Props {
  value: number
  bodyCriterion: BodyCriterion
  unitLabel?: string
  postBodyMeasurements: (data: BodyMeasurementPoint, toastConfig: ToastConfigReduxActions) => void
}

const schema = yup.object({
  value: bodyMeasurementsRangeSchema.required()
})

export const BodyMeasurementInput: FC<Props> = ({ value, bodyCriterion, unitLabel, postBodyMeasurements }: Props) => {
  const [errorText, setErrorText] = useState<string | undefined>()

  const handleAmountChanged = useCallback(
    (newValue) => {
      newValue !== value &&
        schema
          .validate({ value: newValue })
          .then(() => {
            setErrorText('')
            postBodyMeasurements(
              {
                datetime: today,
                bodyCriterion: bodyCriterion,
                value: newValue
              },
              { withDefaultSuccessToast: true }
            )
          })
          .catch(({ message }) => setErrorText(message))
    },
    [value, postBodyMeasurements, today]
  )

  return (
    <div className="row">
      <div className="col-12">
        {value && value >= 0 && (
          <FormInputNumber
            value={value}
            errorText={errorText}
            step={0.1}
            maxFractions={1}
            onChange={handleAmountChanged}
            onChangeDelay={1000}
            theme="large"
          />
        )}
        {unitLabel && <h2 className="mt-2 text-center">{unitLabel}</h2>}
      </div>
    </div>
  )
}
