import { QuantifiedFood } from './QuantifiedFood'
import { QuantifiedRecipe } from './QuantifiedRecipe'

import { ResourceReduxState } from 'Models/Common'
import { Motion, MotionLevelObject } from 'Models/Motion'

export enum FavoritesMapping {
  FOODS = 'foods',
  RECIPES = 'recipes',
  MOTIONS = 'motion-levels'
}

type FavoritesCommon = {
  id: string
  createdAt: Date | null
  updatedAt: Date | null
}

type FavoritesEatable = Required<Pick<QuantifiedFood, 'quantity' | 'unit'>>

export type AddFavoritesFood = FavoritesEatable & {
  foodId: string
}

export type AddFavoritesRecipe = FavoritesEatable & {
  recipeId: string
}

export type AddFavoritesMotionLevel = {
  motionLevelId: string
  duration: number
}

export type AddFavoritesUnion = AddFavoritesFood | AddFavoritesRecipe | AddFavoritesMotionLevel

export type FavoritesFood = FavoritesCommon & Required<QuantifiedFood>

export type FavoritesRecipe = FavoritesCommon & Required<QuantifiedRecipe>

export type FavoritesMotionLevel = FavoritesCommon & {
  weight: number
  duration: number
  energy: number
  motionLevel: MotionLevelObject & { motion: Motion }
}

export type FavoritesStateObjectsUnion = FavoritesFood | FavoritesRecipe | FavoritesMotionLevel

export type FavoritesCategories = {
  [FavoritesMapping.FOODS]: FavoritesFood[]
  [FavoritesMapping.RECIPES]: FavoritesRecipe[]
  [FavoritesMapping.MOTIONS]: FavoritesMotionLevel[]
}

export type FavoritesReduxState = ResourceReduxState<FavoritesCategories>

export type FavoritesUnion = AddFavoritesUnion | FavoritesStateObjectsUnion
