import React, { FC, LegacyRef } from 'react'
import { useWatch } from 'react-hook-form'

import { StepHeading } from '../StepHeading'

import { RenderStepType } from 'Components'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'

type Props = {
  value: ProgramTypes
  label: string
  isSelected: boolean
  onChange: () => void
  register: LegacyRef<HTMLInputElement> | undefined
}

const ProgramTypeButton: FC<Props> = ({ value, label, isSelected, onChange, register }) => {
  return (
    <div className="row justify-content-center col-10">
      <label
        style={{
          height: '100px',
          fontSize: '20px',
          userSelect: 'none',
          borderRadius: '5px',
          outline: isSelected ? '2px solid #32B6EF' : ''
        }}
        className="col-12 bg-gray-4 text-center py-3 cursor"
      >
        <input
          name="activeProgramType"
          ref={register}
          type="radio"
          value={value}
          checked={isSelected}
          onChange={onChange}
        />
        <span className="label">{label}</span>
      </label>
    </div>
  )
}

const ProgramTypeStep: React.FC<RenderStepType> = ({ register, control, setValue, errors, defaultValues = {} }) => {
  const activeProgramType = useWatch({
    name: 'activeProgramType',
    control,
    defaultValue: defaultValues?.programType?.activeProgramType
  })

  return (
    <>
      <StepHeading title="Ihr Ziel mit eBalance" />
      <div className="row col-12 justify-content-center pl-2">
        <ProgramTypeButton
          value={ProgramTypes.LOSE_WEIGHT}
          label="Gesund abnehmen"
          isSelected={activeProgramType === ProgramTypes.LOSE_WEIGHT}
          onChange={() => setValue('activeProgramType', ProgramTypes.LOSE_WEIGHT)}
          register={register}
        />
        <ProgramTypeButton
          value={ProgramTypes.KEEP_WEIGHT}
          label="Gewicht halten"
          isSelected={activeProgramType === ProgramTypes.KEEP_WEIGHT}
          onChange={() => setValue('activeProgramType', ProgramTypes.KEEP_WEIGHT)}
          register={register}
        />
        <div style={{ height: '1px' }} className="col-12 text-center input-error">
          {errors.activeProgramType?.message}
        </div>
      </div>
    </>
  )
}

export default ProgramTypeStep
export * from './config'
