import { ResourceReduxState } from 'Models/Common'
import { Gender } from 'yup-schema/Schemas/gender'
import { UserAddress } from 'yup-schema/Schemas/user'

export interface KeyCloakUserAttributes extends UserAddress {
  phoneNumber?: string
  imageUrl?: string
  gender: Gender | null
  birthday?: Date
}

export interface KeyCloakUserData {
  firstName: string
  lastName: string
}

export interface KeyCloakProfile extends Partial<KeyCloakUserData> {
  attributes: Partial<KeyCloakUserAttributes>
}

export interface UserMasterData extends KeyCloakUserData, Partial<KeyCloakUserAttributes> {}

export interface KeyCloakUser extends KeyCloakUserData {
  id: string
  username: string
  email: string
  authenticated: boolean
  attributes: KeyCloakUserAttributes
  accountUrl?: string
}

export const userRoleTypes = ['PROSPECTIVE_CUSTOMER', 'BASE_CUSTOMER', 'ACTIVE_CUSTOMER'] as const

export type UserRole = typeof userRoleTypes[number]

export interface PermissionRedux {
  roles: UserRole[]
}

export type KeyCloakUserReduxState = ResourceReduxState<KeyCloakUser> & {
  permissions: PermissionRedux
  fetchKeycloakProfilePendingCounter: number
}
