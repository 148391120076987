import { IconColor, IconName } from 'Components/Icon/Icon'
import { CardIconButton } from 'Components/IconButton'

export const COPY_LABEL = 'Kopieren'
export const EDIT_LABEL = 'Editieren'
export const MOVE_LABEL = 'Verschieben'
export const TRASH_LABEL = 'Löschen'
export const FAVORITE_LABEL = 'Favorisieren'
export const REPLACE_LABEL = 'Vorschlag wechseln'
export const MARK_AS_EATEN_LABEL = 'Als gegessen markieren'

type IconLabels =
  | typeof COPY_LABEL
  | typeof EDIT_LABEL
  | typeof MOVE_LABEL
  | typeof TRASH_LABEL
  | typeof FAVORITE_LABEL
  | typeof REPLACE_LABEL
  | typeof MARK_AS_EATEN_LABEL

type CoreProps = {
  name: IconName
  label: IconLabels
  color: IconColor
}

const setupCoreProps = (name: IconName, label: IconLabels, color = IconColor.GRAY_1): CoreProps => {
  return { name, label, color }
}

export const copyButton = (onClick: () => void): CardIconButton => {
  return { ...setupCoreProps(IconName.copy, COPY_LABEL), onClick }
}

export const moveButton = (onClick: () => void): CardIconButton => {
  return { ...setupCoreProps(IconName.move, MOVE_LABEL), onClick }
}

export const deleteButton = (onClick: () => void): CardIconButton => {
  return { ...setupCoreProps(IconName.trash, TRASH_LABEL), onClick }
}

export const editButton = (onClick: () => void): CardIconButton => {
  return { ...setupCoreProps(IconName.edit, EDIT_LABEL), onClick }
}

export const suggestionButton = (onClick: () => void, color?: IconColor): CardIconButton => {
  return { ...setupCoreProps(IconName.replace, REPLACE_LABEL, color), onClick }
}

export const favoriteButton = (onClick: () => void, isFavorite: boolean): CardIconButton => {
  if (isFavorite) {
    return { ...setupCoreProps(IconName.heartfilled, FAVORITE_LABEL, IconColor.GREEN), onClick }
  }
  return { ...setupCoreProps(IconName.heart, FAVORITE_LABEL), onClick }
}

export const markAsEatenButton = (onClick: () => void, isEaten: boolean): CardIconButton => {
  if (isEaten) {
    return { ...setupCoreProps(IconName.check, MARK_AS_EATEN_LABEL, IconColor.BLUE_2), onClick }
  }
  return { ...setupCoreProps(IconName.check, MARK_AS_EATEN_LABEL), onClick }
}
