import algoliasearch from 'algoliasearch/lite'
import { Hit } from 'react-instantsearch-core'

import { appConfig } from 'Config'
import { AlgoliaFood } from 'Models'

export type AlgoliaFoodSearchHit = AlgoliaFood & Hit

export enum AlgoliaIndexName {
  FOODS = 'foods',
  RECIPES = 'recipes',
  MOTIONS = 'motions',
  RECIPES_INSPIRATION = 'recipes-inspiration'
}

export const searchClient = algoliasearch(appConfig.algolia.applicationId, appConfig.algolia.apikey)

export const getIndexName = (indexName: AlgoliaIndexName): string => {
  return appConfig.algolia.environment ? `${indexName}-${appConfig.algolia.environment}` : indexName
}
