import React, { FC, useEffect, useMemo } from 'react'
import { FieldValues, UseFormMethods, useWatch } from 'react-hook-form'

import { RichTextInput, RichTextInputProps } from './RichTextInput'

type Props = Pick<UseFormMethods, 'setValue' | 'control' | 'register' | 'errors'> &
  RichTextInputProps & {
    name: string
    defaultValues?: FieldValues
  }

export const RichTextInputController: FC<Props> = ({
  setValue,
  register,
  errors,
  control,
  name,
  defaultValues,
  ...props
}: Props) => {
  useEffect(() => {
    register({ name })
  }, [])

  const value = useWatch({
    control,
    name,
    defaultValue: defaultValues ? defaultValues[name] : undefined
  })

  const errorText = useMemo(() => errors[name] && errors[name].message, [errors[name]])

  return (
    <RichTextInput
      onChange={(value) => setValue(name, value, { shouldValidate: true })}
      errorText={errorText}
      initialValue={value}
      {...props}
    />
  )
}
