import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { ReduxStoreState } from 'Models'
import { JournalHydrationRecord, JournalHydrationRecordsReduxState } from 'Models/journalHydrationRecords'
import { coreBackendService } from 'Services'

export const fetchJournalHydrationRecords = createAsyncThunk('hydration/fetchRecords', async (date: Date) => {
  const response = await coreBackendService.fetchHydrationRecords({ date })
  return response.data
})

export const fetchJournalHydrationGoal = createAsyncThunk('hydration/fetchGoals', async (date: Date) => {
  const response = await coreBackendService.fetchHydrationGoal({ date })
  return response.data.amount
})

export const addJournalHydrationRecord = createAsyncThunk(
  'hydration/addRecord',
  async (payload: { datetime: Date; amount: number; source: string }) => {
    const response = await coreBackendService.addHydrationrecord(payload)
    return response.data
  }
)

export const deleteJournalHydrationRecord = createAsyncThunk('hydration/deleteRecord', async (id: string) => {
  await coreBackendService.deleteHydrationrecord(id)
  return id
})

const initialState: JournalHydrationRecordsReduxState = { records: [], goal: 0 }

const hydrationRecordsSlice = createSlice({
  name: 'hydration',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchJournalHydrationRecords.pending, (state) => {
      return state
    })
    builder.addCase(fetchJournalHydrationRecords.fulfilled, (state, { payload }) => {
      return { ...state, records: payload }
    })
    builder.addCase(fetchJournalHydrationGoal.pending, (state) => {
      return state
    })
    builder.addCase(fetchJournalHydrationGoal.fulfilled, (state, { payload }) => {
      return { ...state, goal: payload }
    })
    builder.addCase(addJournalHydrationRecord.pending, (state) => {
      return state
    })
    builder.addCase(addJournalHydrationRecord.fulfilled, (state, { payload }) => {
      return { ...state, records: [...state.records, payload] }
    })
    builder.addCase(deleteJournalHydrationRecord.pending, (state) => {
      return state
    })
    builder.addCase(deleteJournalHydrationRecord.fulfilled, (state, { payload }) => {
      const recordsArrayUpdated = state.records.filter((x) => x.id !== payload)
      return { ...state, records: recordsArrayUpdated }
    })
  }
})

export const selectJournalHydrationRecords = ({
  journalHydrationRecords
}: ReduxStoreState): JournalHydrationRecord[] => {
  return journalHydrationRecords.records
}

export const selectJournalHydrationGoal = ({ journalHydrationRecords }: ReduxStoreState): number => {
  return journalHydrationRecords.goal
}

export const selectAmountDrunk = createSelector(selectJournalHydrationRecords, (records) => {
  return records.reduce((acc, cur) => acc + cur.amount, 0)
})

export default hydrationRecordsSlice.reducer
