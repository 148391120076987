import React, { FC } from 'react'
import { ConnectedProps, connect } from 'react-redux'

import { useManageEatable } from './useManageEatable'

import { CardRecipe } from 'Components/CardRecipe/CardRecipe'
import { deleteButton, editButton, favoriteButton } from 'Components/IconButton/iconButtons'
import { routes } from 'Config'
import { FavoritesMapping, Recipe } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAddOrRemoveFavorite } from 'ReduxStore/favorites/useAddOrRemoveFavorite'
import { useAppSelector } from 'ReduxStore/hooks'
import { hideUserRecipeAction } from 'ReduxStore/userRecipes'

const mapDispatchToProps = {
  hideItem: hideUserRecipeAction
}

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type RecipeCardContainerProps = PropsFromRedux & {
  recipe: Recipe
  onClick: () => void
}

const RecipeCardContainer: FC<RecipeCardContainerProps> = ({ recipe, onClick, hideItem }: RecipeCardContainerProps) => {
  const { id: itemId } = recipe
  const favoritesRecipe = useAppSelector(favoriteSelector(FavoritesMapping.RECIPES, recipe.id))
  const { onFavoriteChange } = useAddOrRemoveFavorite()

  const { handleOnEdit, handleOnDelete } = useManageEatable({
    itemId,
    editRoute: routes.myEatables.myRecipeEdit,
    hideItem
  })

  const callbackEditButton = (): void => handleOnEdit()

  const callbackDeleteButton = (): void => handleOnDelete()

  const handleFavorite = (): void => {
    onFavoriteChange(favoritesRecipe, {
      quantity: 1,
      unit: 'PORTION',
      recipeId: recipe.id
    })
  }

  return (
    <CardRecipe
      recipe={recipe}
      onClick={onClick}
      cardButtons={[]}
      iconButtons={[
        editButton(callbackEditButton),
        favoriteButton(handleFavorite, !!favoritesRecipe),
        deleteButton(callbackDeleteButton)
      ]}
    />
  )
}

export default connector(RecipeCardContainer)
