import dayjs from 'dayjs'
import { AnyAction, combineReducers } from 'redux'
import { createSelector } from 'reselect'

import {
  ADD_JOURNAL_ENERGY_MOTION_RECORD,
  ADD_JOURNAL_ENERGY_MOTION_RECORD_FULFILLED,
  ADD_JOURNAL_ENERGY_MOTION_RECORD_PENDING,
  ADD_JOURNAL_ENERGY_MOTION_RECORD_REJECTED,
  FETCH_JOURNAL_ENERGY_MOTION_RECORDS,
  FETCH_JOURNAL_ENERGY_MOTION_RECORDS_FULFILLED,
  REMOVE_JOURNAL_ENERGY_MOTION_RECORD,
  REMOVE_JOURNAL_ENERGY_MOTION_RECORD_PENDING,
  REMOVE_JOURNAL_ENERGY_MOTION_RECORD_REJECTED,
  UPDATE_JOURNAL_ENERGY_MOTION_RECORD,
  UPDATE_JOURNAL_ENERGY_MOTION_RECORD_FULFILLED
} from './actionTypes'
import { createPendingCounterReducer } from './common'

import { today } from 'Config'
import {
  JournalEnergyMotionRecord,
  JournalEnergyMotionRecordsReduxState,
  PromiseAction,
  ReduxStoreState,
  ThunkAction
} from 'Models'
import { currentJournalDateSelector } from 'ReduxStore/journalDay'
import { coreBackendService } from 'Services'
import { Utils } from 'Utils'

// Reducer
const data = (
  state: JournalEnergyMotionRecord[] = [],
  { type, payload, meta }: AnyAction
): JournalEnergyMotionRecord[] => {
  switch (type) {
    case FETCH_JOURNAL_ENERGY_MOTION_RECORDS_FULFILLED:
      return Utils.unique<JournalEnergyMotionRecord>([...state, ...payload])
    case ADD_JOURNAL_ENERGY_MOTION_RECORD_PENDING:
    case REMOVE_JOURNAL_ENERGY_MOTION_RECORD_REJECTED:
      return [...state, meta.data]
    case ADD_JOURNAL_ENERGY_MOTION_RECORD_FULFILLED: {
      return [...state, payload].filter((record: JournalEnergyMotionRecord) => record.id !== meta.data.id)
    }
    case ADD_JOURNAL_ENERGY_MOTION_RECORD_REJECTED:
    case REMOVE_JOURNAL_ENERGY_MOTION_RECORD_PENDING:
      return state.filter((record: JournalEnergyMotionRecord) => record.id !== meta.data.id)
    case UPDATE_JOURNAL_ENERGY_MOTION_RECORD_FULFILLED:
      return state.map((record: JournalEnergyMotionRecord) =>
        record.id === meta.data.id ? { ...record, ...meta.data } : record
      )
    default:
      return state
  }
}

// Actions
export const fetchJournalEnergyMotionRecordsAction = (date: Date): PromiseAction<JournalEnergyMotionRecord[]> => ({
  type: FETCH_JOURNAL_ENERGY_MOTION_RECORDS,
  payload: coreBackendService.fetchJournalEnergyMotionRecords({ date })
})

export const addJournalEnergyMotionRecordAction = (
  description: string,
  energy: number,
  duration: number,
  date: Date = today
): PromiseAction<JournalEnergyMotionRecord> => ({
  type: ADD_JOURNAL_ENERGY_MOTION_RECORD,
  payload: coreBackendService.addJournalEnergyMotionRecord(description, energy, duration, date),
  meta: {
    triggerDefaultSuccessToast: true,
    data: { id: `tmp-${Date.now()}`, description, energy, duration, date }
  }
})

export const removeJournalEnergyMotionRecordAction = (id: string): ThunkAction => async (dispatch, getState) => {
  const {
    journalEnergyMotionRecords: { data: records }
  } = getState()

  const data = records.find((r) => r.id === id)

  if (data) {
    return dispatch({
      type: REMOVE_JOURNAL_ENERGY_MOTION_RECORD,
      payload: coreBackendService.deleteJournalEnergyMotionRecord(id),
      meta: {
        triggerDefaultSuccessToast: true,
        data
      }
    })
  }
}

export const updateJournalEnergyMotionRecordAction = (
  newRecord: JournalEnergyMotionRecord
): PromiseAction<JournalEnergyMotionRecord> => ({
  type: UPDATE_JOURNAL_ENERGY_MOTION_RECORD,
  payload: coreBackendService.updateJournalEnergyMotionRecord(newRecord),
  meta: {
    triggerDefaultSuccessToast: true,
    data: { ...newRecord }
  }
})

// Selectors
export const journalEnergyMotionRecordsSelector = ({
  journalEnergyMotionRecords
}: ReduxStoreState): JournalEnergyMotionRecordsReduxState => journalEnergyMotionRecords

export const journalEnergyMotionRecordsDataSelector = ({
  journalEnergyMotionRecords
}: ReduxStoreState): JournalEnergyMotionRecord[] => journalEnergyMotionRecords.data

export const dayJournalEnergyMotionRecordsSelector = createSelector(
  [currentJournalDateSelector, journalEnergyMotionRecordsDataSelector],
  (currentJournalDate, journalEnergyMotionRecords) =>
    journalEnergyMotionRecords.filter((journalRecord) =>
      journalRecord.datetime ? dayjs(currentJournalDate).isSame(journalRecord.datetime, 'day') : false
    )
)

const asyncActionTypes = [
  FETCH_JOURNAL_ENERGY_MOTION_RECORDS,
  ADD_JOURNAL_ENERGY_MOTION_RECORD,
  UPDATE_JOURNAL_ENERGY_MOTION_RECORD,
  REMOVE_JOURNAL_ENERGY_MOTION_RECORD
]

export default combineReducers<JournalEnergyMotionRecordsReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(...asyncActionTypes)
})
