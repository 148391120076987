import _ from 'lodash'
import React, { FC, useCallback, useState } from 'react'

import { FormInputFoodAmount, ResponsiveCardBase, buildEatableDetails } from 'Components'
import { deleteButton, editButton } from 'Components/IconButton/iconButtons'
import { Amount, FavoritesRecipe, PromiseAction, Recipe } from 'Models'
import { removeFavoritesAction, updateFavoritesAction } from 'ReduxStore/favorites/favorites'
import { useAppDispatch } from 'ReduxStore/hooks'
import { Utils } from 'Utils'

type Props = {
  favorite: FavoritesRecipe
  recipe: Recipe
  onClick: () => void
}

const FavoriteRecipeOverviewCard: FC<Props> = ({ favorite, onClick }: Props) => {
  const dispatch = useAppDispatch()
  const [toggleChildrenIsOn, setToggleChildrenIsOn] = useState(false)

  const [quantifiedEatable, setQuantifiedEatable] = useState({
    recipeId: favorite.recipe.id,
    recipe: favorite.recipe,
    quantity: favorite.quantity,
    unit: favorite.unit
  })

  const amount: Amount = {
    quantity: quantifiedEatable.quantity,
    unit: quantifiedEatable.unit
  }

  const handleAmountChanged = useCallback(
    _.debounce(
      ({ unit, quantity }: Amount) =>
        dispatch(
          updateFavoritesAction(favorite.id, {
            recipeId: quantifiedEatable.recipeId,
            quantity,
            unit
          })
        ),
      1000
    ),
    []
  )

  const handleOnEdit = (): void => setToggleChildrenIsOn(!toggleChildrenIsOn)

  const handleDeleteFavorite = (): PromiseAction<void> => dispatch(removeFavoritesAction(favorite))

  const handleRecipeAmountChange = useCallback((amount: Amount): void => {
    const modifiedEatable = { recipeId: quantifiedEatable.recipeId, recipe: quantifiedEatable.recipe, ...amount }
    const { unit = 'GRAM', quantity = 0 } = modifiedEatable

    if (quantity > 0 && (quantifiedEatable.unit !== unit || quantifiedEatable.quantity !== quantity)) {
      setQuantifiedEatable(modifiedEatable)
      handleAmountChanged({ unit, quantity })
    }
  }, [])

  const details = buildEatableDetails(
    { recipeId: favorite.recipe.id, recipe: favorite.recipe },
    { quantity: favorite.quantity, unit: favorite.unit },
    'normal'
  )

  return (
    <ResponsiveCardBase
      key={favorite.id}
      onClick={onClick}
      title={favorite.recipe.name}
      isFavorite={true}
      toggleChildrenIsOn={toggleChildrenIsOn}
      details={details}
      className="is-eatable"
      imageUrl={favorite.recipe.imageUrl}
      cardButtons={[]}
      iconButtons={[editButton(handleOnEdit), deleteButton(handleDeleteFavorite)]}
    >
      <div className="standardcard-options">
        <div>
          <FormInputFoodAmount
            label=" "
            amount={amount}
            onAmountChange={handleRecipeAmountChange}
            availableUnits={Utils.availableUnits({
              recipeId: quantifiedEatable.recipeId,
              recipe: quantifiedEatable.recipe
            })}
          />
        </div>
      </div>
    </ResponsiveCardBase>
  )
}

export default FavoriteRecipeOverviewCard
