import React, { FC, useState } from 'react'
import Modal from 'react-modal'
import { ConnectedProps, connect } from 'react-redux'

import { Button, FormInputNumber, FormInputText, IconButton, IconName } from 'Components'
import { JournalEnergyMotionRecord } from 'Models'
import { updateJournalEnergyMotionRecordAction } from 'ReduxStore/journalEnergyMotionRecords'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

const mapDispatchToProps = {
  updateJournalEnergyMotionRecord: updateJournalEnergyMotionRecordAction
}

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  isOpen: boolean
  onToggle: () => void
  journalEnergyMotionRecord?: JournalEnergyMotionRecord
  addJournalEnergyMotionRecord?: (description: string, energy: number, duration: number) => void
  resetOnToggle?: boolean
}

const EnergyMotionModal: FC<Props> = ({
  isOpen,
  onToggle,
  resetOnToggle = false,
  journalEnergyMotionRecord,
  addJournalEnergyMotionRecord,
  updateJournalEnergyMotionRecord
}: Props) => {
  const [energy, setEnergy] = useState<number | undefined>(
    (journalEnergyMotionRecord && journalEnergyMotionRecord.energy) || undefined
  )

  const [duration, setDuration] = useState<number | undefined>(
    (journalEnergyMotionRecord && journalEnergyMotionRecord.duration / 60) || undefined
  )

  const [description, setDescription] = useState(
    () => (journalEnergyMotionRecord && journalEnergyMotionRecord.description) || ''
  )

  const onClose = (): void => {
    if (resetOnToggle) {
      setEnergy(undefined)
      setDuration(undefined)
      setDescription('')
    }
    onToggle()
  }

  const onSubmit = (): void => {
    if (energy && energy > 0 && duration && duration > 0 && description && description.length > 0) {
      if (!journalEnergyMotionRecord) {
        addJournalEnergyMotionRecord && addJournalEnergyMotionRecord(description, energy, duration * 60)
      } else {
        updateJournalEnergyMotionRecord({
          id: journalEnergyMotionRecord.id,
          energy,
          description,
          duration: duration * 60
        })
      }
    }
    onClose()
  }

  return (
    <Modal style={modalZIndexPriority} isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
      <div className="modal is-small">
        <IconButton name={IconName.close} onClick={onClose} />
        <div className="row">
          <div className="col-12">
            <h2>Direkter Eintrag</h2>
          </div>
          <div className="col-12 col-lg-6">
            <FormInputNumber name="duration" label="Minuten" value={duration} onChange={setDuration} />
          </div>
          <div className="col-12 col-lg-6">
            <FormInputNumber name="energy" label="kcal" value={energy} onChange={setEnergy} />
          </div>
          <div className="col-12">
            <FormInputText name="description" label="Beschreibung" value={description} onChange={setDescription} />
          </div>
          <div className="col-12">
            <Button
              id="submitJournalEnergyRecord"
              onClick={onSubmit}
              disabled={energy === undefined || duration === undefined || description === '' ? true : false}
            >
              {journalEnergyMotionRecord ? 'Speichern' : 'Hinzufügen'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default connector(EnergyMotionModal)
