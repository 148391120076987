type FormAnswerOption<T = string> = {
  value: T
  label: string
}

import { SelectItemKeyExtractor, SelectItemRenderer, SelectItemType } from 'Components/common/SelectPickerProps'

export const formAnswerOptionLabelExtractor: SelectItemRenderer<SelectItemType, string> = (option) =>
  (option as FormAnswerOption).label

export const formAnswerOptionKeyExtractor: SelectItemKeyExtractor<SelectItemType> = (option) =>
  (option as FormAnswerOption).value
