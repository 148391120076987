import React, { FC, useLayoutEffect } from 'react'

import { FormWizard, FormWizardProps } from './FormWizard'
import { UseFormWizardReturnType } from './useFormWizard'

type Props = Omit<FormWizardProps, 'onGoBack'> &
  Pick<UseFormWizardReturnType<never>, 'goToPreviousStep' | 'renderStep'> & {
    isRestart: boolean
    showBackButton?: boolean
    showSubmitButton?: boolean
    startButtonText?: string
  }

export const FormWizardContainer: FC<Props> = ({
  isRestart,
  currentStep,
  currentStepIndex,
  knownNumberOfSteps,
  totalNumberOfSteps,
  canGoBack,
  isLastStep,
  lastStepButtonText = 'Programm starten',
  isSubmitting,
  goToPreviousStep,
  onSubmit,
  renderStep,
  showBackButton,
  showSubmitButton,
  startButtonText
}) => {
  useLayoutEffect(() => {
    if (currentStep.scrollTop) {
      window.scrollTo(0, 0)
    }
  }, [currentStep])

  return (
    <FormWizard
      isRestart={isRestart}
      currentStep={currentStep}
      currentStepIndex={currentStepIndex}
      knownNumberOfSteps={knownNumberOfSteps}
      totalNumberOfSteps={totalNumberOfSteps}
      canGoBack={canGoBack}
      isLastStep={isLastStep}
      isSubmitting={isSubmitting}
      lastStepButtonText={lastStepButtonText}
      onGoBack={goToPreviousStep}
      onSubmit={onSubmit}
      showBackButton={showBackButton}
      showSubmitButton={showSubmitButton}
      startButtonText={startButtonText}
    >
      {renderStep()}
    </FormWizard>
  )
}
