import React from 'react'

import { FormInputNumberController, RenderStepType } from 'Components'

export const AnamnesisCurrentWeightQuestion: React.FC<Omit<RenderStepType, 'setError' | 'isRestart'>> = ({
  register,
  setValue,
  errors,
  control,
  defaultValues
}) => {
  return (
    <div className="col-12 col-lg-4">
      <FormInputNumberController
        name="currentWeight"
        label="Gewicht in kg"
        maxFractions={1}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
        defaultValues={defaultValues}
      />
    </div>
  )
}

export const AnamnesisHeightAndBellyQuestions: React.FC<Omit<RenderStepType, 'setError' | 'isRestart'>> = ({
  register,
  setValue,
  errors,
  control,
  defaultValues
}) => {
  return (
    <>
      <div className="col-12 col-lg-4">
        <FormInputNumberController
          name="height"
          label="Körpergrösse in cm"
          register={register}
          setValue={setValue}
          errors={errors}
          control={control}
          defaultValues={defaultValues}
        />
      </div>
      <div className="col-12 col-lg-4">
        <FormInputNumberController
          name="bellyCircumference"
          label="Bauchumfang in cm"
          register={register}
          setValue={setValue}
          errors={errors}
          control={control}
          defaultValues={defaultValues}
        />
      </div>
    </>
  )
}
