import { today } from 'Config'
import { Anamnesis } from 'Models'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'
import { postAnamnesisAction, updateAnamnesisAction } from 'ReduxStore/anamnesis'
import { createOrUpdateBodyMeasurementsAction } from 'ReduxStore/bodyMeasurements'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { keyCloakUserSelector } from 'ReduxStore/keyCloakUser'
import { postProgramAction } from 'ReduxStore/programs'

type Props = {
  bodyCriterion: 'HEIGHT' | 'WEIGHT'
  newValue: number
  wasPreviouslyNormalWeight: boolean
  isNowUnderWeight: boolean
  isNowLowWeight: boolean
  isNowOverWeight: boolean
  currentAnamnesis: Anamnesis | null
  anamnesisProps?: { birthday: Date }
  newProgramWeight: number
  openWeightWarningModal: (props: { programType: ProgramTypes }) => void
}

export const useUpdateBodyMeasurmentBMIBoundary = (): ((
  props: Props
) => Promise<{ hasUpdatedWithoutWarning: boolean }>) => {
  const dispatch = useAppDispatch()
  const { attributes } = useAppSelector(keyCloakUserSelector)
  return async ({
    bodyCriterion,
    newValue,
    wasPreviouslyNormalWeight,
    isNowUnderWeight,
    isNowLowWeight,
    isNowOverWeight,
    currentAnamnesis,
    anamnesisProps,
    newProgramWeight,
    openWeightWarningModal
  }: Props) => {
    await dispatch(
      createOrUpdateBodyMeasurementsAction(
        {
          datetime: today,
          bodyCriterion: bodyCriterion,
          value: Number(newValue)
        },
        { withDefaultSuccessToast: true }
      )
    )
    if (
      wasPreviouslyNormalWeight &&
      isNowUnderWeight &&
      currentAnamnesis?.activeProgramType === ProgramTypes.KEEP_WEIGHT
    ) {
      await dispatch(updateAnamnesisAction({ ...currentAnamnesis, ...anamnesisProps }))
      openWeightWarningModal({ programType: currentAnamnesis.activeProgramType })
      return { hasUpdatedWithoutWarning: false }
    }

    if (wasPreviouslyNormalWeight && isNowOverWeight) {
      openWeightWarningModal({ programType: currentAnamnesis?.activeProgramType ?? ProgramTypes.KEEP_WEIGHT })
      return { hasUpdatedWithoutWarning: false }
    }

    if (attributes.gender && currentAnamnesis?.activeProgramType === ProgramTypes.LOSE_WEIGHT) {
      if (isNowLowWeight) {
        dispatch(
          postAnamnesisAction({
            ...currentAnamnesis,
            ...anamnesisProps,
            activeProgramType: ProgramTypes.KEEP_WEIGHT,
            weightToLose: 0,
            weightGainThreshold: undefined,
            shouldDisplayWeightGainWarning: false,
            gender: attributes.gender
          })
          // @ts-ignore
        ).then(({ value: anamnesis }) => {
          dispatch(
            postProgramAction({
              anamnesisId: anamnesis.id,
              bodyCriterion: 'WEIGHT',
              startValue: newProgramWeight,
              goalDate: new Date(),
              goalValue: newProgramWeight
            })
          )
            // @ts-ignore
            .then(() => {
              openWeightWarningModal({ programType: anamnesis.activeProgramType })
            })
        })
        return { hasUpdatedWithoutWarning: false }
      }
    }
    return { hasUpdatedWithoutWarning: true }
  }
}
