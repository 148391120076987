/**
 * This is a controlled component.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import React, {
  ChangeEvent,
  Fragment,
  KeyboardEvent,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useRandomName } from 'Components/common/useRandom'
import { unicodeNormalization } from 'Utils/helpers/unicodeNormalization'

const newItemIdPrefix = 'new-item'
const optionIdPrefix = 'option'
const dropdownIdPrefix = 'dropdown'

export type AutocompleteItemType = Record<string, any>

export type FormInputAutocompleteProps<ItemType extends AutocompleteItemType> = {
  label?: string
  /** a list of items as input suggestions */
  items: ItemType[]
  /** the name of the item's property holding the ui display label */
  itemRenderKey?: string
  /** the name of the item's property holding a unique identifier */
  itemIdKey?: string
  /** the identifier of an already selected item */
  selectedItemId?: string
  maxDisplayItems?: number
  onChange?: (value: ItemType | null, isNew: boolean) => void
  sortItemsFunction?: (a: ItemType, b: ItemType) => number
  errorText?: string
  allowNewItem?: boolean
  inputRef?: RefObject<HTMLInputElement>
}

export const FormInputAutocomplete = <ItemType extends AutocompleteItemType>({
  label,
  items,
  itemRenderKey = 'name',
  itemIdKey: itemIdKey = 'id',
  selectedItemId,
  maxDisplayItems = 10,
  onChange,
  sortItemsFunction,
  errorText,
  allowNewItem = false,
  inputRef
}: FormInputAutocompleteProps<ItemType>): ReactElement => {
  const [inputText, setInputText] = useState<string>('')
  const [suggestedIndex, setSuggestedIndex] = useState<number>()
  const [showFilter, setShowFilter] = useState(false)

  const dropdownId = useRandomName(dropdownIdPrefix)

  useEffect(() => {
    if (items && selectedItemId) {
      const index = items.findIndex((item) => item[itemIdKey] === selectedItemId)
      if (index < 0) {
        return
      }
      setInputText(items[index][itemRenderKey])
    }
  }, [items, selectedItemId])

  // Implement sort
  const suggestionPattern = useMemo(() => new RegExp(`^${_.escapeRegExp(inputText.trim())}`, 'iu'), [inputText])
  const isNewItemAtIndex = (index: number): boolean => allowNewItem && index === 0
  const filteredItems: ItemType[] = useMemo(() => {
    if (!inputText) return []
    const filteredList = items
      .filter((item: ItemType): boolean =>
        unicodeNormalization(item[itemRenderKey]).startsWith(unicodeNormalization(inputText))
      )
      .slice(0, maxDisplayItems)
    sortItemsFunction && filteredList.sort(sortItemsFunction)

    return [
      // optionally add virtual item for new from user input
      ...(allowNewItem ? [{ [itemIdKey]: newItemIdPrefix, [itemRenderKey]: inputText } as ItemType] : []),
      //
      ...filteredList
    ]
  }, [items, inputText, allowNewItem])

  const setItemSelection = useCallback(
    (index: number) => {
      !(allowNewItem && index === 0) && setInputText(filteredItems[index][itemRenderKey])
      let item = filteredItems[index]
      const isNew = isNewItemAtIndex(index)
      if (isNew) {
        item = { ...item, [itemIdKey]: null }
      }

      onChange && onChange(item, isNew)
    },
    [filteredItems, allowNewItem, onChange]
  )

  const buildItemId = (item?: ItemType): string => {
    if (item === undefined) return ''
    return `${dropdownId}-${optionIdPrefix}-${item[itemIdKey]}`
  }

  const renderDropdownList = useCallback(() => {
    return filteredItems.map((item, index) => {
      const isSuggested = suggestedIndex === index
      const formattedTitle = isNewItemAtIndex(index) ? 'Eigene Angabe' : item[itemRenderKey]

      return (
        <Fragment key={index}>
          <li
            id={buildItemId(item)}
            role="option"
            onMouseDown={() => setItemSelection(index)}
            className={isSuggested ? 'is-suggested' : ''}
            aria-selected={isSuggested}
            dangerouslySetInnerHTML={{ __html: formattedTitle }}
          />
        </Fragment>
      )
    })
  }, [filteredItems, inputText, suggestedIndex, allowNewItem, dropdownId])

  const splitSuggestion = useMemo(() => {
    if (!inputText) return ''
    const [firstMatch] = items.filter((item) => suggestionPattern.test(item[itemRenderKey]))
    return firstMatch ? `${inputText}${firstMatch[itemRenderKey].substring(inputText.length)}` : ''
  }, [inputText])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>): void => {
      if (e.key !== 'Enter' && e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return
      e.preventDefault()

      if (e.key === 'Enter' && suggestedIndex !== undefined && filteredItems[suggestedIndex]) {
        setItemSelection(suggestedIndex)
        setShowFilter(false)
      }
      if (e.key === 'ArrowUp' && Number(suggestedIndex) > 0 && filteredItems.length > 1) {
        setSuggestedIndex(Number(suggestedIndex) - 1)
      }
      if (e.key === 'ArrowDown' && filteredItems.length > 0) {
        if (Number(suggestedIndex) < filteredItems.length - 1) {
          setSuggestedIndex(Number(suggestedIndex) + 1)
        }
      }
    },
    [filteredItems, suggestedIndex]
  )

  useEffect(() => {
    const index = filteredItems.findIndex((item) => suggestionPattern.test(item[itemRenderKey]))
    setSuggestedIndex(index)
  }, [filteredItems, suggestionPattern, itemRenderKey])

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputText(e.target.value)
    setShowFilter(true)
  }

  const handleOnFocus = (): void => {
    setShowFilter(true)
  }

  const handleOnBlur = useCallback(() => {
    setShowFilter(false)
    if (inputText === '') {
      onChange && onChange(null, selectedItemId === undefined)
    }
  }, [filteredItems])

  const ariaActiveId = useMemo(() => {
    if (suggestedIndex === undefined) return ''
    return buildItemId(filteredItems[suggestedIndex])
  }, [filteredItems, suggestedIndex, itemIdKey])

  return (
    <div className="input">
      {showFilter && <input type="text" className="autocomplete-suggestion" disabled value={splitSuggestion} />}
      <input
        role="combobox"
        aria-autocomplete="list"
        aria-expanded={showFilter}
        aria-controls={dropdownId}
        aria-activedescendant={ariaActiveId}
        ref={inputRef}
        value={inputText}
        type="text"
        autoComplete="off"
        placeholder=" "
        className="autocomplete-input"
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {label && <label>{label}</label>}
      {showFilter ? (
        <ul className="autocomplete-dropdown" role="listbox" id={dropdownId}>
          {renderDropdownList()}
        </ul>
      ) : null}
      {errorText && <div className="input-error">{errorText}</div>}
    </div>
  )
}
