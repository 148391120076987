import React, { useContext, useEffect, useState } from 'react'
import { Hit } from 'react-instantsearch-core'
import { Index, InfiniteHits } from 'react-instantsearch-dom'

import 'styles/globals.css'
import styles from './RecipesInspirationPage.module.css'
import { FocusedRecipeHook } from './RecipesInspirationPageTypes'

import { CustomSearchBox } from 'Components/Search/searchHelpers'
import { buildCdnImageUrl } from 'Config'
import { Recipe } from 'Models'
import RecipeModal from 'Pages/JournalPage/components/Modals/RecipeModal/RecipeModal'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { fetchRecipeAction, recipesSelector } from 'ReduxStore/recipes'
import { Formatter } from 'Utils'
import { AlgoliaIndexName, getIndexName } from 'Utils/algoliaSearchHelpers'

const RecipeHitContext = React.createContext({} as { initQuery: (id: string) => void })

const useSelectedRecipe = (): FocusedRecipeHook => {
  const dispatch = useAppDispatch()
  const [queryId, setQueryId] = useState<string | undefined>(undefined)
  const queriedRecipes = useAppSelector(recipesSelector)

  const getRecipe = (): Recipe | undefined => {
    return queriedRecipes.find((recipe) => recipe.id === queryId)
  }

  const initQuery = (id: string | undefined): void => {
    setQueryId(id)
  }

  const resetModal = (): void => {
    setQueryId(undefined)
  }

  const fetchRecipe = (id: string): void => {
    dispatch(fetchRecipeAction(id))
  }

  useEffect(() => {
    if (queryId === undefined) {
      return
    }
    if (getRecipe() !== undefined) {
      return
    }
    fetchRecipe(queryId as string)
  })
  return { initQuery, getRecipe, resetModal }
}

export const RecipesInspirationResult: React.FC = () => {
  const { initQuery, getRecipe, resetModal } = useSelectedRecipe()
  const recipe = getRecipe()

  return (
    <>
      <RecipeHitContext.Provider value={{ initQuery }}>
        <div className="col-12 col-lg-9">
          <div className="pl-2 pr-2">
            <CustomSearchBox />
          </div>
          <SearchResults />
        </div>
        <RecipeModal
          quantifiedRecipe={{ recipe: recipe, quantity: recipe?.defaultServingsCount, unit: 'PORTION' }}
          onClose={() => resetModal()}
        />
      </RecipeHitContext.Provider>
    </>
  )
}

const SearchResults: React.FC = () => {
  return (
    <Index indexName={getIndexName(AlgoliaIndexName.RECIPES_INSPIRATION)}>
      <div className={styles.recipeGrid}>
        <SearchRecipeHit hitComponent={RecipeHit} />
      </div>
    </Index>
  )
}

const SearchRecipeHit: React.FC<{
  hitComponent: React.FC<{ hit: Recipe }>
  hits?: Hit[]
}> = (props) => (
  <InfiniteHits
    {...props}
    translations={{
      loadMore: 'Mehr anzeigen …'
    }}
  />
)

const RecipeHit: React.FC<{ hit: Recipe }> = ({ hit }) => {
  const { initQuery } = useContext(RecipeHitContext)
  const { imageUrl, ...recipeContent } = hit

  return (
    <div onClick={() => initQuery(hit.id)} className="recipecard fullHeight flexColumn">
      <RecipeImage imageUrl={imageUrl} />
      <RecipeContent recipeContent={recipeContent} />
    </div>
  )
}

const RecipeImage: React.FC<{ imageUrl: string | null | undefined }> = ({ imageUrl }) => {
  return (
    <div className={`recipecard-image ${styles.image}`}>
      <img src={imageUrl ? `${imageUrl}&width=350` : buildCdnImageUrl('standardcard-eatable.svg')} />
    </div>
  )
}

const RecipeContent: React.FC<{ recipeContent: Recipe }> = ({ recipeContent }) => {
  const { name, preparationTime, nutritionsPerPortion, diet } = recipeContent
  return (
    <div className="col recipecard-content fullHeight">
      <div className="fullHeight flexColumn">
        <h5 className="col">{name}</h5>
        <div>{diet}</div>
        <div className="d-flex justify-content-between">
          <span>{Formatter.formatEnergy(nutritionsPerPortion.energy)}</span>
          <span>{Formatter.formatDurationInMinutes(preparationTime)}</span>
        </div>
      </div>
    </div>
  )
}
