import { FavoriteTabNames } from './FavoriteTabNames'

import { SectionListItem, getSectionListSortedByNormalizedName } from 'Components'
import { NormalizedName, addNormalizedNameProp } from 'Components/SectionList/sortItemList'
import { FavoritesFood, FavoritesMotionLevel, FavoritesRecipe } from 'Models'

const favoriteEatablesWithNormalizedName = (
  favoriteEatables: (FavoritesFood | FavoritesRecipe)[]
): ((FavoritesFood | FavoritesRecipe) & NormalizedName)[] =>
  favoriteEatables.map((favorite) => {
    if ('recipe' in favorite) {
      return addNormalizedNameProp(favorite, favorite.recipe.name)
    }
    return addNormalizedNameProp(favorite, favorite.food.name)
  })

type GetFavoriteEatableSectionListArgs = {
  key: string
  favoriteSystemFoods: FavoritesFood[]
  favoriteSystemRecipes: FavoritesRecipe[]
  favoriteUserEatables: (FavoritesFood | FavoritesRecipe)[]
}

export const getFavoriteEatablesSectionList = ({
  key,
  favoriteSystemFoods,
  favoriteSystemRecipes,
  favoriteUserEatables
}: GetFavoriteEatableSectionListArgs): SectionListItem<(FavoritesFood | FavoritesRecipe) & NormalizedName>[] => {
  const favoriteEatables =
    key === FavoriteTabNames.FOODS
      ? favoriteSystemFoods
      : key === FavoriteTabNames.RECIPES
      ? favoriteSystemRecipes
      : favoriteUserEatables

  return getSectionListSortedByNormalizedName(favoriteEatablesWithNormalizedName(favoriteEatables))
}

export const getFavoriteMotionsSectionList = (
  favoritesMotionLevels: FavoritesMotionLevel[]
): SectionListItem<FavoritesMotionLevel & NormalizedName>[] =>
  getSectionListSortedByNormalizedName(
    favoritesMotionLevels.map((favorite) => addNormalizedNameProp(favorite, favorite.motionLevel.motion.name))
  )
