import { AnyAction, combineReducers } from 'redux'

import { EatableSource, EatableSourcePost, EatableSourcesReduxState, PromiseAction, ReduxStoreState } from 'Models'
import {
  ADD_EATABLE_SOURCES,
  ADD_EATABLE_SOURCES_FULFILLED,
  FETCH_EATABLE_SOURCES,
  FETCH_EATABLE_SOURCES_FULFILLED
} from 'ReduxStore/actionTypes'
import { createPendingCounterReducer } from 'ReduxStore/common'
import { coreBackendService } from 'Services'

// Actions
export const fetchEatableSourcesAction = (): PromiseAction<EatableSource[]> => ({
  type: FETCH_EATABLE_SOURCES,
  payload: coreBackendService.fetchEatableSources()
})

export const addEatableSourcesAction = (source: EatableSourcePost): PromiseAction<EatableSource> => ({
  type: ADD_EATABLE_SOURCES,
  payload: coreBackendService.addEatableSources(source)
})

// Reducer & initialState
export const initialState: EatableSource[] = []

const data = (state = initialState, { type, payload }: AnyAction): EatableSource[] => {
  switch (type) {
    case ADD_EATABLE_SOURCES_FULFILLED:
      return [...state, payload]
    case FETCH_EATABLE_SOURCES_FULFILLED:
      return payload || initialState
    default:
      return state
  }
}

// Selectors
export const eatableSourcesSelector = ({ eatableSources }: ReduxStoreState): EatableSource[] => eatableSources.data

export default combineReducers<EatableSourcesReduxState>({
  data,
  pendingCounter: createPendingCounterReducer(FETCH_EATABLE_SOURCES)
})
