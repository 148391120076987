import React, { FC } from 'react'
import { UseFormMethods, useWatch } from 'react-hook-form'
import { ConnectedProps, connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { FormInputSelect } from 'Components'
import { FoodCategory, ReduxStoreState } from 'Models'
import { foodCategoriesSelector } from 'ReduxStore/foodCategories'

type FoodFormSelectorProps = {
  foodCategoriesMain: FoodCategory[]
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, FoodFormSelectorProps>({
  foodCategoriesMain: foodCategoriesSelector
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & Pick<UseFormMethods, 'register' | 'errors' | 'control'>

const FoodCategoriesSelect: FC<Props> = ({ foodCategoriesMain, register, control, errors }: Props) => {
  const foodCategoryId = useWatch({
    control,
    name: 'foodCategoryId',
    defaultValue: undefined
  })

  const { subCategories = [] } = foodCategoryId
    ? { ...foodCategoriesMain.find((cat) => cat.id === foodCategoryId) }
    : ({} as FoodCategory)

  return foodCategoriesMain.length > 0 ? (
    <>
      <div className="col-12 col-lg-6">
        <FormInputSelect
          label="Kategorie"
          name="foodCategoryId"
          items={foodCategoriesMain}
          keyExtractor={(category) => category.id}
          labelExtractor={(category) => category.name}
          register={register}
          errors={errors}
        />
      </div>

      <div className="col-12 col-lg-6">
        <FormInputSelect
          label="Unterkategorie"
          name="foodSubCategoryId"
          items={subCategories}
          keyExtractor={(category) => category.id}
          labelExtractor={(category) => category.name}
          register={register}
          errors={errors}
        />
      </div>
    </>
  ) : null
}

export default connector(FoodCategoriesSelect)
