import React, { FC } from 'react'

export type NotificationTheme = 'error' | 'warning' | 'success' | 'info' | 'placeholder'

type NotificationProps = {
  theme: NotificationTheme
}

export const Notification: FC<NotificationProps> = ({ children, theme }) => {
  return <div className={`notification is-${theme}`}>{children}</div>
}
