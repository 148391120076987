import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { defaultValuesFirstAnamnesis } from './defaultValues'
import { firstAnamnesisSteps, restartAnamnesisSteps } from './steps'
import { AnamnesisFormValues, AnamnesisFormValuesByStepId, ProgramTypes } from './types'

import { FormWizardContainer, useFormWizard } from 'Components/FormWizard'
import { routes, today } from 'Config'
import { postAnamnesisAction } from 'ReduxStore/anamnesis'
import { createOrUpdateBodyMeasurementsAction } from 'ReduxStore/bodyMeasurements'
import {
  coreBackendUserInfoSelector,
  resetGoalNutritions,
  updateCoreBackendUserAction
} from 'ReduxStore/coreBackendUser'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { keyCloakUserSelector } from 'ReduxStore/keyCloakUser'
import { postProgramAction } from 'ReduxStore/programs'
import { updateUserSettingsAction } from 'ReduxStore/userSettings'
import { Calculator } from 'Utils'

type Props = {
  isRestart: boolean
  defaultValuesForRestart?: AnamnesisFormValuesByStepId
}

const AnamnesisWizard: FC<Props> = ({ defaultValuesForRestart, isRestart = false }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const coreBackendUserInfo = useAppSelector(coreBackendUserInfoSelector)
  const { attributes } = useAppSelector(keyCloakUserSelector)

  const handleFormSubmit = useCallback(
    (values) => {
      const hasLoseWeightProgram = values.activeProgramType === ProgramTypes.LOSE_WEIGHT
      const bmi = Calculator.calculateBMI(values.currentWeight, values.height)

      if (hasLoseWeightProgram && bmi < 20) {
        values = {
          ...values,
          activeProgramType: ProgramTypes.KEEP_WEIGHT,
          weightGainThreshold: 3,
          shouldDisplayWeightGainWarning: true,
          weightToLose: 0
        }
      } else if (hasLoseWeightProgram) {
        values = { ...values, shouldDisplayWeightGainWarning: false, weightGainThreshold: undefined }
      } else {
        values = { ...values, weightGainThreshold: Number(values.weightGainThreshold), weightToLose: 0 }
      }
      if (!values) return
      dispatch(
        postAnamnesisAction({
          ...values,
          gender: attributes.gender
        })
      ) // @ts-ignore
        .then(({ value: anamnesis }) => {
          dispatch(
            postProgramAction({
              anamnesisId: anamnesis.id,
              bodyCriterion: 'WEIGHT',
              startValue: values.currentWeight as number,
              goalDate: values.goalDate,
              goalValue:
                values.activeProgramType === ProgramTypes.LOSE_WEIGHT
                  ? Number((values.currentWeight - values.weightToLose).toFixed(1))
                  : (values.currentWeight as number)
            })
          ) // @ts-ignore
            .then(() => {
              if (defaultValuesForRestart) {
                dispatch(resetGoalNutritions())
                history.push(routes.journal)
              }
            })
        })
      dispatch(
        updateCoreBackendUserAction({
          diet: values.diet
        })
      )

      dispatch(
        createOrUpdateBodyMeasurementsAction(
          {
            datetime: today,
            bodyCriterion: 'HEIGHT',
            value: Number(values.height)
          },
          { withDefaultSuccessToast: false }
        )
      )

      values.bellyCircumference &&
        dispatch(
          createOrUpdateBodyMeasurementsAction(
            {
              datetime: today,
              bodyCriterion: 'BELLY',
              value: values.bellyCircumference
            },
            { withDefaultSuccessToast: false }
          )
        )
      if (!isRestart) {
        dispatch(updateUserSettingsAction({ menuPlanHidden: !values.isMenuPlanEnabled }))
      }
    },
    [coreBackendUserInfo, attributes]
  )

  const {
    currentStep,
    currentStepIndex,
    numberOfSteps,
    goToPreviousStep,
    canGoBack,
    isLastStep,
    onSubmit,
    renderStep,
    isSubmitting,
    formValues
  } = useFormWizard<AnamnesisFormValues>({
    steps: isRestart ? restartAnamnesisSteps() : firstAnamnesisSteps(),
    dynamicDefaultValues: isRestart ? defaultValuesForRestart : defaultValuesFirstAnamnesis,
    handleFormSubmit,
    isRestart: isRestart
  })

  const height = formValues?.profile?.height ?? null
  const weight = formValues?.profile?.currentWeight ?? null
  const programType = formValues?.programType?.activeProgramType

  let showBackButton = true
  let showSubmitButton = true

  if (isLastStep) {
    if (Calculator.isUserUnderweight(weight, height)) {
      showBackButton = false
      showSubmitButton = false
    }
    if (Calculator.isUserLowWeight(weight, height)) {
      if (programType === ProgramTypes.LOSE_WEIGHT) {
        showBackButton = false
      }
    }
  }

  return (
    <FormWizardContainer
      isRestart={isRestart}
      onSubmit={onSubmit}
      currentStep={currentStep}
      currentStepIndex={currentStepIndex}
      totalNumberOfSteps={numberOfSteps}
      goToPreviousStep={goToPreviousStep}
      canGoBack={canGoBack}
      isLastStep={isLastStep}
      renderStep={renderStep}
      isSubmitting={isSubmitting}
      showBackButton={showBackButton}
      showSubmitButton={showSubmitButton}
      startButtonText={!isRestart && currentStepIndex === 0 ? 'Starten' : undefined}
    />
  )
}

export default AnamnesisWizard
