import { NormalizedName, sortAlphabetically, sortByNormalizedNameProp } from 'Components/SectionList/sortItemList'

export type SectionListItem<T> = {
  header: string
  data: T[]
}

export const getSectionListSortedByNormalizedName = <T extends NormalizedName>(items: T[]): SectionListItem<T>[] => {
  const sortedItems = sortByNormalizedNameProp(items)
  return sortedItems
    .reduce((itemsInSections, item) => {
      const itemSectionHeader = item.normalizedName[0].replace(/[ÉÈ]/i, 'E')
      const isSectionAlreadyCreated = itemsInSections.find((section) => section.header === itemSectionHeader)
      if (!isSectionAlreadyCreated) {
        return [...itemsInSections, { header: itemSectionHeader, data: [item] }]
      }

      itemsInSections.forEach((section) => {
        if (section.header === itemSectionHeader) {
          section.data.push(item)
        }
      })

      return itemsInSections
    }, [] as SectionListItem<T>[])
    .sort((a, b) => sortAlphabetically(a.header, b.header))
}
