import React, { useState } from 'react'

import { FormInputRange, FormInputToggle, RenderStepType } from 'Components'

const gap = 'mt-0 pt-0 mb-2'

export const AnamnesisLoseWeightQuestion: React.FC<Pick<RenderStepType, 'register' | 'errors' | 'defaultValues'>> = ({
  register,
  errors,
  defaultValues = {}
}) => {
  const [weightToLose, setWeightToLose] = useState(defaultValues.weightToLose)

  return (
    <div className="col-12">
      <h4 className={gap}>Wie viele Kilos möchten Sie verlieren?</h4>
      <FormInputRange
        name="weightToLose"
        minimumValue={1}
        maximumValue={52}
        defaultValue={weightToLose}
        register={register}
        errors={errors}
        onChange={(period) => setWeightToLose(period)}
      />
    </div>
  )
}

export const AnamnesisKeepWeightQuestions: React.FC<Pick<RenderStepType, 'register' | 'errors' | 'defaultValues'>> = ({
  register,
  errors,
  defaultValues = {}
}) => {
  const [shouldDisplayWeightGainWarning, setShouldDisplayWeightGainWarning] = useState(
    defaultValues.shouldDisplayWeightGainWarning
  )
  const [weightGainThreshold, setWeightGainThreshold] = useState(defaultValues.weightGainThreshold)

  return (
    <div className="align-items-center justify-content-between">
      <div className="row">
        <div className="col-12">
          <h4 className={gap}>Möchten Sie einen Hinweis bei Gewichtszunahme erhalten?</h4>
          <FormInputToggle
            name="shouldDisplayWeightGainWarning"
            register={register}
            errors={errors}
            onValueChange={() => setShouldDisplayWeightGainWarning(!shouldDisplayWeightGainWarning)}
          />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-12">
          <h4 className={gap}>Ab welcher Zunahme (kg) möchten Sie den Hinweis erhalten?</h4>
          <p className="text-small mb-2">Der Hinweis erscheint beim Eintragen des Gewichtes</p>
          <FormInputRange
            name="weightGainThreshold"
            isDisabled={!shouldDisplayWeightGainWarning}
            inputStep="0.5"
            minimumValue={1}
            maximumValue={6}
            onChange={(threshold: number) => setWeightGainThreshold(threshold)}
            defaultValue={weightGainThreshold}
            register={register}
            errors={errors}
          />
        </div>
      </div>
    </div>
  )
}
