import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useUpdateBodyMeasurmentBMIBoundary } from './useUpdateBodyMeasurmentBMIBoundary'

import { Button, DatePickerController, FormInputNumberController, PageLayout } from 'Components'
import { date18YearsAgo, getAge, routes } from 'Config'
import { useFetchLatestAnamnesis } from 'Pages/AnamnesisPage/AnamnesisPage'
import { EnergyLevelLabel, EnergyPlanCard, LabelMapping } from 'Pages/AnamnesisPage/steps/EnergyPlanStep/EnergyPlanCard'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'
import WeightWarningModal from 'Pages/MassPage/WeightWarningModal'
import { getAnamnesisDataSelector, updateAnamnesisAction } from 'ReduxStore/anamnesis'
import { currentBodyMeasurementValuesSelector, fetchLatestBodyMeasurementsAction } from 'ReduxStore/bodyMeasurements'
import { resetGoalNutritions } from 'ReduxStore/coreBackendUser'
import { energyRequirementsSelector, fetchEnergyRequirementsAction } from 'ReduxStore/energyRequirements'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { Calculator } from 'Utils'
import { anamnesisSchema } from 'yup-schema/Schemas/anamnesis'

const BaseDataPage: FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const energyRequirements = useAppSelector(energyRequirementsSelector)
  const anamnesis = useAppSelector(getAnamnesisDataSelector)
  const currentBodyMeasurments = useAppSelector(currentBodyMeasurementValuesSelector)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showEnergyRequirements, setShowEnergyRequirements] = useState(false)

  const [weightWarningModalProps, setWeightWarningModalProps] = useState<{
    height?: number | null
    weight?: number
    programType?: ProgramTypes
    isOpen: boolean
  }>({ isOpen: false })

  useFetchLatestAnamnesis()
  const updateBodyMeasurmentsBMIBoundary = useUpdateBodyMeasurmentBMIBoundary()

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(anamnesisSchema.pick(['birthday', 'height']))
  })

  const defaultValues = {
    birthday: anamnesis && new Date(anamnesis.birthday),
    height: currentBodyMeasurments.height
  }

  useEffect(() => {
    dispatch(fetchLatestBodyMeasurementsAction({ type: 'HEIGHT' }))
    dispatch(fetchLatestBodyMeasurementsAction({ type: 'WEIGHT' }))
  }, [])

  useEffect(() => {
    if (!isSubmitted) {
      setValue('birthday', defaultValues.birthday)
      setValue('height', defaultValues.height)
    }
  }, [anamnesis])

  const onSubmit: SubmitHandler<{ height: number; birthday: Date }> = async ({ height, birthday }): Promise<void> => {
    setIsSubmitted(true)

    if (anamnesis) {
      dispatch(updateAnamnesisAction({ ...anamnesis, birthday }))
      dispatch(
        fetchEnergyRequirementsAction({
          age: getAge(birthday),
          gender: anamnesis.gender,
          height,
          overweightForTwoYears: anamnesis.overweightForTwoYears,
          parentsOverweight: anamnesis.parentsOverweight,
          previousFailureCount: anamnesis.previousFailureCount,
          professionalMotionLevel: anamnesis.professionalMotionLevel,
          weight: currentBodyMeasurments.currentWeight,
          weightToLose: anamnesis.weightToLose ?? 0,
          workingHours: anamnesis.workingHours
        })
      )

      dispatch(resetGoalNutritions())
    }
    const latestHeight = currentBodyMeasurments.height
    const wasPreviouslyNormalWeight = Calculator.isUserNormalWeight(
      currentBodyMeasurments.currentWeight ?? null,
      latestHeight ?? null
    )
    const isNowUnderWeight = Calculator.isUserUnderweight(currentBodyMeasurments.currentWeight ?? null, height ?? null)
    const isUserLowWeight = Calculator.isUserLowWeight(currentBodyMeasurments.currentWeight ?? null, height ?? null)
    const isUserOverweight = Calculator.isUserOverweight(currentBodyMeasurments.currentWeight ?? null, height ?? null)

    const { hasUpdatedWithoutWarning } = await updateBodyMeasurmentsBMIBoundary({
      bodyCriterion: 'HEIGHT',
      newValue: height,
      wasPreviouslyNormalWeight,
      isNowUnderWeight,
      isNowLowWeight: isUserLowWeight,
      isNowOverWeight: isUserOverweight,
      currentAnamnesis: anamnesis,
      anamnesisProps: { birthday },
      newProgramWeight: currentBodyMeasurments.currentWeight,
      openWeightWarningModal: ({ programType }) =>
        setWeightWarningModalProps({
          height: height,
          weight: currentBodyMeasurments.currentWeight,
          programType,
          isOpen: true
        })
    })

    if (hasUpdatedWithoutWarning) {
      setShowEnergyRequirements(true)
    }
  }

  if (showEnergyRequirements && energyRequirements && anamnesis) {
    return (
      <PageLayout theme="smallcircle">
        <div className="container max-700">
          <div className="row justify-content-center mb-3">
            <div className="col-12">
              <h1>Ihr Ziel</h1>
              <div className="row mb-2 mt-0">
                <div>
                  <p>Zielgewicht:</p>
                  <p>Aktueller Kalorienbedarf:</p>
                </div>
                <div>
                  <p>{Math.round(currentBodyMeasurments.currentWeight - (anamnesis.weightToLose ?? 0))} kg</p>
                  <p>{Math.round(energyRequirements.activeMetabolicRate || 0)} kcal pro Tag</p>
                </div>
              </div>
              <EnergyPlanCard
                cardHighlight={{ border: '0' }}
                energyPlan={energyRequirements.energyPlans.find(
                  (energyPlan) => energyPlan.level.name === anamnesis.energyLevelName
                )}
                label={<EnergyLevelLabel text="Ihre Reduzierung" textColor={LabelMapping.LOW.textColor} />}
              />
            </div>
          </div>
          <div className="infopanel">
            <p>Warum verändert sich mein Kalorienniveau durch eine Anpassung der Basisdaten?</p>
            <p>
              Wie viel Energie ein Mensch benötigt, hängt von verschiedenen Faktoren ab. Auch die Basisdaten Alter und
              Körpergrösse zählen dazu und fliessen in unsere Berechnungen zum Kalorienniveau ein. Verändert sich bspw.
              das Alter, sei es durch das Ändern eines falschen Geburtsdatums oder im Laufe des Älterwerdens, kann sich
              der Kalorienbedarf verändern. So sinkt das Kalorienniveau, wenn man älter ist und umgekehrt. Ebenso
              verhält es sich mit einer Korrektur der Körpergrösse, welche Auswirkungen auf den BMI hat. Sobald also
              eine Änderung erfolgt, wird das Kalorienniveau automatisch angepasst. Die Höhe der Anpassung hängt hierbei
              von der getätigten Veränderung ab.
            </p>
          </div>
          <div className="row align-items-center justify-content-center mt-0">
            <div className="col-6">
              <Button onClick={() => history.push(routes.journal)}>Zum Tagebuch</Button>
            </div>
          </div>
        </div>
      </PageLayout>
    )
  }

  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <h1>Basisdaten ändern</h1>
        <p>
          Ändern Sie Ihr Geburtsdatum oder Ihre Körpergrösse. Die Änderungen verändern möglicherweise Ihre
          Kalorienbilanz.
        </p>
        {anamnesis && currentBodyMeasurments.height && (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="col-4 mt-5">
              <DatePickerController
                name="birthday"
                label="Ihr Geburtstag"
                range={{
                  to: date18YearsAgo
                }}
                format="[Heute], d.m.Y"
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                defaultValues={defaultValues}
                ignoreInternalErrors
              />
            </div>
            <div className="col-4 mt-5">
              <FormInputNumberController
                name="height"
                label="Körpergrösse in cm"
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                defaultValues={defaultValues}
              />
            </div>
            <div className="row align-items-center justify-content-center mt-1">
              <div className="col-6">
                <Button type="submit">Speichern</Button>
              </div>
            </div>
          </form>
        )}
      </div>
      <WeightWarningModal
        {...weightWarningModalProps}
        closeModal={() => {
          setWeightWarningModalProps({ isOpen: false })
          setShowEnergyRequirements(true)
        }}
      />
    </PageLayout>
  )
}

export default BaseDataPage
