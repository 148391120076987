import _ from 'lodash'
import React, { ReactElement, useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { Button } from '../Button'
import { DatePicker } from '../DatePicker'
import { FormInputFoodAmount } from '../FormInputFoodAmount'
import { FormInputSelect } from '../FormInputSelect'
import {
  SelectItemKeyExtractor,
  SelectItemRenderer,
  SelectItemType,
  defaultKeyExtractor
} from '../common/SelectPickerProps'

import styles from './JournalCardSearchBase.module.css'

import { FavoriteIcon } from 'Components/common/FavoriteIcon'
import { today } from 'Config'
import { Amount, ImageUrl, UnitId } from 'Models'
import { ZIndexPriority } from 'Utils/zIndexPriority'

export const dateFormatToday = '[Heute] DD.MM.YYYY'
export const dateFormatGeneric = 'dddd DD.MM.YYYY'

export type Mode = 'normal' | 'adding' | 'submitted'

type SearchResultCardOnSaveHandler<Item, Category> = (
  item: Item,
  amount: Amount,
  date: Date,
  category?: Category
) => void

type SearchResultCardBuildDetailsHook<Item, Category> = (
  item: Item,
  amount: Amount,
  mode: Mode,
  date?: Date,
  category?: Category
) => string

interface Props<Item, Category extends SelectItemType> {
  item: Item
  initialDate: Date
  source?: string
  title?: string
  className?: string
  imageUrl?: ImageUrl
  availableUnits?: UnitId[]
  categories?: Category[]
  category?: Category
  amount?: Amount
  isFavorite: boolean
  buildDetails?: SearchResultCardBuildDetailsHook<Item, Category>
  categoryLabelRenderer?: SelectItemRenderer<Category, string>
  categoryKeyExtractor?: SelectItemKeyExtractor<Category>
  onCategoryChange?: (category: Category) => void
  onAmountChange?: (amount: Amount) => void
  onDelete?: () => void
  onSave?: SearchResultCardOnSaveHandler<Item, Category>
  renderTitle?: () => JSX.Element
}

export const JournalCardSearchBase = <Item, Category extends SelectItemType>({
  item,
  initialDate = today,
  source = '',
  title = '',
  className,
  imageUrl = '',
  availableUnits = [],
  categories,
  category,
  amount = {} as Amount,
  isFavorite,
  buildDetails = () => '',
  categoryLabelRenderer,
  categoryKeyExtractor = defaultKeyExtractor,
  onCategoryChange,
  onAmountChange,
  onDelete,
  onSave,
  renderTitle
}: Props<Item, Category>): ReactElement => {
  const [mode, setMode] = useState<Mode>('normal')
  const [date, setDate] = useState<Date>(initialDate)

  const details = buildDetails(item, amount, mode, date, category)

  const handleSave = useCallback(() => {
    if (amount?.quantity > 0) {
      setMode('submitted')
      onSave && onSave(item, amount, date, category)
    } else {
      toast('Die eingetragene Menge muss grösser als 0 sein.', { type: 'error' })
    }
  }, [item, amount, date, category, onSave])

  const handleCategoryChange = useCallback(
    (category: SelectItemType) => {
      onCategoryChange && onCategoryChange(category as Category)
    },
    [onCategoryChange]
  )

  const clazzName = _.compact(['standardcard', className]).join(' ')

  return (
    <div className={clazzName}>
      <div style={{ position: 'absolute', top: -7, left: -10, zIndex: ZIndexPriority.LOW }}>
        <FavoriteIcon isFavorite={isFavorite} />
      </div>
      <div className="standardcard-image">{imageUrl && <img src={`${imageUrl}&width=120`} alt={title} />}</div>
      <div className="standardcard-content">
        {renderTitle ? renderTitle() : title}
        <span className={`${styles.source} ${styles.sourceWidth}`}>{source}</span>
        <span>{details}</span>
      </div>
      <div className="standardcard-icons">
        {mode === 'adding' && (
          <Button name="saveJournalCard" theme="tiny" onClick={handleSave}>
            SPEICHERN
          </Button>
        )}
        {mode === 'normal' && (
          <Button name="addJournalCard" theme="secondary-tiny" onClick={() => setMode('adding')}>
            HINZUFÜGEN
          </Button>
        )}
        {mode === 'submitted' && (
          <Button name="removeJournalCard" theme="tiny" onClick={onDelete}>
            LÖSCHEN
          </Button>
        )}
      </div>
      {mode === 'adding' && (
        <div className="standardcard-options">
          <div>
            <FormInputFoodAmount
              label=" "
              amount={amount}
              availableUnits={availableUnits}
              onAmountChange={onAmountChange}
              onChangeDelay={0}
            />
          </div>
          <div>
            <DatePicker label=" " value={date} onChange={setDate} />
          </div>
          {categories && (
            <div>
              <FormInputSelect
                label=" "
                name="mealCategory"
                items={categories}
                selectedKey={category ? categoryKeyExtractor(category) : undefined}
                labelExtractor={categoryLabelRenderer as SelectItemRenderer<SelectItemType, string>}
                keyExtractor={categoryKeyExtractor as SelectItemKeyExtractor<SelectItemType>}
                onChange={handleCategoryChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
