import { EatableType, QuantifiedEatable } from 'Models'

export const eatableType = (eatable: QuantifiedEatable): EatableType | null => {
  if (!eatable) {
    return null
  }
  if (eatable.food || eatable.foodId) {
    return 'FOOD'
  }
  if (eatable.recipe || eatable.recipeId) {
    return 'RECIPE'
  }
  return null
}
