/**
 * This file defined a common interface for implementing Components which let's the user choose one of many items.
 */

import { ReactNode } from 'react'

type ItemValue = string | number

type DefaultItem = {
  label?: string
  value?: ItemValue
}

/** User-defined Type Guard for DefaultItem */
function isDefaultItem(item: SelectItemType): item is DefaultItem {
  return (item as DefaultItem).value !== undefined && (item as DefaultItem).label !== undefined
}

/** Allows items to be passed for user selection */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectItemType = string | number | Record<string, any>

/** Return tpye for SelectItemRenderer */
export type RenderedItemType = ReactNode | string

/**
 * Callback function that turns a select item into user representation.
 */
export interface SelectItemRenderer<I extends SelectItemType, R extends RenderedItemType> {
  (item: I, isSelected: boolean): R
}

/**
 * Default implementation for retrieving the UI label for a select item.
 * @param item the current item
 * @return label / string representation of the item
 */
export function defaultLabelExtractor<T extends SelectItemType>(item: T): string {
  if (typeof item === 'string') return item
  if (typeof item === 'number') return item.toString()
  if (isDefaultItem(item) && 'label' in item) {
    return item.label ? item.label.toString() : ''
  }
  throw new Error(`unsupported item type ${typeof item}`)
}

/**
 * Callback function that derives a key from a select item.
 */
export type SelectItemKeyExtractor<T, R = string> = {
  (item: T): R
}

export function defaultKeyExtractor<T extends SelectItemType>(item: T): string {
  if (typeof item === 'string') return item
  if (typeof item === 'number') return item.toString()
  if (isDefaultItem(item)) {
    return item.value ? item.value.toString() : ''
  }
  throw new Error(`unsupported item type ${typeof item}`)
}

export interface SingleSelectProps<Item extends SelectItemType> {
  /** List of all options the user can choose from */
  items: Item[]
  /** The key of the [pre]selected item. Works in combination with keyExtractor */
  selectedKey?: ItemValue
  /** Callback to derive the key from a option item */
  keyExtractor?: SelectItemKeyExtractor<Item>
  /** Callback to retrieve the UI label of an option */
  labelExtractor?: SelectItemRenderer<Item, string>
  /** Callback when the user changed the selection */
  onChange?: (item: Item) => void
}

export interface MultiSelectProps<Item extends SelectItemType> {
  /** List of all options the user can choose from */
  items: Item[]
  /** The key of the [pre]selected item. Works in combination with keyExtractor */
  selectedKeys?: string[]
  /** Callback to derive the key from a option item */
  keyExtractor?: SelectItemKeyExtractor<Item>
  /** Callback to retrieve the UI label of an option */
  labelExtractor?: SelectItemRenderer<Item, string>
  /** Callback when the user changed the selection */
  onChange?: (items: Item[]) => void
}
