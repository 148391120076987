import React, { FC, useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import MyEatablesPage from './MyEatablesPage'

import { routes } from 'Config'
import { CoreBackendRecipe, Food, ReduxStoreState } from 'Models'
import { isLoadingSelector } from 'ReduxStore/common'
import { didLoadUserFoodsSelector, userFoodsVisibleSelector } from 'ReduxStore/userFoods'
import { didLoadUserRecipesSelector, userRecipesSelector } from 'ReduxStore/userRecipes'

type SelectorProps = {
  userFoods: Food[]
  isFoodsLoading: boolean
  didLoadUserFoods: boolean
  userRecipes: CoreBackendRecipe[]
  isRecipesLoading: boolean
  didLoadUserRecipes: boolean
}

const mapStateToProps = createStructuredSelector<ReduxStoreState, SelectorProps>({
  isFoodsLoading: (state) => isLoadingSelector(state.userFoods),
  didLoadUserFoods: didLoadUserFoodsSelector,
  userFoods: userFoodsVisibleSelector,
  isRecipesLoading: (state) => isLoadingSelector(state.userRecipes),
  didLoadUserRecipes: didLoadUserRecipesSelector,
  userRecipes: userRecipesSelector
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & Pick<RouteComponentProps<{ highlightId?: string }>, 'match'>

const MyEatablesPageContainer: FC<Props> = ({
  match: {
    params: { highlightId }
  },
  ...propsFromRedux
}: Props) => {
  const location = useLocation()
  const history = useHistory()
  const [modalFoodId, setModalFoodId] = useState<string | undefined>(undefined)
  const [modalRecipeId, setModalRecipeId] = useState<string | undefined>(undefined)

  const resetUrl = (): void => history.replace(routes.myEatables.index)
  const handleFoodModalClose = useCallback(() => {
    resetUrl()
    setModalFoodId(undefined)
  }, [modalFoodId])
  const handleRecipeModalClose = useCallback(() => {
    resetUrl()
    setModalRecipeId(undefined)
  }, [modalRecipeId])

  useEffect(() => {
    if (!highlightId) return
    if (propsFromRedux.userFoods.length === 0) return
    const setModelId =
      location.pathname === routes.myEatables.myRecipeView(highlightId) ? setModalRecipeId : setModalFoodId
    setModelId(highlightId)
  }, [highlightId, propsFromRedux.userFoods])

  return (
    <MyEatablesPage
      {...propsFromRedux}
      modalFoodId={modalFoodId}
      onFoodCardClick={setModalFoodId}
      onFoodModalClose={handleFoodModalClose}
      modalRecipeId={modalRecipeId}
      onRecipeCardClick={setModalRecipeId}
      onRecipeModalClose={handleRecipeModalClose}
    />
  )
}

export default connector(MyEatablesPageContainer)
