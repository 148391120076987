import React, { FC } from 'react'

import { DebugPanel } from 'Components/Debug'
import { ProgressCircle } from 'Components/ProgressCircle/ProgressCircle'
import { Nutritions } from 'Models'
import { Calculator, Formatter } from 'Utils'

export type CockpitProps = {
  date: Date
  isLoading: boolean
  /** sum of all direct energy records in kcal */
  acceptedDirectEnergyIntake: number
  goalEnergy: number
  /** sum of all searched meal energy in kcal */
  acceptedNutritions?: Nutritions
  goalNutritions?: Nutritions
  acceptedEnergyMotionSum: number
}

export const Cockpit: FC<CockpitProps> = (props: CockpitProps) => {
  const {
    acceptedNutritions,
    goalNutritions,
    isLoading,
    acceptedDirectEnergyIntake,
    goalEnergy,
    acceptedEnergyMotionSum
  } = props

  const acceptedEnergySum = ((acceptedNutritions && acceptedNutritions.energy) || 0) + acceptedDirectEnergyIntake

  const remainingEnergy = goalEnergy - acceptedEnergySum + acceptedEnergyMotionSum

  const progressLeft =
    Math.min(Calculator.calculatePercentage(acceptedEnergySum, goalEnergy + acceptedEnergyMotionSum), 1) * 100
  return (
    <>
      {isLoading ? (
        <ProgressCircle loading />
      ) : (
        <ProgressCircle
          topItem={{
            label: 'KCAL VERBLEIBEND',
            value: `${Formatter.formatEnergy(remainingEnergy, false)}`
          }}
          leftItem={{
            label: 'Gegessen',
            value: `${Formatter.formatEnergy(acceptedEnergySum, false)}`
          }}
          rightItem={{
            label: 'Aktivität',
            value: `${Formatter.formatEnergy(acceptedEnergyMotionSum, false)}`
          }}
          progressLeft={progressLeft}
          acceptedNutritions={acceptedNutritions}
          goalNutritions={goalNutritions}
        />
      )}
      <DebugPanel>
        <code>
          {JSON.stringify(
            {
              props,
              remainingEnergy,
              goalEnergy,
              acceptedEnergySum,
              acceptedEnergyMotionSum
            },
            null,
            2
          )}
        </code>
      </DebugPanel>
    </>
  )
}
