import React from 'react'

import { AnamnesisStepIds, MotionValues } from '../../types'

import { BellyInputTutorial } from 'Components'
import { StepConfig } from 'Components/FormWizard'
import { OptionType } from 'Models'
import { ProfessionalMotionLevel, anamnesisSchema } from 'yup-schema/Schemas/anamnesis'

// @ts-ignore
const validationSchema = anamnesisSchema.pick(['workingHours', 'professionalMotionLevel'])

export const motionConfig: StepConfig<MotionValues> = {
  id: AnamnesisStepIds.activity,
  scrollTop: true,
  infoTextElement: (
    <>
      <p>
        Für das optimale Erreichen Ihres Ziels, müssen wir wissen, wie aktiv Sie über den Tag und bei der Arbeit sind.
        Ebenso, wie viele Stunden Sie arbeiten.
      </p>
      <p>
        <BellyInputTutorial />
      </p>
    </>
  ),
  validationSchema
}

export const professionalMotionLevelOptions: OptionType<ProfessionalMotionLevel>[] = [
  {
    value: 'INACTIVE',
    label: 'Ich bewege mich fast gar nicht, das bedeutet, ich sitze oder liege ausschliesslich.'
  },
  {
    value: 'VERY_LOW',
    label: 'Ich bewege mich sehr wenig und sitze viel (Rentner, Büroarbeit etc.).'
  },
  {
    value: 'LOW',
    label:
      'Ich sitze meist, aber gehe und stehe auch zeitweilig (z. B. Student/in, Laborant/in, Fliessbandarbeiter/in).'
  },
  {
    value: 'MEDIUM',
    label: 'Ich stehe und gehe überwiegend (z. B. als Verkäufer/in, Handwerker/in, Kellner/in).'
  },
  {
    value: 'HIGH',
    label: 'Ich bewege mich sehr viel bei starker körperlicher Aktivität (z. B. als Landwirt/in, Bauarbeiter/in).'
  }
]
