import { EbalanceBackendService } from './EbalanceBackendService'
import { coreBackendService } from './coreBackendServiceInstance'

export class UserDataImporterService extends EbalanceBackendService {
  async resetUser(): Promise<void> {
    try {
      await this.client.post(`import`, {
        userId: this.userId
      })
    } catch (error) {
      console.error(`Failed to reset user`, error)
      if (error.response.status === 404) {
        // assuming no user id mapping was defined
        // falling back to at least resetting the core backend user
        await coreBackendService.resetUser()
      } else {
        throw error
      }
    }
  }
}
