import React, { ReactElement, useState } from 'react'

import { JournalCardSearchEatable, JournalCardSearchMotion, SectionList } from 'Components'
import TabSwitcher from 'Components/TabSwitcher/TabSwitcher'
import {
  FavoritesFood,
  FavoritesMapping,
  FavoritesMotionLevel,
  FavoritesRecipe,
  MealCategory,
  Motion,
  MotionLevelObject,
  QuantifiedEatable
} from 'Models'
import { FavoriteTabNames } from 'Pages/Favorites/helpers/FavoriteTabNames'
import {
  getFavoriteEatablesSectionList,
  getFavoriteMotionsSectionList
} from 'Pages/Favorites/helpers/getFavoriteSectionList'
import { currentWeightSelector } from 'ReduxStore/bodyMeasurements'
import {
  favoriteSystemEatablesSelector,
  favoriteUserEatablesSelector,
  favoritesSelector
} from 'ReduxStore/favorites/favorites'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { currentJournalDateSelector } from 'ReduxStore/journalDay'
import { postOrPatchJournalEatableRecordAction } from 'ReduxStore/journalEatableRecords'
import { addJournalMotionRecordAction } from 'ReduxStore/journalMotionRecords'

type Props = {
  onClose: () => void
}

const JournalFavoriteTabs: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch()
  const [currentTabName, setCurrentTabName] = useState(FavoriteTabNames.FOODS)
  const currentJournalDate = useAppSelector(currentJournalDateSelector)
  const currentWeight = useAppSelector(currentWeightSelector)

  const favoritesMotionLevels = useAppSelector(favoritesSelector<FavoritesMotionLevel>(FavoritesMapping.MOTIONS))
  const favoriteUserEatables = useAppSelector(favoriteUserEatablesSelector)

  const favoriteSystemFoods = useAppSelector(favoriteSystemEatablesSelector<FavoritesFood>(FavoritesMapping.FOODS))
  const favoriteSystemRecipes = useAppSelector(
    favoriteSystemEatablesSelector<FavoritesRecipe>(FavoritesMapping.RECIPES)
  )

  const renderEatables = (): JSX.Element => {
    const renderCard = (favorite: FavoritesFood | FavoritesRecipe): ReactElement => {
      const onSave = (eatable: QuantifiedEatable, date: Date, mealCategory: MealCategory): void => {
        dispatch(postOrPatchJournalEatableRecordAction(eatable, mealCategory, date, 'search'))
        onClose()
      }

      return (
        <JournalCardSearchEatable
          key={favorite.id}
          quantifiedEatable={
            'food' in favorite
              ? {
                  foodId: favorite.food.id,
                  food: favorite.food,
                  quantity: favorite.quantity,
                  unit: favorite.unit
                }
              : {
                  recipeId: favorite.recipe.id,
                  recipe: favorite.recipe,
                  quantity: favorite.quantity,
                  unit: favorite.unit
                }
          }
          initialDate={currentJournalDate}
          onSave={onSave}
        />
      )
    }

    const sectionList = getFavoriteEatablesSectionList({
      key: currentTabName,
      favoriteSystemFoods,
      favoriteSystemRecipes,
      favoriteUserEatables
    })

    return <SectionList<FavoritesFood | FavoritesRecipe> sectionList={sectionList} renderCard={renderCard} />
  }

  const renderMotions = (): JSX.Element => {
    const onSave = (motion: Motion, duration: number, date: Date, category: MotionLevelObject | undefined): void => {
      dispatch(addJournalMotionRecordAction(motion, duration, (category || ({} as MotionLevelObject)).id, date))
      onClose()
    }

    const renderCard = (favorite: FavoritesMotionLevel): ReactElement => (
      <JournalCardSearchMotion
        key={favorite.id}
        motion={favorite.motionLevel.motion}
        initialDate={currentJournalDate}
        weight={currentWeight}
        onSave={onSave}
      />
    )

    return (
      <SectionList<FavoritesMotionLevel>
        sectionList={getFavoriteMotionsSectionList(favoritesMotionLevels)}
        renderCard={renderCard}
      />
    )
  }

  return (
    <TabSwitcher
      onTabSwitch={(tabName) => setCurrentTabName(tabName as FavoriteTabNames)}
      tabs={[
        {
          name: FavoriteTabNames.FOODS,
          title: 'Nahrungsmittel',
          renderContent: renderEatables()
        },
        {
          name: FavoriteTabNames.RECIPES,
          title: 'Rezepte',
          renderContent: renderEatables()
        },
        {
          name: FavoriteTabNames.USER_EATABLES,
          title: 'Mein Essen',
          renderContent: renderEatables()
        },
        {
          name: FavoriteTabNames.MOTIONS,
          title: 'Aktivitäten',
          renderContent: renderMotions()
        }
      ]}
    />
  )
}

export default JournalFavoriteTabs
