import Highcharts from 'highcharts'

import getExactTheme, { SeriesOptions as ExactSeriesOptions, getDefaultSeries as getExactDefaultSeries } from './exact'
import getSmoothTheme, {
  SeriesOptions as SmoothSeriesOptions,
  getDefaultSeries as getSmoothDefaultSeries
} from './smooth'

export type SeriesOptions = SmoothSeriesOptions | ExactSeriesOptions

interface Theme {
  options: Highcharts.Options
  defaultSeries: SeriesOptions
}

const themes = {
  smooth: {
    options: getSmoothTheme(),
    defaultSeries: getSmoothDefaultSeries()
  },
  exact: {
    options: getExactTheme(),
    defaultSeries: getExactDefaultSeries()
  }
}

export default function getTheme(themeName: 'exact' | 'smooth'): Theme {
  return themes[themeName]
}
