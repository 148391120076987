import React, { FC } from 'react'

import { formAnswerOptionKeyExtractor, formAnswerOptionLabelExtractor } from '../../helpers'
import { StepHeading } from '../StepHeading'

import { professionalMotionLevelOptions } from './config'

import { FormInputRadio, FormInputRange } from 'Components'
import { RenderStepType } from 'Components/FormWizard'

const MotionStep: FC<RenderStepType> = ({ register, errors, defaultValues = {} }: RenderStepType) => (
  <>
    <StepHeading title="Ihre Aktivitäten" />
    <div className="row">
      <div className="col-12">
        <h4 className="mb-4">Wie viele Stunden arbeiten Sie durchschnittlich pro Woche?</h4>
        <FormInputRange
          name="workingHours"
          minimumValue={0}
          maximumValue={41}
          defaultValue={defaultValues['workingHours']}
          register={register}
          errors={errors}
          renderValue={(value) => `${value === 41 ? '40+' : value}`}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <h4 className="mb-4">Wie viel bewegen Sie sich im Berufsalltag?</h4>
        <FormInputRadio
          name="professionalMotionLevel"
          items={professionalMotionLevelOptions}
          labelExtractor={formAnswerOptionLabelExtractor}
          keyExtractor={formAnswerOptionKeyExtractor}
          register={register}
          errors={errors}
        />
      </div>
    </div>
  </>
)

export default MotionStep

export * from './config'
