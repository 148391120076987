import React, { useCallback, useMemo, useState } from 'react'

import { JournalCardSearchBase, Mode, dateFormatGeneric, dateFormatToday } from '../JournalCardSearchBase'

import { Amount, FavoritesMapping, FavoritesMotionLevel, Motion, MotionLevelObject, UnitId } from 'Models'
import { favoriteSelector } from 'ReduxStore/favorites/favorites'
import { useAppSelector } from 'ReduxStore/hooks'
import { Calculator, Formatter, Utils } from 'Utils'

interface Props {
  motion: Motion
  onSave?: (motion: Motion, duration: number, date: Date, category?: MotionLevelObject) => void
  renderTitle?: () => JSX.Element
  onDelete?: () => void
  initialDate: Date
  weight: number
}

const buildDetails = (weight: number) => (
  _: Motion,
  amount: Amount,
  mode: Mode,
  date?: Date,
  level?: MotionLevelObject
) => {
  const energy =
    level &&
    level.metFactor &&
    Calculator.calculateMotionEnergy(level.metFactor, weight, Utils.durationFromAmount(amount))

  const result = [Formatter.formatEnergy(energy), Formatter.formatDurationInMinutes(amount.quantity)]

  if (mode === 'submitted') {
    level && level.name && result.push(level.name)
    date && result.push(Formatter.formatDate(date, dateFormatToday, dateFormatGeneric))
  }

  return result.join(mode === 'submitted' ? ' | ' : ' / ')
}

export const JournalCardSearchMotion: React.FC<Props> = (props: Props) => {
  const { motion, weight, onSave } = props
  const motionLevelIds = motion.levels.map((level) => level.id)
  const favorite = useAppSelector(favoriteSelector<FavoritesMotionLevel>(FavoritesMapping.MOTIONS, motionLevelIds))
  const categories = motion.levels
  const { initialDuration, initialCategory } = useMemo(() => {
    const favoriteMotionLevel = categories.find((level) => favorite?.motionLevel!.id === level.id)
    // Algolia motion-levels could be out of sync with backend motions.
    // Checking if the algolia index has the actual favorite motion-level is a safe guard
    if (!favorite || !favoriteMotionLevel) {
      return { initialDuration: 30, initialCategory: categories[0] }
    }
    return {
      initialDuration: favorite.duration! / 60,
      initialCategory: favoriteMotionLevel
    }
  }, [])
  const [amount, setAmount] = useState({ quantity: initialDuration, unit: 'MINUTE' } as Amount)
  const availableUnits: UnitId[] = ['MINUTE']

  const [category, setCategory] = useState(initialCategory)

  const handleSave = useCallback(
    (item, amount, date, level) => {
      onSave && onSave(item, Utils.durationFromAmount(amount), date, level)
    },
    [onSave]
  )

  return (
    <JournalCardSearchBase
      {...props}
      item={motion}
      title={motion.name}
      className="is-motion"
      imageUrl={motion.imageUrl}
      buildDetails={buildDetails(weight)}
      availableUnits={availableUnits}
      categories={categories.length > 1 ? categories : undefined}
      category={category}
      categoryLabelRenderer={(level: MotionLevelObject) => level.name}
      categoryKeyExtractor={(category: MotionLevelObject) => category.id}
      isFavorite={!!favorite}
      onCategoryChange={setCategory}
      amount={amount}
      onAmountChange={setAmount}
      onSave={handleSave}
    />
  )
}
