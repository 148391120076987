import { yupResolver } from '@hookform/resolvers/yup'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { DefaultValues, FieldValues, UseFormMethods, useForm } from 'react-hook-form'

import { WizardStepType } from './types'

export type UseFormWizardReturnType<TFieldValues extends FieldValues> = {
  formValues?: Record<string, DefaultValues<TFieldValues>>
  onSubmit: React.FormEventHandler
  currentStep: WizardStepType
  currentStepIndex: number
  numberOfSteps: number
  isLastStep: boolean
  canGoBack: boolean
  goToNextStep: () => void
  goToPreviousStep: () => void
  renderStep: () => ReactNode
  useFormMethods: UseFormMethods<TFieldValues>
  isSubmitting: boolean
  /**
   * Extended version of useFormMethods.watch, which keeps the value when the step of the watched field was left.
   */
  wizardWatch: <Result = string>(stepId: string, fieldName: string) => Result | undefined
}

export type UseFormWizardProps<TFieldValues extends FieldValues> = {
  initialStep?: number
  steps: WizardStepType[]
  dynamicDefaultValues?: Record<string, DefaultValues<TFieldValues>>
  isRestart: boolean
  handleFormSubmit: (values: TFieldValues) => void
}

export const useFormWizard = <T extends FieldValues>({
  initialStep = 0,
  steps,
  dynamicDefaultValues,
  isRestart,
  handleFormSubmit
}: UseFormWizardProps<T>): UseFormWizardReturnType<T> => {
  const [currentStepIndex, setStepIndex] = useState<number>(initialStep)
  const [values, setValues] = useState<Record<string, DefaultValues<T>>>({ ...dynamicDefaultValues })

  const currentStep = steps[currentStepIndex]

  const { Component, defaultValues: configDefaultValues } = currentStep
  const defaultValues: DefaultValues<T> = {
    ...configDefaultValues,
    ...(dynamicDefaultValues ? dynamicDefaultValues[currentStep.id] : ({} as DefaultValues<T>))
  }

  const numberOfSteps = useMemo(() => Object.keys(steps).length, [steps])

  const canGoBack = currentStepIndex > 0 && currentStepIndex !== numberOfSteps

  const isLastStep = currentStepIndex === numberOfSteps - 1

  const goToNextStep = (): void => setStepIndex(Math.min(currentStepIndex + 1, numberOfSteps - 1))

  const goToPreviousStep = (): void => setStepIndex(Math.max(currentStepIndex - 1, 0))
  const onSubmit = useCallback(
    (stepValues: DefaultValues<T>): void => {
      const newValues = { ...values, [currentStep.id]: stepValues }
      setValues(newValues)
      if (isLastStep) {
        const flatValues = Object.values(newValues).reduce((acc, val) => ({ ...acc, ...val }), {} as T)
        return handleFormSubmit(flatValues as T)
      } else {
        goToNextStep()
      }
    },
    [currentStep, isLastStep]
  )
  const defaultValuesStep = (values[currentStep.id] as DefaultValues<T>) || defaultValues

  const useFormMethods = useForm<T>({
    defaultValues: defaultValuesStep,
    resolver: yupResolver(currentStep.validationSchema),
    shouldUnregister: false
  })

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting }
  } = useFormMethods

  useEffect(() => {
    reset(defaultValuesStep)
  }, [JSON.stringify(defaultValuesStep), reset])

  const renderStep = useCallback((): ReactNode => {
    return <Component {...useFormMethods} formValues={values} defaultValues={defaultValuesStep} isRestart={isRestart} />
  }, [defaultValues, values])

  function wizardWatch<ResultType = string>(stepId: string, fieldName: string): ResultType | undefined {
    if (stepId === currentStep.id) {
      return watch(fieldName) as ResultType | undefined
    }
    // @ts-ignore
    const result = values[stepId]?.[fieldName]
    return result as ResultType | undefined
  }

  return {
    formValues: values,
    currentStep,
    currentStepIndex,
    isLastStep,
    numberOfSteps,
    canGoBack,
    goToNextStep,
    goToPreviousStep,
    renderStep,
    onSubmit: handleSubmit(onSubmit),
    useFormMethods,
    isSubmitting,
    wizardWatch
  }
}
