import React, { FC, useCallback, useState } from 'react'

import { Button } from '../Button'
import { FormInputNumber } from '../FormInputNumber'

import { BellyInputTutorial } from './BellyInputTutorial'

import { today } from 'Config'
import { BodyMeasurementPoint, UNITS } from 'Models'
import { BodyMeasurementInput } from 'Pages/MassPage/components/BodyMeasurementInput'
import { ToastConfigReduxActions } from 'ReduxStore/toastMessage'
import yup from 'yup-schema'
import { bodyMeasurementsRangeSchema } from 'yup-schema/Schemas/common'

interface Props {
  postBodyMeasurements: (data: BodyMeasurementPoint, toastConfig: ToastConfigReduxActions) => void
  latestBellyBodyMeasurement?: BodyMeasurementPoint
}

const schema = yup.object({
  bellyInput: bodyMeasurementsRangeSchema.required()
})

export const BellyInput: FC<Props> = ({ postBodyMeasurements, latestBellyBodyMeasurement }: Props) => {
  const [bellyInput, setBellyInput] = useState<number>()
  const [errorText, setErrorText] = useState<string>()

  const onSubmitBellyInputHandler = useCallback(() => {
    bellyInput &&
      schema
        .validate({ bellyInput })
        .then(() => {
          setErrorText('')
          postBodyMeasurements(
            {
              datetime: today,
              bodyCriterion: 'BELLY',
              value: bellyInput
            },
            { withDefaultSuccessToast: true }
          )
        })
        .catch(({ message }) => setErrorText(message))
  }, [bellyInput, postBodyMeasurements, today])

  return latestBellyBodyMeasurement ? (
    <BodyMeasurementInput
      value={latestBellyBodyMeasurement.value}
      postBodyMeasurements={postBodyMeasurements}
      bodyCriterion="BELLY"
      unitLabel={UNITS.CENTIMETER.abbreviation}
    />
  ) : (
    <div className="row">
      <div className="col-12">
        <FormInputNumber label="Bauchumfang in cm" onChange={setBellyInput} value={bellyInput} errorText={errorText} />
      </div>
      <div className="col-12">
        <BellyInputTutorial />
      </div>
      <div className="col-12 col-lg-4 offset-4">
        <Button theme="small" onClick={onSubmitBellyInputHandler}>
          Speichern
        </Button>
      </div>
    </div>
  )
}
