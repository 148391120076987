import _ from 'lodash'
import React, { FC, useCallback, useState } from 'react'

import { FormInputFoodAmount, ResponsiveCardBase, buildEatableDetails } from 'Components'
import { deleteButton, editButton } from 'Components/IconButton/iconButtons'
import { Amount, FavoritesFood, PromiseAction } from 'Models'
import { removeFavoritesAction, updateFavoritesAction } from 'ReduxStore/favorites/favorites'
import { useAppDispatch } from 'ReduxStore/hooks'
import { Utils } from 'Utils'

type Props = {
  favorite: FavoritesFood
  onClick: () => void
}
const FavoriteFoodOverviewCard: FC<Props> = ({ favorite, onClick }: Props) => {
  const dispatch = useAppDispatch()
  const [toggleChildrenIsOn, setToggleChildrenIsOn] = useState(false)

  const [quantifiedEatable, setQuantifiedEatable] = useState({
    foodId: favorite.food.id,
    food: favorite.food,
    quantity: favorite.quantity,
    unit: favorite.unit
  })

  const amount: Amount = {
    quantity: quantifiedEatable.quantity,
    unit: quantifiedEatable.unit
  }

  const handleAmountChanged = useCallback(
    _.debounce(
      ({ unit, quantity }: Amount) =>
        dispatch(
          updateFavoritesAction(favorite.id, {
            foodId: quantifiedEatable.foodId,
            quantity,
            unit
          })
        ),
      1000
    ),
    []
  )

  const handleRecipeAmountChange = useCallback((amount: Amount): void => {
    const modifiedEatable = { foodId: quantifiedEatable.foodId, food: quantifiedEatable.food, ...amount }
    const { unit = 'GRAM', quantity = 0 } = modifiedEatable

    if (quantity > 0 && (quantifiedEatable.unit !== unit || quantifiedEatable.quantity !== quantity)) {
      setQuantifiedEatable(modifiedEatable)
      handleAmountChanged({ unit, quantity })
    }
  }, [])

  const handleOnEdit = (): void => setToggleChildrenIsOn(!toggleChildrenIsOn)

  const handleDeleteFavorite = (): PromiseAction<void> => dispatch(removeFavoritesAction(favorite))

  const details = buildEatableDetails(
    {
      foodId: favorite.food.id,
      food: favorite.food
    },
    {
      quantity: favorite.quantity,
      unit: favorite.unit
    },
    'normal'
  )

  return (
    <ResponsiveCardBase
      key={favorite.id}
      onClick={onClick}
      title={favorite.food.name}
      isFavorite={true}
      toggleChildrenIsOn={toggleChildrenIsOn}
      details={details}
      className="is-eatable"
      imageUrl={favorite.food.imageUrl}
      source={favorite.food.source?.name}
      cardButtons={[]}
      iconButtons={[editButton(handleOnEdit), deleteButton(handleDeleteFavorite)]}
    >
      <div className="standardcard-options">
        <div>
          <FormInputFoodAmount
            label=" "
            amount={amount}
            onAmountChange={handleRecipeAmountChange}
            availableUnits={Utils.availableUnits({
              foodId: quantifiedEatable.foodId,
              food: quantifiedEatable.food
            })}
          />
        </div>
      </div>
    </ResponsiveCardBase>
  )
}

export default FavoriteFoodOverviewCard
