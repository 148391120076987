import { useMemo } from 'react'

import { SelectItemKeyExtractor, SelectItemType } from './SelectPickerProps'

type SelectChangeHandler = (key: string) => void

export const useMultiSelect = function <Item extends SelectItemType>(
  selectedKeys: string[] | undefined,
  items: Item[],
  keyExtractor: SelectItemKeyExtractor<Item>,
  onChange?: (items: Item[]) => void
): [Set<string>, SelectChangeHandler] {
  const itemsByKeys = useMemo(() => {
    const data: Map<string, Item> = new Map()
    items.forEach((item) => data.set(keyExtractor(item), item))
    return data
  }, [items])

  const selectedKeysSet = useMemo(() => new Set(selectedKeys), [selectedKeys])

  const toggleSelectItem = (key: string): void => {
    const newSelectedKeysSet = new Set(selectedKeys)

    newSelectedKeysSet.has(key) ? newSelectedKeysSet.delete(key) : newSelectedKeysSet.add(key)
    onChange && onChange(Array.from(newSelectedKeysSet, (key) => itemsByKeys.get(key) as Item))
  }

  return [selectedKeysSet, toggleSelectItem]
}
