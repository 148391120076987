import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { PageLayout } from 'Components'
import { routes } from 'Config'

const WelcomePage: FC<{ firstName: string }> = ({ firstName = '' }) => {
  return (
    <PageLayout theme="smallcircle">
      <div className="container max-700">
        <article>
          <h1>Geschafft, {firstName}!</h1>
          <p>Sie haben die Anamnese erfolgreich ausgefüllt und damit Ihr individuelles Programm erstellt.</p>
          <p>
            Ihre Angaben wirken sich auf die Darstellung in Ihrem Tagebuch aus und bestimmen Ihre individuelle
            Kalorienbilanz sowie den im Tagebuch integrierten Menüplan. Gemeinsam können wir jetzt Ihr Projekt
            «Wunschgewicht» in Angriff nehmen.
          </p>
          <div className="col-12 col-lg-6 offset-lg-3">
            <Link to={routes.journal} className="btn">
              Jetzt das eBalance Programm starten
            </Link>
          </div>
          <div className="divider"></div>
          <p>
            In unserer eBalance-Tour geben wir Ihnen eine Einführung in die Funktionalitäten und zeigen Ihnen hilfreiche
            Tutorials.
          </p>
          <div className="col-12 col-lg-4 offset-lg-4">
            <Link to={routes.tour} className="btn">
              eBalance-Tour starten
            </Link>
          </div>
          <div className="divider"></div>
          <p>
            Sie haben Fragen zur Ernährung oder Anregungen zur Plattform? Sie würden etwas anders machen, oder es fehlt
            Ihnen etwas auf den aktuellen Seiten? Dann freuen wir uns sehr auf Ihr Feedback, das Sie uns über{' '}
            <a href={'mailto:info@eBalance.ch'}>info@eBalance.ch</a> zukommen lassen können.
          </p>
          <p>Gestalten Sie mit uns Ihr ideales eBalance!</p>
          <p>Liebe Grüsse</p>
          <p>Ihr eBalance-Team</p>
          <div className="col-12 col-lg-6 offset-lg-3">
            <Link to={routes.journal} className="btn">
              Jetzt das eBalance Programm starten
            </Link>
          </div>
        </article>
      </div>
    </PageLayout>
  )
}

export default WelcomePage
