import React from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { Link } from 'react-router-dom'

import { Icon, IconColor, IconName, PageLayout } from 'Components'
import { buildCdnImageUrl, routes } from 'Config'

interface HeadlineAnchorProps {
  id: string
  offset?: string
}

const HeadlineAnchor: React.FC<HeadlineAnchorProps> = ({ id, offset = '-80px' }) => (
  <a id={id} style={{ display: 'block', position: 'relative', top: offset }} />
)
const TableOfContentsEntry: React.FC = ({ children }) => (
  <li style={{ textDecoration: 'underline', marginBottom: '0.3rem' }}>{children}</li>
)

const WhiteSection: React.FC = ({ children }) => <section className="py-5 container max-700">{children}</section>
const GraySection: React.FC = ({ children }) => (
  <section className="py-5 bg-gray-4">
    <div className="container max-700">{children}</div>
  </section>
)

const HelpAndTourPage: React.FC<{ isHelpPage: boolean }> = ({ isHelpPage }) => {
  return (
    <PageLayout theme="smallcircle">
      <WhiteSection>
        <h1 className="pb-4">{isHelpPage ? 'Hilfe' : 'eBalance-Tour'}</h1>
        <ul>
          <TableOfContentsEntry>
            <a href="#anamnese">Individuelle Empfehlungen dank Anamnese</a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a href="#tagebuch">Das eBalance-Tagebuch</a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a href="#menueplan">Ihr individueller Menüplan</a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a href="#analyse">Die eBalance-Analyse</a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a className="ml-3" href="#naehrwerte">
              Nährwerte
            </a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a className="ml-3" href="#masse">
              Masse
            </a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a href="#inspirieren">Lassen Sie sich inspirieren</a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a href="#eigene">Eigene Nahrungsmittel und Rezepte erstellen</a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a href="#daten">Perönliche Daten ändern</a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a href="#faq">Häufige Fragen (FAQ)</a>
          </TableOfContentsEntry>
          <TableOfContentsEntry>
            <a href="#feedback"> Feedback</a>
          </TableOfContentsEntry>
        </ul>
      </WhiteSection>

      <GraySection>
        <h2>
          <HeadlineAnchor id={'anamnese'} />
          Individuelle Empfehlungen dank Anamnese
        </h2>
        <p>
          Jeder Mensch ist einzigartig - aus diesem Grund setzen wir bei eBalance auf eine noch stärkere
          Personalisierung. Eine gründliche Anamnese ist der Schlüssel für nachhaltige Gewichtsreduktion. Wir haben
          unsere Anamnese um neueste wissenschaftliche Erkenntnisse und individuelle Faktoren ergänzt, um Ihr
          Kalorienziel noch präziser zu berechnen.
        </p>
      </GraySection>

      <WhiteSection>
        <h2>
          <HeadlineAnchor id={'tagebuch'} />
          Das eBalance-Tagebuch
        </h2>
        <p>
          Das eBalance-Tagebuch ist das Herzstück unseres Abnehmprogramms. Unser Algorithmus berechnet Ihre individuelle
          Kalorienbilanz auf Basis Ihrer zu sich genommenen Nahrungsmittel und ausgeführten Aktivitäten.
        </p>
        <p>
          Beim Öffnen des Tagebuches sehen Sie immer der aktuelle Kalendertag. Wenn Sie Mahlzeiten und Aktivitäten
          nachtragen möchten, wechseln Sie bitte das Datum zum gewünschten Tag.
        </p>
        <div className="mt-4">
          <div className="col-12">
            <div className="tour-wrapper">
              <div className="tour-marker" style={{ top: '-20px', left: '50%', transform: 'translateX(-50%)' }}></div>
              <div className="datepicker2-nav-wrapper mb-1">
                <div className="datepicker2-nav">
                  <a className="iconbutton">
                    <div className="icon icon-arrowleft-gray-2"></div>
                  </a>
                  <div className="datepicker2-nav-text">Heute, 30. Juni</div>
                  <a className="iconbutton">
                    <div className="icon icon-arrowright-gray-2"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <p>
            Mittels der <b>Suchfunktion</b> fügen Sie Nahrungsmittel, Rezepte oder Ihre täglichen Aktivitäten einfach
            und unkompliziert dem smarten Tagebuch hinzu.
          </p>
          <p>
            Sollte Ihnen der Energiegehalt für Mahlzeiten und Energieverbrauch für die Aktivitäten bekannt sein, ist in
            den beiden darunter liegenden Feldern ein <b>schneller Eintrag</b> der Kilokalorien auch ohne Suchfunktion
            möglich.
          </p>
          <p>
            Mit dem Klick auf das Herz-Icon öffnet sich Ihre persönliche <b>Favoritenliste</b>, mit der Sie
            Nahrungsmittel und Rezepte eintragen können.
          </p>
        </div>
        <div className="mt-4">
          <div className="tour-wrapper">
            <div className="row g-1">
              <div className="col-9">
                <div className="journalentrypane is-search">
                  <div className="icon icon-search-blue-2"></div>
                  <span className="text-bold">Mahlzeit oder Aktivität mit der Suche hinzufügen</span>
                </div>
              </div>
              <div className="col-3">
                <div className="journalentrypane is-favorite">
                  <Icon name={IconName.heart} color={IconColor.BLUE_2} />
                  <span>Favoriten</span>
                </div>
              </div>
              <div className="col-6">
                <div className="journalentrypane">
                  <div className="icon icon-utensils-gray-1"></div>
                  <span>Mahlzeit ohne Suche eintragen</span>
                </div>
              </div>
              <div className="col-6">
                <div className="journalentrypane">
                  <div className="icon icon-weight-gray-1"></div>
                  <span>Aktivität ohne Suche eintragen</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <p>
            In der <b>Tagebuch-Übersicht</b> haben Sie die Möglichkeit, den Eintrag über das gekennzeichnete Menü-Icon
            zu editieren, zu kopieren, zu verschieben oder zu entfernen.
          </p>
        </div>
        <div className="mt-4">
          <div style={{ paddingBottom: '12rem' }}>
            <div className="col-12 mb-4 container max-700">
              <div className="tour-wrapper">
                <div className="tour-marker" style={{ top: '-8px', right: '-14px' }}></div>
                <div className="standardcard">
                  <div className="standardcard-image">
                    <img src={buildCdnImageUrl('demo/salad-1.jpg')} />
                  </div>
                  <div className="standardcard-content">
                    Salat
                    <span>eBalance Rezept</span>
                    <span>300 kcal / 1 Portion (256 g)</span>
                  </div>
                  <div className="standardcard-icons">
                    <a className="iconbutton">
                      <div className="icon icon-menuv-gray-2"></div>
                    </a>
                    <ul className="standardcard-dropdown">
                      <li>
                        <a className="d-flex iconbutton">
                          <div className="icon icon-edit-gray-1"></div>
                          <span className="ml-1">Editieren</span>
                        </a>
                      </li>
                      <li>
                        <a className="d-flex iconbutton">
                          <div className="icon icon-copy-gray-1"></div>
                          <span className="ml-1">Kopieren</span>
                        </a>
                      </li>
                      <li>
                        <a className="d-flex iconbutton">
                          <div className="icon icon-move-gray-1"></div>
                          <span className="ml-1">Verschieben</span>
                        </a>
                      </li>
                      <li>
                        <a className="d-flex iconbutton">
                          <div className="icon icon-heart-gray-1"></div>
                          <span className="ml-1">Favorisieren</span>
                        </a>
                      </li>
                      <li>
                        <a className="d-flex iconbutton">
                          <div className="icon icon-trash-gray-1"></div>
                          <span className="ml-1">Löschen</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WhiteSection>

      <GraySection>
        <h2>
          <HeadlineAnchor id={'menueplan'} />
          Ihr individueller Menüplan
        </h2>
        <p>
          Sie ernähren sich vegetarisch, vegan oder omnivor? Wenn Sie Ihren individuellen Menüplan aktivieren, zeigen
          wir Ihnen täglich wechselnde Vorschläge direkt in Ihrem Tagebuch an.
        </p>

        <div className="mt-4">
          <div className="tour-wrapper">
            <div className="tour-marker" style={{ top: '-27px', left: '53%' }}></div>
            <div className="d-flex align-items-center justify-content-center">
              <div className="mr-2">Menüplan:</div>
              <label className="toggle">
                <input type="checkbox" />
                <span className="label-left">Nein</span>
                <span className="label-right">Ja</span>
              </label>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <p>
            Der auf Ihre Ernährungsform angepasste Menüplan ist im Tagebuch farblich gekennzeichnet. Wenn Sie den
            Vorschlag als gegessen markieren, werden die Kalorien in dem Tagebuch einkalkuliert.
          </p>
        </div>

        <div className="tour-wrapper mt-4 mb-6">
          <div className="tour-marker" style={{ top: '-8px', right: '14px' }}></div>
          <div className="standardcard is-menuplan">
            <div className="standardcard-image">
              <img src={buildCdnImageUrl('demo/salad-1.jpg')} />
              <div className="standardcard-label">Menüplan</div>
            </div>
            <div className="standardcard-content">
              Salat
              <span>eBalance Rezept</span>
              <span>300 kcal / 1 Portion (256 g)</span>
            </div>
            <div className="standardcard-icons">
              <div className="pt-1">
                <IoMdCheckmark size={'1.5rem'} color={'#E99508'} />
              </div>
              <a className="iconbutton">
                <div className="icon icon-menuv-gray-2"></div>
              </a>
              <ul className="standardcard-dropdown">
                <li>
                  <a className="d-flex iconbutton">
                    <div className="icon icon-edit-gray-1"></div>
                    <span className="ml-1">Editieren</span>
                  </a>
                </li>
                <li>
                  <a className="d-flex iconbutton">
                    <div className="icon icon-check-gray-1"></div>
                    <span className="ml-1">Als gegessen markieren</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </GraySection>

      <WhiteSection>
        <h2>
          <HeadlineAnchor id={'analyse'} />
          Die eBalance-Analyse
        </h2>
        <p>
          Unter dem Menüpunkt Analyse finden Sie Daten zu Ihrer Ernährung (Nährwerte) und Ihren Körpermassen (Masse).
        </p>

        <h3>
          <HeadlineAnchor id={'naehrwerte'} />
          Nährwerte
        </h3>
        <p>
          Auf der Nährwertseite finden Sie die gewohnte <b>Nährwertanalyse</b>, die Ihren Konsum von Makronährstoffen
          mit der Empfehlung der Ernährungsberater vergleicht. Sie können die Ansicht wechseln: zwischen einem Tag,
          einer Woche, zwei Wochen und einem Monat.
        </p>
        <p>
          In dem Diagramm darunter sehen Sie Ihre <b>Kalorienbilanz</b>. Auch in dieser Ansicht können Sie den
          dargestellten Zeitraum wechseln: eine Woche, ein Monat oder seit Programmstart. Die blaue Linie zeigt Ihr
          Kalorienziel.
        </p>
        <p>
          Im Diagramm <b>Mahlzeitenverteilung</b> können Sie sehen, wie sich Ihre Kalorien auf die täglichen Mahlzeiten
          verteilen. Auch diese Ansicht können Sie sich über verschiedene Zeiträume anzeigen lassen.
        </p>

        <h3>
          <HeadlineAnchor id={'masse'} />
          Masse
        </h3>
        <p>
          Auf der Seite Masse finden Sie alles zu Ihren Körpermassen. Oben befindet sich der <b>Gewichtstacho</b>, der
          anzeigt, wie viel Sie bereits von Ihrem Ziel geschafft haben.
        </p>

        <div className="gaugegoal my-3 mt-4">
          <img src={buildCdnImageUrl('demo/gaugegoal.png')} />
          <div className="gaugegoal-values">
            <div>Start: 100 kg</div>
            <div>Ziel: 90 kg</div>
          </div>
          <div className="gaugegoal-percent">30%</div>
        </div>
        <p>
          {' '}
          Daneben finden Sie die <b>Veränderung Ihres Bauchumfangs</b>, sobald Sie hier genug Werte eingetragen haben.
          Direkt unterhalb finden Sie jeweils die Eingabefelder für die beiden Werte.
        </p>
        <p>
          Im nächsten Diagramm sehen Sie den <b>Verlauf Ihres Gewichts</b> – oder, wenn Sie umschalten, den Verlauf
          Ihres BMI. Diese Ansicht können Sie sich auch über verschiedene Laufzeiten anzeigen lassen – seit der
          Anmeldung, seit dem Programmstart oder seit den letzten 30 Tagen.
        </p>
        <p>
          Darunter finden Sie Ihr gewohntes <b>Gewichtsprofil</b> und alle Daten noch einmal in einer Tabelle – samt den
          aktuellen Veränderungen.
        </p>
      </WhiteSection>

      <GraySection>
        <h2>
          <HeadlineAnchor id={'inspirieren'} />
          Lassen Sie sich inspirieren
        </h2>
        <p>
          Unter dem Menüpunkt Inspiration finden Sie alle <b>eBalance-Rezepte</b>. Mit der Suche können Sie die Auswahl
          noch verfeinern. Zudem finden Sie auf der Inspirationsseite Links zum Magazin und den{' '}
          <b>eBalance-Botschaftern</b>.
        </p>
      </GraySection>

      <WhiteSection>
        <h2>
          <HeadlineAnchor id={'eigene'} />
          Eigene Nahrungsmittel und Rezepte erstellen
        </h2>
        <p>
          Sie möchten Ihr Lieblingsrezept speichern oder ein Nahrungsmittel in Ihr Tagebuch eintragen, das Sie nicht in
          unserer umfangreichen Datenbank finden können? Unter dem Menüpunkt <b>Mein Essen</b> finden Sie die passenden
          Formulare, um eigene Nahrungsmittel und Rezepte zu erstellen. Füllen Sie alle Fragen aus und speichern Sie
          Ihre Nahrungsmittel oder Rezepte. Über die Suche im Menüpunkt Tagebuch können Sie diese anschliessend Ihrem
          Tagebuch hinzufügen.
        </p>
      </WhiteSection>

      <GraySection>
        <h2>
          <HeadlineAnchor id={'daten'} />
          Persönliche Daten ändern
        </h2>
        <p>
          Wenn Sie im Menü auf Ihren Namen gehen, haben Sie die Möglichkeit, Ihre persönlichen Daten zu bearbeiten. Dazu
          zählen Ihr Benutzer-Account und Passwort sowie Ihre E-Mail-Adresse und Kontaktdaten. Dort können Sie auch ein
          Bild von sich hochladen.
        </p>
        <p>
          Unter <b>Mein Programm</b> können Sie Ihre Körpergrösse und Ihr Geburtsdatum ändern. Sie können einen neuen
          Menüplan wählen oder einen Neustart des Abnehmprogramms durchführen.
        </p>
      </GraySection>

      <WhiteSection>
        <h2>
          <HeadlineAnchor id={'faq'} />
          Häufige Fragen (FAQ)
        </h2>
        <p>
          Auf unserer Fragen-und-Antworten-Seite finden Sie die häufigsten Anliegen unserer Mitglieder.{' '}
          <a href="https://ebalance.ch/hilfe-portal?utm_source=Portal &utm_medium=referral&utm_campaign=zurfaqseite">
            Hier
          </a>{' '}
          geht es zur Seite.
        </p>
      </WhiteSection>

      <GraySection>
        <h2>
          <HeadlineAnchor id={'feedback'} />
          Feedback
        </h2>
        <p>
          Sie haben Fragen oder Anregungen zur neuen Plattform? Sie würden etwas anders machen, oder es fehlt Ihnen
          etwas auf den aktuellen Seiten? Dann freuen wir uns sehr auf Ihr Feedback, das Sie uns über unsere&nbsp;
          <Link to={routes.contact}>Kontaktseite</Link> zukommen lassen können. Sie können uns auch per E-Mail an{' '}
          <a href="mailto:service@eBalance.ch">service@eBalance.ch</a> erreichen.
        </p>
        <p>Gestalten Sie mit uns Ihr ideales eBalance!</p>
      </GraySection>
    </PageLayout>
  )
}

export default HelpAndTourPage
