import React, { FC, useEffect } from 'react'

type ThemeType = 'smallcircle' | 'largecircle'

type Props = {
  theme?: ThemeType
}

export const PageLayout: FC<Props> = ({ children, theme }) => {
  const isSmallCircle = theme === 'smallcircle'
  const isLargeCircle = theme === 'largecircle'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main
      className={`
        ${isSmallCircle ? 'is-sm-circle' : ''}
        ${isLargeCircle ? 'is-xl-circle' : ''}
      `}
    >
      <div className="pagecircle"></div>
      {children}
    </main>
  )
}
