import { EbalanceBackendService } from './EbalanceBackendService'

import { Anamnesis, AnamnesisData, BodyAnalytics, BodyAnalyticsSection, Query } from 'Models'

export class AnamnesisService extends EbalanceBackendService {
  // anamnesis

  async addAnamnesis(data: AnamnesisData): Promise<Anamnesis> {
    const res = await this.client.post('anamnesis', data)
    return res.data
  }

  async updateAnamnesis(payload: Anamnesis): Promise<AnamnesisData> {
    const { data } = await this.client.put(`anamnesis/${payload.id}`, payload)
    return data
  }

  async fetchAnamnesisById(id: string): Promise<Anamnesis> {
    const res = await this.client.get(`anamnesis/${id}`)
    return res.data
  }

  // body analytics
  async fetchBodyAnalytics(query: BodyAnalytics): Promise<BodyAnalyticsSection[]> {
    const queryString = this.buildUrlQuery((query as unknown) as Query)
    const { data } = await this.client.get(`/body-analytics?${queryString}`)

    return data
  }
}
