import React, { FC, ReactNode } from 'react'

type ThemeType = 'invoice' | 'recipe' | 'nutrition' | 'data'

type Props = {
  rows: Array<ReactNode>[]
  titleRow?: Array<ReactNode>
  titleAsRow?: boolean
  theme?: ThemeType
}

export const Table: FC<Props> = ({ rows, titleRow, titleAsRow, theme }: Props) => {
  const tableTitle = titleRow && !titleAsRow ? titleRow : null
  const tableRows = [...(titleRow && titleAsRow ? [titleRow] : []), ...rows]

  const isInvoice = theme === 'invoice'
  const isRecipe = theme === 'recipe'
  const isNutrition = theme === 'nutrition'

  return (
    <table
      className={`
        table
        ${isInvoice ? 'is-invoice' : ''}
        ${isRecipe ? 'is-recipe' : ''}
        ${isNutrition ? 'is-nutrition' : ''}
      `}
    >
      {tableTitle ? (
        <thead>
          <tr>
            {tableTitle.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
      ) : null}
      <tbody>
        {tableRows.map((row, index) => (
          <tr key={index}>
            {row.map((column, index) => (
              <td key={index}>{column}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
