/*
  This is a controlled component. 
*/

import React, { FC } from 'react'

import { FormInputFoodAmount, FormInputFoodAmountProps } from 'Components/FormInputFoodAmount'
import { Amount, Food } from 'Models'
import { Utils } from 'Utils'

export type IngredientAmountInputProps = Pick<FormInputFoodAmountProps, 'amount' | 'onAmountChange'> & {
  formInputIngredientItem: FormInputIngredientsItem
  onRemoveClick?: () => void
}

export type FormInputIngredientsItem = {
  food: Omit<Food, 'hidden' | 'foodCriteria'>
  amount: Amount
}

export const IngredientAmountInput: FC<IngredientAmountInputProps> = ({
  formInputIngredientItem,
  onRemoveClick,
  ...amountInputProps
}) => {
  const availableUnits = Utils.availableUnitsForFood(formInputIngredientItem.food)
  return (
    <>
      <li>
        <div className="item-name">{formInputIngredientItem.food.name}</div>
        <FormInputFoodAmount {...amountInputProps} availableUnits={availableUnits} />
        <span className="icon-remove" onClick={onRemoveClick}></span>
      </li>
    </>
  )
}
