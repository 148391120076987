import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import yup from '../'

import { bodyMeasurementValueBoundary, bodyMeasurementsRangeSchema } from './common'
import { genderSchema } from './gender'

import { ProgramTypes } from 'Pages/AnamnesisPage/types'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const date18YearsAgo = dayjs().subtract(18, 'year').toDate()
const date120YearsAgo = dayjs().subtract(120, 'year').toDate()

export const programTypes = [ProgramTypes.LOSE_WEIGHT, ProgramTypes.KEEP_WEIGHT] as const

export const professionalMotionLevels = ['INACTIVE', 'VERY_LOW', 'LOW', 'MEDIUM', 'HIGH'] as const
export type ProfessionalMotionLevel = typeof professionalMotionLevels[number]

export const dietTypes = ['OMNIVOR', 'NO_MEAT', 'NO_FISH', 'VEGETARIAN', 'VEGAN'] as const
export type Diet = typeof dietTypes[number]

const heightRestrictions = {
  min: 100,
  max: 250
}

const heighRestrictionErrorText = `Ihre Eingabe entspricht nicht den Vorgaben. Ihre Körpergrösse muss zwischen ${heightRestrictions.min} und ${heightRestrictions.max} cm liegen.`

export const anamnesisSchema = yup
  .object({
    birthday: yup
      .date()
      .required()
      .test('minimumAge', 'Ihre Eingabe entspricht nicht den Vorgaben. Sie müssen über 18 Jahre sein.', (value) =>
        dayjs(value as Date).isSameOrBefore(date18YearsAgo, 'day')
      )
      .test('maximumAge', 'Ihre Eingabe entspricht nicht den Vorgaben. Sie müssen unter 120 Jahre sein.', (value) =>
        dayjs(value as Date).isSameOrAfter(date120YearsAgo, 'day')
      ),

    /** weight at the beginning of the program, given in kilogram */
    currentWeight: bodyMeasurementsRangeSchema
      .min(
        bodyMeasurementValueBoundary.min,
        `Ihre Eingabe entspricht nicht den Vorgaben. Ihr Gewicht muss grösser ${bodyMeasurementValueBoundary.min} kg sein.`
      )
      .max(
        bodyMeasurementValueBoundary.max,
        `hre Eingabe entspricht nicht den Vorgaben. Ihr Gewicht muss kleiner ${bodyMeasurementValueBoundary.max} kg sein.`
      )
      .required(),

    /** height in cm  */
    height: yup
      .number()
      .min(heightRestrictions.min, heighRestrictionErrorText)
      .max(heightRestrictions.max, heighRestrictionErrorText)
      .required(),

    /** belly circumference at the beginning of the program, in cm */
    bellyCircumference: bodyMeasurementsRangeSchema,

    /** the amount of weight the user wants to lose given in kilogram */
    weightToLose: yup
      .number()
      .test(
        'targetWeight',
        `Ihre Eingabe entspricht nicht den Vorgaben. Ihr Gewicht muss grösser ${bodyMeasurementValueBoundary.min} kg sein.`,
        function (weightToLose) {
          return this.parent.currentWeight - (weightToLose as number) >= bodyMeasurementValueBoundary.min
        }
      ),

    /** number of working hours per week */
    workingHours: yup.number().min(0).max(41).required(),

    /** Classification for the amount motion the user performs during work time. */
    professionalMotionLevel: yup.string().oneOf(professionalMotionLevels).required(),

    /** Number of previous failed attempts to lose weight */
    previousFailureCount: yup.number().min(0).required(),

    /** Defines if the parents were or are currently overweight */
    parentsOverweight: yup.boolean().required(),

    diet: yup.string().oneOf(dietTypes).required(),

    longTermMotivated: yup.boolean().required(),

    overweightForTwoYears: yup.boolean().required(),

    energyLevelName: yup
      .string()
      .oneOf(['LOW', 'MEDIUM', 'HIGH'] as const)
      .required(),

    gender: genderSchema.required(),

    activeProgramType: yup.string().oneOf(programTypes).required()
  })
  .required()
