import React, { useEffect, useState } from 'react'

const IsoClock: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(() => new Date())

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentTime(new Date())
    }, 1000)
    return () => clearTimeout(timer)
  })

  return <span>{currentTime.toISOString()}</span>
}

export default IsoClock
