import React from 'react'

import { SelectItemRenderer, SingleSelectProps, defaultKeyExtractor } from '../common/SelectPickerProps'
import { useSingleSelect } from '../common/useSingleSelect'

import { UnitId } from 'Models'
import { Formatter } from 'Utils'

type Props = SingleSelectProps<UnitId>

const defaultLabelExtractor: SelectItemRenderer<UnitId, string> = (item) => {
  return Formatter.formatUnit(item) as string
}

export const FormInputUnitpicker: React.FC<Props> = ({
  items,
  onChange,
  selectedKey,
  keyExtractor = defaultKeyExtractor,
  labelExtractor = defaultLabelExtractor
}: Props) => {
  const handleSelectChange = useSingleSelect(items, keyExtractor, onChange)

  return (
    <select className="unitpicker text-center" value={selectedKey} onChange={handleSelectChange}>
      {items.map((current) => {
        const key = keyExtractor(current)
        const isSelected = key === selectedKey

        return (
          <option key={key} value={key}>
            {labelExtractor(current, isSelected)}
          </option>
        )
      })}
    </select>
  )
}
